import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import axios from 'axios';
import Button from 'common/components/buttons/button';
import {
  InputLabelOutlined,
  MaterialCheckbox,
  SelectLabel,
} from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { format, parseISO } from 'date-fns';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import {
  cepMask,
  cpfMask,
  dateMask,
  dateReducedMask,
  phoneMask,
} from 'helpers/masks';
import states from 'helpers/states.json';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect, useState } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { appColors } from 'styles/color';
import { getDetail, purchasePlan, showConfirmation } from './plansActions';

function PlansPurchaseForm(props) {
  const { action, id } = useParams();
  const { showConfirmation, formValues } = props;

  const { loading, detail } = props.plans;
  const { subscription } = props.auth.user;
  const {
    name,
    amount,
    days,
    // trial_days,
    discount,
  } = detail;

  const [willChangePaymentMethod, setWillChangePaymentMethod] = useState(false);
  const [finalAmountLabel, setFinalAmountLabel] = useState('Carregando...');
  const [finalAmount, setFinalAmount] = useState(0);
  const [daysLabel, setDaysLabel] = useState('');
  const [statesSelectList, setStatesSelectList] = useState(states);
  const [citiesSelectList, setCitiesSelectList] = useState([]);

  useEffect(() => {
    props.getDetail(id);
  }, [id, props]);

  useEffect(() => {
    return () => {
      showConfirmation(false);
    };
  }, [showConfirmation]);

  useEffect(() => {
    const formattedStatesList = states.map((item) => ({
      value: item.sigla,
      label: item.nome,
    }));

    setStatesSelectList(formattedStatesList);
  }, []);

  useEffect(() => {
    if (amount) {
      const tempFinalAmount = parseFloat(amount) - parseFloat(discount);
      setFinalAmount(tempFinalAmount);
      setFinalAmountLabel(tempFinalAmount.toFixed(2).replace('.', ','));
    }
  }, [amount, discount]);

  useEffect(() => {
    if (days) {
      switch (days) {
        case 30:
          return setDaysLabel('mês');
        case 183:
          return setDaysLabel('semestre');
        case 365:
          return setDaysLabel('ano');
        default:
          return setDaysLabel('erro periodicidade');
      }
    }
  }, [days]);

  useEffect(() => {
    if (formValues?.state) {
      async function getCitiesData() {
        const cities = await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${formValues.state}/municipios`
        );
        setCitiesSelectList(
          cities.data.map((item) => ({ value: item.nome, label: item.nome }))
        );
      }
      getCitiesData();
    }
  }, [formValues]);

  function onSubmit(values) {
    const newValues = {
      ...values,
      plan_id: id,
    };

    props.purchasePlan(
      newValues,
      action === 'comprar' ? 'post' : 'put',
      action === 'comprar' ? null : subscription.internal_id
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  return (
    <section id="plans-purchase" style={{ marginTop: 64, minHeight: '100vh' }}>
      {props.plans.showConfirmation &&
      props.auth.user.subscription.transaction.date_created ? (
        <Box display="flex" justifyContent="center">
          <Box maxWidth={1200}>
            <Box display="flex" justifyContent="space-between" mb={5}>
              <Box display="flex-column">
                <p className="title mb-2">Pedido realizado com sucesso!</p>
                <p className="h3-bold">
                  {format(
                    parseISO(
                      props.auth.user.subscription.transaction.date_created
                    ),
                    "dd/MM/yyyy 'às' HH:mm"
                  )}
                </p>
              </Box>
              {/* <p>qr code</p> */}
            </Box>
            <p className="h3 mb-5">
              ID do pedido #{props.auth.user.subscription.transaction.id}
            </p>
            <p className="h3 mb-5" style={{ maxWidth: 400 }}>
              Texto explicando que depois de 30 dias vai cobrar tantos por mês a
              partir da data{' '}
              {format(
                parseISO(props.auth.user.subscription.current_period_end),
                'dd/MM/yyyy'
              )}
            </p>
            <p className="h3-bold mb-3">
              Plano <span style={{ color: appColors.v2.blue }}>{name}</span>
            </p>
            <Box
              display="flex"
              justifyContent="space-between"
              mt={8}
              alignItems="center"
            >
              <Box>
                <p className="h3-bold" style={{ textTransform: 'capitalize' }}>
                  Cartão {props.auth.user.subscription.card.brand}
                </p>
                <p className="h4">
                  ****** **** {props.auth.user.subscription.card.last_digits}
                </p>
              </Box>

              <p className="title" style={{ color: appColors.v2.blue }}>
                R$ {finalAmountLabel}
                <span className="h3" style={{ color: appColors.v2.mediumGray }}>
                  /{daysLabel}
                </span>
              </p>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            variant="h4"
            className="h3-bold text-center"
            style={{ marginBottom: 48 }}
          >
            {action === 'comprar'
              ? ' Você está prestes a fazer a assinatura do plano'
              : 'Você está prestar a atualizar sua assinatura para o plano'}{' '}
            <span className="h3-bold" style={{ color: appColors.v2.blue }}>
              {name}
            </span>
          </Typography>
          <Form
            role="form"
            onSubmit={props.handleSubmit(onSubmit)}
            noValidate
            style={{ marginBottom: 48 }}
          >
            {action === 'mudar' && finalAmount > 0 && (
              <MaterialGrid container spacing={3} justifyContent="center">
                <MaterialGrid item xs={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginTop: 12 }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e) =>
                            setWillChangePaymentMethod(e.target.checked)
                          }
                          checked={willChangePaymentMethod}
                        />
                      }
                      label="Desejo alterar a forma de pagamento"
                    />
                  </Box>
                </MaterialGrid>
              </MaterialGrid>
            )}
            {(action === 'comprar' && finalAmount > 0) ||
            (action === 'mudar' && willChangePaymentMethod) ? (
              <MaterialGrid container spacing={3} justifyContent="center">
                <MaterialGrid item xs={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginTop: 12 }}
                  >
                    <Field
                      component={MaterialCheckbox}
                      label="Deseja salvar os dados desse cartão para compras futuras?"
                      name={`save`}
                    />
                  </Box>
                </MaterialGrid>
              </MaterialGrid>
            ) : null}
            <MaterialGrid container spacing={3} justifyContent="center">
              {(action === 'comprar' && finalAmount > 0) ||
              (action === 'mudar' && willChangePaymentMethod) ? (
                <MaterialGrid item md={12} lg={6}>
                  <Box>
                    <p
                      className="h4-bold"
                      style={{ color: appColors.v2.black, marginBottom: 8 }}
                    >
                      Dados do cartão
                    </p>
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Número do cartão"
                      type="tel"
                      name={`card_number`}
                      normalize={handleWhiteSpaces}
                      inputProps={{
                        maxLength: 16,
                      }}
                      validate={[FORM_RULES.required, FORM_RULES.card_number]}
                    />
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome escrito no cartão"
                      type="text"
                      name={`card_holder_name`}
                      validate={[FORM_RULES.required]}
                    />
                  </Box>
                  {action === 'comprar' && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      style={{ marginTop: 12 }}
                    >
                      <Box flex={1}>
                        <Field
                          component={InputLabelOutlined}
                          label="CPF"
                          type="text"
                          name={`cpf`}
                          validate={[FORM_RULES.required, FORM_RULES.cpf]}
                          {...cpfMask}
                        />
                      </Box>

                      <Box flex={1} ml={1}>
                        <Field
                          component={InputLabelOutlined}
                          label="Data de Nascimento"
                          type="tel"
                          name="birthday"
                          validate={[FORM_RULES.required]}
                          {...dateMask}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ marginTop: 12 }}
                  >
                    <Box style={{ marginRight: 6, flex: 1 }}>
                      <Field
                        component={InputLabelOutlined}
                        label="Expiração"
                        type="tel"
                        name={`card_expiration_date`} /*
                    inputProps={{
                      maxLength: 4,
                    }} */
                        validate={[
                          FORM_RULES.required,
                          FORM_RULES.expiry,
                          FORM_RULES.max(4),
                        ]}
                        {...dateReducedMask()}
                      />
                    </Box>
                    <Box style={{ marginLeft: 6, flex: 1 }}>
                      <Field
                        component={InputLabelOutlined}
                        label="CVC"
                        type="tel"
                        name={`card_cvv`}
                        inputProps={{
                          maxLength: 3,
                        }}
                        validate={[
                          FORM_RULES.required,
                          FORM_RULES.cvc,
                          FORM_RULES.max(3),
                        ]}
                      />
                    </Box>
                  </Box>
                </MaterialGrid>
              ) : null}
              {action === 'comprar' && finalAmount > 0 ? (
                <MaterialGrid
                  item
                  md={12}
                  lg={6}
                  style={{ padding: '12px 0px 12px 0px' }}
                >
                  <Box>
                    <p
                      className="h4-bold"
                      style={{ color: appColors.v2.black, marginBottom: 8 }}
                    >
                      Endereço de cobrança
                    </p>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ marginTop: 12 }}
                  >
                    <Box flex={1}>
                      <Field
                        component={InputLabelOutlined}
                        label="Telefone"
                        type="tel"
                        inputlabelprops={{
                          shrink: true,
                        }}
                        name="phone"
                        validate={[FORM_RULES.required]}
                        {...phoneMask}
                      />
                    </Box>

                    <Box ml={1} flex={1}>
                      <Field
                        component={InputLabelOutlined}
                        label="CEP"
                        type="tel"
                        name={`zipcode`}
                        validate={[FORM_RULES.required]}
                        {...cepMask}
                      />
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Logradouro"
                      type="text"
                      name={`street`}
                      validate={[FORM_RULES.required]}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ marginTop: 12 }}
                  >
                    <Box flex={1}>
                      <Field
                        component={InputLabelOutlined}
                        label="Número"
                        type="tel"
                        name={`street_number`}
                        validate={[FORM_RULES.required]}
                      />
                    </Box>
                    <Box flex={1} ml={1}>
                      <Field
                        component={InputLabelOutlined}
                        label="Bairro"
                        name={`neighborhood`}
                        validate={[FORM_RULES.required]}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ marginTop: 12 }}
                  >
                    <Box flex="1">
                      <Field
                        component={SelectLabel}
                        options={statesSelectList}
                        label="Estado"
                        type="text"
                        name={`state`}
                        validate={[FORM_RULES.required]}
                      />
                    </Box>
                    <Box flex="1" ml={1}>
                      <Field
                        component={SelectLabel}
                        options={citiesSelectList}
                        label="Cidade"
                        type="text"
                        name={`city`}
                        validate={[FORM_RULES.required]}
                      />
                    </Box>
                  </Box>
                </MaterialGrid>
              ) : null}
            </MaterialGrid>
            {finalAmount > 0 ? (
              <Box display="flex" justifyContent="center" mt={3}>
                <p
                  className="h4 text-right"
                  style={{ maxWidth: 600, marginBottom: 48, width: '100%' }}
                >
                  Total da compra{' '}
                  <span className="h2-bold" style={{ marginLeft: 24 }}>
                    R$ {finalAmountLabel}/{daysLabel}
                  </span>
                </p>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" mt={3}>
                <p
                  className="h4 text-center"
                  style={{ maxWidth: 600, marginBottom: 48, width: '100%' }}
                >
                  Total da compra{' '}
                  <span className="h2-bold" style={{ marginLeft: 24 }}>
                    R$ {finalAmountLabel}
                  </span>
                </p>
              </Box>
            )}

            {finalAmount > 0 ? (
              <Box display="flex" justifyContent="center">
                <p
                  className="h5"
                  style={{
                    color: appColors.v2.gray,
                    maxWidth: 600,
                    marginBottom: 48,
                  }}
                >
                  Você será cobrado automaticamente todo {daysLabel} o valor de
                  R$ {finalAmountLabel}. Você pode cancelar o plano a qualquer
                  momento sem multa ou penalidade em 'Configurações'.
                </p>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center">
                <p
                  className="h5"
                  style={{
                    color: appColors.v2.gray,
                    maxWidth: 600,
                    marginBottom: 48,
                  }}
                >
                  Você não será cobrado nenhum valor.
                </p>
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              <Button
                label={finalAmount > 0 ? 'Realizar pagamento' : 'Continuar'}
                buttonStyle={{ marginRight: 16 }}
                buttonType="submit"
                progress={parseInt(props.general.submitProgress)}
              />
            </Box>
          </Form>
        </Box>
      )}
    </section>
  );
}

const PlansPurchaseReduxForm = reduxForm({
  form: 'plansPurchaseForm',
  destroyOnUnmount: true,
})(PlansPurchaseForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plans: state.plans,
    general: state.general,
    formValues: getFormValues('plansPurchaseForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getDetail, purchasePlan, showConfirmation },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansPurchaseReduxForm);
