/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import {
  InputFile,
  InputLabelOutlined,
  SelectLabel,
} from 'common/components/fields/fields';
import { COMPANY_STATION_ID, SINDILEGIS_STATION_ID } from 'config/consts';
import { dateMask } from 'helpers/masks';
import states from 'helpers/states.json';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, initialize, reduxForm } from 'redux-form';
import {
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
} from '../../../config/consts';
import { fileRejected, updateProfile } from '../../../pages/auth/authActions';
import { ButtonSecondary } from '../buttons/ButtonSecondary';

states.sort(function (a, b) {
  if (a.sigla < b.sigla) {
    return -1;
  }
  if (a.sigla > b.sigla) {
    return 1;
  }
  return 0;
});

const setInfoUserValues = (values) => {
  return (dispatch) => {
    dispatch(initialize('PerfilForms', values));
  };
};

let PerfilForms = function (props) {
  function onSubmit(values) {
    const values_prep = {
      name: values.name,
      state: values.state,
      birthday: values.birthday,
    };
    if (!_.isUndefined(values.image.base64)) {
      values_prep.imageType = imgToImgType(values.image);
      values_prep.base64 = imgToBase64(values.image);
    }
    return props.updateProfile(values_prep, props.auth.user.id);
  }

  useEffect(() => {
    let values = {
      name: props.auth.user.name,
      birthday: moment(props.auth.user.birthday).format('DD/MM/YYYY'),
      state: props.auth.user.addresses[0].state,
    };
    if (!_.isNull(props.auth.user.image)) {
      values.image = props.auth.user.image;
    }

    props.setInfoUserValues(values);
  }, [props.auth.user]);

  const statesList = states.map((item, index) => ({
    value: item.sigla,
    label: item.nome,
  }));

  return (
    <section
      style={{
        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
        border: `2px solid ${GENERAL_TEXT_COLOR}`,
      }}
    >
      <CardContent>
        <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
          <Field
            component={InputFile}
            name="image"
            validate={[FORM_RULES.required]}
            margin="normal"
            cols="12"
            onDropRejected={props.fileRejected}
          />
          <br />
          <Box display="flex" justifyContent="center">
            <Typography variant="body2" component="p">
              Clique acima para alterar sua foto de perfil
            </Typography>
          </Box>
          <br />
          <Field
            component={InputLabelOutlined}
            label="Nome"
            name="name"
            validate={[FORM_RULES.required]}
            helperText="Modifique para alterar seu nome"
            disabled={COMPANY_STATION_ID === SINDILEGIS_STATION_ID}
          />
          <br />
          <Field
            component={SelectLabel}
            type="text"
            label={`Estado de residência`}
            name={`state`}
            margin="normal"
            options={statesList}
            validate={[FORM_RULES.required]}
            disabled={COMPANY_STATION_ID === SINDILEGIS_STATION_ID}
          />
          <br />
          <Field
            component={InputLabelOutlined}
            label="Data de Nascimento"
            type="tel"
            name="birthday"
            validate={[FORM_RULES.required]}
            {...dateMask}
            disabled={COMPANY_STATION_ID === SINDILEGIS_STATION_ID}
          />
          <br />
          <br />
          {COMPANY_STATION_ID !== SINDILEGIS_STATION_ID && (
            <Box display="flex" justifyContent="flex-end">
              <ButtonSecondary
                type="submit"
                size="small"
                disabled={props.auth.buttonLoading}
              >
                Salvar
              </ButtonSecondary>
            </Box>
          )}
        </Form>
      </CardContent>
    </section>
  );
};

PerfilForms = reduxForm({ form: 'PerfilForms' })(PerfilForms);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateProfile,
      setInfoUserValues,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PerfilForms);
