import { Box, Icon, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { SIGNED_IN_NAV_TEXT_COLOR } from 'config/consts';
import React, { useState, useEffect } from 'react';
import './carousel.css';

export default function Carousel(props) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (props.children.length <= props.itemsDisplay) {
      setIndex(0);
    } else {
      if (props.indexStart <= props.children.length - props.itemsDisplay) {
        setIndex(props.indexStart);
      } else {
        setIndex(props.children.length - props.itemsDisplay);
      }
    }
  }, [props.children.length, props.indexStart, props.itemsDisplay]);

  const handleForwards = (index) => {
    if (index < props.children.length - props.itemsDisplay) {
      setIndex(index + 1);
    } else {
      return null;
    }
  };
  const handleBackwards = (index) => {
    if (index > 0) {
      setIndex(index - 1);
    } else {
      return null;
    }
  };
  return (
    <MaterialGrid container style={{ marginLeft: -26, marginRight: -26, marginBottom: -26,  }}>
      {props.journey || props.item ? (
        <Typography
          variant="h5"
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '29px',
            letterSpacing: '0.25px',
            width: '100%',
          }}
        >
          {props.categoryName ? props.categoryName : ''}
        </Typography>
      ) : (
        <div className="d-flex justify-content-between w-100">
          <Typography
            variant="h5"
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '1.0em',
              lineHeight: '29px',
              letterSpacing: '-0.15px',
              width: '100%',
            }}
          >
            {props.categoryName ? props.categoryName : ''}
          </Typography>
          {props.children.length > 1 ? (

<Box
  display="flex"
  justifyContent="flex-end" // Align content to the right
  alignItems="center" // Vertically align the text and icon
  style={{ cursor: 'pointer', width: '100%' }} // Make the whole container clickable and take full width
  onClick={props.onClickCategory} // Handle click event
>
  <Typography
    variant="subtitle1"
    style={{
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '0.8em',
      lineHeight: '29px',
      letterSpacing: '-0.25px',
    }}
  >
    Veja mais
  </Typography>
  <ArrowForwardIosIcon
    style={{
      fontSize: '0.8em', // Adjust icon size
      marginLeft: '4px', // Add space between text and arrow
    }}
  />
</Box>


          ) : null}
        </div>
      )}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: 12,
          width: '100%',
          marginLeft: -24,
        }}
      >
        {props.children.length > props.itemsDisplay && index > 0 ? (
          <Icon
            onClick={() => handleBackwards(index)}
            style={{
              marginRight: 'auto',
              height: 'auto',
              cursor: 'pointer',
              color: SIGNED_IN_NAV_TEXT_COLOR,
              
            }}
          >
            <ArrowBackIosIcon />
          </Icon>
        ) : (
          <div style={{ width: 26, height: 34 }}></div>
        )}
        <Box
          display="flex"
          alignItems="center"
          style={{ width: '100%', overflow: 'hidden' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
<MaterialGrid container spacing={2}>
  {props.children
    .slice(0, window.innerWidth < 960 ? 1 : 3) // 1 card for mobile, 3 for desktop
    .map((child, idx) => (
      <MaterialGrid key={idx} item xs={12} md={4}> {/* 1 card on mobile, 3 cards on desktop */}
        {child}
      </MaterialGrid>
    ))}
</MaterialGrid>
          </Box>
        </Box>
        <div
          style={{
            marginRight: -48,
          }}
        >
          {props.children.length > props.itemsDisplay &&
          index < props.children.length - props.itemsDisplay ? (
            <Icon
              onClick={() => handleForwards(index)}
              style={{
                marginLeft: 'auto',
                height: 'auto',
                cursor: 'pointer',
                color: 'transparent',
              }}
            >
              <ArrowForwardIosIcon />
            </Icon>
          ) : (
            <div style={{ width: 26, height: 34 }}></div>
          )}
        </div>
      </Box>
    </MaterialGrid>
  );
}
