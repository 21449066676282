import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { destroy } from 'redux-form';
import type from './types';
import { COMPANY_STATION_ID } from '../../../../config/consts';

const URL_ACTIONS_BY_STATION_ID = `${BASE_API}/actions/?where[station_id]=`;
const URL_ACTIONS_BY_ID = `${BASE_API}/actions`;
const URL_PUBLIC_ACTIONS_BY_ID = `${BASE_API}/public-actions`;
const URL_SUBSCRIBE = `${BASE_API}/subscribe-to-presential-course`;
const URL_BUY_CUPOM = `${BASE_API}/user-buys-cupom`;
const URL_ANSWER = `${BASE_API}/answer`;
const URL_ANSWER_QUIZ = `${BASE_API}/answer-quiz`;
const URL_ACTIVITIES_BY_NAME = `${BASE_API}/actions-by-name`;
const URL_SEND_EMAILS = `${BASE_API}/send-html-to-mails-mgm`;
const URL_SEND_EMAILS_PERMISSIONS = `${BASE_API}/send-html-to-mails-mgp`;
const URL_LOTTIE = `${BASE_API}/lottie-files`;
const URL_SHARE_VALIDATION = `${BASE_API}/validate-sharing`;
const URL_REQUEST_PRODUCT_ORDER = `${BASE_API}/product-order`;
const URL_AGREEMENT = `${BASE_API}/agreement`;
const URL_DONATION = `${BASE_API}/user-donates`;
const URL_RAW_CONTENT = `${BASE_API}/raw-content-participation`;
const URL_VIDEO_PARTICIPATION = `${BASE_API}/video-playlist-participation`;
const URL_VIDEO_YT_PARTICIPATION = `${BASE_API}/video-youtube-participation`;
const URL_CHECK_VIDEO = `${BASE_API}/videos/check-videos`;
const URL_POSTS = `${BASE_API}/comments-action`;
const URL_LIKES_AND_DISLIKES = `${BASE_API}/likes-action`;

export const ACTIVITY_POST_CREATE_LOAD = 'ACTIVITY_POST_CREATE_LOAD';
export const GET_ALL_ACTION_COMMENTS_LOAD = 'GET_ALL_ACTION_COMMENTS_LOAD';
export const ALL_COMMENTS_FETCHED = 'ALL_COMMENTS_FETCHED';
export const ALL_COMMENTS_DATA = 'ALL_COMMENTS_DATA';
export const DELETE_ACTION_COMMENT_LOAD = 'DELETE_ACTION_COMMENT_LOAD';
export const SEND_LIKE_LOADING = 'SEND_LIKE_LOADING';
export const SEND_DISLIKE_LOADING = 'SEND_DISLIKE_LOADING';
export const GET_ACTIVITY_LIKES_LOADING = 'GET_ACTIVITY_LIKES_LOADING';
export const GET_ACTIVITY_LIKES = 'GET_ACTIVITY_LIKES';

export const getPrivateFeaturedActivities = (id, user) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'FEATURED_PRIVATE_ACTIVITIES_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(
        `${URL_ACTIONS_BY_ID}/?where[featuredPrivate]=1&&where[station_id]=${id}&&where[user_id]=${user}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'FEATURED_PRIVATE_ACTIVITIES_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const buyCoupon = (
  values,
  history = undefined,
  cleanupFunction = undefined
) => {
  return (dispatch) => {
    axios
      .post(`${URL_BUY_CUPOM}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch(setFeedbackModal(true));
        dispatch({
          type: 'DATA_CODE',
          payload: response.data.UserBoughtCupom.code,
        });
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const donate = (values, history = undefined, getUserPoints, user) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);
    axios
      .post(`${URL_DONATION}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        /* toastr.success('Sucesso', 'Obrigado por participar da pesquisa.'); */
        dispatch([
          getUserPoints(user),
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },

          setFeedbackModal(true),
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        try {
          if (e.response.data.length > 0) {
            for (let i of e.response.data) {
              toastr.error(i, 'Erro');
            }
          }
        } catch {
          toastr.error('Erro ');
        }
      });
  };
};

export const agree = (values, history = undefined) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
    ]);
    axios
      .post(`${URL_AGREEMENT}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(() => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
        ]);
        history.push('/homepage');
      })
      .catch((e) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
        ]);
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const getActionsByStationId = (id, user) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_ACTIONS_BY_STATION_ID}${id}&&?where[user_id]=${user}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
        dispatch([
          {
            type: type.LIST_LOAD,
            payload: false,
          },
        ]);
      });
  };
};

export const getActionById = (user, id) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
      {
        type: 'LOTTIE_DETAIL',
        payload: {},
      },
    ]);
    axios
      .get(`${URL_ACTIONS_BY_ID}/${id}/?where[user_id]=${user}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: response.data,
          },
          {
            type: type.LIST_LOAD,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data.messages) {
            toastr.error('Erro', e.response.data.messages[0]);
          }
          if (e.response.data.error) {
            toastr.error('Erro', e.response.data.error);
          }
        } catch {
          toastr.error('Erro');
        }
        dispatch([
          {
            type: type.LIST_LOAD,
            payload: false,
          },
        ]);
      });
  };
};

export const getPublicActionById = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_PUBLIC_ACTIONS_BY_ID}/${id}/${COMPANY_STATION_ID}`)
      .then((response) => {
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: response.data,
          },
          {
            type: type.LIST_LOAD,
            payload: false,
          },
          changePublicActionsModal(true),
        ]);
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
        dispatch([
          {
            type: type.LIST_LOAD,
            payload: false,
          },
        ]);
      });
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
      {
        type: type.ITEM_DETAIL,
        payload: values,
      },
    ]);
  };
};

export const setLoading = (value) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: value,
      },
    ]);
  };
};

export const subscribe = (values, user) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);
    axios
      .post(
        `${URL_SUBSCRIBE}`,
        {
          ...values,
          user,
        },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )} %`,
              },
            ]),
        }
      )
      .then((response) => {
        toastr.success('Sucesso');
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'DATA_CODE',
            payload: response.data.code,
          },
          {
            type: 'DATA_CREATED_AT',
            payload: response.data.createdAt,
          },

          {
            type: type.SUBSCRIBED,
            payload: true,
          },
        ]);
      })
      .catch((e) => {
        try {
          toastr.error('Erro', e.response.data.error);
        } catch {
          toastr.error('Erro Load');
        }
      });
  };
};

export const setSubscribed = (value) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.SUBSCRIBED,
        payload: value,
      },
    ]);
  };
};

export const submitSurvey = (
  values,
  history = undefined,
  callback = undefined
) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);
    axios
      .post(`${values.type_id === 6 ? URL_ANSWER_QUIZ : URL_ANSWER}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        /* toastr.success('Sucesso', 'Obrigado por participar da pesquisa.'); */
        const passed =
          response.data.correct_answer_percentage >= 0.5 ? true : false;
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'QUIZ_MODAL',
            payload: true,
          },
          {
            type: 'QUIZ_MODAL_DID_WIN',
            payload: passed,
          },
          setFeedbackModal(true),
        ]);
        if (!_.isUndefined(callback)) {
          callback();
        }
      })
      .catch((e) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'QUIZ_MODAL',
            payload: true,
          },
          {
            type: 'QUIZ_MODAL_DID_WIN',
            payload: false,
          },
          setFeedbackModal(true),
        ]);
        try {
          toastr.error(e.response.data, 'Erro');
        } catch {
          toastr.error('Erro ');
        }
      });
  };
};

export const submitCheckListSurvey = (
  values,
  history = undefined,
  callback = undefined
) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);
    axios
      .post(`${URL_ANSWER}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          setFeedbackQuizModal(true),
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          setFeedbackQuizModal(true),
        ]);
        try {
          toastr.error(e.response.data, 'Erro');
        } catch {
          toastr.error('Erro ');
        }
      });
  };
};
export const submitEmailsPermission = (
  sender_name,
  values,
  station_name,
  points,
  history = undefined,
  station_id,
  actionId
) => {
  const array_values = [];
  for (let props in values) {
    if (props !== 'emails') {
      array_values.push(values[props]);
    }
  }
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);
    axios
      .post(
        `${URL_SEND_EMAILS_PERMISSIONS}`,
        {
          sender_name: sender_name,
          emails: array_values,
          station_name: station_name,
          points: points,
          station_id: station_id,
          action_id: actionId,
        },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )} %`,
              },
            ]),
        }
      )
      .then((response) => {
        toastr.success('Sucesso', 'Obrigado por compartilhar.');
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        if (!_.isUndefined(history)) {
          history.push('/homepage');
        }
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro ');
        }
      });
  };
};
export const submitEmails = (
  sender_name,
  values,
  link,
  station_name,
  points,
  history = undefined,
  station_id
) => {
  const array_values = [];
  for (let props in values) {
    if (props !== 'emails') {
      array_values.push(values[props]);
    }
  }
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);
    axios
      .post(
        `${URL_SEND_EMAILS}`,
        {
          sender_name: sender_name,
          emails: array_values,
          link: link,
          station_name: station_name,
          points: points,
          station_id: station_id,
        },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )} %`,
              },
            ]),
        }
      )
      .then((response) => {
        toastr.success('Sucesso', 'Obrigado por compartilhar.');
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        if (!_.isUndefined(history)) {
          history.push('/homepage');
        }
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro ');
        }
      });
  };
};

export const getActivitiesByName = (name, id, stationId) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'ACTIVITIES_BY_NAME_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(
        `${URL_ACTIVITIES_BY_NAME}/?where[user_id]=${id}&&where[name]=${name}&&where[station_id]=${stationId}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'ACTIVITIES_BY_NAME_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const changePublicActionsModal = (boolean) => {
  return (dispatch) => {
    dispatch({
      type: 'PUBLIC_ACTIONS_MODAL',
      payload: boolean,
    });
  };
};

export const setFeedbackModal = (boolean) => {
  return (dispatch) => {
    dispatch({
      type: 'FEEDBACK_MODAL',
      payload: boolean,
    });
  };
};

export const setFeedbackQuizModal = (boolean) => {
  return (dispatch) => {
    dispatch({
      type: 'FEEDBACK_QUIZ_MODAL',
      payload: boolean,
    });
  };
};

export const setQuizResultsModal = (isOpen) => {
  return (dispatch) => {
    dispatch({
      type: 'QUIZ_MODAL',
      payload: isOpen,
    });
  };
};

export const getLottieById = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'LOTTIE_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_LOTTIE}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const initialValues = {
          ...response.data,
          json: JSON.parse(response.data.json),
        };
        dispatch([
          {
            type: 'LOTTIE_DETAIL',
            payload: initialValues,
          },
        ]);
      });
  };
};

export function short_url(long_url) {
  return (dispatch) => {
    axios
      .post(
        'https://url-shortener-service.p.rapidapi.com/shorten',
        {
          url: long_url,
        },
        {
          headers: {
            'content-type': 'application/json',
            'x-rapidapi-host': 'url-shortener-service.p.rapidapi.com',
            'x-rapidapi-key':
              '22b050b90cmsh2761f8428ea8b27p1d1b80jsnc55899adae6f',
          },
        }
      )
      .then((response) => {
        const short_link = response.data.result_url;
        dispatch([
          {
            type: 'SHORT_LINK',
            payload: short_link,
          },
        ]);
      });
  };
}

export const validateShare = (values, cleanup) => {
  return (dispatch) => {
    axios
      .post(`${URL_SHARE_VALIDATION}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'FEEDBACK_MODAL',
            payload: true,
          },
          {
            type: 'SHARE_VALIDATED',
            payload: response.data.action.contents[0].link,
          },
        ]);

        if (cleanup) {
          cleanup();
        }
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const setFeaturedActivitiesLoad = (boolean) => {
  return (dispatch) => {
    dispatch({
      type: 'FEATURED_PRIVATE_ACTIVITIES_LOAD',
      payload: boolean,
    });
  };
};

export const submitCheckListQuiz = (values) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);
    axios
      .post(`${URL_ANSWER_QUIZ}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          setFeedbackQuizModal(true),
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          setFeedbackQuizModal(true),
        ]);
        try {
          toastr.error('Erro', e.response.data[0]);
        } catch {
          toastr.error('Erro ');
        }
      });
  };
};

export const productOrder = (values, history = undefined) => {
  return (dispatch) => {
    if (!values.grocery_list) {
      return toastr.error(
        'Para enviar, selecione pelo menos 1 dos produtos abaixo'
      );
    } else {
      const arrayOfZeros = [];
      const arrayOfAll = [];
      for (let i in values.grocery_list) {
        arrayOfAll.push(values.grocery_list[i]);
        if (!(parseInt(values.grocery_list[i]) > 0)) {
          arrayOfZeros.push(values.grocery_list[i]);
        }
      }
      if (arrayOfAll.length === arrayOfZeros.length) {
        return toastr.error(
          'Para enviar, selecione pelo menos 1 dos produtos abaixo'
        );
      }
    }
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
    ]);
    axios
      .post(`${URL_REQUEST_PRODUCT_ORDER}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        destroy('productDeliveryForm');
        toastr.success('Pedido enviado com sucesso.');
        history.push(`/homepage/pedidos/${response.data.id}`);
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error(e.response.data.error);
          }
        } catch (error) {
          toastr.error('Erro interno no servidor', 'Erro');
        }
      })
      .finally(() => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
        ]);
      });
  };
};

export const rawContentView = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(`${URL_RAW_CONTENT}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(() => {
        return;
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error(e.response.data.messages[0], 'Erro');
          }
        } catch (error) {
          toastr.error('Erro interno no servidor', 'Erro');
        }
      });
  };
};

export const saveCheckVideo = (videoId, values, history = undefined) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
    ]);
    axios
      .post(`${URL_CHECK_VIDEO}/${videoId}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(() => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
        ]);
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const updateCheckVideo = (videoId, values, history = undefined) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOAD,
        payload: true,
      },
    ]);
    axios
      .put(`${URL_CHECK_VIDEO}/${videoId}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(() => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: type.BUTTON_LOAD,
            payload: false,
          },
        ]);
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const videoPlaylistParticipation = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(`${URL_VIDEO_PARTICIPATION}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(() => {
        return;
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error(e.response.data.messages[0], 'Erro');
          }
        } catch (error) {
          toastr.error('Erro interno no servidor', 'Erro');
        }
      });
  };
};
export const videoYoutubeParticipation = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(`${URL_VIDEO_YT_PARTICIPATION}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(() => {
        return;
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error(e.response.data.messages[0], 'Erro');
          }
        } catch (error) {
          toastr.error('Erro interno no servidor', 'Erro');
        }
      });
  };
};

export const getActionComments = (actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: GET_ALL_ACTION_COMMENTS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${BASE_API}/comments/action/${actionId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const commentsArray = response.data.map(
          (comment) => comment.description
        );
        dispatch([
          {
            type: ALL_COMMENTS_FETCHED,
            payload: commentsArray,
          },
          {
            type: ALL_COMMENTS_DATA,
            payload: response.data,
          },
          {
            type: GET_ALL_ACTION_COMMENTS_LOAD,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: GET_ALL_ACTION_COMMENTS_LOAD,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendActivityPost = (values, actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: ACTIVITY_POST_CREATE_LOAD,
        payload: true,
      },
    ]);

    axios
      .post(URL_POSTS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          getActionComments(actionId),
          {
            type: ACTIVITY_POST_CREATE_LOAD,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: ACTIVITY_POST_CREATE_LOAD,
            PAYLOAD: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendSubCommentPost = (values, actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: ACTIVITY_POST_CREATE_LOAD,
        payload: true,
      },
    ]);

    axios
      .post(URL_POSTS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          getActionComments(actionId),
          {
            type: ACTIVITY_POST_CREATE_LOAD,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: ACTIVITY_POST_CREATE_LOAD,
            PAYLOAD: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const getActivityLikes = (actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: GET_ACTIVITY_LIKES_LOADING,
        payload: true,
      },
    ]);

    axios
      .get(`${BASE_API}/likes/action/${actionId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: GET_ACTIVITY_LIKES,
            payload: response.data,
          },
          {
            type: GET_ACTIVITY_LIKES_LOADING,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: GET_ACTIVITY_LIKES_LOADING,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendActivityLike = (likeData, actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: SEND_LIKE_LOADING,
        payload: true,
      },
    ]);

    axios
      .post(URL_LIKES_AND_DISLIKES, likeData, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          // keep commented allows localLikeCount works
          // getActionComments(actionId),
          // getActivityLikes(actionId),
          {
            type: SEND_LIKE_LOADING,
            PAYLOAD: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: SEND_LIKE_LOADING,
            PAYLOAD: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendCommentLike = (likeData, actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: SEND_LIKE_LOADING,
        payload: true,
      },
    ]);

    axios
      .post(URL_LIKES_AND_DISLIKES, likeData, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          // getActionComments(actionId),
          // getActivityLikes(actionId),
          // getActivityLikes(actionId),
          {
            type: SEND_LIKE_LOADING,
            PAYLOAD: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: SEND_LIKE_LOADING,
            PAYLOAD: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendActivityDislike = (dislikeData, actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: SEND_DISLIKE_LOADING,
        payload: true,
      },
    ]);

    axios
      .put(URL_LIKES_AND_DISLIKES, dislikeData, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          // keep commented allows localLikeCount works
          // getActionComments(actionId),
          // getActivityLikes(actionId),
          {
            type: SEND_DISLIKE_LOADING,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: SEND_DISLIKE_LOADING,
            PAYLOAD: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendCommentDislike = (dislikeData, actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: SEND_DISLIKE_LOADING,
        payload: true,
      },
    ]);

    axios
      .put(URL_LIKES_AND_DISLIKES, dislikeData, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          // keep commented allows localLikeCount works
          // getActionComments(actionId),
          // getActivityLikes(actionId),
          {
            type: SEND_DISLIKE_LOADING,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: SEND_DISLIKE_LOADING,
            PAYLOAD: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendCommentDeletion = (commentId, actionId) => {
  return (dispatch) => {
    dispatch([
      {
        type: DELETE_ACTION_COMMENT_LOAD,
        payload: true,
      },
    ]);

    axios
      .delete(`${BASE_API}/comments/${commentId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          console.log(response),
          getActionComments(actionId),
          {
            type: DELETE_ACTION_COMMENT_LOAD,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: DELETE_ACTION_COMMENT_LOAD,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};
