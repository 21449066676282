import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: 'auto',
    display: 'block',
  },
  line: {
    backgroundColor: '#52C22C33',
  },
  bar: {
    backgroundColor: '#52C22C',
  },
});

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(props.progress);

  React.useEffect(() => {
    setCompleted(props.progress);
  }, [props.progress]);

  return (
    <div className={classes.root}>
      <LinearProgress
        value={completed}
        classes={{ root: classes.line, bar: classes.bar }}
      />
    </div>
  );
}
