import React from 'react';
import { Route, Switch } from 'react-router-dom';
import News from './news';
import NewsDetail from './newsDetail';

export default (
  <Route path="/homepage/noticias">
    <Switch>
      <Route exact path="/homepage/noticias" component={News} />
      <Route path="/homepage/noticias/:id" component={NewsDetail} />
    </Switch>
  </Route>
);
