/* eslint-disable react-hooks/exhaustive-deps */
import Spinner from 'common/components/spinner/spinner';
import { BASE_S3 } from 'config/consts';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListById } from './newsActions';
import './styles.css';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';

export function NewsDetail(props) {
  const { id } = useParams();
  useEffect(() => {
    props.getListById(id);
  }, []);

  if (props.news.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: '#fff' }}
      >
        <Spinner />
      </div>
    );
  }
  function renderContent() {
    return (
      <div className="row justify-content-center mt-4">
        <div className="col-sm-10 station-font-lh-2">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.news.detail.body),
            }}
          ></div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="d-flex justify-content-center"
      style={{ backgroundColor: '#fff', width: '100%', height: '95vh' }}
    >
      <div className="col-sm-10 col-lg-9 mt-4">
        <div style={{ backgroundColor: '#fff' }}>
          <div className="row justify-content-center">
            <div className="col-10">
              <h2
                className="font-weight-bold py-3"
                style={{ fontSize: '2.5em' }}
              >
                {props.news.detail.title}
              </h2>
              <h5 className="mb-3" style={{ fontSize: '1.5625em' }}>
                {props.news.detail.subtitle}
              </h5>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url(${BASE_S3}/${props.news.detail.image})`,
              width: '100%',
              height: 300,
              borderRadius: 0,
              overflow: 'hidden',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'relative',
            }}
          ></div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetail);
