import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import ProductDescription from 'common/components/dialog/dialog';
import { String2Currency } from 'helpers/dataTransformers';
import { destroyForm } from '../../shoppingActions';
import {COMPANY_FONT_FAMILY} from 'config/consts';
import {
  DescriptionContainer,
  ItemImage,
  Prices,
  RadioSelect,
  SeeMore,
  Title,
} from './styles';

let ItemCard = function ({
  callBack,
  data,
  input: { onChange, value },
  destroyForm,
}) {
  const [visible, setVisible] = useState(false);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));

  const price = useMemo(() => {
    return String2Currency(data.value);
  }, [data.value]);

  const discountPrice = useMemo(() => {
    return String2Currency(data.value - data.discount_value);
  }, [data.discount_value, data.value]);

  // const handleSelected = useCallback(
  //   async () => {
  //     await destroyForm();
  //     onChange(1);
  //   },
  //   [destroyForm, onChange]
  //   );

  const handleRadioClick = async (e) => {
    onChange(e.target.value);
    callBack(e.target.value);
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems=""
      xs={12}
      style={{
        margin: '0 0 .8rem 0',
        paddingBottom: '.8rem',
        borderBottom: '1px solid rgb(131, 131, 131, 0.5)',
      }}
    >
      {/* ITEM IMAGE */}
      <Grid
        item
        container
        justifyContent={xs || sm ? 'center' : 'flex-start'}
        alignItems="center"
        xs={4}
        md={3}
      >
        <ItemImage image={data.image} onClick={() => setVisible(true)} />
      </Grid>
      {/* ITEM INFO + CONTENT */}
      <Grid
        item
        container
        justifyContent="center"
        alignItems="space-between"
        xs={8}
        md={9}
        style={{
          height: '100% !important',
        }}
      >
        {/* INFO */}
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="space-between"
          xs={10}
          style={{
            paddingLeft: '.3rem',
          }}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="body1">
              <Title onClick={() => setVisible(true)}>{data.name}</Title>
            </Typography>
            <Typography variant="subtitle2">
              <SeeMore onClick={() => setVisible(true)}>Ver mais</SeeMore>
            </Typography>
          </Box>
          <Typography variant="body1">
            <Prices>
              <span>{discountPrice}</span>
              {data.discount_value > 0 && (
                <span style={{ fontFamily: COMPANY_FONT_FAMILY }}>{price}</span>
              )}
            </Prices>
          </Typography>
        </Grid>
        {/* RADIO OPTION */}
        <Grid item container justifyContent="flex-end" alignItems="center" xs={2}>
          <RadioSelect>
            <label>
              <input
                onClick={(e) => handleRadioClick(e)}
                type="radio"
                name="product"
                value={data.id}
              />
              <span>
                <section />
              </span>
            </label>
          </RadioSelect>
        </Grid>
        <ProductDescription
          visible={visible}
          onClose={() => setVisible(false)}
          maxWidth={'sm'}
          fullWidth
        >
          <ItemImage description image={data.image} isRounded={false} />
          <DescriptionContainer>
            <Typography variant="body1">
              <p>{data.name}</p>
              <Prices>
                <span>{discountPrice}</span>
                {data.discount_value > 0 && <span>{price}</span>}
              </Prices>
              <span>{data.description}</span>
            </Typography>
          </DescriptionContainer>
        </ProductDescription>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('shoppingForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      destroyForm,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);
