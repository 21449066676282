import React from 'react';
import { Route } from 'react-router-dom';
import PoliticalThemesFormModal from './politicalThemesFormModal';
import PoliticalThemes from './politicalThemes';
import Political from './political';
import PoliticalContacts from './politicalContacts';
import PoliticalInfo from './politicalInfo';
import PoliticalCoffeeVideos from './politicalCoffeeVideos';
import PoliticalTransformVideos from './politicalTransformVideos';
import PoliticalNews from './politicalNews';
import PoliticalNewsDetail from './politicalNewsDetail';

export default (
  <Route path="/homepage/politico-sindical">
    <Route exact path="/homepage/politico-sindical" component={Political} />
    <Route
      path="/homepage/politico-sindical/temas"
      component={PoliticalThemes}
    />
    <Route
      path="/homepage/politico-sindical/contato"
      component={PoliticalContacts}
    />
    <Route
      path="/homepage/politico-sindical/informacoes"
      component={PoliticalInfo}
    />
    <Route
      path="/homepage/politico-sindical/noticias"
      component={PoliticalNews}
    />
    <Route
      path="/homepage/politico-sindical/noticias/detalhe"
      component={PoliticalNewsDetail}
    />
    <Route
      path="/homepage/politico-sindical/temas/sugerir"
      component={PoliticalThemesFormModal}
    />
    <Route
      path="/homepage/politico-sindical/cafe-com-politica"
      component={PoliticalCoffeeVideos}
    />
    <Route
      path="/homepage/politico-sindical/transforma"
      component={PoliticalTransformVideos}
    />
  </Route>
);
