import styled from 'styled-components';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  COMPANY_FONT_FAMILY,
  GENERAL_TEXT_COLOR,
} from 'config/consts';
import { shade } from 'polished';

// this file expects changes from feed refactor @

export const Form = styled.form`
  background: transparent;
  /* padding: 20px; */
  border-radius: 10px;

  header {
    display: flex;
    align-items: center;

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }

    input {
      flex: 1;
      height: 50px;
      border: 1px solid grey;
      border-radius: 20px;
      padding-left: 15px;
      overflow-wrap: anywhere;
      word-break: break-word;

      &::placeholder {
        color: #999;
      }
    }

    button {
      cursor: pointer;
      background-color: ${(props) =>
        props.disabled ? '#999' : COMPANY_COLORS.primary};
      height: 50px;
      width: 100px;
      color: white;
      margin-left: 15px;
      border-radius: 20px;
      transition: background-color 0.2s;

      &:hover {
        background-color: ${(props) =>
          props.disabled
            ? shade(0.2, '#999')
            : shade(0.2, COMPANY_COLORS.primary)};
      }

      &:focus {
        outline: none;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 10px 0 0 0;
    justify-content: space-around;

    li {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 5px;
      }
    }
  }
`;

export const IconBackground = styled.div`
  label {
    cursor: pointer;
    margin: 0;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }

    input {
      display: none;
    }
  }
`;

export const Media = styled.div`
  padding: 20px 0px 10px 0px;
  display: flex;
  justify-content: center;

  img {
    max-height: 420px;
    max-width: 620px;
    margin: 0 auto;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled ? '#999' : BUTTON_BACKGROUND_COLOR};
  height: 50px;
  width: 100px;
  color: ${BUTTON_TEXT_COLOR};
  /* margin-left: 15px; */
  border-radius: 20px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? shade(0.2, '#999')
        : shade(0.2, BUTTON_BACKGROUND_COLOR)};
  }

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input`
  /* flex: 1; */
  padding-left: ${(props) => (props.isMobile ? '5px' : '15px')};
  width: 100%;
  height: 50px;
  border: 1px solid grey;
  border-radius: 20px;
  overflow-wrap: anywhere;
  word-break: break-word;
  background-color: transparent;
  color: ${GENERAL_TEXT_COLOR};
  font-family: ${COMPANY_FONT_FAMILY}

  &::placeholder {
    color: #ccc;
  }
`;
