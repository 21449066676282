import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './types';

const URL = `${BASE_API}/schedules/`;

export const getListByStationId = (id) => {
  return (dispatch) => {
    dispatch({
      type: type.LIST_LOAD,
      payload: true,
    });

    axios
      .get(`${URL}?where[station_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getListById = (id) => {
  return (dispatch) => {
    dispatch({
      type: type.LIST_LOAD,
      payload: true,
    });

    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: response,
          },
        ]);
      });
  };
};

export const setLoading = (value) => {
  return (dispatch) => {
    dispatch([{ type: type.LIST_LOAD, payload: value }]);
  };
};
