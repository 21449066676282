import { Typography } from '@material-ui/core';
import {
  BASE_S3,
  COMPANY_FONT_FAMILY,
  HEADER_FONT_FAMILY,
  HEADER_TEXT_COLOR,
} from 'config/consts';
import React from 'react';

export default function AboutCard(props) {
  const { image, title, text } = props;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <img
        src={`${BASE_S3}/${image}`}
        className="card-img-top"
        alt="Imagem do card"
        style={{ maxWidth: '100%', height: 'auto' }}
      />

      <div className="card-body" style={{}}>
        <Typography
          variant="h2"
          className="station-font-semibold text-center py-3 m-0"
          style={{
            fontSize: '1rem',
            // color: COMPANY_COLORS.aboutCard.title,
            color: props.titleColor || HEADER_TEXT_COLOR,
            fontFamily: HEADER_FONT_FAMILY,
          }}
        >
          {title ? title : ''}
        </Typography>
        <Typography
          variant="body1"
          className="station-font-medium text-center pb-3 m-0"
          style={{
            // color: COMPANY_COLORS.aboutCard.text,
            color: props.subtitleColor || HEADER_TEXT_COLOR,
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            fontFamily: COMPANY_FONT_FAMILY,
          }}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
}
