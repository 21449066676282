/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import CallToActionCard from 'common/components/cards/callToActionCard';
import {
  InputLabelOutlined,
  SelectLabel,
} from 'common/components/fields/fields';
import GroceryList from 'common/components/GroceryList';
import FeedbackModal from 'common/components/modal/feedbackModal';
import DOMPurify from 'dompurify';
import { cpfMask, phoneMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, initialize, reduxForm } from 'redux-form';
import { productOrder } from './activitiesActions';
import { DomPurifyContentContainer } from './styles';

const useStyles = makeStyles(() => ({
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  },
  flexForm: {
    backgroundColor: 'transparent',
    minWidth: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '.3rem 0',
  },
}));

const initializeForm = (values) => {
  return initialize('productDeliveryForm', values);
};

const DeliveryForm = (props) => {
  const [step, setStep] = useState(0);
  const [visible, setVisible] = useState(false);
  const [locationPrice, setLocationPrice] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const values = {
      cpf: props.auth.user.cpf,
      phone: props.auth.user.tel,
      name: props.auth.user.name,
    };
    props.initializeForm(values);
  }, []);

  function onSubmit(values) {
    if (step !== 2) {
      setStep(step + 1);
    } else {
      const submit = {
        ...values,
        user_id: props.auth.user.id,
        action_id: props.activities.detail.id,
      };
      props.productOrder(submit, props.history);
    }
  }

  function setLocation(item) {
    const { price } = props.activities.detail.delivery_locations.filter(
      (location) => location.id === item
    )[0];
    setLocationPrice(parseFloat(price));
  }

  function handlePrevious() {
    if (step !== 0) {
      setStep(step - 1);
    } else {
      props.history.push('/homepage');
    }
  }

  const locationsList = props.activities.detail.delivery_locations.map(
    (item, index) => ({
      value: item.id,
      label: item.name,
    })
  );
  const { handleSubmit } = props;

  return (
    <>
      <Grid item container justifyContent="center" alignItems="center" xs={12}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <Typography variant="body1">
            <DomPurifyContentContainer
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activities.detail.description),
              }}
            />
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12}>
          {props.activities.detail.action_videos.length > 0 ? (
            <Grid
              item
              container
              xs={12}
              justyfy="center"
              alignItems="center"
              style={{ margin: '.5rem 0 1rem 0' }}
            >
              <ReactPlayer
                width={'100%'}
                height={isMobile ? '17rem' : '30rem'}
                controls
                className={classes.reactPlayer}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12}>
          <CallToActionCard
            public={props.public || false}
            activityButtonLabel={'Fazer Pedido'}
            website={props.website}
            history={props.history}
            auth={props.auth}
            activity={props.activities.detail}
            onClick={() => setVisible(true)}
            {...props}
          />
        </Grid>
      </Grid>

      {visible ? (
        <>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            sm={12}
            style={{ marginTop: '2rem' }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className={classes.flexForm}
            >
              <Grid item container justifyContent="center" alignItems="center" xs={12}>
                <h4 className="station-font-bold">Faça o pedido abaixo</h4>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                direction="column"
                spacing={1}
                alignItems="center"
                xs={12}
              >
                {step === 0 ? (
                  <>
                    <Grid item container xs={12} sm={6}>
                      <Field
                        component={InputLabelOutlined}
                        label="CPF"
                        name="cpf"
                        validate={[FORM_RULES.required]}
                        {...cpfMask}
                        disabled
                      />
                    </Grid>
                    <Grid item container xs={12} sm={6}>
                      <Field
                        component={InputLabelOutlined}
                        label="Nome completo"
                        name="name"
                        validate={[FORM_RULES.required]}
                        disabled
                      />
                    </Grid>
                  </>
                ) : null}
                {step === 1 ? (
                  <>
                    <Grid item container xs={12} sm={6}>
                      <Field
                        component={SelectLabel}
                        type="text"
                        label={`Local de Entrega`}
                        name={`delivery_location_id`}
                        onChange={(e) => setLocation(e.target.value)}
                        margin="normal"
                        options={locationsList}
                        validate={[FORM_RULES.required]}
                      />
                    </Grid>
                    <Grid item container xs={12} sm={6}>
                      <Field
                        component={InputLabelOutlined}
                        label="Endereço"
                        name="address"
                        validate={[FORM_RULES.required]}
                      />
                    </Grid>
                    <Grid item container xs={12} sm={6}>
                      <Field
                        component={InputLabelOutlined}
                        label="Complemento/referência"
                        name="reference"
                        validate={[FORM_RULES.required]}
                      />
                    </Grid>
                    <Grid item container xs={12} sm={6}>
                      <Field
                        component={InputLabelOutlined}
                        label="Telefone"
                        name="phone"
                        validate={[FORM_RULES.required]}
                        {...phoneMask}
                      />
                    </Grid>
                  </>
                ) : null}
                {step === 2 ? (
                  <>
                    <Grid item container xs={12} sm={6}>
                      <Field
                        name={'grocery_list'}
                        items={props.activities.detail.products.map((item) => ({
                          ...item,
                          value: parseFloat(item.value),
                        }))}
                        locationPrice={locationPrice}
                        action={props.activities.detail}
                        component={GroceryList}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                direction="column"
                spacing={1}
                alignItems="center"
                xs={12}
                style={{
                  paddingTop: isMobile ? '.8rem' : '.4rem',
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  sm={6}
                >
                  <FullButton
                    classNameProp="py-3 px-4"
                    type="submit"
                    disabled={props.activities.buttonLoading}
                  >
                    {step === 2 ? 'Enviar' : 'Próximo'}
                  </FullButton>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  sm={6}
                >
                  <FullButton
                    classNameProp="py-3 px-4 mt-3"
                    type="button"
                    onClick={handlePrevious}
                  >
                    Voltar
                  </FullButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </>
      ) : null}

      {props.activities.feedbackModal ? (
        <FeedbackModal
          visible={props.activities.feedbackModal}
          onCloseRoute="/homepage"
          history={props.history}
          setFeedbackModal={props.setFeedbackModal}
          firstText={'Página Inicial'}
          activity={props.activities.detail}
          firstActionType="route"
          firstRoute="/homepage"
          lottie={props.activities.lottieDetail}
          cleanupFunction={() => props.getUserPoints(props.auth.user)}
        />
      ) : null}
    </>
  );
};

const Delivery = reduxForm({ form: 'productDeliveryForm' })(DeliveryForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      initializeForm,
      productOrder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
