// import { Box, Typography } from '@material-ui/core';
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { qrcodeVerify } from '../authActions';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  CircularProgress,
} from '@material-ui/core';
import { getStationData } from '../../../modules/website/websiteActions';
import {
  COMPANY_STATION_ID,
  COMPANY_COLORS,
  COMPANY_SIGNED_OFF_BACKGROUND_COLOR,
} from 'config/consts';
import NavigationBar from '../../../modules/common/components/Header/navigationBar';
import { makeStyles } from '@material-ui/styles';
import Footer from '../../../modules/website/modules/footer';
import AlertDialogSlide from 'common/components/dialog/generalDialog';
import PrivacyPolicy from '../policies/privacyPolicy';
import UseTerms from '../policies/usePolicy';
import { getAboutList } from '../../../modules/website/websiteActions';

function QrcodeValidation(props) {
  console.log('props:', props);
  const { history } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openModalSec, setOpenModalSec] = useState(false);

  const useStyles = makeStyles((props) => ({
    root: {
      padding: 0,
      minHeight: '100vh',
      backgroundColor: COMPANY_SIGNED_OFF_BACKGROUND_COLOR,
    },
    headerAndFooter: {
      padding: 0,
    },
  }));

  const { id: userid } = useParams();
  const classes = useStyles(props);
  const [id] = useState(COMPANY_STATION_ID);

  useEffect(() => {
    getStationData(COMPANY_STATION_ID);
    props.getAboutList(id);
  }, [props.getStationData]);

  useEffect(() => {
    if (props.qrcodeVerify) props.qrcodeVerify(userid);
  }, [props.qrcodeVerify]);

  if (props.auth.isQrCodeValid === true) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="space-between"
        className={classes.root}
      >
        <Container
          maxWidth={false}
          component="main"
          className={classes.headerAndFooter}
        >
          <NavigationBar
            isMainPage={false}
            website={props.website}
            history={history}
          />
        </Container>
        <Container height="60vh">
          <Typography
            variant={'h3'}
            style={{
              textAlign: 'center',
              alignItems: 'center',
              color: COMPANY_COLORS.success,
            }}
          >
            Usuário válido!
          </Typography>
        </Container>
        <Container
          maxWidth={false}
          className={classes.headerAndFooter}
          component="main"
        >
          <Footer
            setOpenModal={setOpenModal}
            setOpenModalSec={setOpenModalSec}
            website={props.website}
            history={props.history}
          />
          <AlertDialogSlide
            onClose={() => {
              setOpenModal(false);
            }}
            visible={openModal}
            titleBackground={'#FFFF'}
            backgroundColor={'#FFFF'}
          >
            <PrivacyPolicy />
          </AlertDialogSlide>
          <AlertDialogSlide
            onClose={() => {
              setOpenModalSec(false);
            }}
            visible={openModalSec}
            titleBackground={'#FFFF'}
            backgroundColor={'#FFFF'}
          >
            <UseTerms />
          </AlertDialogSlide>
        </Container>
      </Box>
    );
  } else if (props.auth.isQrCodeValid === false) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="space-between"
        className={classes.root}
      >
        <Container
          maxWidth={false}
          component="main"
          className={classes.headerAndFooter}
        >
          <NavigationBar
            isMainPage={false}
            website={props.website}
            history={history}
          />
        </Container>
        <Container height="60vh">
          <Typography
            variant={'h3'}
            style={{
              textAlign: 'center',
              alignItems: 'center',
              color: COMPANY_COLORS.error,
            }}
          >
            Usuário inválido!
          </Typography>
        </Container>
        <Container
          maxWidth={false}
          className={classes.headerAndFooter}
          component="main"
        >
          <Footer
            setOpenModal={setOpenModal}
            setOpenModalSec={setOpenModalSec}
            website={props.website}
            history={props.history}
          />
          <AlertDialogSlide
            onClose={() => {
              setOpenModal(false);
            }}
            visible={openModal}
            titleBackground={'#FFFF'}
            backgroundColor={'#FFFF'}
          >
            <PrivacyPolicy />
          </AlertDialogSlide>
          <AlertDialogSlide
            onClose={() => {
              setOpenModalSec(false);
            }}
            visible={openModalSec}
            titleBackground={'#FFFF'}
            backgroundColor={'#FFFF'}
          >
            <UseTerms />
          </AlertDialogSlide>
        </Container>
      </Box>
    );
  } else {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { qrcodeVerify, getStationData, getAboutList },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QrcodeValidation);
