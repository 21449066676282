/* eslint-disable object-curly-spacing */
const INITIAL_STATE = {
  list: [],
  loading: false,
  loadingPost: false,
  loadingBottom: false,
  limit: 3,
  listByUser: [],
  listFixed: [],
  loadingFixed: false,
  loadingUser: false,
  uploadProgress: '',
  isGettingPostList: true
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @return {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'POSTS_LOAD':
      return {
        ...state,
        loading: payload,
        isGettingPostList: payload
      };
    case 'POSTS_FETCHED':
      return {
        ...state,
        loading: false,
        list: payload.content,
        limit: payload.limit,
        isGettingPostList: false,
      };

    case 'POSTS_PAGE_LOAD':
      return { ...state, loadingBottom: payload };

    case 'POSTS_PAGE_FETCHED':
      return {
        ...state,
        loadingBottom: false,
        list: payload.content,
        limit: payload.limit,
      };
    case 'POSTS_CREATE_LOAD':
      return { ...state, loadingPost: payload };
    case 'POSTS_BY_USER_FETCHED':
      return { ...state, loadingUser: false, listByUser: payload };
    case 'POSTS_BY_USER_LOAD':
      return { ...state, loadingUser: payload };
    case 'POSTS_FIXED_FETCHED':
      return {
        ...state,
        loadingFixed: false,
        listFixed: payload.content,
      };
    case 'POSTS_FIXED_LOAD':
      return { ...state, loadingFixed: payload };
    case 'UPLOAD_VIDEO_PROGRESS':
      return { ...state, uploadProgress: payload };
    default:
      return state;
  }
};
