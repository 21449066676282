import { FullButton } from 'common/components/buttons/fullButton';
import {
  CaptchaInput,
  InputLabelOutlined,
} from 'common/components/fields/fields';
import { COMPANY_COLORS, COMPANY_STATION_ID } from 'config/consts';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, reduxForm } from 'redux-form';
import { submitSupport } from '../../../contactUs/contactUsActions';
import { Container } from './styles';

let ContactUs = function (props) {
  const { body } = COMPANY_COLORS.website;

  function onSubmit(values) {
    values['station_id'] = COMPANY_STATION_ID;
    values['subject'] = 'Consulta por plano enterprise';
    values['user_id'] = props.auth.user.id;
    values['name'] = props.auth.user?.name;
    values['phone'] = props.auth.user?.tel;
    values['email'] = props.auth.user.email;
    props.submitSupport(values, props.history);
  }

  return (
    <Container>
      <h1
        style={{
          fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
          textAlign: isMobileOnly ? 'center' : '',
          color: body.text,
        }}
      >
        {'Plano Enterprise'}
      </h1>
      <h5
        style={{
          fontSize: isMobileOnly ? '1rem' : '1.0rem',
          textAlign: isMobileOnly ? 'center' : 'center',
          color: '#666',
        }}
      >
        {'Responderemos o mais rápido possível.'}
      </h5>
      <Form onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div>
          <Field
            component={InputLabelOutlined}
            label="Conte-nos um pouco sobre a sua empresa e necessidade"
            inputlabelprops={{
              shrink: true,
            }}
            multiline
            rows={5}
            name="message"
            validate={[FORM_RULES.required]}
          />
        </div>
        <div>
          <Field
            component={CaptchaInput}
            inputlabelprops={{
              shrink: true,
            }}
            name="captcha"
            validate={[FORM_RULES.required]}
          />
        </div>
        <div>
          <FullButton
            classNameProp="py-3 px-4"
            type="submit"
            disabled={props.website.buttonLoading}
          >
            Enviar
          </FullButton>
        </div>
      </Form>
    </Container>
  );
};

ContactUs = reduxForm({ form: 'supportForm' })(ContactUs);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    general: state.general,
    website: state.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitSupport,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
