import { Box, Grid } from '@material-ui/core';
import React from 'react';
import TextCard from '../../../../../common/components/cards/textCard';

export default function Accounting() {
  return (
    <section id="accounting">
      <p className="body-title mt-4">Consultoria contábil</p>
      <p className="h4 mt-4" style={{ color: '#838383', maxWidth: 500 }}>
        Acesse os serviços da Consultoria Contábil, que tem o objetivo de
        auxiliar os filiados na resolução de problemas na área contábil:
      </p>

      <Grid container spacing={3} style={{ marginTop: 64 }}>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-contabil/orientacoes">
              Como usar
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-contabil/processos">
              Processos e procedimentos
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-contabil/agendamentos">
              Agendamentos de consultas
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-contabil/contato">
              Equipe e contato
            </TextCard>
          </Box>
        </Grid>
        {/* <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-contabil/informacoes">
              Painel de informações
            </TextCard>
          </Box>
        </Grid> */}
        <Grid item xs={3}>
          <TextCard href="#/homepage/consultoria-contabil/noticias">
            Notícias
          </TextCard>
        </Grid>
        {/* <Grid item xs={3}>
          <TextCard href="##/homepage/consultoria-contabil/eventos">
            Eventos
          </TextCard>
        </Grid> */}
      </Grid>
    </section>
  );
}
