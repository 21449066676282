import { Box, Grid } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProcessesList } from './legalActions';
import LegalProcessesCard from './legalProcessesCard';
import LegalProcessesDetailModal from './legalProcessesDetailModal';
import './styles.css';
import { appColors } from '../../../../../styles/color';

const LegalProcesses = ({ getProcessesList, legal }) => {
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [selectedLawsuit, setSelectedLawsuit] = useState(null);

  function handleClose() {
    setSelectedLawsuit(null);
    setIsDetailDialogOpen(false);
  }

  function handleOpen(lawsuit) {
    setSelectedLawsuit(lawsuit);
    setIsDetailDialogOpen(true);
  }

  useEffect(() => {
    getProcessesList();
  }, [getProcessesList]);

  const { processesList, processesLoading } = legal;

  return (
    <section id="processes">
      <p className="body-title mt-4">Processos e procedimentos</p>
      {processesLoading ? (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      ) : processesList.length > 0 ? (
        <>
          <p className="h4 mt-5" style={{ color: appColors.v2.gray }}>
            Causas coletivas
          </p>

          {processesList.filter((item) => item.publicarPortalSindilegis)
            .length > 0 ? (
            <Grid container spacing={3} style={{ marginTop: 16 }}>
              {processesList
                .filter((item) => item.publicarPortalSindilegis)
                .map((item) => (
                  <Grid item xs={4} key={item.numeroProcesso}>
                    <LegalProcessesCard
                      onClick={() => handleOpen(item)}
                      header={`${item.tipo}/${format(
                        new Date(item.dataProtocolo),
                        'yyyy'
                      )}`}
                      type={item.tipo}
                      status={item.situacao}
                      body={item.numeroProcesso}
                      footer={item.tribunalOrgao}
                      title={item.titulo}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <p className="h6 mt-4">
              Não foram encontradas causas coletivas em seu CPF.
            </p>
          )}
          <p className="h4 mt-5" style={{ color: appColors.v2.gray }}>
            Causas pessoais
          </p>

          {processesList.filter((item) => !item.publicarPortalSindilegis)
            .length > 0 ? (
            <Grid container spacing={3} style={{ marginTop: 16 }}>
              {processesList
                .filter((item) => !item.publicarPortalSindilegis)
                .map((item) => (
                  <Grid item xs={4} key={item.numeroProcesso}>
                    <LegalProcessesCard
                      onClick={() => handleOpen(item)}
                      header={`${item.tipo}/${format(
                        new Date(item.dataProtocolo),
                        'yyyy'
                      )}`}
                      type={item.tipo}
                      status={item.situacao}
                      body={item.numeroProcesso}
                      footer={item.tribunalOrgao}
                      title={item.titulo}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <p className="h6 mt-4">
              Não foram encontradas causas pessoais em seu CPF.
            </p>
          )}
        </>
      ) : (
        <p className="h4 mt-4">Não foram encontrados processos em seu CPF.</p>
      )}
      {selectedLawsuit && (
        <LegalProcessesDetailModal
          onClose={handleClose}
          isOpen={isDetailDialogOpen}
          title={`${selectedLawsuit.tipo}/${format(
            new Date(selectedLawsuit.dataProtocolo),
            'yyyy'
          )}`}
          status={selectedLawsuit.situacao}
          type={selectedLawsuit.tipo}
          body={selectedLawsuit.numeroProcesso}
          footer1={selectedLawsuit.tribunalOrgao}
          footer2={selectedLawsuit.valorCausa}
          moreInfoLink={selectedLawsuit.linkProcesso}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    legal: state.legal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProcessesList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalProcesses);
