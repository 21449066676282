import React from 'react';

function TextCard({ children, href }) {
  return (
    <a
      href={href}
      style={{
        backgroundColor: 'transparent',
        border: '1px solid #C4C4C4',
        borderRadius: 8,
        padding: '66px 49px 66px 49px',
        textDecoration: 'none',
        textAlign: 'center',
        width: '100%',
      }}
      type="button"
      className="h4"
    >
      {children}
    </a>
  );
}

export default TextCard;
