import React from 'react';
import { Question, TextImage } from './styles';

export default function Questions({ question }) {
  return (
    <>
      <Question
        background={question.color}
        textColor={question.textColor}
        image={question.image}
      >
        {!question.image ? <p>{question.question}</p> : null}
      </Question>
      {question.image ? (
        <TextImage
          disabled
          textColor={
            question.textColor === '#FFF' ? '#000' : question.textColor
          }
          value={question.question}
        >
          <p>{question.question}</p>
        </TextImage>
      ) : null}
    </>
  );
}
