import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR, GENERAL_TEXT_COLOR } from '../../../config/consts';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: GENERAL_TEXT_COLOR,
  },
}));

export default function MaterialModal(props) {
  const classes = useStyles();
  const { onClose, open, children, title } = props;

  function handleClose() {
    onClose();
  }

  function renderTitle() {
    return (
      <MuiDialogTitle disableTypography style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}>
        {props.title ? (
          <Typography variant="h6">
            {props.redirect ? title : children}
          </Typography>
        ) : null}

        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth={props.maxWidth || 'md'}
      fullWidth={props.fullWidth || false}
      PaperProps={props.PaperProps}
      keepMounted={true}
      aria-describedby="alert-dialog-slide-description"
      fullScreen={props.fullScreen}
    >
      {props.showTitle ? renderTitle() : null}
      {props.redirect ? (
        <div style={{ padding: '16px 24px' }}>{children}</div>
      ) : (
        children
      )}
    </Dialog>
  );
}
