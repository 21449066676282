/* eslint-disable react-hooks/exhaustive-deps */
import Spinner from 'common/components/spinner/spinner';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getListById } from './schedulesActions';

export function NewsDetail(props) {
  const { id } = useParams();
  useEffect(() => {
    props.getListById(id);
  }, []);

  if (props.news.loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <section id="news-list">
      <div className="row justify-content-center h-100 py-3">
        <div className="col-10">
          <div className="row justify-content-center">
            <div className="text-center">
              <h4>{props.news.detail.title}</h4>
              <h5>{props.news.detail.subtitle}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>{props.news.detail.body}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetail);
