import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './types';
import { toastr } from 'react-redux-toastr';

const URL_POLITICAL = `${BASE_API}/political`;
const URL_POLITICAL_NEWS = `${BASE_API}/political/news`;

export const getThemesList = () => {
  return (dispatch) => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL_POLITICAL}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch({ type: type.LIST_LOAD, payload: false });
      });
  };
};

export const setLoading = (value) => {
  return (dispatch) => {
    dispatch([{ type: type.LIST_LOAD, payload: value }]);
  };
};

export const sendThemeSuggestion = (values, cleanup) => {
  return (dispatch) => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .post(`${URL_POLITICAL}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([{ type: type.LIST_LOAD, payload: false }]);
        cleanup();
        toastr.success('Sucesso', 'Solicitação enviada com sucesso.');
      })
      .catch((e) => {
        dispatch([{ type: type.LIST_LOAD, payload: false }]);
        try {
          toastr.error('Erro', e.response.data.messages[0]);
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const getNewsList = (page, tagId) => {
  return (dispatch) => {
    dispatch({
      type: type.NEWS_LIST_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_POLITICAL_NEWS}?page=${page}&tagId=${tagId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({
          type: type.NEWS_LIST_FETCHED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch([
          {
            type: type.NEWS_LIST_LOAD,
            payload: false,
          },
        ]);
      });
  };
};

export const setNewsList = (value) => {
  return (dispatch) => {
    dispatch([{ type: type.NEWS_LIST_SET, payload: value }]);
  };
};
