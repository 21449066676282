import axios from 'axios';
import {
  BASE_API,
  BASE_AUTH,
  COMPANY_STATION_ID,
  COMPANY_SUBDOMAIN,
  USER_TOKEN,
} from 'config/consts';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { initialize, reset as resetForm } from 'redux-form';
import { DUKITS_STATION_ID } from '../../config/consts';
import { default as type, default as types } from './type';

const URL_ESTABLISHMENT = `${BASE_API}/establishments`;
const URL_WEBSITE = `${BASE_API}/generateWebsite`;
const URL_SIGNUP_ADMIN = `${BASE_AUTH}/signup-admin`;
const URL_LOGIN_TOKEN = `${BASE_AUTH}/login-token`;
const URL_ANSWER_QUESTIONS = `${BASE_API}/establishment-questions`;
// const URL_VALIDATE_REGISTRATION = `${BASE_API}/validate-contact`;
const URL_VALIDATE_MGM = `${BASE_API}/validate-mgm-code`;
const RESEND_EMAIL = `${BASE_AUTH}/resend-confirmation-email`;
const URL_VALIDATE_REGISTRATION = `${BASE_AUTH}/signup-participant-validations`;
const URL_SIGNUP_PARTICIPANT = `${BASE_AUTH}/signup-participant`;
const URL_UPDATE_PARTICIPANT = `${BASE_AUTH}/update-participant`;
const URL_CONFIRMATION_METHOD = `${BASE_AUTH}/confirmation-method`;
const URL_QRCODE_VALIDATION = `${BASE_API}/users`;

const URL_FAN =
  process.env.REACT_APP_API_KEY === 'production'
    ? `https://app.dukits.com`
    : process.env.REACT_APP_API_KEY === 'test'
    ? `https://sandbox-gestor.dukits.com`
    : 'http://localhost:3000';

export const login = (values, history = undefined) => {
  return (dispatch) => {
    dispatch(setLoadingLogin(true));
    axios
      .post(`${BASE_AUTH}/login/establishment/${COMPANY_STATION_ID}`, values)
      .then((response) => {
        if (
          COMPANY_STATION_ID === DUKITS_STATION_ID &&
          response.data.content.briefingDone
        ) {
          return (window.location.href = `${URL_FAN}/#/login-token/${response.data.tempToken}`);
        } else {
          ///console.log(responseParticipant);
          response.data.content['establishment_participant'] =
            response.data.content.station.establishment;
          dispatch([
            { type: 'USER_FETCHED', payload: response },
            dispatch(setLoadingLogin(false)),
          ]);
          if (!_.isUndefined(history)) {
            console.log(response.data.is_first_login);
            if (response.data.is_first_login) {
              history.push('/signup');
            } else {
              history.push('/');
            }
          }
        }
      })
      .catch((e) => {
        dispatch(setLoadingLogin(false));
        if (e.response.status === 401) {
          toastr.error('Erro', e.response.data.messages[0]);
          history.push({
            pathname: '/cadastrar',
            state: {
              stepForm: 2,
              affiliatedInfo: e.response.data.user,
            },
          });
        } else {
          try {
            if (e.response.data) {
              toastr.error('Erro', e.response.data[0]);
            }
          } catch (error) {
            toastr.error('Erro', 'Erro interno no servidor');
          }
        }
      });
  };
};

export const resendMail = (email) => {
  return (dispatch) => {
    dispatch([
      { type: types.LOADING_REGISTER, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(
        RESEND_EMAIL,
        { email, station_id: COMPANY_STATION_ID },
        {
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )} %`,
              },
            ]),
        }
      )
      .then((response) => {
        dispatch([
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'E-mail enviado com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      });
  };
};

export const logout = () => {
  return { type: 'TOKEN_VALIDATED', payload: false };
};

export const codeValidate = (code, token) => {
  return (dispatch) => {
    axios.get(`${URL_ESTABLISHMENT}/activation/${code}`).then((response) => {
      if (response.data.length > 0) {
        dispatch([
          {
            type: 'ACTIVATION_CODE_FETCHED',
            payload: response.data[0],
          },
          !_.isNull(token) && token === 'activate'
            ? initializeForm({ hasCompany: '1' })
            : null,
        ]);
      } else {
        toastr.error('Não existe empresa cadastrada com esse código');
      }
    });
  };
};

export const activeUser = (
  email,
  history = undefined,
  establishment,
  station,
  hash
) => {
  return (dispatch) => {
    const body = {
      station,
      establishment,
      email,
      hash,
    };
    axios
      .post(`${BASE_AUTH}/signup/activate-participant/`, body)
      .then((responseActive) => {
        dispatch([
          {
            type: types.USER_ACTIVED,
            payload: true,
          },
        ]);
        axios
          .post(
            URL_LOGIN_TOKEN,
            {},
            {
              headers: {
                Authorization: responseActive.data.token,
              },
            }
          )
          .then((response) => {
            /////console.log(response);
            response.data.content['establishment_participant'] =
              response.data.content.station.establishment;
            ///console.log(responseParticipant);
            toastr.success('Sucesso, aproveite.');
            dispatch([
              { type: 'USER_FETCHED', payload: response },
              dispatch(setLoadingLogin(false)),
            ]);
            if (response.data.is_first_login) {
              history.push('/signup');
            } else {
              history.push('/');
            }
          })
          .catch((e) => {
            console.log(e);
            try {
              if (e.response.data[0]) {
                toastr.error('Erro', e.response.data[0]);
              }
            } catch (error) {
              toastr.error('Erro', 'Erro interno no servidor');
            }
          });
      })
      .catch((err) => {
        history.push(`/confirmacao/${email}`);
        toastr.error(err.response.data.messages[0]);
        dispatch([
          {
            type: types.USER_ACTIVED,
            payload: false,
          },
        ]);
      });
  };
};

export const loginToken = (token, history = undefined) => {
  return (dispatch) => {
    axios
      .post(
        URL_LOGIN_TOKEN,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        /////console.log(response);
        response.data.content['establishment_participant'] =
          response.data.content.station.establishment;
        ///console.log(responseParticipant);
        toastr.success('Sucesso, aproveite.');
        dispatch([
          { type: 'USER_FETCHED', payload: response },
          dispatch(setLoadingLogin(false)),
        ]);
        if (response.data.is_first_login) {
          history.push('/signup');
        } else {
          history.push('/homepage');
        }
      })
      .catch((e) => {
        console.log(e);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const validateToken = (token, userId) => {
  return (dispatch) => {
    delete axios.defaults.headers.common['authorization'];
    axios
      .get(`${BASE_API}/users-web/${userId}`, {
        headers: { Authorization: `${token}` },
      })
      .then((response) => {
        response.data[0]['establishment_participant'] =
          response.data[0].station.establishment;
        if (response.data.length > 0) {
          dispatch([
            { type: 'TOKEN_VALIDATED', payload: true },
            { type: 'USER_FETCHED_AGAIN', payload: response.data[0] },
          ]);
        } else {
          dispatch({ type: 'TOKEN_VALIDATED', payload: false });
        }
      })
      .catch((e) => {
        ///////console.log(e)
        dispatch({ type: 'TOKEN_VALIDATED', payload: false });
      });
  };
};

export const sendPasswordReset = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/password/email`, values)
      .then((response) => {
        dispatch(setLoading(false));
        history.push('/login');
        toastr.success('Sucesso', response.data.response.content.message);
      })
      .catch((e) => {
        dispatch(setLoading(false));
        toastr.error('Erro', e.response.data.response.content.message);
      });
  };
};

export const initializeForm = (values) => {
  return initialize('authForm', values);
};

export const resetPassword = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/password/reset/${values.token}`, values)
      .then((response) => {
        dispatch(setLoading(false));
        toastr.success('Sucesso', response.data.response.content.message);
        dispatch({
          type: types.USER_RECOVERED,
          payload: true,
        });
      })
      .catch((e) => {
        dispatch(setLoading(false));
        toastr.error('Erro', e.response.data.response.content.message);
        dispatch({
          type: types.USER_RECOVERED,
          payload: false,
        });
      });
  };
};

export const setUser = (id) => {
  return (dispatch) => {
    axios
      .get(`${BASE_AUTH}/user/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const user = {
          cpf: response.data.content.cpf,
          profile: response.data.content.profile,
          name: response.data.content.name,
          phone: response.data.content.phone,
          cnh: response.data.content.cnh,
        };
        user['token'] = JSON.parse(localStorage.getItem(USER_TOKEN));
        dispatch({ type: 'USER_FETCHED', payload: { ...user } });
      });
  };
};

export const setLoading = (status) => {
  return { type: 'LOADING_REGISTER', payload: status };
};
export const setLoadingLogin = (status) => {
  return { type: 'LOADING_LOGIN', payload: status };
};

export const signup = (values, history) => {
  return submit(values, `${BASE_AUTH}/signup`, 'signup', history);
};

const submit = (values, url, method, history = undefined) => {
  return (dispatch) => {
    values['type'] = 2;
    values['subdomain'] = COMPANY_SUBDOMAIN;
    dispatch([
      { type: types.LOADING_REGISTER, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(url, values, {
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Cadastro realizado com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (!_.isUndefined(history)) {
          history.push(`/confirmacao/${values.email}`);
        }
      })
      .catch((e) => {
        dispatch([
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        try {
          dispatch([
            { type: types.LOADING_REGISTER, payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          if (e.response.data) {
            toastr.error('Erro', e.response.data.error);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const reset = () => {
  return resetForm('signupStepForm');
};

export const signupAdmin = (values) => {
  return (dispatch) => {
    values.type = 1;
    axios
      .post(URL_SIGNUP_ADMIN, values)
      .then((response) => {
        toastr.success('Sucesso', 'Verifique sua caixa de e-mails.');

        dispatch([reset(), { type: 'SHOW_MODAL', payload: true }]);
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              toastr.error('Erro', e.response.data[i][j]);
            }
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: 'SHOW_MODAL', payload: false });
  };
};

export const answerQuestions = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(URL_ANSWER_QUESTIONS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          { type: 'SECTIONS_STATUS', payload: true },
          {
            type: 'SIGNUP_COMPANY',
            payload: false,
          },
        ]);
      });
  };
};

export const skipOnboarding = () => {
  return (dispatch) => {
    dispatch({
      type: 'SKIP_QUESTIONS',
      payload: true,
    });
  };
};

//Solicitar reset de senha

export const requestPasswordReset = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/validate-email-forgot-pass`, values)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Sucesso! Verifique sua caixa de e-mails`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        history.push('/login');
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

//Solicitar reset de senha logado

export const requestPasswordResetLogged = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/validate-email-forgot-pass`, values)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Sucesso! Verifique sua caixa de e-mails`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        history.push('/owner');
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

//Atualizar a senha

export const updatePassword = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/update-password`, values)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Sucesso! Você já pode logar com a nova senha`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        history.push('/login');
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

// MGM

export const validateMGM = (values, history = undefined) => {
  return (dispatch) => {
    dispatch({
      type: 'MGM_LOAD',
      payload: true,
    });
    axios
      .post(`${URL_VALIDATE_MGM}/${values}`)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Sucesso! Código validado`,
              variant: 'success',
              open: true,
            },
          },
          {
            type: 'MGM_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

export const updateProfile = (values, id) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .put(`${BASE_API}/update-user-profile-image/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Você atualizou a seu perfil com sucesso`,
              variant: 'success',
              open: true,
            },
          },
          {
            type: type.BUTTON_LOADING,
            payload: false,
          },
        ]);
        window.location.reload();
      })
      .catch((e) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Erro ${e.response.data.messages[0]}`,
              variant: 'error',
              open: true,
            },
          },
          {
            type: type.BUTTON_LOADING,
            payload: false,
          },
        ]);
      });
  };
};

export const fileRejected = () => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: 'Erro: Imagem maior que 1MB',
        variant: 'error',
        open: true,
      },
    });
  };
};

export const participantCredentialsValidations = (
  values,
  cleanup = undefined
) => {
  return (dispatch) => {
    dispatch([
      { type: types.LOADING_REGISTER, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(`${URL_VALIDATE_REGISTRATION}`, values, {
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (!_.isUndefined(cleanup)) {
          cleanup(response.data.content);
        }
      })
      .catch((e) => {
        dispatch([
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        try {
          console.log(e);
          if (e.response.data) {
            toastr.error('Erro', e.response.data.error);
          }
        } catch (error) {
          console.log(error);
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const signupParticipant = (values, cleanup) => {
  return (dispatch) => {
    dispatch([
      { type: types.LOADING_REGISTER, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(URL_SIGNUP_PARTICIPANT, values, {
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Cadastro realizado com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);

        if (!_.isUndefined(cleanup)) {
          cleanup(response.data.createUser);
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch([
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        try {
          dispatch([
            { type: types.LOADING_REGISTER, payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          if (e.response.data) {
            toastr.error('Erro', e.response.data.error);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const updateParticipant = (
  values,
  id,
  history,
  participant = undefined,
  cleanup
) => {
  return (dispatch) => {
    dispatch([
      { type: types.LOADING_REGISTER, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .put(`${URL_UPDATE_PARTICIPANT}/${id}`, values, {
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'USER_FETCHED_AGAIN', payload: response.data },
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Cadastro atualizado com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (participant) {
          if (!_.isUndefined(history)) {
            history.push('/');
          }
        }
        if (cleanup) cleanup();
      })
      .catch((e) => {
        dispatch([
          { type: types.LOADING_REGISTER, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        console.log(e);
        toastr.error('Erro', e.response.data.error);
      });
  };
};

export const signupCompanyAndStation = (values, history, auth, nextStep) => {
  return (dispatch) => {
    dispatch([
      { type: types.LOADING_REGISTER, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(
        `${URL_WEBSITE}`,
        {
          ...values,
        },
        {
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )} %`,
              },
            ]),
        }
      )
      .then((responseStation) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Sucesso! Aproveite.',
              variant: 'success',
              open: true,
            },
          },

          { type: types.LOADING_REGISTER, payload: false },
          { type: 'SUBMIT_PROGRESS', payload: 0 },
        ]);
        if (!_.isUndefined(history)) {
          history.push('/');
        }
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            dispatch([
              {
                type: 'SNACKBAR',
                payload: {
                  message: `Erro: ${e.response.data.messages[0]}`,
                  variant: 'error',
                  open: true,
                },
              },
              { type: types.LOADING_REGISTER, payload: false },
              { type: 'SUBMIT_PROGRESS', payload: 0 },
            ]);
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: 'Erro',
              variant: 'error',
              open: true,
            },
          });
        }
      });
  };
};

export const selectConfirmationMethod = (values, cleanup) => {
  return (dispatch) => {
    console.log('entrou');
    dispatch([
      { type: 'LOADING_REGISTER', payload: true },
      { type: types.BUTTON_LOADING, payload: true },
    ]);
    axios
      .post(URL_CONFIRMATION_METHOD, values)
      .then((response) => {
        console.log('response');
        dispatch([
          {
            type: types.BUTTON_LOADING,
            payload: false,
          },
        ]);
        /* navigation.navigate('ConfirmEmail', {
          email: values.email,
          type: values.type,
        }); */
        if (cleanup) cleanup(values);
      })
      .catch((e) => {
        dispatch([
          {
            type: types.BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data?.messages[0] || ''}`,
              variant: 'error',
              open: true,
            },
          },
          { type: types.LOADING_REGISTER, payload: false },
          { type: 'SUBMIT_PROGRESS', payload: 0 },
        ]);
      });
  };
};

export const qrcodeVerify = (id) => {
  return (dispatch) => {
    dispatch([{ type: 'QR_CODE_VALIDATION_LOADING', payload: true }]);
    axios
      .get(`${URL_QRCODE_VALIDATION}/${id}/validation`)
      .then((response) => {
        // console.log(response.data.isValid);
        dispatch([
          {
            type: 'QR_CODE_VALIDATION_DONE',
            payload: response.data.isValid,
          },
        ]);
      })
      .catch(() => {
        dispatch({ type: 'QR_CODE_VALIDATION_LOADING', payload: false });
      });
  };
};
