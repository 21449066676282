import React from 'react';
import { COMPANY_COLORS, BASE_S3 } from 'config/consts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect } from 'react';
import { subscribeEmail, unsubscribeEmail } from './emailSubscriptionActions';
import Spinner from '../../../../common/components/spinner/spinner';
import { useParams } from 'react-router-dom';

function EmailSubscription({
  emailSubscription,
  subscribeEmail,
  unsubscribeEmail,
}) {
  const { website } = COMPANY_COLORS;
  const { userId, userHash, type } = useParams();
  const { error } = emailSubscription;

  useEffect(() => {
    const values = { id: userId, hash: userHash };
    if (type === 'inscricao') {
      subscribeEmail(values);
    } else if (type === 'desinscricao') {
      unsubscribeEmail(values);
    }
  }, [unsubscribeEmail, subscribeEmail, userId, userHash, type]);

  if (emailSubscription.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '100vh', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <section
      className="container-fluid"
      style={{
        background: `url(${BASE_S3}/${website.header.background_back_header})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
      }}
    >
      <div
        className="row"
        style={{
          background: `url(${BASE_S3}/${website.header.background_auth_station})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '20% 0%',
          minHeight: '100vh',
        }}
      >
        <div className="col-sm-0 col-md-6"></div>
        <div className="col-sm-12 col-md-6 col-lg-4 align-self-center my-md-0 my-5">
          <div style={{ background: '#ffffff', borderRadius: 4 }}>
            <div className="row justify-content-center">
              <div className="col-7 d-flex justify-content-center py-3">
                <img
                  className
                  src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                  alt="Logo"
                  style={{ maxWidth: '100%', height: 'auto', maxHeight: 85 }}
                />
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div className="col-10">
                <div
                  style={{
                    background: 'lightgray',
                    height: 1,
                    width: '100%',
                    opacity: 0.5,
                  }}
                ></div>
              </div>
            </div>
            <div className="row justify-content-center pb-5">
              <div className="col-10 text-center">
                {!error ? (
                  type === 'desinscricao' ? (
                    <>
                      <p
                        className="station-font-medium"
                        style={{ color: '#999999', fontSize: '0.875rem' }}
                      >
                        Você se desinscreveu com sucesso da nossa lista de
                        e-mails.
                      </p>
                      <p
                        className="station-font-medium"
                        style={{ color: '#999999', fontSize: '0.875rem' }}
                      >
                        Caso deseje se inscrever novamente,{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`#/email/inscricao/${userId}/${userHash}`}
                        >
                          <p
                            className="station-font-medium"
                            style={{
                              color: COMPANY_COLORS.general.link,
                              fontSize: '0.875rem',
                              display: 'inline-block',
                            }}
                          >
                            clique aqui.
                          </p>
                        </a>
                      </p>
                    </>
                  ) : type === 'inscricao' ? (
                    <>
                      <p
                        className="station-font-medium"
                        style={{ color: '#999999', fontSize: '0.875rem' }}
                      >
                        Você se inscreveu novamente com sucesso em nossa lista.
                      </p>
                    </>
                  ) : null
                ) : type === 'desinscricao' ? (
                  <>
                    <p
                      className="station-font-medium"
                      style={{ color: '#999999', fontSize: '0.875rem' }}
                    >
                      Houve um erro ao desinscrevê-lo(a) em nossa lista, por
                      favor, recarregue a página.
                    </p>
                  </>
                ) : type === 'inscricao' ? (
                  <>
                    <p
                      className="station-font-medium"
                      style={{ color: '#999999', fontSize: '0.875rem' }}
                    >
                      Houve um erro ao inscrevê-lo(a) em nossa página. Por
                      favor, recarregue a página.
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    emailSubscription: state.emailSubscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ subscribeEmail, unsubscribeEmail }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscription);
