import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import type from './type';

const URL_QUIZZES = `${BASE_API}/quizzes/establishment`;

export const getQuizzes = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.QUIZZES_LOAD,
        payload: true,
      },
    ]);

    axios
      .get(`${URL_QUIZZES}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.QUIZZES_FETCHED,
            payload: response.data,
          },
        ]);
      });
  };
};

export const dispatchChosenQuiz = (quiz, history = undefined) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.QUIZ_CHOSEN,
        payload: quiz,
      },
    ]);
    if (!_.isUndefined(history)) {
      history.push('/homepage/quizzes/quiz');
    }
  };
};
