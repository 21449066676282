import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { toastr } from 'react-redux-toastr';
import type from './types';

const URL_RESEARCH = `${BASE_API}/research`;
const URL_ANSWER = `${BASE_API}/answer`;

export const getSurveyById = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.SURVEY_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_RESEARCH}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.SURVEYS_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const submitSurvey = (
  values,
  method = undefined,
  history = undefined
) => {
  return (dispatch) => {
    dispatch({ type: type.SURVEY_LOAD, payload: true });
    axios
      .post(`${URL_ANSWER}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        toastr.success('Sucesso', 'Operação realizada com sucesso.');

        dispatch([
          {
            type: type.SURVEY_LOAD,
            payload: false,
          },
          {
            type: type.SURVEY_SENT,
            payload: true,
          },
        ]);

        /* dispatch([reset(), getList()]); */

        /*  if (!_.isUndefined(history)) {
                    history.push("/owner/");
                } */
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              toastr.error(i, e.response.data[i][j]);
            }
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};
