import ButtonPrimary from 'common/components/buttons/ButtonPrimary';
import FeedbackContent from 'common/components/modal/content/newFeedbackContent';
import Answers from 'common/components/quiz/AnswersParticipant';
import Questions from 'common/components/quiz/QuestionsParticipant';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  getLottieById,
  setFeedbackQuizModal,
  submitCheckListSurvey,
} from '../activitiesActions';
import { Container } from './styles';

let CheckListQuizParticipant = function (props) {
  const [percentage, setPercentage] = useState(0);

  async function onSubmit(values) {
    const { auth, activities, options } = props;
    const newValues = {};

    newValues.user = auth.user.id;
    newValues.author = 'user';
    newValues.action = activities.detail.id;
    newValues.establishment = auth.user.establishment_participant.id;
    newValues.research = activities.detail.researches[0].id;
    newValues.answers = [];

    let marked = [];
    for (let i in values) {
      if (values[i].checked) {
        marked.push(parseInt(i.split('-')[1]));
      }
    }
    newValues.answers.push({
      question: props.question.id,
      options: marked,
    });

    setPercentage((marked.length / options.length).toFixed(2));

    props.submitCheckListSurvey(newValues, props.history);
  }

  return (
    <>
      {!props.activities.feedbackQuizModal ? (
        <Form onSubmit={props.handleSubmit(onSubmit)} noValidate>
          <Field
            name="question"
            component={Questions}
            question={props.question}
          />
          <Container>
            {props.options.map((item, index) => (
              <Field
                name={`option-${item.id}`}
                component={Answers}
                background={item.color ? item.color : '#2D95EF'}
                textColor={item.textColor ? item.textColor : '#FFF'}
                text={item.label ? item.label : null}
                image={item.image ? item.image : null}
              />
            ))}
          </Container>
          <ButtonPrimary
            disabled={props.activities.buttonLoading}
            progress={parseInt(props.general.submitProgress)}
            type="submit"
          >
            {props.activities.buttonLoading ? 'Enviando' : 'Enviar'}
          </ButtonPrimary>
        </Form>
      ) : (
        <FeedbackContent
          visible={props.activities.feedbackQuizModal}
          onCloseRoute="/homepage"
          history={props.history}
          quizFeedbacks={props.activities.detail.researches[0].quiz_feedbacks}
          percentage={percentage}
          lottie={props.activities.lottieDetail}
          setFeedbackQuizModal={props.setFeedbackQuizModal}
        />
      )}
    </>
  );
};

CheckListQuizParticipant = reduxForm({ form: 'checkListForm' })(
  CheckListQuizParticipant
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('checkListForm')(state),
    activities: state.activities,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitCheckListSurvey,
      setFeedbackQuizModal,
      getLottieById,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckListQuizParticipant);
