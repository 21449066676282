/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@material-ui/core';
import ActivityButton from 'common/components/buttons/activityButton';
import ButtonPrimary from 'common/components/buttons/ButtonPrimary';
import AlertDialogSlide from 'common/components/dialog/generalDialog';
import { emailForms } from 'common/components/forms/forms';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import IF from 'common/components/operador/if';
import { COMPANY_NAME } from 'config/consts';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { default as React, useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray, Form, reduxForm } from 'redux-form';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR } from '../../../../config/consts';
import {
  getActionById,
  setDetail,
  setSubscribed,
  submitEmailsPermission,
  subscribe,
} from './activitiesActions';

const checkLimitPerUser = (action) => {
  if (parseInt(action.limitPerUser) <= action.userActions.length) {
    return false;
  } else return true;
};

function MemberGetPermission(props) {
  const [codeVisible, setCodeVisible] = useState(false);
  const [emailVisible, setEmailVisible] = useState(false);

  const { participationStart } = props.activities.detail;
  const { participationEnd } = props.activities.detail;

  function callToActionFunction() {
    if (props.onClick) {
      props.onClick();
    } else {
      setCodeVisible(true);
      setEmailVisible(true);
    }
  }

  function onSubmit(values) {
    props.submitEmailsPermission(
      props.auth.user.name,
      values,
      COMPANY_NAME,
      props.activities.detail.member_get_members[0].points_invited,
      props.history,
      props.auth.user.station.id,
      props.activities.detail.id
    );
  }
  function handleMgmModal() {
    setCodeVisible(false);
  }

  return (
    <>
      <div className="row justify-content-center mt-2 mb-2">
        <div className="col-12">
          <Typography variant="body1">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activities.detail.description),
              }}
            ></div>
          </Typography>
        </div>
      </div>
      <div className="row justify-content-center mt-2 mb-5">
        <div className="col-10" style={{ background: '#000' }}>
          {props.activities.detail.action_videos.length > 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ReactPlayer
                config={{
                  file: { attributes: { controlsList: 'nodownload' } },
                }}
                width={'100%'}
                height={'400px'}
                controls
                style={{ backgroundColor: 'black', float: 'left' }}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="row justify-content-center">
          <>
            {participationStart && participationEnd ? (
              moment().isBetween(participationStart, participationEnd) ? (
                props.public ? (
                  <ActivityButton
                    onClick={() => props.history.push('/cadastrar')}
                    disabled={props.activities.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}
                    label={'Gerar convite'}
                  />
                ) : checkLimitPerUser(props.activities.detail) ? (
                  <>
                    <ActivityButton
                      onClick={callToActionFunction}
                      label={'Gerar convite'}
                    />
                    <AlertDialogSlide
                      onClose={handleMgmModal}
                      visible={codeVisible}
                      backgroundColor={COMPANY_SIGNED_IN_BACKGROUND_COLOR}
                      titleBackground={COMPANY_SIGNED_IN_BACKGROUND_COLOR}
                    >
                      <IF test={emailVisible}>
                        <Form
                          role="form"
                          onSubmit={props.handleSubmit(onSubmit)}
                          style={{
                            backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                          }}
                        >
                          <FieldArray
                            name="emails"
                            component={emailForms}
                            props={props}
                          />
                          <GeneralGrid container spacing={2}>
                            <ButtonPrimary type="submit" color="primary">
                              Enviar convite
                            </ButtonPrimary>
                          </GeneralGrid>
                        </Form>
                      </IF>
                    </AlertDialogSlide>
                  </>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography variant="body1">
                      Olá, você já ultrapassou o limite de participações nessa
                      atividade!
                    </Typography>
                    <ActivityButton
                      onClick={() => props.history.push('/homepage/vouchers')}
                      label={'Ir para meus Vouchers!'}
                      style={{ marginTop: 15 }}
                    />
                  </Box>
                )
              ) : (
              <Typography
                element="p"
              >
                {`Você pode participar entre os dias ${moment(participationStart).format('DD/MM/YYYY')}
                às ${moment(participationStart).format('HH:mm')} e
                ${moment(participationEnd).format('DD/MM/YYYY')}
                às ${moment(participationEnd).format('HH:mm')}.`}
              </Typography>
              )
            ) : props.public ? (
              <ActivityButton
                onClick={() => props.history.push('/cadastrar')}
                disabled={props.activities.buttonLoading}
                progress={parseInt(props.general.submitProgress)}
                label={'Gerar convite'}
              />
            ) : checkLimitPerUser(props.activities.detail) ? (
              <>
                <ActivityButton
                  onClick={callToActionFunction}
                  label={'Gerar convite'}
                />
                <AlertDialogSlide
                  onClose={handleMgmModal}
                  visible={codeVisible}
                >
                  <IF test={emailVisible}>
                    <Form role="form" onSubmit={props.handleSubmit(onSubmit)}>
                      <FieldArray
                        name="emails"
                        component={emailForms}
                        props={props}
                      />
                      <GeneralGrid container spacing={2}>
                        <ButtonPrimary type="submit" color="primary">
                          Enviar convite
                        </ButtonPrimary>
                      </GeneralGrid>
                    </Form>
                  </IF>
                </AlertDialogSlide>
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography variant="body1">
                  Olá, você já ultrapassou o limite de participações nessa
                  atividade!
                </Typography>
                <ActivityButton
                  onClick={() => props.history.push('/homepage/vouchers')}
                  label={'Ir para meus Vouchers!'}
                  style={{ marginTop: 15 }}
                />
              </Box>
            )}
          </>
        </div>
      </div>
    </>
  );
}

let MemberGetPermissionForm = reduxForm({ form: 'memberGetPermissionForm' })(
  MemberGetPermission
);
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      subscribe,
      setSubscribed,
      submitEmailsPermission,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberGetPermissionForm);
