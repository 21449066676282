import { useURLQueryParams } from 'hooks/useURLQueryParams';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validateShare } from '../../modules/user/pages/activities/activitiesActions';

function ShareValidation({ history, validateShare, activities }) {
  const query = useURLQueryParams(history);

  useEffect(() => {
    const source = query.get('source');
    const campaign = query.get('campaign');
    validateShare({ action_id: campaign, user_id: source });
  }, [query, validateShare]);

  useEffect(() => {
    const { shareLink } = activities;
    if (shareLink) {
      window.location = shareLink;
    }
  }, [activities]);

  return null;
}

const mapStateToProps = (state) => {
  return {
    activities: state.activities,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      validateShare,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareValidation);
