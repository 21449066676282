import React from 'react'
import { Grid } from '@material-ui/core';
import SimpleBlockSkeleton from './simpleBlockSkeleton';
import PropTypes from 'prop-types';

function CarouselSkeleton(props) {
  const {
    elementsHeight,
    animation
  } = props;

  return (
    <Grid
      item
      justifyContent="space-between"
      spacing={0}
      container
      xs={12}
      style={{ maxHeight: elementsHeight, margin: 0 }}
    >
      {
        [1, 2, 3, 4].map((e) => {
          return (
            <SimpleBlockSkeleton
              key={e}
              width="23%"
              height={elementsHeight}
              variant="rect"
              animation={animation || "pulse"}
            />
          );
        })
      }
    </Grid>
  )
}

CarouselSkeleton.propTypes = {
  elementsHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  animation: PropTypes.string,
};

export default CarouselSkeleton;
