// import moment from 'moment';
import React, { Component } from 'react';
import '../privacyPolicyStyle.css';

// const styles = {
//   container: {
//     flex: 1,
//     paddingTop: 30,
//     backgroundColor: '#fff',
//     marginBottom: 16,
//   },
//   head: { height: 60, backgroundColor: '#fff' },
//   text: { marginVertical: 8, textAlign: 'left', fontWeight: '300' },
//   bulletText: {
//     marginVertical: 8,
//     paddingLeft: 16,
//     textAlign: 'left',
//     fontWeight: '300',
//   },
//   title: {
//     fontSize: 32,
//     fontWeight: '600',
//     marginVertical: 8,
//     textAlign: 'left',
//   },
//   subtitle: {
//     fontSize: 20,
//     textAlign: 'left',
//     fontWeight: '500',
//   },
// };

export default class BrbFlaUsePolicyText extends Component {
  render() {
    // const { socialName, stationName, createdAt } = this.props;
    return (
      <div className="brb-fla-policy" style={{textAlign: 'left'}}>
        <p><span>O Banco de Brasília S.A. e as suas afiliadas respeitam a sua privacidade.</span></p>
        <p>Este Aviso de Privacidade Global descreve os tipos de Dados Pessoais que coletamos, as finalidades para as quais coletamos esses Dados Pessoais, os terceiros com as quais poderemos compartilhá-los e as medidas que adotamos para proteger a segurança dos dados. Também o informa sobre os seus direitos e escolhas no que diz respeito aos seus Dados Pessoais, e sobre como você pode entrar em contato conosco para saber mais sobre as nossas práticas de privacidade.</p>
        <p>Por favor note que nós também agimos em nome e de acordo com as instruções de instituições financeiras, estabelecimentos comerciais e outros parceiros que atuam como controladores de dados, inclusive para o processamento de transações de pagamento. Por favor, consulte as respectivas políticas de privacidade para mais informações relativas ao tratamento dos seus Dados Pessoais nesses contextos.</p>
        <p><b>1. Dados Pessoais que Podemos Coletar</b><br />“Dados Pessoais” significa qualquer informação relativa à uma pessoa natural identificada ou identificável. Podemos coletar as seguintes categorias de Dados Pessoais:</p>
        <p>Informações pessoais, dados sobre transações, número de conta, o nome e a localização do estabelecimento comercial em que a transação foi realizada, a data e o valor total da transação, bem como outras informações fornecidas por outras instituições financeiras ou pelos estabelecimentos comerciais quando somos intermediários das operações.</p>
        <p>Informações sobre produtos e serviços, como informações de registro e pagamento, informações sobre programas específicos, quando você nos solicita produtos ou serviços diretamente.</p>
        <p>Informações sobre a utilização de Internet Banking, aplicativos móveis, terminais de autoatendimento e informações semelhantes coletadas por meios automatizados através da utilização dos canais de atendimento.</p>
        <p><b>2. Como Podemos Usar Seus Dados Pessoais</b><br />Podemos usar seus Dados Pessoais para:</p>
        <p>Processar suas transações de consulta e transações financeiras.</p>
        <p>Proteger contra e prevenir fraudes, e outros riscos jurídicos ou de segurança da informação.</p>
        <p>Fornecer-lhe e comunicar a você produtos e serviços oferecidos pelo BRB, BRBCard, SegurosBRB e estabelecimentos comerciais parceiros.</p>
        <p>Fornecer-lhe serviços e recomendações personalizadas.</p>
        <p>Operar, avaliar e melhorar a nosso negócio, incluindo a anonimização e a análise de dados.</p>
        <p>Para outras finalidades para as quais fornecemos um aviso específico no momento da coleta, ou, de outro modo, conforme autorizado ou exigido por lei.</p>
        <p>Conhecê-lo, incluindo as suas preferências ou outras características. Tratamos essas inferências como Dados Pessoais, quando exigidas pela legislação aplicável.</p>
        <p>Sempre que for exigido nos termos da legislação aplicável, nós só utilizaremos os seus Dados Pessoais com o seu consentimento; conforme necessário para lhe fornecer produtos e serviços; para cumprir com uma obrigação legal; ou quando houver um interesse legítimo e imperativo que requeira o uso.</p>
        <p>O BRB pode utilizar seus dados biométricos (Digital e fotografia do rosto) para identificação, autenticação e cadastramento de novo dispositivo em nossas plataformas. A Finalidade da utilização desses dados será para maior garantia da prevenção à fraude e maior segurança dos titulares respeitadas as legítimas expectativas, os direitos e liberdades fundamentais.</p>
        <p><b>3. Como Compartilhamos os Seus Dados Pessoais</b><br />Podemos compartilhar seus Dados Pessoais com:</p>
        <p>Entidades dentro do grupo de empresas do BRB.</p>
        <p>Prestadores de serviços que atuem em nosso favor.</p>
        <p>Outros participantes do ecossistema de pagamentos, incluindo instituições financeiras e estabelecimento comerciais.</p>
        <p>Outros intervenientes no ecossistema de Open Banking, incluindo instituições financeiras, estabelecimentos comerciais e terceiros.</p>
        <p>Terceiros, para fins de controle e prevenção de fraude, ou para outras finalidades exigidas por lei.</p>
        <p>Terceiros, cujo recurso você utilize no âmbito de nossos produtos e serviços, ou com o seu consentimento.</p>
        <p>Outras entidades, conforme exigido pela legislação aplicável, ou em caso de venda ou transferência de nosso negócio ou ativos.</p>
        <p><b>4. Os Seus Direitos e as Suas Escolhas</b><br />Você poderá ter o direito ou a escolha de:</p>
        <p>Desativar a coleta ou uso de alguns Dados Pessoais, incluindo a utilização de cookies e tecnologias similares, a utilização dos seus Dados Pessoais para fins de marketing e anonimização dos seus Dados Pessoais para análises de dados.</p>
        <p>Acessar seus Dados Pessoais, retificá-las, limitar ou se opor ao seu tratamento ou solicitar a sua exclusão ou anonimização.</p>
        <p>Receber os Dados Pessoais que você nos forneceu para os transmitir para outra empresa.<br />Retirar qualquer consentimento que tenha dado.</p>
        <p>Sempre que for caso, apresentar queixa junto à sua autoridade supervisora.</p>
        <p>Se não correspondermos às suas expetativas, no que diz respeito ao tratamento dos seus Dados Pessoais, ou se você pretender apresentar uma queixa sobre as nossas práticas em matéria de privacidade, solicitamos que nos informe, dando-nos assim a oportunidade de resolver o problema. Para nos ajudar a responder ao seu pedido, solicitamos que nos forneça informações pormenorizadas sobre a questão. Procuramos analisar e responder a todas as queixas dentro de um prazo razoável e conforme exigido por lei.</p>
        <p><b>5. Como protegemos os seus Dados Pessoais</b><br />Aplicamos medidas de segurança adequadas para proteger os seus Dados Pessoais e só os conservamos durante um período de tempo limitado.</p>
        <p>A segurança dos seus Dados Pessoais é importante para o BRB. Estamos empenhados em proteger as informações que coletamos. Aplicamos adequadas medidas administrativas, técnicas e físicas que visam proteger os Dados Pessoais que você fornece ou que coletamos contra destruição, perda, alteração, acesso, divulgação ou uso acidental, ilício ou não autorizado.</p>
        <p>Também adotamos medidas para apagar seus Dados Pessoais ou conservá-las em um formato que não permita identificá-lo quando os dados deixarem de ser necessários para as finalidades para as quais as tratamos, exceto se formos obrigados por lei a conservar esses dados por um período de tempo maior.</p>
        <p><b>6. Privacidade dos Menores</b><br />Os produtos e serviços do Banco de Brasília não são dirigidos, nem direcionados a menores de 18 anos. Não obstante, o BRB poderá coletar Dados Pessoais de menores de 18 anos juntamente aos seus pais ou representantes legais, mediante o consentimento explícito destes.</p>
        <p><b>7. Atualizações a este Aviso de Privacidade Global</b><br />Este Aviso de Privacidade Global poderá ser atualizado periodicamente para refletir as alterações introduzidas em nossas práticas em matéria de Dados Pessoais. Publicaremos um aviso em destaque em nos sites relevantes, para lhe dar conhecimento sobre alterações significativas e/ou materiais em nosso Aviso de Privacidade Global, antes de que se tornem vigentes, com indicação, no início deste Aviso, da data da última atualização. Caso nós atualizemos a nosso Aviso de Privacidade Global, poderemos, em certos casos, pedir o seu consentimento.</p>
        <p><b>8. Como nos contatar</b><br />Encarregado Setorial: Verandir Araujo da Silva<br />Suplente: Fabiano Almeida Leal<br />E-mail : dpo@BRB.com.br</p>
        <p>Telebanco<br />3322 1515 (Distrito Federal)<br />4002 6161 (Rio de Janeiro capital e Goiânia)<br />0800 61 3030 (Outras Localidades)<br />55 61 3322 1515 (Clientes no exterior)<br />Canal de atendimento que conta com uma equipe especializada à disposição de seus clientes, oferecendo comodidade, rapidez e segurança na realização de transações bancárias, consultas e contestações.<br />Atendimento eletrônico (consulta de saldo e extrato): das 06h às 24h.<br />Atendimento humano: das 07h às 22h*.</p>
        <p>SAC<br />0800 648 6161<br />Pessoa com deficiência auditiva/fala<br />0800 648 6162 **ou por meio do chat BRB, clique aqui</p>
        <p>O SAC está disponível ao cliente para fornecer informações sobre produtos e serviços, registrar reclamações*, esclarecer dúvidas e atender pedidos de cancelamento de cartão.<br />Atendimento 24 horas por dia, todos os dias da semana.<br />*Reclamações relacionadas a contestações de movimentações financeiras em conta e suspeita de fraude devem ser realizadas por meio do Telebanco (clique aqui).<br />**O atendimento telefônico aos clientes/usuários com limitação PCD-auditiva por meio do número 0800 648 6162 está indisponível para manutenção</p>						</div>
    );
  }
}
