import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COMPANY_COLORS } from '../../../config/consts';

const useStyles = makeStyles(theme => ({
  progress: {
    color: COMPANY_COLORS.primary,
  },
}));

export default function LoadingProgress(props) {
  const classes = useStyles();

  return (
    <div>
      <CircularProgress
        style={props.customColor ? { color: props.customColor } : null}
        className={props.customColor ? null : classes.progress}
      />
      <p style={{ fontSize: 14, display: 'inline-block', marginLeft: '1rem' }}>
        {props.children}
      </p>
    </div>
  );
}
