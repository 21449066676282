import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR, GENERAL_TEXT_COLOR } from '../../../config/consts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide(props) {
  return (
    <div>
      <Dialog
        open={props.visible}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            minHeight: props.height || null,
            minWidth: props.maxWidth === 'xl' ? '100%' : null,
            backgroundColor: props.backgroundColor ? props.backgroundColor : COMPANY_SIGNED_IN_BACKGROUND_COLOR,
            // backgroundColor: 'red'
          },
        }}
        onClose={props.onClose}
        style={{
          padding: '0px important!',
        }}
        fullWidth={true}
        maxWidth={props.maxWidth || 'md'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <MuiDialogTitle
          hidden={props.hiddenTitle || false}
          disableTypography
          style={{
            background: props.titleBackground || COMPANY_SIGNED_IN_BACKGROUND_COLOR,
            padding: 0,
          }}
        >
          <Typography variant="h6">
            {props.title}
            {props.onClose ? (
              <IconButton
                style={{
                  float: 'right',
                  color: GENERAL_TEXT_COLOR
                }}
                aria-label="close"
                onClick={props.onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Typography>
        </MuiDialogTitle>
        <DialogContent>
          <div style={{ padding: '0 30px 30px 30px' }}>{props.children}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AlertDialogSlide;
