import React, { Component } from 'react';
import './generalButton.css';

class GeneralButton extends Component {
  render() {
    const { onClick, type, style } = this.props;
    if (onClick) {
      return (
        <button
          type={type}
          className="general-button"
          style={style}
          onClick={onClick}
        >
          {this.props.label}
        </button>
      );
    } else {
      return (
        <button type={type} className="general-button" style={style}>
          {this.props.label}
        </button>
      );
    }
  }
}

export default GeneralButton;
