import React from 'react';

export default function DownArrow(props) {
  return (
    <svg
      width="12px"
      height="7px"
      viewBox="0 0 12 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      fill={props.color || 'inherit'}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="inherit"
        fillRule="evenodd"
      >
        <g
          id="Icon-/-Down-arrow"
          transform="translate(-6.000000, -9.000000)"
          fill="inherit"
        >
          <path
            d="M12,13.5857864 L16.2928932,9.29289322 C16.6834175,8.90236893 17.3165825,8.90236893 17.7071068,9.29289322 C18.0976311,9.68341751 18.0976311,10.3165825 17.7071068,10.7071068 L12.7071068,15.7071068 C12.3165825,16.0976311 11.6834175,16.0976311 11.2928932,15.7071068 L6.29289322,10.7071068 C5.90236893,10.3165825 5.90236893,9.68341751 6.29289322,9.29289322 C6.68341751,8.90236893 7.31658249,8.90236893 7.70710678,9.29289322 L12,13.5857864 Z"
            id="🎨-Icon-Color"
          ></path>
        </g>
      </g>
    </svg>
  );
}
