import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FullButton } from 'common/components/buttons/fullButton';
import {
  CaptchaInput,
  InputLabelOutlined,
} from 'common/components/fields/fields';
import { COMPANY_STATION_ID } from 'config/consts';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, reduxForm } from 'redux-form';
import { submitSupport } from './contactUsActions';

const useStyles = makeStyles((props) => ({
  root: {
    marginTop: props.xs || props.sm ? '1rem' : '2rem',
    backgroundColor: '',
    minHeight: '100vh',
  },
  flexForm: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    minWidth: '100%',
  },
  captcha: {
    marginTop: '1rem',
  },
}));

let ContactUs = function (props) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles(xs, sm);

  function onSubmit(values) {
    values['station_id'] = COMPANY_STATION_ID;
    values['user_id'] = props.auth.user.id;
    values['name'] = props.auth.user?.name;
    values['phone'] = props.auth.user?.tel;
    values['email'] = props.auth.user.email;
    props.submitSupport(values, props.history);
  }

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
        className={classes.root}
      >
        {/* NORMALIZE GRID */}
        <Grid item container xs={12} sm={10} md={8}>
          {/* TITLE + SUBTITLE*/}
          <Grid item container direction="column" alignItems="center" xs={12}>
            <Typography variant="h4"
            
            style={{
              textAlign: 'center',
              marginTop: 64,
              fontSize: 24,
              marginBottom: 48,
            }}>Fale conosco</Typography>
            
          </Grid>
          {/* FORM */}
          <Form
            onSubmit={props.handleSubmit(onSubmit)}
            noValidate
            className={classes.flexForm}
          >
            {/* FIELDS */}
            <Grid
              container
              // direction="column"
              justifyContent="center"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={12} sm={10} md={7}>
                <Field
                  component={InputLabelOutlined}
                  label="Assunto"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  limitChar={200}
                  name="subject"
                  validate={[FORM_RULES.required]}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={7}>
                <Field
                  component={InputLabelOutlined}
                  label="Mensagem"
                  limitChar={600}
                  inputlabelprops={{
                    shrink: true,
                  }}
                  multiline
                  rows={5}
                  name="message"
                  validate={[FORM_RULES.required]}
                />
              </Grid>
              {/* FIELD CAPTCHA */}
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={7}
                className={classes.captcha}
              >
                <Field
                  component={CaptchaInput}
                  inputlabelprops={{
                    shrink: true,
                  }}
                  name="captcha"
                  validate={[FORM_RULES.required]}
                />
              </Grid>
              {/* SUBMIT BUTTON */}
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={7}
              >
                <FullButton
                  classNameProp="py-3 px-4"
                  type="submit"
                  disabled={props.website.buttonLoading}
                >
                  Enviar
                </FullButton>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </>
  );
};

ContactUs = reduxForm({ form: 'supportForm' })(ContactUs);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    general: state.general,
    website: state.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitSupport,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
