/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import DOMPurify from 'dompurify';
import ReactPlayer from 'react-player';
import { Grid, makeStyles } from '@material-ui/core';
import { DomPurifyContentContainer } from './styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  }
}));

function ActivityRawContent(props) {
  const classes = useStyles();

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <DomPurifyContentContainer
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.activities.detail.description),
            }}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          {
            props.activities.detail.action_videos.length > 0 ? (
              <Grid item container xs={12} justyfy="center" alignItems="center">
                <ReactPlayer
                  width={'100%'}
                  height={ isMobile ? '17rem' : '30rem' }
                  controls
                  className={ classes.reactPlayer }
                  style={{ backgroundColor: 'black', float: 'left' }}
                  key={props.activities.detail.action_videos[0].video.url}
                  url={props.activities.detail.action_videos[0].video.url}
                />
              </Grid>
            ) : null
          }
        </Grid>
      </Grid>
    </>
  );
}

export default ActivityRawContent;
