import { COMPANY_COLORS } from 'config/consts';
import React from 'react';

export default function ActivitiesCard(props) {
  const { image, text, summary, haveImage } = props;
  const MAX_LENGTH_TITLE = 70;
  const MAX_LENGTH_SUMMARY = 82;

  return (
    <div>
      <div
        className="card"
        style={{
          width: '100%',
          height: haveImage ? 320 : 150,
          borderWidth: 0.1,
          maxWidth: 330,
          minWidth: 240,
        }}
      >
        {haveImage ? (
          <div
            style={{
              position: 'relative',
              paddingBottom: '56.25%',
              width: '100%',
              height: 0,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <div
                style={{
                  background: `url(${image}) center center / contain no-repeat`,
                  height: '100%',
                  width: '100%',
                }}
              ></div>
            </div>
          </div>
        ) : null}

        <div
          className="card-body"
          style={{
            marginTop: 10,
            paddingTop: 10,
            paddingBottom: 0,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="d-flex flex-column justify-content-start h-100">
              <p
                className="station-font-semibold"
                style={{
                  margin: 0,
                  padding: 0,
                  color: COMPANY_COLORS.activityCard.text,
                  fontSize: '1rem',
                  lineHeight: '1.375rem',
                  minHeight: 20,
                  height: 'auto',
                  maxHeight: 60,
                  overflow: 'hidden',
                  textAlign: 'left',
                }}
              >
                {text
                  ? text.length > MAX_LENGTH_TITLE
                    ? `${text.substring(0, MAX_LENGTH_TITLE)}...`
                    : text
                  : null}
              </p>
              <p
                style={{
                  color: '#626262',
                  fontSize: '0.9rem',
                  minHeight: 20,
                  height: 'auto',
                  maxHeight: 60,
                  overflow: 'hidden',
                  lineHeight: '1.275rem',
                  textAlign: 'left',
                  margin: 0,
                  padding: 0,
                }}
              >
                {summary
                  ? summary.length > MAX_LENGTH_SUMMARY
                    ? `${summary.substring(0, MAX_LENGTH_SUMMARY)}...`
                    : summary
                  : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
