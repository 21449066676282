import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import promise from 'redux-promise';
import multi from 'redux-multi';
import thunk from 'redux-thunk';
import ReduxToastr from 'react-redux-toastr';
import reducers from './main/reducers';
import Routes from './main/routes';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './styles/styles.scss';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-typist/dist/Typist.css';
import CustomizedSnackbar from 'common/components/snackbar/Snackbar';
import moment from 'moment';
import 'moment/locale/pt-br'; // without this line it didn't work`
import { createTheme, ThemeProvider } from '@material-ui/core';
import {
  BUTTON_BACKGROUND_COLOR,
  COMPANY_COLORS,
  COMPANY_FONT_FAMILY,
  GENERAL_TEXT_COLOR,
  HEADER_FONT_FAMILY,
  HEADER_TEXT_COLOR,
  LINK_TEXT_COLOR,
  NAV_FONT_FAMILY,
  SIGNED_IN_NAV_TEXT_COLOR,
  SIGNED_OFF_NAV_TEXT_COLOR,
} from 'config/consts';

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = applyMiddleware(multi, thunk, promise)(createStore)(
  reducers,
  devTools
);
moment.locale('pt-BR');

const theme = createTheme({
  typography: {
    fontFamily: [COMPANY_FONT_FAMILY].join(','),
    h1: {
      color: HEADER_TEXT_COLOR,
      fontFamily: HEADER_FONT_FAMILY,
    },
    h2: {
      color: HEADER_TEXT_COLOR,
      fontFamily: HEADER_FONT_FAMILY,
    },
    h3: {
      color: HEADER_TEXT_COLOR,
      fontFamily: HEADER_FONT_FAMILY,
    },
    h4: {
      color: HEADER_TEXT_COLOR,
      fontFamily: HEADER_FONT_FAMILY,
    },
    h5: {
      color: HEADER_TEXT_COLOR,
      fontFamily: HEADER_FONT_FAMILY,
    },
    h6: {
      color: HEADER_TEXT_COLOR,
      fontFamily: HEADER_FONT_FAMILY,
    },
    subtitle1: {
      color: SIGNED_IN_NAV_TEXT_COLOR,
      display: 'inline-block',
      fontFamily: NAV_FONT_FAMILY,
    },
    subtitle2: {
      color: LINK_TEXT_COLOR,
      display: 'inline-block',
      fontFamily: NAV_FONT_FAMILY,
      fontSize: 16,
    },
    subtitle3: {
      color: SIGNED_OFF_NAV_TEXT_COLOR,
      display: 'inline-block',
      fontFamily: NAV_FONT_FAMILY,
    },
    body1: {
      color: GENERAL_TEXT_COLOR,
    },
    body2: {
      color: GENERAL_TEXT_COLOR,
    },
    caption: {
      color: COMPANY_COLORS.primary,
    },
  },
  button: {
    fontFamily: NAV_FONT_FAMILY,
    color: SIGNED_IN_NAV_TEXT_COLOR,
    backgroundColor: BUTTON_BACKGROUND_COLOR,
  },
  palette: {
    primary: {
      main: GENERAL_TEXT_COLOR,
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <div style={{ minHeight: '100vh' }}>
        <Routes />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <CustomizedSnackbar />
      </div>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.

// Learn more about service workers: http://bit.ly/CRA-PWA
//registerServiceWorker();
