import React from 'react';
import './styles.css';

const LegalGeneralInfo = () => {
  return (
    <section id="legal-info" className="d-flex justify-content-center mt-5">
      <div style={{ maxWidth: 600, lineHeight: 42 }}>
        <p className="body-title mt-5">Como usar</p>
        <p className="h6 mt-5">
          A Consultoria Jurídica presta atendimento de segunda à sexta-feira,
          das 9 às 18 horas.
        </p>
        <p className="h6 mt-4">
          O atendimento dos advogados é realizado nas segundas, quartas e
          sextas-feiras das 9 às 13 horas mediante prévio agendamento que, pode
          ser feito conforme a seguir:
        </p>

        <p className="h6 mt-4">
          - Por esta plataforma{' '}
          <a
            href={
              process.env.REACT_APP_API_KEY === 'production'
                ? 'https://mais.sindilegis.org.br/#/homepage/consultoria-juridica/agendamentos'
                : process.env.REACT_APP_API_KEY === 'test'
                ? 'http://sindilegisteste.fantest.site/#/homepage/consultoria-juridica/agendamentos'
                : 'http://localhost:3000/#/homepage/consultoria-juridica/agendamentos'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#000' }}
            className="h6"
          >
            clique aqui.
          </a>
        </p>
        <p className="h6">- Por telefone – (61) 3214-7301 ou 3214-7339</p>
        <p className="h6">- Por e-mail – consulegis@sindilegis.org.br</p>
        <p className="h6 mt-4">
          Observação: devido a atual pandemia provocada pelo COVID-19, nosso
          atendimento está sendo realizado exclusivamente on line. Pode ser
          feito por e-mail, telefone ou se ainda preferir, para o seu conforto,
          por meio de vídeo conferência.
        </p>
        <p className="h6-bold mt-4">Consultoria Jurídica gratuita</p>
        <p className="h6">
          O Sindicato, por meio da Consultoria Jurídica e dos escritórios
          terceirizados, prestam assistência judiciária gratuita somente aos
          filiados nas seguintes áreas do direito: Civil; Família; Consumidor;
          Previdenciário; Administrativo; Tributário; Contratual; Criminal
          (exclusivo aos policiais legislativos em exercício da função).
        </p>
      </div>
    </section>
  );
};

export default LegalGeneralInfo;
