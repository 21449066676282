import { Box, Grid } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProcessesList } from './accountingActions';
import AccountingProcessesCard from './accountingProcessesCard';
import AccountingProcessesDetailModal from './accountingProcessesDetailModal';
import './styles.css';

const AccountingProcesses = ({ getProcessesList, accounting }) => {
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [selectedLawsuit, setSelectedLawsuit] = useState(null);

  function handleClose() {
    setSelectedLawsuit(null);
    setIsDetailDialogOpen(false);
  }

  function handleOpen(lawsuit) {
    setSelectedLawsuit(lawsuit);
    setIsDetailDialogOpen(true);
  }

  useEffect(() => {
    getProcessesList();
  }, [getProcessesList]);

  const { processesList, processesLoading } = accounting;

  return (
    <section id="processes">
      <p className="body-title mt-4">Processos e procedimentos</p>
      {processesLoading ? (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      ) : processesList.length > 0 ? (
        <Grid container spacing={3} style={{ marginTop: 32 }}>
          {processesList.map((item) => (
            <Grid item xs={4} key={item.numeroProcesso}>
              <AccountingProcessesCard
                onClick={() => handleOpen(item)}
                header={`${item.tipo}/${format(
                  new Date(item.dataProtocolo),
                  'yyyy'
                )}`}
                type={item.tipo}
                status={item.situacao}
                body={item.numeroProcesso}
                footer={item.tribunalOrgao}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <p className="h4 mt-4">Não foram encontrados processos em seu CPF.</p>
      )}
      {selectedLawsuit && (
        <AccountingProcessesDetailModal
          onClose={handleClose}
          isOpen={isDetailDialogOpen}
          title={`${selectedLawsuit.tipo}/${format(
            new Date(selectedLawsuit.dataProtocolo),
            'yyyy'
          )}`}
          status={selectedLawsuit.situacao}
          type={selectedLawsuit.tipo}
          body={selectedLawsuit.numeroProcesso}
          footer1={selectedLawsuit.tribunalOrgao}
          footer2={selectedLawsuit.valorCausa}
          moreInfoLink={selectedLawsuit.linkProcesso}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    accounting: state.accounting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProcessesList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountingProcesses);
