/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  CircularProgress,
  Typography,
  useMediaQuery,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AboutCard from 'common/components/cards/aboutCard';
import { DefaultCard } from 'common/components/cards/DefaultCard';
import AlertDialogSlide from 'common/components/dialog/generalDialog';
import Spinner from 'common/components/spinner/spinner';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  COMPANY_SIGNED_OFF_BACKGROUND_COLOR,
  COMPANY_STATION_ID,
  GENERAL_TEXT_COLOR,
  HEADER_TEXT_COLOR,
  IS_OLD_FAN,
} from 'config/consts';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import ItemsCarousel from 'react-items-carousel';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  COMPANY_WEBSITE_IMAGES,
  COMPANY_WEBSITE_SECTIONS,
} from '../../config/consts';
import PrivacyPolicy from '../../pages/auth/policies/privacyPolicy';
import UseTerms from '../../pages/auth/policies/usePolicy';
import NavigationBar from '../common/components/Header/navigationBar';
import { changePublicActionsModal } from '../user/pages/activities/activitiesActions';
import CookieConsent from './BeforeContinue/cookieConsent';
import MajorityAgeTerm from './BeforeContinue/majorityAgeTerm';
import Footer from './modules/footer';
import Plans from './sections/Plans';
import { BlockButton } from './style';
import SupportMessageForm from './supportMessageForm';
import './website.css';
import {
  changeHeaderIndex,
  getAboutList,
  getBenefitsList,
  getPublicActions,
  getStationData,
} from './websiteActions';

function Website(props) {
  const { history } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openModalSec, setOpenModalSec] = useState(false);
  const [id] = useState(COMPANY_STATION_ID);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 30;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));

  useEffect(() => {
    props.getAboutList(id);
    props.getBenefitsList(id);
    props.getPublicActions(id);
    props.getStationData(id);
  }, []);

  useEffect(() => {
    if (isMobileOnly) {
      const timer = setTimeout(
        () =>
          props.changeHeaderIndex(
            COMPANY_COLORS.website.mobile_headers.filter((item) => item)
              .length < props.website.headerIndex
              ? 0
              : props.website.headerIndex + 1
          ),
        5000
      );
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(
        () =>
          props.changeHeaderIndex(
            COMPANY_COLORS.website.desktop_headers.filter((item) => item)
              .length < props.website.headerIndex
              ? 0
              : props.website.headerIndex + 1
          ),
        5000
      );
      return () => clearTimeout(timer);
    }
  }, [props.website.headerIndex]);

  function favicon(href) {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `https://s3.amazonaws.com/fermento.app.files/${href}`;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  favicon(COMPANY_COLORS.general.favicon);

  function handleActionClick(id, action) {
    if (parseInt(action.type_id) === 13) {
      return window.open(action.redirect_links[0].url);
    }
    props.changePublicActionsModal(true);
    props.history.push(`/atividade/${id}`);
  }

  function renderWhat() {
    return props.website.aboutLoading ? (
      <Grid
        item
        container
        style={{ minHeight: '80vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    ) : !_.isEmpty(props.website.aboutList.content) ? (
      props.website.aboutList.content.map((item, index) => {
        // Maps sections
        // console.log(item.type.id);
        if (item.type_id === 1) {
          // GRID 2X3 (ORIGINAL)
          return (
            <section
              key={index.toString()}
              id={`secao${item.id}`}
              style={{
                background:
                  item.background_type === 'image'
                    ? `url(${BASE_S3}/${item.background_image})`
                    : item.background_type === 'color'
                    ? item.background_color
                    : '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `center`,
              }}
            >
              {/* NAVBAR */}
              {index === 0 ? (
                <>
                  <NavigationBar website={props.website} history={history} />
                </>
              ) : null}
              <article
                key={index.toString()}
                className="row justify-content-center pt-5"
                style={{
                  paddingTop:
                    (item.title === null && item.subtitle === null) ||
                    item.title === null ||
                    item.subtitle === null
                      ? 32
                      : null,
                }}
              >
                <div className="col-12 col-md-10 col-xl-9 ">
                  <div
                    className="my-3"
                    style={{
                      margin: '0 auto',
                      width: isMobileOnly ? '85%' : '50%',
                    }}
                  >
                    <div className="row justify-content-center">
                      <div className="col-auto mb-3 mb-md-3">
                        <Typography
                          variant="h1"
                          style={{
                            fontWeight: '700',
                            fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
                            textAlign: isMobileOnly ? 'center' : 'center',
                            color: item.title_color || HEADER_TEXT_COLOR,
                          }}
                        >
                          {item.title || null}
                        </Typography>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-auto mb-3 mb-md-5">
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: isMobileOnly ? '1rem' : '1.0rem',
                            textAlign: isMobileOnly ? 'center' : 'center',
                            color: item.subtitle_color || GENERAL_TEXT_COLOR,
                          }}
                        >
                          {item.subtitle || null}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {item.section_items.map(
                      (
                        sectionItem,
                        index // Maps blocks
                      ) => (
                        <div
                          className="col-sm-12 col-md-4 mb-4 mb-md-0"
                          key={index.toString()}
                        >
                          <div
                            onClick={() => {
                              if (sectionItem.link_type === 'external') {
                                window.location.href =
                                  sectionItem.external_link;
                                return;
                              }
                              props.history.push(
                                sectionItem.link_type === 'nolink'
                                  ? '/'
                                  : sectionItem.link_type === 'login'
                                  ? '/login'
                                  : sectionItem.link_type === 'signup'
                                  ? '/cadastrar'
                                  : sectionItem.link_type === 'additional'
                                  ? `/sobre/${sectionItem.id}`
                                  : '/'
                              );
                            }}
                            style={
                              sectionItem.link_type !== 'nolink'
                                ? {
                                    cursor: 'pointer',
                                  }
                                : {}
                            }
                          >
                            <AboutCard
                              image={sectionItem.image}
                              title={sectionItem.name}
                              text={sectionItem.description}
                              titleColor={item.title_color || HEADER_TEXT_COLOR}
                              subtitleColor={
                                item.subtitle_color || GENERAL_TEXT_COLOR
                              }
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </article>
            </section>
          );
        } else if (item.type_id === 2) {
          // GRID TEXTO IMAGEM VERTICALIZADO
          if (isMobileOnly) {
            return (
              <section
                id={`secao${item.id}`}
                style={{
                  background:
                    item.background_type === 'image'
                      ? `url(${BASE_S3}/${item.background_image})`
                      : item.background_type === 'color'
                      ? item.background_color
                      : '#fff',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: `center`,
                }}
              >
                {index === 0 ? (
                  <>
                    {/* {renderMain(item)} */}
                    <NavigationBar
                      // idAbastecimento={idAbastecimento}
                      // responsiveContainerMaxWidth={responsiveContainerMaxWidth}
                      website={props.website}
                      history={history}
                    />
                  </>
                ) : null}
                {(item.title && item.subtitle) ||
                item.title ||
                item.subtitle ? (
                  <article
                    className="row justify-content-center pt-5"
                    style={{ marginTop: index === 0 ? 32 : 0 }}
                  >
                    <div className="col-12 col-md-10 col-xl-9 ">
                      <div
                        className="my-3"
                        style={{
                          margin: '0 auto',
                          width: isMobileOnly ? '85%' : '50%',
                        }}
                      >
                        <div className="row justify-content-center">
                          <div className="col-auto mb-3 mb-md-3">
                            <Typography
                              variant="h1"
                              style={{
                                fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
                                textAlign: 'center',
                                color: item.title_color || HEADER_TEXT_COLOR,
                              }}
                            >
                              {item.title || null}
                            </Typography>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-auto mb-3 mb-md-5">
                            <Typography
                              variant="body1"
                              style={{
                                fontSize: isMobileOnly ? '1rem' : '1.0rem',
                                textAlign: 'center',
                                color:
                                  item.subtitle_color || GENERAL_TEXT_COLOR,
                              }}
                            >
                              {item.subtitle || 'Texto'}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                ) : null}
                {item.section_items.map((sectionItem, sectionIndex) => (
                  <div
                    key={sectionIndex.toString()}
                    className="container"
                    style={{
                      marginTop:
                        index === 0
                          ? (item.title && item.subtitle) ||
                            item.title ||
                            item.subtitle
                            ? 0
                            : 32
                          : 0,
                    }}
                  >
                    {index === 0 ? (
                      <div className="row">
                        <>
                          <div className="col-xs-12 col-sm-12 col-md-2"></div>

                          <div
                            className="col-xs-12 col-sm-12 col-md-8 d-flex flex-column justify-content-center align-content-center align-items-center"
                            onClick={
                              sectionItem.button_label &&
                              sectionItem.link_type !== 'nolink'
                                ? () => {
                                    if (sectionItem.link_type === 'external') {
                                      window.location.href =
                                        sectionItem.external_link;
                                      return;
                                    }
                                    props.history.push(
                                      sectionItem.link_type === 'nolink'
                                        ? '/'
                                        : sectionItem.link_type === 'login'
                                        ? '/login'
                                        : sectionItem.link_type === 'signup'
                                        ? '/cadastrar'
                                        : sectionItem.link_type === 'additional'
                                        ? `/sobre/${sectionItem.id}`
                                        : '/'
                                    );
                                  }
                                : null
                            }
                            style={
                              sectionItem.link_type !== 'nolink'
                                ? {
                                    cursor: 'pointer',
                                  }
                                : {}
                            }
                          >
                            <Typography
                              variant="h2"
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: index !== 0 ? '28px' : '48px',
                                lineHeight: index !== 0 ? '32px' : '50px',
                                display: 'flex',
                                alignItems: 'flex-end',
                                color:
                                  index === 0
                                    ? item.title_color
                                    : item.title_color || HEADER_TEXT_COLOR,
                                maxWidth: '100%',
                                marginBottom: 25,
                                textAlign: 'center',
                              }}
                            >
                              {sectionItem.name}
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '18px',
                                lineHeight: '29px',
                                color:
                                  index === 0
                                    ? item.subtitle_color
                                    : item.subtitle_color || GENERAL_TEXT_COLOR,
                                maxWidth: '80%',
                                marginBottom: 25,
                                textAlign: 'center',
                              }}
                            >
                              {sectionItem.description}
                            </Typography>

                            {sectionItem.button_label &&
                            sectionItem.link_type !== 'nolink' ? (
                              <>
                                <BlockButton
                                  background={BUTTON_BACKGROUND_COLOR}
                                  color={BUTTON_TEXT_COLOR}
                                  xs={xs}
                                  sm={sm}
                                  onClick={() => {
                                    if (sectionItem.link_type === 'external') {
                                      window.location.href =
                                        sectionItem.external_link;
                                      return;
                                    }
                                    props.history.push(
                                      sectionItem.link_type === 'nolink'
                                        ? '/'
                                        : sectionItem.link_type === 'login'
                                        ? '/login'
                                        : sectionItem.link_type === 'signup'
                                        ? '/cadastrar'
                                        : sectionItem.link_type === 'additional'
                                        ? `/sobre/${sectionItem.id}`
                                        : '/'
                                    );
                                  }}
                                >
                                  {sectionItem.button_label}
                                </BlockButton>
                              </>
                            ) : null}
                            {sectionItem.image && !sectionItem.video_id ? (
                              <img
                                src={BASE_S3 + '/' + sectionItem.image}
                                style={{ maxWidth: '100%', aspectRatio: 1 }}
                                alt="imagem"
                              />
                            ) : !sectionItem.image && sectionItem.video_id ? (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <ReactPlayer
                                    width={'100%'}
                                    height={'400px'}
                                    controls
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: 'nodownload',
                                        },
                                      },
                                    }}
                                    style={{
                                      backgroundColor: 'black',
                                      cssFloat: 'left',
                                    }}
                                    key={sectionItem.video.url}
                                    url={sectionItem.video.url}
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>

                          {/* ???? */}
                          <div className="col-xs-12 col-sm-12 col-md-2"></div>
                        </>
                      </div>
                    ) : (
                      <div className="row">
                        <>
                          {sectionItem.image && !sectionItem.video_id ? (
                            <img
                              src={BASE_S3 + '/' + sectionItem.image}
                              style={{ maxWidth: '100%', aspectRatio: 1 }}
                              alt="imagem"
                            />
                          ) : !sectionItem.image && sectionItem.video_id ? (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <ReactPlayer
                                  width={'100%'}
                                  height={'400px'}
                                  controls
                                  style={{
                                    backgroundColor: 'black',
                                    cssFloat: 'left',
                                  }}
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: 'nodownload',
                                      },
                                    },
                                  }}
                                  key={sectionItem.video.url}
                                  url={sectionItem.video.url}
                                />
                              </div>
                            </>
                          ) : null}

                          {/* ????????? */}
                          <div className="col-xs-12 col-sm-12 col-md-2 mt-3"></div>

                          <div className="col-xs-12 col-sm-12 col-md-8 d-flex flex-column justify-content-center align-content-center align-items-center">
                            <Typography
                              variant="h2"
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: index !== 0 ? '28px' : '48px',
                                lineHeight: index !== 0 ? '32px' : '50px',
                                display: 'flex',
                                alignItems: 'flex-end',

                                color:
                                  index === 0
                                    ? item.title_color
                                    : item.title_color || HEADER_TEXT_COLOR,
                                maxWidth: '100%',
                                marginBottom: 25,
                                textAlign: 'center',
                              }}
                            >
                              {sectionItem.name}
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '18px',
                                lineHeight: '29px',
                                color:
                                  index === 0
                                    ? item.subtitle_color
                                    : item.subtitle_color || GENERAL_TEXT_COLOR,
                                maxWidth: '80%',
                                marginBottom: 25,
                                textAlign: 'center',
                              }}
                            >
                              {sectionItem.description}
                            </Typography>

                            {sectionItem.button_label &&
                            sectionItem.link_type !== 'nolink' ? (
                              // SECTION
                              <>
                                <BlockButton
                                  background={BUTTON_BACKGROUND_COLOR}
                                  color={BUTTON_TEXT_COLOR}
                                  xs={xs}
                                  sm={sm}
                                  onClick={() => {
                                    if (sectionItem.link_type === 'external') {
                                      window.location.href =
                                        sectionItem.external_link;
                                      return;
                                    }
                                    props.history.push(
                                      sectionItem.link_type === 'nolink'
                                        ? '/'
                                        : sectionItem.link_type === 'login'
                                        ? '/login'
                                        : sectionItem.link_type === 'signup'
                                        ? '/cadastrar'
                                        : sectionItem.link_type === 'additional'
                                        ? `/sobre/${sectionItem.id}`
                                        : '/'
                                    );
                                  }}
                                >
                                  {sectionItem.button_label}
                                </BlockButton>
                              </>
                            ) : null}
                          </div>

                          {/* ???????? */}
                          <div className="col-xs-12 col-sm-12 col-md-2"></div>
                        </>
                      </div>
                    )}
                  </div>
                ))}
              </section>
            );
          }
          return (
            <section
              key={index.toString()}
              id={`secao${item.id}`}
              style={{
                background:
                  item.background_type === 'image'
                    ? `url(${BASE_S3}/${item.background_image})`
                    : item.background_type === 'color'
                    ? item.background_color
                    : '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `center`,
              }}
            >
              {index === 0 ? (
                <>
                  {/* {renderMain(item)} */}
                  <NavigationBar
                    // idAbastecimento={idAbastecimento}
                    // responsiveContainerMaxWidth={responsiveContainerMaxWidth}
                    website={props.website}
                    history={history}
                  />
                </>
              ) : null}
              {(item.title && item.subtitle) || item.title || item.subtitle ? (
                <article
                  className="row justify-content-center pt-5"
                  style={{
                    // marginTop: index === 0 ? 32 : 0,
                    paddingTop:
                      (item.title === null && item.subtitle === null) ||
                      item.title === null ||
                      item.subtitle === null
                        ? 32
                        : null,
                  }}
                >
                  <div className="col-12 col-md-10 col-xl-9 ">
                    <div
                      className="my-3"
                      style={{
                        margin: '0 auto',
                        width: isMobileOnly ? '85%' : '50%',
                      }}
                    >
                      <div className="row justify-content-center">
                        <div className="col-auto mb-3 mb-md-3">
                          <Typography
                            variant="h1"
                            style={{
                              fontWeight: '700',
                              fontSize: '2.125rem',
                              color: item.title_color || HEADER_TEXT_COLOR,
                              textAlign: isMobileOnly ? 'center' : 'center',
                            }}
                          >
                            {item.title || null}
                          </Typography>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-auto mb-3 mb-md-5">
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: isMobileOnly ? '1rem' : '1.0rem',
                              textAlign: isMobileOnly ? 'center' : 'center',
                              color: item.subtitle_color || GENERAL_TEXT_COLOR,
                            }}
                          >
                            {item.subtitle || null}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              ) : null}
              {item.section_items.map((sectionItem, indexItem) => (
                <article
                  key={indexItem.toString()}
                  className="row pt-4 justify-content-center"
                  style={{
                    marginTop:
                      index === 0 ? (item.title && item.subtitle ? 0 : 32) : 0,
                  }}
                >
                  <div
                    className="w-100 d-flex justify-content-center mx-1"
                    onClick={() => {
                      if (sectionItem.link_type === 'external') {
                        window.location.href = sectionItem.external_link;
                        return;
                      }
                      props.history.push(
                        sectionItem.link_type === 'nolink'
                          ? '/'
                          : sectionItem.link_type === 'login'
                          ? '/login'
                          : sectionItem.link_type === 'signup'
                          ? '/cadastrar'
                          : sectionItem.link_type === 'additional'
                          ? `/sobre/${sectionItem.id}`
                          : '/'
                      );
                    }}
                    style={
                      sectionItem.link_type !== 'nolink'
                        ? {
                            cursor: 'pointer',
                          }
                        : {}
                    }
                  >
                    <div className="col-12 px-4">
                      <div className="row">
                        {sectionItem.image_left ? (
                          <>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                              {sectionItem.image && !sectionItem.video_id ? (
                                <img
                                  src={BASE_S3 + '/' + sectionItem.image}
                                  style={{ maxWidth: '100%', aspectRatio: 1 }}
                                  alt="imagem"
                                />
                              ) : !sectionItem.image && sectionItem.video_id ? (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <ReactPlayer
                                      width={'100%'}
                                      height={'400px'}
                                      controls
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: 'nodownload',
                                          },
                                        },
                                      }}
                                      style={{
                                        backgroundColor: 'black',
                                        cssFloat: 'left',
                                      }}
                                      key={sectionItem.video.url}
                                      url={sectionItem.video.url}
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6  d-flex flex-column justify-content-center align-content-start">
                              <Typography
                                variant="h2"
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 'bold',
                                  fontSize: index !== 0 ? '28px' : '48px',
                                  lineHeight: index !== 0 ? '32px' : '50px',
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  color:
                                    index === 0
                                      ? item.title_color
                                      : item.title_color || HEADER_TEXT_COLOR,
                                  maxWidth: '100%',
                                  marginBottom: 25,
                                }}
                              >
                                {sectionItem.name}{' '}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 'normal',
                                  fontSize: '18px',
                                  lineHeight: '29px',
                                  color:
                                    index === 0
                                      ? item.subtitle_color
                                      : item.subtitle_color ||
                                        GENERAL_TEXT_COLOR,
                                  maxWidth: '80%',
                                  marginBottom: 25,
                                }}
                              >
                                {sectionItem.description}
                              </Typography>
                              {sectionItem.button_label &&
                              sectionItem.link_type !== 'nolink' ? (
                                <>
                                  <BlockButton
                                    background={BUTTON_BACKGROUND_COLOR}
                                    color={BUTTON_TEXT_COLOR}
                                    xs={xs}
                                    sm={sm}
                                  >
                                    {sectionItem.button_label}
                                  </BlockButton>
                                </>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-xs-12 col-sm-12 col-md-6 d-flex flex-column justify-content-center align-content-start">
                              <Typography
                                variant="h2"
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 'bold',
                                  fontSize: index !== 0 ? '28px' : '48px',
                                  lineHeight: index !== 0 ? '32px' : '50px',
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  color:
                                    index === 0
                                      ? item.title_color
                                      : item.title_color || HEADER_TEXT_COLOR,
                                  maxWidth: '100%',
                                  marginBottom: 25,
                                }}
                              >
                                {sectionItem.name}{' '}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 'normal',
                                  fontSize: '18px',
                                  lineHeight: '29px',
                                  color:
                                    index === 0
                                      ? item.subtitle_color
                                      : item.subtitle_color ||
                                        GENERAL_TEXT_COLOR,
                                  maxWidth: '80%',
                                  marginBottom: 25,
                                }}
                              >
                                {sectionItem.description}
                              </Typography>
                              {sectionItem.button_label &&
                              sectionItem.link_type !== 'nolink' ? (
                                <>
                                  <BlockButton
                                    background={BUTTON_BACKGROUND_COLOR}
                                    color={BUTTON_TEXT_COLOR}
                                    xs={xs}
                                    sm={sm}
                                  >
                                    {sectionItem.button_label}
                                  </BlockButton>
                                </>
                              ) : null}
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                              {sectionItem.image && !sectionItem.video_id ? (
                                <img
                                  src={BASE_S3 + '/' + sectionItem.image}
                                  style={{ maxWidth: '100%', aspectRatio: 1 }}
                                  alt="imagem"
                                />
                              ) : !sectionItem.image && sectionItem.video_id ? (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <ReactPlayer
                                      width={'100%'}
                                      height={'400px'}
                                      controls
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: 'nodownload',
                                          },
                                        },
                                      }}
                                      style={{
                                        backgroundColor: 'black',
                                        cssFloat: 'left',
                                      }}
                                      key={sectionItem.video.url}
                                      url={sectionItem.video.url}
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </article>
              ))}
            </section>
          );
        } else if (item.type_id === 3) {
          // CAROUSEL
          return (
            <section
              key={index.toString()}
              id={`secao${item.id}`}
              style={{
                background:
                  item.background_type === 'image'
                    ? `url(${BASE_S3}/${item.background_image})`
                    : item.background_type === 'color'
                    ? item.background_color
                    : '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `center`,
              }}
            >
              {index === 0 ? (
                <>
                  <NavigationBar website={props.website} history={history} />
                </>
              ) : null}
              <div
                className="row py-3  justify-content-center"
                key={index.toString()}
                style={{ marginTop: index === 0 ? 32 : 0 }}
              >
                <div className="col-12 col-md-10 col-xl-9">
                  {(item.title && item.subtitle) ||
                  item.title ||
                  item.subtitle ? (
                    <div
                      className="my-3"
                      style={{
                        margin: '0 auto',
                        width: isMobileOnly ? '85%' : '50%',
                      }}
                    >
                      <div className="row justify-content-center">
                        <div className="col-auto mb-4 mb-md-4">
                          <Typography
                            variant="h1"
                            style={{
                              fontWeight: '700',
                              fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
                              textAlign: isMobileOnly ? 'center' : 'center',
                              color: item.title_color || HEADER_TEXT_COLOR,
                            }}
                            className="mb-0"
                          >
                            {item.title || null}
                          </Typography>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-auto mb-4 mb-md-4">
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: isMobileOnly ? '1rem' : '1.0rem',
                              textAlign: isMobileOnly ? 'center' : 'center',

                              color: item.subtitle_color || GENERAL_TEXT_COLOR,
                            }}
                          >
                            {item.subtitle || null}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row" style={{ minHeight: 400 }}>
                    <div className="col-12">
                      <div
                        style={{
                          padding: `0 ${chevronWidth}px`,
                        }}
                      >
                        <ItemsCarousel
                          requestToChangeActive={setActiveItemIndex}
                          activeItemIndex={activeItemIndex}
                          numberOfCards={isMobileOnly ? 1 : isTablet ? 2 : 3}
                          gutter={20}
                          leftChevron={<FontAwesomeIcon icon={faChevronLeft} />}
                          rightChevron={
                            <FontAwesomeIcon icon={faChevronRight} />
                          }
                          outsideChevron
                          chevronWidth={chevronWidth}
                        >
                          {item.section_items.map((sectionItem, index) => (
                            <div className="col-sm-12" key={index.toString()}>
                              <div
                                onClick={() => {
                                  if (sectionItem.link_type === 'external') {
                                    window.location.href =
                                      sectionItem.external_link;
                                    return;
                                  }
                                  props.history.push(
                                    sectionItem.link_type === 'nolink'
                                      ? '/'
                                      : sectionItem.link_type === 'login'
                                      ? '/login'
                                      : sectionItem.link_type === 'signup'
                                      ? '/cadastrar'
                                      : sectionItem.link_type === 'additional'
                                      ? `/sobre/${sectionItem.id}`
                                      : '/'
                                  );
                                }}
                                style={
                                  sectionItem.link_type !== 'nolink'
                                    ? {
                                        cursor: 'pointer',
                                      }
                                    : {}
                                }
                              >
                                <AboutCard
                                  image={sectionItem.image}
                                  title={sectionItem.name}
                                  text={sectionItem.description}
                                  titleColor={
                                    item.title_color || HEADER_TEXT_COLOR
                                  }
                                  subtitleColor={
                                    item.subtitle_color || GENERAL_TEXT_COLOR
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </ItemsCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        } else if (item.type_id === 4) {
          // CENTRALIZADO COM IMAGEM EM BAIXO
          return (
            <section
              key={index.toString()}
              id={`secao${item.id}`}
              style={{
                background:
                  item.background_type === 'image'
                    ? `url(${BASE_S3}/${item.background_image})`
                    : item.background_type === 'color'
                    ? item.background_color
                    : '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `center`,
              }}
            >
              {/* NAVBAR */}
              {index === 0 ? (
                <>
                  <NavigationBar website={props.website} history={history} />
                </>
              ) : null}
              <div
                style={{
                  paddingTop:
                    (item.title && item.subtitle) || item.title || item.subtitle
                      ? 0
                      : '96px',
                }}
              ></div>
              {(item.title && item.subtitle) || item.title || item.subtitle ? (
                <article
                  className="row justify-content-center"
                  style={{
                    marginTop: index === 0 ? 32 : 0,
                    paddingTop: '96px',
                  }}
                >
                  <div className="col-12 col-md-10 col-xl-9 ">
                    <div
                      // className="my-3"
                      style={{
                        margin: '0 auto',
                        width: isMobileOnly ? '85%' : '50%',
                        // marginBottom: '1rem',
                      }}
                    >
                      <div className="row justify-content-center">
                        <div className="col-auto mb-3 mb-md-3">
                          <Typography
                            variant="h1"
                            style={{
                              fontWeight: '700',
                              fontSize: '2.125rem',
                              textAlign: 'center',
                              color: item.title_color || HEADER_TEXT_COLOR,
                            }}
                          >
                            {item.title || null}
                          </Typography>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-auto">
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: isMobileOnly ? '1rem' : '1.0rem',
                              textAlign: 'center',
                              color: item.subtitle_color || GENERAL_TEXT_COLOR,
                            }}
                          >
                            {item.subtitle || null}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              ) : null}
              {item.section_items.map(
                (
                  sectionItem,
                  index // MAPS BLOCKS
                ) => (
                  <div
                    key={index.toString()}
                    className="container"
                    style={{
                      // paddingTop:
                      //   index === 0 ? (item.title === null && item.subtitle === null ? 32 : 0) : 0
                      paddingTop: '.8rem',
                    }}
                  >
                    <div className="row">
                      <>
                        <div className="col-xs-12 col-sm-12 col-md-2"></div>
                        <div
                          className="col-xs-12 col-sm-12 col-md-12 d-flex flex-column justify-content-center align-content-center align-items-center"
                          onClick={
                            sectionItem.button_label &&
                            sectionItem.link_type !== 'nolink'
                              ? () => {
                                  if (sectionItem.link_type === 'external') {
                                    window.location.href =
                                      sectionItem.external_link;
                                    return;
                                  }
                                  props.history.push(
                                    sectionItem.link_type === 'nolink'
                                      ? '/'
                                      : sectionItem.link_type === 'login'
                                      ? '/login'
                                      : sectionItem.link_type === 'signup'
                                      ? '/cadastrar'
                                      : sectionItem.link_type === 'additional'
                                      ? `/sobre/${sectionItem.id}`
                                      : '/'
                                  );
                                }
                              : null
                          }
                          style={
                            sectionItem.link_type !== 'nolink'
                              ? {
                                  cursor: 'pointer',
                                }
                              : {}
                          }
                        >
                          <Typography
                            variant="h2"
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'bold',
                              fontSize: index !== 0 ? '28px' : '48px',
                              lineHeight: index !== 0 ? '32px' : '50px',
                              display: 'flex',
                              alignItems: 'flex-end',
                              color:
                                index === 0
                                  ? item.title_color
                                  : item.title_color || HEADER_TEXT_COLOR,
                              maxWidth: '100%',
                              marginBottom: 25,
                              textAlign: 'center',
                            }}
                          >
                            {sectionItem.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '18px',
                              lineHeight: '29px',
                              color: item.subtitle_color || GENERAL_TEXT_COLOR,
                              maxWidth: '80%',
                              marginBottom: 25,
                              textAlign: 'center',
                            }}
                          >
                            {sectionItem.description}
                          </Typography>

                          {sectionItem.button_label &&
                          sectionItem.link_type !== 'nolink' ? (
                            <>
                              <BlockButton
                                background={BUTTON_BACKGROUND_COLOR}
                                color={BUTTON_TEXT_COLOR}
                                xs={xs}
                                sm={sm}
                                onClick={() => {
                                  if (sectionItem.link_type === 'external') {
                                    window.location.href =
                                      sectionItem.external_link;
                                    return;
                                  }
                                  props.history.push(
                                    sectionItem.link_type === 'nolink'
                                      ? '/'
                                      : sectionItem.link_type === 'login'
                                      ? '/login'
                                      : sectionItem.link_type === 'signup'
                                      ? '/cadastrar'
                                      : sectionItem.link_type === 'additional'
                                      ? `/sobre/${sectionItem.id}`
                                      : '/'
                                  );
                                }}
                                style={{
                                  marginBottom: '3rem',
                                }}
                              >
                                {sectionItem.button_label}
                              </BlockButton>
                            </>
                          ) : null}
                          {sectionItem.image && !sectionItem.video_id ? (
                            <img
                              src={BASE_S3 + '/' + sectionItem.image}
                              style={{ maxWidth: '100%', aspectRatio: 1 }}
                              alt="imagem da secao"
                            />
                          ) : !sectionItem.image && sectionItem.video_id ? (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <ReactPlayer
                                  width={'100%'}
                                  height={'400px'}
                                  controls
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: 'nodownload',
                                      },
                                    },
                                  }}
                                  style={{
                                    backgroundColor: 'black',
                                    cssFloat: 'left',
                                  }}
                                  key={sectionItem.video.url}
                                  url={sectionItem.video.url}
                                />
                              </div>
                            </>
                          ) : null}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-2"></div>
                      </>
                    </div>
                  </div>
                )
              )}
            </section>
          );
        } else if (item.type_id === 5) {
          // ATIVIDADES EM DESTAQUE
          return (
            <article key={index.toString()} id={`secao${item.id}`}>
              {renderActivities(item, index)}
            </article>
          );
        } else if (item.type_id === 6) {
          // renderBenefits()
          return (
            <article key={index.toString()} id={`secao${item.id}`}>
              {props.website.stationData.has_app
                ? renderBenefits(item, index)
                : null}
            </article>
          );
        } else if (item.type_id === 7) {
          // PLANOS
          return (
            <article
              key={index.toString()}
              id="website-plans"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {props.website.stationData.plans?.length > 0 ? (
                <Plans
                  item={item}
                  website={props.website}
                  history={props.history}
                />
              ) : null}
            </article>
          );
        } else if (item.type_id === 8) {
          // Form
          return (
            <section
              key={index.toString()}
              id={`secao${item.id}`}
              style={{
                background: !isMobileOnly
                  ? item.background_type === 'image'
                    ? `url(${BASE_S3}/${item.background_image})`
                    : item.background_type === 'color'
                    ? item.background_color
                    : '#fff'
                  : null,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `center`,
              }}
            >
              <Grid container>
                <Grid item md={6} xs={12}>
                  {(item.title && item.subtitle) ||
                  item.title ||
                  item.subtitle ? (
                    <article
                      className="row justify-content-center"
                      style={{
                        marginTop: index === 0 ? 32 : 0,
                        paddingTop: '96px',
                      }}
                    >
                      <div className="col-12 col-md-10 col-xl-9">
                        <div
                          // className="my-3"
                          style={{
                            margin: '0 auto',
                            width: isMobileOnly ? 330 : 450,
                            // marginBottom: '1rem',
                          }}
                        >
                          <div className="row justify-content-start">
                            <div className="mb-3 mb-md-3">
                              <Typography
                                variant="h1"
                                style={{
                                  fontWeight: '700',
                                  fontSize: '2.125rem',
                                  textAlign: 'left',
                                  color: item.title_color || HEADER_TEXT_COLOR,
                                }}
                              >
                                {item.title || null}
                              </Typography>
                            </div>
                          </div>
                          <div className="row justify-content-start">
                            <Typography
                              variant="body1"
                              style={{
                                fontSize: isMobileOnly ? '1rem' : '1.0rem',
                                textAlign: 'left',
                                color:
                                  item.subtitle_color || GENERAL_TEXT_COLOR,
                              }}
                            >
                              {item.subtitle || null}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </article>
                  ) : null}
                  {<SupportMessageForm />}
                </Grid>
                <Grid item md={6} xs={12}>
                  <div></div>
                </Grid>
              </Grid>
            </section>
          );
        } else if (item.type_id === 9) {
          // HERO

          if (isMobileOnly) {
            return (
              <section
                id={`secao${item.id}`}
                style={{
                  background:
                    item.background_type === 'image'
                      ? `url(${BASE_S3}/${item.background_image})`
                      : item.background_type === 'color'
                      ? item.background_color
                      : '#fff',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: `center`,
                }}
              >
                {index === 0 ? (
                  <>
                    {/* {renderMain(item)} */}
                    <NavigationBar
                      // idAbastecimento={idAbastecimento}
                      // responsiveContainerMaxWidth={responsiveContainerMaxWidth}
                      website={props.website}
                      history={history}
                    />
                  </>
                ) : null}
                {item.section_items.map((sectionItem, sectionIndex) => (
                  <div
                    key={sectionIndex.toString()}
                    className="container"
                    style={{
                      marginTop: index === 0 ? 32 : 0,
                    }}
                  >
                    {index === 0 ? (
                      <div className="row">
                        <>
                          <div className="col-xs-12 col-sm-12 col-md-2"></div>

                          <div
                            className="col-xs-12 col-sm-12 col-md-8 d-flex flex-column justify-content-center align-content-center align-items-center"
                            onClick={
                              sectionItem.button_label &&
                              sectionItem.link_type !== 'nolink'
                                ? () => {
                                    if (sectionItem.link_type === 'external') {
                                      window.location.href =
                                        sectionItem.external_link;
                                      return;
                                    }
                                    props.history.push(
                                      sectionItem.link_type === 'nolink'
                                        ? '/'
                                        : sectionItem.link_type === 'login'
                                        ? '/login'
                                        : sectionItem.link_type === 'signup'
                                        ? '/cadastrar'
                                        : sectionItem.link_type === 'additional'
                                        ? `/sobre/${sectionItem.id}`
                                        : '/'
                                    );
                                  }
                                : null
                            }
                            style={
                              sectionItem.link_type !== 'nolink'
                                ? {
                                    cursor: 'pointer',
                                  }
                                : {}
                            }
                          >
                            {sectionItem.image && !sectionItem.video_id ? (
                              <img
                                src={BASE_S3 + '/' + sectionItem.image}
                                style={{ maxWidth: '100%', aspectRatio: 1 }}
                                alt="imagem"
                              />
                            ) : !sectionItem.image && sectionItem.video_id ? (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <ReactPlayer
                                    width={'100%'}
                                    height={'400px'}
                                    controls
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: 'nodownload',
                                        },
                                      },
                                    }}
                                    style={{
                                      backgroundColor: 'black',
                                      cssFloat: 'left',
                                    }}
                                    key={sectionItem.video.url}
                                    url={sectionItem.video.url}
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>

                          {/* ???? */}
                          <div className="col-xs-12 col-sm-12 col-md-2"></div>
                        </>
                      </div>
                    ) : (
                      <div className="row">
                        <>
                          {sectionItem.image && !sectionItem.video_id ? (
                            <img
                              src={BASE_S3 + '/' + sectionItem.image}
                              style={{ maxWidth: '100%', aspectRatio: 1 }}
                              alt="imagem"
                            />
                          ) : !sectionItem.image && sectionItem.video_id ? (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <ReactPlayer
                                  width={'100%'}
                                  height={'400px'}
                                  controls
                                  style={{
                                    backgroundColor: 'black',
                                    cssFloat: 'left',
                                  }}
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: 'nodownload',
                                      },
                                    },
                                  }}
                                  key={sectionItem.video.url}
                                  url={sectionItem.video.url}
                                />
                              </div>
                            </>
                          ) : null}

                          <div className="col-xs-12 col-sm-12 col-md-2"></div>
                        </>
                      </div>
                    )}
                  </div>
                ))}
              </section>
            );
          }
          return (
            <section
              key={index.toString()}
              id={`secao${item.id}`}
              style={{
                background:
                  item.background_type === 'image'
                    ? `url(${BASE_S3}/${item.background_image})`
                    : item.background_type === 'color'
                    ? item.background_color
                    : '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `center`,
              }}
            >
              {index === 0 ? (
                <>
                  {/* {renderMain(item)} */}
                  <NavigationBar
                    // idAbastecimento={idAbastecimento}
                    // responsiveContainerMaxWidth={responsiveContainerMaxWidth}
                    website={props.website}
                    history={history}
                  />
                </>
              ) : null}
              {item.section_items.map((sectionItem, indexItem) => (
                <article
                  key={indexItem.toString()}
                  className="row pt-4 justify-content-center"
                  style={{
                    marginTop: 0,
                  }}
                >
                  <div
                    className="w-100 d-flex justify-content-center mx-1"
                    onClick={() => {
                      if (sectionItem.link_type === 'external') {
                        window.location.href = sectionItem.external_link;
                        return;
                      }
                      props.history.push(
                        sectionItem.link_type === 'nolink'
                          ? '/'
                          : sectionItem.link_type === 'login'
                          ? '/login'
                          : sectionItem.link_type === 'signup'
                          ? '/cadastrar'
                          : sectionItem.link_type === 'additional'
                          ? `/sobre/${sectionItem.id}`
                          : '/'
                      );
                    }}
                    style={
                      sectionItem.link_type !== 'nolink'
                        ? {
                            cursor: 'pointer',
                          }
                        : {}
                    }
                  >
                    <div className="col-12 px-4">
                      <div className="row">
                        <>
                          <div className="col-12 d-flex justify-content-center">
                            {sectionItem.image && !sectionItem.video_id ? (
                              <img
                                src={BASE_S3 + '/' + sectionItem.image}
                                style={{ maxWidth: '100%', aspectRatio: 1 }}
                                alt="imagem"
                              />
                            ) : !sectionItem.image && sectionItem.video_id ? (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <ReactPlayer
                                    width={'100%'}
                                    height={'400px'}
                                    controls
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: 'nodownload',
                                        },
                                      },
                                    }}
                                    style={{
                                      backgroundColor: 'black',
                                      cssFloat: 'left',
                                    }}
                                    key={sectionItem.video.url}
                                    url={sectionItem.video.url}
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </article>
              ))}
            </section>
          );
        } else return null;
      })
    ) : null;
  }

  function renderBenefits(item, index) {
    const { second_section_subtitle } = COMPANY_WEBSITE_SECTIONS;
    const { image_download_app, image_download_app_mobile } =
      COMPANY_WEBSITE_IMAGES;

    return (
      <>
        <div
          key={index.toString()}
          className="row justify-content-center"
          style={{
            background:
              item.background_type === 'image'
                ? `url(${BASE_S3}/${item.background_image})`
                : item.background_type === 'color'
                ? item.background_color
                : '#fff',
          }}
        >
          <div
            className="col-12 col-md-10 col-xl-12"
            style={{
              display: 'flex',
              height: isMobileOnly ? 658 : 500,
              width: !isMobileOnly ? 1190 : 540,
              justifyContent: !isMobileOnly ? 'flex-end' : 'center',
              alignItems: 'center',
              backgroundImage: `url(${BASE_S3}/${
                !isMobileOnly ? image_download_app : image_download_app_mobile
              })`,
              backgroundRepeat: 'no repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              alignSelf: 'center',
            }}
          >
            <div
              style={{
                width: !isMobileOnly ? '50%' : '90%',
                height: 300,
                maxWidth: 600,
                justifyContent: 'center',
                alignItems: isMobileOnly ? 'flex-start' : 'flex-start',
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="h1"
                style={{
                  width: isMobileOnly ? '85%' : '65%',
                  fontSize: isMobileOnly ? '2.125rem' : '2.125rem',
                  textAlign: 'left',
                  color: item.title_color || HEADER_TEXT_COLOR,
                  fontWeight: '700',
                  marginLeft: 10,
                }}
                className="my-3"
              >
                {item?.title || second_section_subtitle || 'Baixe nosso App!'}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: 'auto',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <a
                  href={
                    isMobileOnly
                      ? props.website.stationData.ios
                      : props.website.stationData.ios.replace(
                          'itms-apps://',
                          'https://'
                        )
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    width: isMobileOnly ? '50%' : 'auto',
                    objectFit: 'contain',
                    height: 65,
                  }}
                >
                  <img
                    src={
                      'https://s3.amazonaws.com/fermento.app.files/files/appstore-apple.png'
                    }
                    alt="Badge App Store"
                    style={{
                      width: isMobileOnly ? '100%' : 'auto',
                      objectFit: 'contain',
                      height: '100%',
                    }}
                  />
                </a>
                <a
                  href={
                    isMobileOnly
                      ? props.website.stationData.android
                      : props.website.stationData.android.replace(
                          'market://',
                          'https://play.google.com/store/apps/'
                        )
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    width: isMobileOnly ? '50%' : 'auto',

                    objectFit: 'contain',
                    height: 82,
                  }}
                >
                  <img
                    src={`https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png`}
                    alt="Badge Google Play"
                    style={{
                      width: isMobileOnly ? '100%' : 'auto',
                      objectFit: 'contain',
                      height: '100%',
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderActivities(item, index) {
    return (
      <div
        className="row py-3 justify-content-center"
        style={{
          background:
            item.background_type === 'image'
              ? `url(${BASE_S3}/${item.background_image})`
              : item.background_type === 'color'
              ? item.background_color
              : '#fff',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: `center`,
        }}
      >
        <div className="col-12 pt-5" style={{ padding: 0 }}>
          <div
            className="my-3"
            style={{
              margin: '0 auto',
              width: isMobileOnly ? '85%' : '50%',
            }}
          >
            <div className="row justify-content-center">
              <div className="col-auto mb-4 mb-md-4">
                <Typography
                  variant="h1"
                  style={{
                    fontWeight: '700',
                    fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
                    textAlign: isMobileOnly ? 'center' : 'center',
                    color: item.title_color || HEADER_TEXT_COLOR,
                  }}
                  className="mb-0"
                >
                  {item.title || null}
                </Typography>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto mb-4 mb-md-4">
                <Typography
                  variant="body1"
                  style={{
                    fontSize: isMobileOnly ? '1rem' : '1.0rem',
                    textAlign: isMobileOnly ? 'center' : 'center',

                    color: item.subtitle_color || GENERAL_TEXT_COLOR,
                  }}
                >
                  {item.subtitle || null}
                </Typography>
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              minHeight: 400,
              margin: 0,
            }}
          >
            <div className="col-12">
              <div style={{ padding: `0 ${chevronWidth}px` }}>
                <ItemsCarousel
                  requestToChangeActive={setActiveItemIndex}
                  activeItemIndex={activeItemIndex}
                  numberOfCards={isMobileOnly ? 1 : isTablet ? 2 : 4}
                  gutter={5}
                  leftChevron={<FontAwesomeIcon icon={faChevronLeft} />}
                  rightChevron={<FontAwesomeIcon icon={faChevronRight} />}
                  outsideChevron
                  chevronWidth={chevronWidth}
                >
                  {!props.website.publicActionsLoading ? (
                    props.website.publicActions.map((action, index) => {
                      return (
                        <div
                          style={{
                            marginTop: 64,
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          key={index.toString()}
                        >
                          <DefaultCard
                            onClick={() => handleActionClick(action.id, action)}
                            imgSource={`${BASE_S3}/${action.image}`}
                            actionName={action.name}
                            labelClick={`${action.click_me_label}`}
                            textColor={
                              item.subtitle_color || GENERAL_TEXT_COLOR
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex">
                      <Spinner />
                    </div>
                  )}
                </ItemsCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (IS_OLD_FAN) {
    return (
      <Box
        justifyContent={'center'}
        display="flex"
        flexDirection={'column'}
        alignItems={'center'}
        height="100vh"
      >
        <img
          src={`${BASE_S3}/${COMPANY_COLORS.website.navbar.station_primary_logo}`}
          style={{ height: 50 }}
          className="img-fluid"
          alt="Logo da estação"
        />
        <Typography variant="h5">Em manutenção</Typography>
      </Box>
    );
  }

  return (
    <div
      className="container-fluid station-container"
      style={{
        backgroundColor: COMPANY_SIGNED_OFF_BACKGROUND_COLOR,
      }}
    >
      {renderWhat()}
      <Footer
        setOpenModal={setOpenModal}
        setOpenModalSec={setOpenModalSec}
        website={props.website}
        history={props.history}
      />
      <CookieConsent />
      {props.website?.stationData?.has_majority_security ? (
        <MajorityAgeTerm />
      ) : null}
      <AlertDialogSlide
        onClose={() => {
          setOpenModal(false);
        }}
        visible={openModal}
        titleBackground={'#FFFF'}
        backgroundColor={'#FFFF'}
      >
        <PrivacyPolicy />
      </AlertDialogSlide>
      <AlertDialogSlide
        onClose={() => {
          setOpenModalSec(false);
        }}
        visible={openModalSec}
        titleBackground={'#FFFF'}
        backgroundColor={'#FFFF'}
      >
        <UseTerms />
      </AlertDialogSlide>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAboutList,
      getBenefitsList,
      getPublicActions,
      changePublicActionsModal,
      changeHeaderIndex,
      getStationData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Website);
