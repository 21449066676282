import { shade } from 'polished';
import styled, { css } from 'styled-components';
import { COMPANY_COLORS } from '../../../../../config/consts';

export const ContentContainer = styled.div`
  padding: 15px;
  width: 280px;
  height: fit-content;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + section {
      margin-top: 15px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      width: 50%;
      text-align: right;
      color: #102c2d;
    }

    div {
      color: #c4c4c4;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      max-width: 50%;

      span {
        color: ${COMPANY_COLORS.general.general_color};
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        max-width: 50%;
      }
    }
  }

  button {
    width: 100%;
    height: 40px;
    color: white;
    background: ${COMPANY_COLORS.primary};
    cursor: pointer;
    text-align: center;
    margin-top: ${(props) => (props.hasCashback ? '20px' : '0px')};
    transition: background-color 0.5s;

    &:focus {
      outline: none;
    }

    &:hover {
      background: ${shade(0.2, COMPANY_COLORS.primary)};
    }

    ${(props) =>
      props.disabled &&
      css`
        background: grey;
        &:hover {
          background-color: ${shade(0.1, 'grey')};
        }
      `}
  }
`;

export const Container = styled.section``;

export const Divider = styled.div`
  border-top: 1px solid #ccc;
  width: 100%;
  margin: 15px 0;
`;

export const ExtraInfo = styled.div`
  margin-top: 15px;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 500px;
      font-size: 14px;
      width: 50%;
      text-align: right;
    }

    div {
      font-weight: 500px;
      font-size: 14px;
      max-width: 50%;

      span {
        font-weight: 500px;
        font-size: 14px;
        max-width: 50%;
      }
    }
  }

  p {
    font-size: 14px;
    margin: 0;
    text-align: right;
  }
`;

export const CashbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 25px;

  span:nth-child(1) {
    font-size: 14px;
    color: #602318;
  }

  span:nth-child(2) {
    font-size: 24px;
    color: ${COMPANY_COLORS.general.general_color};
  }

  span:nth-child(3) {
    font-size: 12px;
    color: #602318;
  }

  svg {
    position: absolute;
  }
`;
