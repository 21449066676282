import { Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import React from 'react';

// import { Container } from './styles';

function AccountingProcessesDetailModal({
  onClose,
  isOpen,
  title,
  status,
  body,
  footer1,
  footer2,
  moreInfoLink,
  selected,
}) {
  console.log(selected);

  const { acoes_sindilegis, itens_destaque, processos, descricao } = selected;

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="md">
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon
          onClick={onClose}
          style={{
            cursor: 'pointer',
            fontSize: 32,
            marginTop: 12,
            marginRight: 12,
          }}
        />
      </Box>
      <Box style={{ padding: '32px 71px' }}>
        <Box display="flex" justifyContent="space-between">
          <p className="h4" style={{ color: '#838383' }}>
            {title}
          </p>
          {status && (
            <div
              style={{
                backgroundColor: '#F98979',
                padding: '4px 12px',
                color: '#fff',
                borderRadius: 5,
              }}
            >
              {status}
            </div>
          )}
        </Box>
        <p className="h4-bold mt-4">{body}</p>
        <p className="h5 mt-4">{descricao}</p>

        <p className="h4-bold mt-4">Destaques</p>
        {itens_destaque.map(({ descricao_item, posicionamento, id }) => (
          <p key={id} className="mt-2 h5">
            {'\u2022'} {descricao_item}:{' '}
            <span className="h5-bold">{posicionamento}</span>
          </p>
        ))}
        <p className="h4-bold mt-4">Ações do Sindilegis</p>
        {acoes_sindilegis.map(({ data_acao, descricao_acao, id }) => (
          <p key={id} className="mt-2 h5-bold">
            {'\u2022'} {format(new Date(data_acao), 'dd/MM/yyyy')}:{' '}
            <span className="h5">{descricao_acao}</span>
          </p>
        ))}
        <p className="h4-bold mt-4">Processos</p>
        {processos.map(({ identificacao_processo, tribunalOrgao, id }) => (
          <p key={id} className="mt-2 h5">
            {'\u2022'} {tribunalOrgao}:{' '}
            <span className="h5-bold">{identificacao_processo}</span>
          </p>
        ))}
        <p className="h4-bold mt-4">Interessados</p>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <p className="h4" style={{ color: '#838383' }}>
            {footer1}
          </p>
          <p className="h4" style={{ color: '#838383' }}>
            {footer2}
          </p>
        </Box>
      </Box>
    </Dialog>
  );
}

export default AccountingProcessesDetailModal;
