import {
  SIGNED_IN_NAV_TEXT_COLOR,
  // COMPANY_SIGNED_IN_BACKGROUND_COLOR
} from 'config/consts';
import styled from 'styled-components';

export const Container = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  margin-bottom: 12px;
  margin-top: 12px;
`;

export const NavigationItem = styled.button `
  background: transparent;
  color: ${(props) =>
    props.active === props.item.id
      ? props.companyColors.link
      : SIGNED_IN_NAV_TEXT_COLOR};
  border: none;
  font-style: normal;
  font-weight: ${(props) =>
    props.active === props.item.id ? 'bold' : 'normal'};
  font-size: 16px;
  padding-left: 0;
  padding: 0rem 0.8rem 0rem 0;
  transition: color 0.2s;
  border-radius: 0;

  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    border-radius: 0;
  }
`;

export const NavigationSubItem = styled.span `
  /* background-color: COMPANY_SIGNED_IN_BACKGROUND_COLOR; */
  color: SIGNED_IN_NAV_TEXT_COLOR;
  border: none;
  font-style: normal;
  font-size: 16px;
  min-width: 100%;
  text-align: start;
  border-radius: 0;

  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    border-radius: 0;
  }

  :hover {
    color: ${(props) => props.companyColors.link};
    background-color: #7a7a7a;
    border-radius: 0;
    cursor: pointer;
  }
`;

export const ChangeStationButton = styled.button `
  border-radius: 40px 40px 40px 40px !important;
  /* margin-right: 27; */
  /* width: 98;
  height: 52; */
  font-style: 'normal';
  font-weight: 'normal';
  font-size: '16px';
  line-height: '160%';
  cursor: 'pointer';
  padding: 0.2rem 1rem;
  margin: 0 0.3rem;
  background: ${(props) => (props.children === 'Gerenciar' ? '' : '#E5E5E5')};
  border: ${(props) =>
    props.children === 'Gerenciar' ? '1px solid #282828' : ''};
  /* padding: .2rem 1.4rem; */
`;

export const DropdownItem = styled.button `
  text-align: start;
  min-width: 100%;
  min-height: 100%;
  margin-bottom: 10;

  :focus {
    min-width: 100%;
    min-height: 100%;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;
