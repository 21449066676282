import styled from 'styled-components';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
} from '../../../../config/consts';

export const Container = styled.div``;

export const BackgroundImage = styled.div`
  background-image: ${(props) => `url(${BASE_S3}/${props.image})`};
  max-width: 100%;
  height: ${(props) => (props.step === 0 ? '400px' : '200px')};
  width: auto;
  border-radius: 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative;
`;

// props.companyColors.general.general_color

export const GoShoppingBtn = styled.button`
  padding: 1rem;
  background-color: ${BUTTON_BACKGROUND_COLOR};
  color: ${BUTTON_TEXT_COLOR};
  font-family: ${BUTTON_FONT_FAMILY};
  border-radius: 6px;

  margin-top: 0.5rem;

  :focus {
    outline: none;
  }
`;
