import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import type from './types';
import _ from 'lodash';

const URL = `${BASE_API}/user-shopping-cart`;
const URL_DELETE = `${BASE_API}/remove-product-from-shopping-cart`;
const URL_UPDATE = `${BASE_API}/update-shopping-cart`;
const URL_RESERVATION = `${BASE_API}/single-product-orders`;
const URL_REQUEST_PRODUCT_ORDER = `${BASE_API}/cupom-product-order-cart`;

export const getList = () => {
  return (dispatch) => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        const products = response.data.map((item) => ({
          id: item.product_id,
          detail: item.product,
          quantity: item.quantity,
        }));

        const actionsIds = products.map((product) => product.detail.action.id);

        const actionsIdsNotRepeat = actionsIds.filter(onlyUnique);

        const actionsWithProducts = [];

        for (let actionId of actionsIdsNotRepeat) {
          const actionProducts = products.filter(
            (product) =>
              parseInt(product.detail.action.id) === parseInt(actionId)
          );
          actionsWithProducts.push({
            products: actionProducts,
            action: actionProducts[0].detail.action,
          });
        }
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
          initializeForm({ products, actions: actionsWithProducts }),
        ]);
      });
  };
};

export const deleteProductFromCart = (productId) => {
  return (dispatch) => {
    axios
      .delete(`${URL_DELETE}/${productId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([getList()]);
      });
  };
};

export const updateShoppingCart = (shoppingItems, body, handleForward) => {
  return (dispatch) => {
    axios
      .put(
        `${URL_UPDATE}`,
        { shoppingItems },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          getList(),
          singleProductOrdersReservation(body, handleForward),
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data.error) {
            toastr.error('Erro', `${e.response.data.error}`);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const singleProductOrdersReservation = (body, handleForward) => {
  return (dispatch) => {
    dispatch({ type: 'CART_STEP_LOAD', payload: true });
    axios
      .post(`${URL_RESERVATION}`, body, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          handleForward(),
          {
            type: '@ORDER_RESERVE_TIME',
            payload: response.data.reserveTime,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data.message) {
            toastr.error(
              'Erro',
              `Não foi possível completar sua transação. Por favor, tente novamente. Motivo: ${e.response.data.message}`
            );
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      })
      .finally(() => {
        dispatch({ type: 'CART_STEP_LOAD', payload: false });
      });
  };
};

export const productOrder = (
  values,
  history = undefined,
  cleanup,
  retryCallback
) => {
  return (dispatch) => {
    dispatch({ type: 'CART_STEP_LOAD', payload: true });
    axios
      .post(URL_REQUEST_PRODUCT_ORDER, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        /*  history.push(`/homepage/pedidos/${response.data.id}`); */
        dispatch({
          type: type.ITEM_DETAIL,
          payload: [response.data],
        });
        if (cleanup) cleanup();
      })
      .catch((e) => {
        try {
          if (e.response.data.message) {
            toastr.error(
              'Erro',
              `Não foi possível completar sua transação. Por favor, tente novamente. Motivo: ${e.response.data.message}`
            );
            if (retryCallback) retryCallback(e.response.data.message);
          } else if (_.isArray(e.response.data)) {
            for (let i of e.response.data) {
              toastr.error(
                'Erro',
                `Não foi possível completar sua transação. Por favor, tente novamente. Motivo: ${i}`
              );
              if (retryCallback) retryCallback(i);
            }
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      })
      .finally(() => {
        dispatch({ type: 'CART_STEP_LOAD', payload: false });
      });
  };
};

export const initializeForm = (values) => {
  return initialize('userShoppingCartForm', values);
};
