import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getNewsList, setNewsList } from './legalActions';
import { Box, Grid } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';

function LegalNews({ getNewsList, legal, history, setNewsList }) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    getNewsList(1, 13752);
    setPage(2);

    return () => {
      setNewsList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    getNewsList(page, 13752);
    setPage(page + 1);
  };

  return (
    <section
      id="legal-news"
      style={{ backgroundColor: '#fff', height: '100vh' }}
    >
      <p className="body-title mt-4 mb-3">Notícias</p>

      {legal.newsList.length > 0 ? (
        <InfiniteScroll
          dataLength={legal.newsList.length}
          next={fetchData}
          hasMore
          loader={
            <Box display="flex" height="100vh" justifyContent="center" mt={7}>
              <LoadingProgress />
            </Box>
          }
          scrollThreshold={0.5}
          endMessage={
            <p style={{ textAlign: 'center', marginTop: 15, fontSize: 16 }}>
              <b>Isso é tudo por enquanto!</b>
            </p>
          }
          style={{ overflow: 'unset' }}
        >
          <Grid container spacing={3}>
            {legal.newsList.map((item) => (
              <Grid
                item
                xs={4}
                key={item.id}
                style={{ cursor: 'pointer', marginTop: 16 }}
                onClick={() =>
                  history.push({
                    pathname: '/homepage/consultoria-juridica/noticias/detalhe',
                    state: { item },
                  })
                }
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="100%"
                >
                  <div
                    style={{
                      width: '100%',
                      height: 180,
                      backgroundImage: `url('${item.image}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  />

                  <p className="h4 mt-2">{item.title.rendered}</p>
                  <p className="h4 mt-2" style={{ color: '#F98979' }}>
                    &gt; Saiba mais
                  </p>
                </Box>
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      ) : /* <p style={{ margin: '20px auto', padding: 20, width: 'fit-content' }}>
          Ainda não há notícias.
        </p> */
      null}
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    legal: state.legal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getNewsList, setNewsList }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(LegalNews);
