import LoadingProgress from 'common/components/progress/loading';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { COMPANY_ID, COMPANY_STATION_ID } from '../../../config/consts';
import { activeUser } from '../authActions';

class ActivateUser extends Component {
  componentDidMount() {
    const { match } = this.props;
    this.props.activeUser(
      match.params.email,
      this.props.history,
      COMPANY_ID,
      COMPANY_STATION_ID,
      match.params.hash
    );
  }

  render() {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <LoadingProgress />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ activeUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateUser);
