import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import SimpleBlockSkeleton from '../../../../common/components/skeleton/simpleBlockSkeleton';
import { BASE_S3, COMPANY_COLORS } from '../../../../config/consts';
import { getListByUserId as getUserCreditCards } from '../homepage/creditCards/creditCardsActions';
import { getUserPoints } from '../homepage/homepageActions';
import { getUserAddresses } from '../shopping/shoppingActions';
import Address from './pages/Address';
import Cart from './pages/Cart';
import Confirmation from './pages/Confirmation';
import Payment from './pages/Payment';
import SuccessfulPurchase from './pages/SuccessfulPurchase';
import { GoShoppingBtn } from './styles';
import { getList } from './userShoppingCartActions';

const calculateTimeLeft = (date = new Date()) => {
  let difference = date - new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  return { timeLeft, difference };
};

export function UserShoppingCart(props) {
  const { list, loading } = props.userShoppingCart;
  const [step, setStep] = useState(0);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft().timeLeft);
  const [difference, setDifference] = useState(calculateTimeLeft().difference);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));

  useEffect(() => {
    const timer = setTimeout(() => {
      const timeLeftObject = calculateTimeLeft(props.shopping.reserveTime);
      setTimeLeft(timeLeftObject.timeLeft);
      setDifference(timeLeftObject.difference);
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (step > 0 && props.shopping.reserveTime) {
      if (calculateTimeLeft(props.shopping.reserveTime).difference > 0) {
      } else {
        toastr.error(
          'Tempo de reserva esgotado',
          'O tempo limite de reserva de produto para compra se esgotou. Por favor, comece novamente.'
        );
        setStep(0);
      }
    }
  }, [difference, step, setStep, props.shopping.reserveTime]);

  const getList = props.getList;
  const getUserAddresses = props.getUserAddresses;
  const getUserCreditCards = props.getUserCreditCards;

  const auth = props.auth;

  useEffect(() => {
    getList();
    getUserAddresses(auth.user.id);
    getUserCreditCards(auth.user.id);
    getUserPoints(auth.user.id);
  }, [getList, getUserAddresses, getUserCreditCards, auth]);

  function renderEmptyScreen() {
    return (
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
        style={{
          minHeight: '92vh',
          marginTop: xs || sm ? '2rem' : '1rem',
          textAlign: 'center',
          verticalAlign: 'center',
          // backgroundColor: 'pink'
        }}
      >

        <Grid item>
        <Typography variant="h6"
        style={{
        marginBottom: '64px',
        }}

        >
            Ooops! Você ainda não possui itens no seu carrinho.
          </Typography>

        </Grid>

        <Grid item direction="column">

          <GoShoppingBtn
            onClick={() => props.history.push('/homepage')}
            primary
            companyColors={COMPANY_COLORS}
          >
            Clique para ir às compras!
          </GoShoppingBtn>
        </Grid>
      </Grid>
    );
  }

  if (loading) {
    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        style={{
          minHeight: '100vh',
          margin: 'auto'
        }}
      >
        <Grid
          item
          container
          justifyContent="center"
          xs={5}
        >
          <SimpleBlockSkeleton
            height={400}
            width={500}
          />
        </Grid>
      </Grid>
    );
  }

  if (list.content.length < 1) {
    return renderEmptyScreen();
  }

  const steps = [
    <Cart
      setStep={setStep}
      step={step}
      props={props}
      history={props.history}
    />,
    <Address setStep={setStep} step={step} timeLeft={timeLeft} />,
    <Payment setStep={setStep} step={step} timeLeft={timeLeft} />,
    <Confirmation
      setStep={setStep}
      step={step}
      history={props.history}
      timeLeft={timeLeft}
    />,
    <SuccessfulPurchase history={props.history} setStep={setStep} />,
  ];

  return (
    <section
      id="userShoppingCart"
      style={{
        width: '100%',
      }}
    >
      {steps[step]}
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userShoppingCart: state.userShoppingCart,
    shopping: state.shopping,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      getUserAddresses,
      getUserCreditCards,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserShoppingCart);
