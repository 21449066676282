import { Grid, Typography } from '@material-ui/core';
import {
  InputLabelOutlined,
  SelectLabel,
} from 'common/components/fields/fields';
import { String2Currency } from 'helpers/dataTransformers';
import { cepMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Field } from 'redux-form';
import ItemCardMultiSelect from '../../components/ItemCard/multiSelect';

export class RenderProducts extends React.Component {
  isDelivery = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return deliveryLocation.delivery;
    }
  };

  addresHelper1 = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return deliveryLocation.helper_label_field_1;
    }
  };

  addresHelper2 = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return deliveryLocation.helper_label_field_2;
    }
  };

  withdrawalAddress = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return (
        'Retirar em: ' +
        deliveryLocation.street +
        ', número ' +
        deliveryLocation.street_number +
        '. CEP: ' +
        deliveryLocation.zipcode
      );
    }
  };

  render() {
    return this.props.fields.map((item, index) => {
      const deliveryList = this.props.formValues['actions'][
        index
      ].action.delivery_locations.map((deliveryLocation) => ({
        label: `${deliveryLocation.name} - ${String2Currency(
          deliveryLocation.price
        )}`,
        value: deliveryLocation.id,
      }));

      return (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          key={index}
          style={{
            paddingTop: isMobile ? '1rem' : '',
          }}
        >
          {this.props.formValues['actions'][index].products.map(
            (item, productIndex) => (
              <Field
                key={productIndex}
                name={`actions[${index}].products[${productIndex}]`}
                component={ItemCardMultiSelect}
              />
            )
          )}

          {this.props.formValues['actions'][index].action &&
          !this.props.formValues['actions'][index].action.digital_product ? (
            <Grid item container xs={12}>
              {/* <AddressContainer>
                <AddressContentContainer> */}
              {/* <label> */}
              <Grid
                item
                xs={12}
                alignItems="center"
              >
                <Typography variant="h6">Entrega ou retirada?</Typography>
              </Grid>
              <Typography variant="body1">
                Veja as localidades que estamos fazendo entrega ou escolha
                buscar no estabelecimento.
              </Typography>
              {/* </label> */}
              <Grid
                container
                spacing={3}
                style={{
                  marginBottom: 15,
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid item xs={12} md={12} lg={6}>
                  <Field
                    name={`${item}.location.deliveryLocation`}
                    label="Região de entrega"
                    options={deliveryList}
                    component={SelectLabel}
                    validate={[FORM_RULES.required]}
                  />
                </Grid>
                {this.props.formValues &&
                this.props.formValues['actions'][index]
                  ? this.isDelivery(
                      this.props.formValues['actions'][index]
                    ) && (
                      <>
                        <Grid item xs={12} md={12} lg={6}>
                          <Field
                            name={`${item}.location.address`}
                            label={
                              'Endereço completo' /* this.addresHelper1(
                                    this.props.formValues['actions'][index]
                                  ) */
                            }
                            component={InputLabelOutlined}
                            type="text"
                            validate={[FORM_RULES.required]}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Field
                            name={`${item}.location.number`}
                            label={
                              'Complemento' /* this.addresHelper2(
                                    this.props.formValues['actions'][index]
                                  ) */
                            }
                            component={InputLabelOutlined}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Field
                            component={InputLabelOutlined}
                            label="CEP"
                            type="tel"
                            name={`${item}.location.zipcode`}
                            onChange={(e) => this.handleGetAdress(e)}
                            validate={[FORM_RULES.required]}
                            {...cepMask}
                          />
                        </Grid>
                      </>
                    )
                  : null}
              </Grid>
              {this.props.formValues && this.props.formValues['actions'][index]
                ? !this.isDelivery(this.props.formValues['actions'][index]) && (
                    <label>
                      <Typography variant="body1">
                        {this.withdrawalAddress(
                          this.props.formValues['actions'][index]
                        )}
                      </Typography>
                    </label>
                  )
                : null}
              {/* </AddressContentContainer>
              </AddressContainer> */}
            </Grid>
          ) : (
            <label>
              <Typography variant="body1">Esse produto é digital, portanto, não possui frete.</Typography>
            </label>
          )}
        </Grid>
      );
    });
  }
}
