import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlideCoupon(props) {
  return (
    <div>
      <Dialog
        open={props.visible}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            minHeight: props.height || null,
            minWidth: props.maxWidth === 'xl' ? '100%' : null,
            backgroundColor: props.backgroundColor || '#fff',
          },
        }}
        onClose={props.onClose}
        style={{
          padding: '0px important!',
        }}
        fullWidth={true}
        maxWidth={props.maxWidth || 'md'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <MuiDialogTitle
          hidden={props.hiddenTitle || false}
          disableTypography
          style={{ background: props.titleBackground || '#fff' }}
        >
          <Typography variant="h6">
            {props.title}
            {props.onClose ? (
              <IconButton
                style={{ float: 'right' }}
                aria-label="close"
                onClick={props.onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Typography>
        </MuiDialogTitle>
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}

export default AlertDialogSlideCoupon;
