import { Box, CircularProgress } from '@material-ui/core';
import Button from 'common/components/buttons/button';
import { COMPANY_STATION_ID } from 'config/consts';
import React, { useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getStationData } from '../../../modules/website/websiteActions';

const DeepLink = ({ history, getStationData, website }) => {
  const { actionId, type, resource } = useParams();
  const { stationData, stationDataLoading } = website;

  useEffect(() => {
    getStationData(COMPANY_STATION_ID);
  }, [getStationData]);

  useEffect(() => {
    const { url, domain, ios, android } = stationData;

    let appLink, desktopLink;

    if (type && url && domain) {
      appLink =
        process.env.REACT_APP_API_KEY === 'development'
          ? `exp://192.168.0.16:19000/--/${resource}/${type}/${actionId}`
          : `${domain}:///${resource}/${type}/${actionId}`;
      desktopLink =
        process.env.REACT_APP_API_KEY === 'development'
          ? `http://localhost:3000/#/homepage/atividades/${actionId}`
          : `${url}/#/homepage/atividades/${actionId}`;
    }

    if (isMobile && appLink) {
      window.location.replace(appLink);
    } else if (isMobile === false && desktopLink) {
      window.location.replace(desktopLink);
    }

    if (ios && android) {
      setTimeout(() => {
        if (isMobile) {
          if (isIOS) {
            window.location.replace(ios);
          } else {
            window.location.replace(android);
          }
        } else {
          window.location.replace(desktopLink);
        }
      }, 2000);
    }
  }, [actionId, resource, stationData, type]);

  if (stationDataLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (isMobile) {
    return (
      <Box display="flex" justifyContent="center">
        <Button
          label={'Abrir o app'}
          onClick={() => {
            window.location.replace(
              `${website.stationData?.domain}:///${resource}/${type}/${actionId}`
            );
          }}
          buttonStyle={{
            width: '45%',
            minWidth: '270px',
            marginTop: 32,
          }}
        />
      </Box>
    );
  }

  return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStationData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeepLink);
