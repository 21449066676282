import styled from 'styled-components';
import { COMPANY_COLORS } from '../../../../../../../config/consts';
import { shade } from 'polished';
import { isMobileOnly } from 'react-device-detect';

export const Container = styled.section`
  margin-top: 15px;
  background: transparent;
  padding: 30px;
  border-radius: 10px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    section {
      display: flex;
      img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }

      div {
        margin-left: 15px;
        p {
          margin: 0;
        }
      }
    }
    svg {
      color: ${COMPANY_COLORS.primary};
      cursor: pointer;

      &:hover {
        color: ${shade(0.2, COMPANY_COLORS.primary)};
      }
    }
  }
`;

export const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  p {
    display: flex;
    overflow-wrap: anywhere;
    word-break: break-word;
  }

  img {
    max-height: 420px;
    max-width: ${isMobileOnly ? '90vw' : '620px'};
    margin: 0 auto;
  }
`;

export const Interaction = styled.section`
  display: flex;
  margin-top: 15px;

  button {
    cursor: pointer;
    margin: 0;
    border: 0;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    svg {
      color: lightgray;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, 'gray')};
      }
    }

    & + button {
      margin-left: 50px;
    }

    span {
      margin-left: 5px;
    }
  }
`;

export const Confirmation = styled.section`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    padding: 10px;
    display: flex;

    button {
      padding: 10px;
      width: 60px;
      color: ${(props) => props.color};
      cursor: pointer;
      text-decoration: underline;

      & + button {
        margin-left: 30px;
        color: white;
        background: ${(props) => props.color};
        text-decoration: none;
      }

      &:focus {
        outline: none;
      }
    }
  }
`;
