/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BASE_S3 } from 'config/consts';
import { setLoading, getActionsByStationId } from './activitiesActions';
import { Link } from 'react-router-dom';
import Spinner from 'common/components/spinner/spinner';
import _ from 'lodash';
import { tableSearch } from 'helpers/utils';

import { COMPANY_COLORS } from 'config/consts';
// import { bindActionCreators } from "redux";
import station_filter from 'assets/imgs/station_filter.png';
import { InputLabelOutlined } from 'common/components/fields/fields';
import { COMPANY_STATION_ID } from '../../../../config/consts';

export function Activities(props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    props.getActionsByStationId(COMPANY_STATION_ID);
  }, []);

  useEffect(() => {
    setData(props.activities.list.content);
  }, [props.activities.list.content]);

  const [data, setData] = useState([]);

  function selectActivity(id) {
    props.setLoading(true);
    props.history.push(`/homepage/atividades/${id}`);
  }

  function handleChange(e) {
    setData(tableSearch(e, props.activities.list.content));
    setValue(e.target.value);
  }

  if (props.activities.loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  if (_.isEmpty(props.activities.list.content)) {
    return (
      <section id="activities-list">
        <div className="row justify-content-center h-100 py-3">
          <div className="col-10">
            <div className="row justify-content-center">
              <div className="col-4 d-flex justify-content-center">
                <h4>Atividades</h4>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <p>Não há atividades</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="activities-list">
      <div className="row justify-content-center h-100 py-3">
        <div className="col-10">
          <div className="row justify-content-center">
            <div className="col-4 d-flex justify-content-center">
              <h4>Atividades</h4>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <h5 className="station-font-black">Em destaque</h5>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3 d-flex justify-content-end align-items-center">
              <h5
                style={{ display: 'inline-block', marginRight: '1rem' }}
                className="text-muted"
              >
                Filtrar
              </h5>
              <span
                style={{
                  background: COMPANY_COLORS.primary,
                  padding: 15,
                  borderRadius: 10,
                }}
              >
                <img src={station_filter} alt="FILTER-ICON" />
              </span>
            </div>
          </div>
          {!props.activities.loading ? (
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="row justify-content-center">
                  {data.map((item, index) => (
                    <Link
                      to={
                        item.type_id === 13 ? item.redirect_links[0].url : null
                      }
                    >
                      <div
                        className="col-xs-12 col-sm-6 col-md-4 mb-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => selectActivity(item.id)}
                      >
                        <div
                          className="card"
                          style={{ width: '100%', height: '100%' }}
                        >
                          <img
                            src={`${BASE_S3}/${item.image}`}
                            className="card-img-top"
                            alt="..."
                          />
                          <div className="card-body">
                            <p
                              className="text-muted station-font-bold"
                              style={{
                                letterSpacing: 1.5,
                                fontSize: '0.75rem',
                                textTransform: 'uppercase',
                              }}
                            >
                              {item.type ? item.type.name : ''}
                            </p>
                            <h6 className="station-font-regular">
                              {item.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoading,
      getActionsByStationId,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
