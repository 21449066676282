import styled, { css } from 'styled-components';
import {
  COMPANY_COLORS,
  BASE_S3,
  GENERAL_TEXT_COLOR,
} from '../../../../../../config/consts';
import { shade } from 'polished';
import { isMobileOnly } from 'react-device-detect';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 150px;
  padding: 15px 0;
  border-bottom: 1px solid #c4c4c4;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 15px;
  flex: 1;

  p {
    margin: 0;
  }

  svg {
    cursor: pointer;
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: ${isMobileOnly ? 'flex-start' : 'flex-end'};
    flex-direction: ${isMobileOnly ? 'column' : 'row'};
  }
`;

export const ItemImage = styled.div`
  cursor: pointer;
  background-image: ${(props) => `url(${BASE_S3}/${props.image})`};
  border-radius: ${(props) => props.isRounded ? '8px' : ''};
  user-select: none;
  /* width: ${isMobileOnly ? '89px' : '178px'};
  height: ${isMobileOnly ? '50px' : '100px'}; */
  width: ${(props) => (props.isMobile ? '143px' : '178px')};
  height: ${(props) => (props.isMobile ? '80px' : '100px')};
  background-position: center;
  background-size: cover;

  ${(props) =>
    props.description &&
    css`
      width: 600px;
      height: 337px;
    `}
`;

export const Delete = styled.span`
  cursor: pointer;
`;
export const Title = styled.p`
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  width: 100%;
  height: fit-content;
  user-select: none;
`;

export const secTitle = styled.span`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 29px !important;
  letter-spacing: 0.25px !important;
  color: #505050 !important;
  margin: 0.5rem 0 !important;
`;

export const Quantity = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  align-items: center;
  user-select: none;
  border: 1px solid ${GENERAL_TEXT_COLOR};
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 103px;

  svg {
    color: ${GENERAL_TEXT_COLOR};
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, GENERAL_TEXT_COLOR)};
    }
  }

  p {
    font-size: 14px;
    height: fit-content;
    color: ${GENERAL_TEXT_COLOR};
    margin: 0 8px 2px 8px;
  }
`;

export const Prices = styled.div`
  user-select: none;
  display: flex;
  align-items: flex-end;
  min-width: 150px;

  span:nth-child(1) {
    font-weight: 500;
    font-size: 14px;
    height: fit-content;
    line-height: 16px;
    align-items: center;
  }

  span:nth-child(2) {
    margin-left: 12px;
    font-weight: 500;
    height: fit-content;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #c4c4c4;
  }
`;

export const FinalPrice = styled.div`
  display: flex;
  /* min-width: ${isMobileOnly ? '0px' : '150px'}; */
  justify-content: flex-end;
  align-items: flex-end;
  color: #c4c4c4;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  span {
    margin-left: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    color: ${GENERAL_TEXT_COLOR};
  }
`;

export const DescriptionContainer = styled.div`
  padding: 70px 90px;
  display: flex;
  flex-direction: column;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.01em;
    color: ${GENERAL_TEXT_COLOR};
  }

  span {
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
`;

export const RadioSelect = styled.div`
  height: 50px;
  width: 25px;

  label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover input ~ span section {
      display: block;
      background-color: #c4c4c4;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ span section {
      display: block;
    }

    span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border: 2px solid ${COMPANY_COLORS.general.general_color};
      border-radius: 50%;

      section {
        display: none;
        height: 15px;
        width: 15px;
        background-color: #c4c4c4;
        border-radius: 50%;
      }
    }
  }
`;

export const SeeMore = styled.span`
  font-size: 14px;
  cursor: pointer;
`;
