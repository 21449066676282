import { Box, Grid, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import {
  InputLabelOutlined,
  MaterialCheckbox,
  SelectLabel,
} from 'common/components/fields/fields';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues } from 'redux-form';
import { createTextMask } from 'redux-form-input-masks';
import {
  getUserAddress,
  initializeForm,
} from '../../../../shopping/shoppingActions';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import { phoneMask, dateMask } from 'helpers/masks';
import { isMobileOnly } from 'react-device-detect';

class CreditCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCard: true,
      preview: false,
      issuer: null,
      payment: {
        cvv: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
      },
    };

    this.cepMask = createTextMask({
      pattern: '99.999-999',
      stripMask: true,
      onChange: (updatedValue) => this.handleGetAdress(updatedValue),
    });
    this.cpf = createTextMask({
      pattern: '999.999.999-99',
      stripMask: true,
    });

    this.dateReducedMask = createTextMask({
      pattern: '99/99',
      stripMask: true,
      onChange: (value) =>
        this.handleInputChange({ target: { name: 'payment.expiry', value } }),
    });
  }

  handleNewCard = (e) => {
    if (e.target.value === 0) {
      this.setState({
        newCard: true,
        preview: false,
        issuer: null,
        payment: {
          name: '',
          number: '',
          expiry: '',
        },
      });
    } else {
      this.setState({
        newCard: false,
        preview: true,
        issuer: this.props.creditCards.filter(
          (item) => item.id === e.target.value
        )[0].brand,
        payment: {
          ...this.state.payment,
          name: '*******',
          number: `************${
            this.props.creditCards.filter(
              (item) => item.id === e.target.value
            )[0].last_digits
          }`,
          expiry: '****',
        },
      });
    }
  };

  componentDidMount() {
    if (
      this.props.formValues.payment &&
      this.props.formValues.payment.selected_card > 0
    ) {
      this.setState({
        newCard: false,
        preview: true,
        issuer: this.props.creditCards.filter(
          (item) => item.id === this.props.formValues.payment.selected_card
        )[0].brand,
        payment: {
          ...this.state.payment,
          name: '*******',
          number: `************${
            this.props.creditCards.filter(
              (item) => item.id === this.props.formValues.payment.selected_card
            )[0].last_digits
          }`,
          expiry: '****',
        },
      });
    } else if (this.props.creditCards.length > 0) {
      this.setState({
        newCard: false,
        preview: true,
        issuer: this.props.creditCards[0].brand,
        payment: {
          ...this.state.payment,
          name: '*******',
          number: `************${this.props.creditCards[0].last_digits}`,
          expiry: '****',
        },
      });
      this.props.initializeForm({
        ...this.props.formValues,
        payment: {
          ...this.props.formValues.payment,
          selected_card: this.props.creditCards[0].id,
        },
      });
    }
  }

  componentDidUpdate() {
    if (this.props.formValues?.payment?.type === 'pix' && this.state.newCard) {
      this.setState({ newCard: false });
    }
  }

  handleGetAdress = (value) => {
    if (value.length === 8) {
      const zipcode = { zipcode: value };
      this.props.getUserAddress(zipcode, this.props.formValues, true);
    } else {
      return null;
    }
  };

  handleInputFocus = (e) => {
    const name = e.target.name.split('.')[1];
    this.setState({ payment: { ...this.state.payment, focus: name } });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const finalName = name.split('.')[1];
    this.setState({ payment: { ...this.state.payment, [finalName]: value } });
  };

  render() {
    let cardList = [{ value: 0, label: 'Cadastre novo cartão' }];
    this.props.creditCards.forEach((item, index) => {
      cardList[index + 1] = {
        value: item.id,
        label: `${item.brand.toUpperCase()} **** **** **** ${item.last_digits}`,
      };
    });

    return (
      <>
        <Grid
          item
          container
          // spacing={3}
          xs={12}
          style={
            {
              // padding: isMobileOnly ? '24px 12px' : '0px 24px'
            }
          }
        >
          <Grid
            item
            container
            // direction="column"
            xs={12}
            style={
              {
                // padding: '12px 6px 12px 0'
              }
            }
          >
            <Grid item container xs={12}>
              <Typography
                variant="h6"
                style={{ marginBottom: 8, marginTop: 16 }}
              >
                Selecione uma forma de pagamento
              </Typography>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-start"
              xs={12}
              md={7}
              style={{ marginBottom: 16 }}
            >
              <Field
                component={SelectLabel}
                label="Escolha uma opção"
                name="payment.type"
                options={[
                  { label: 'Cartão de crédito', value: 'credit_card' },
                  { label: 'PIX', value: 'pix' },
                ]}
                validate={[FORM_RULES.required]}
              />
            </Grid>
            {this.props.formValues?.payment?.type === 'credit_card' && (
              <>
                <Grid item container xs={12}>
                  <Typography
                    variant="h6"
                    style={{ color: '#707070', marginBottom: '24px' }}
                  >
                    Selecione um cartão
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  xs={12}
                  md={7}
                >
                  <Field
                    component={SelectLabel}
                    label="Escolha ou cadastre um cartão"
                    type="text"
                    name={`payment.selected_card`}
                    options={cardList}
                    onChange={(e) => this.handleNewCard(e)}
                    validate={[FORM_RULES.required]}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {!isMobileOnly &&
          this.props.formValues?.payment?.type === 'credit_card' ? (
            <MaterialGrid item xs={12} style={{ padding: '12px 6px 12px 0' }}>
              <Box style={{ margin: 24 }}>
                <Cards
                  cvc={this.state.payment.cvv}
                  expiry={this.state.payment.expiry}
                  focused={this.state.payment.focus}
                  name={this.state.payment.name}
                  number={this.state.payment.number}
                  preview={this.state.preview}
                  issuer={this.state.issuer}
                />
              </Box>
            </MaterialGrid>
          ) : null}
        </Grid>
        {this.state.newCard && (
          <MaterialGrid
            container
            spacing={3}
            style={{ padding: isMobileOnly ? '24px 12px' : '0px 24px' }}
          >
            <MaterialGrid
              item
              xs={12}
              // lg={6}
              style={{ padding: '12px 6px 12px 0' }}
            >
              <Box>
                <Typography
                  variant="h6"
                  style={{ color: '#707070', marginBottom: 8 }}
                >
                  Dados do cartão
                </Typography>
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="Número do cartão"
                  type="tel"
                  name={`payment.number`}
                  normalize={handleWhiteSpaces}
                  inputProps={{
                    maxLength: 16,
                  }}
                  validate={[FORM_RULES.required, FORM_RULES.card_number]}
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="Nome escrito no cartão"
                  type="text"
                  name={`payment.name`}
                  validate={[FORM_RULES.required]}
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="CPF do dono do cartão"
                  type="text"
                  name={`payment.cpf`}
                  validate={[FORM_RULES.required, FORM_RULES.cpf]}
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  {...this.cpf}
                />
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="Data de Nascimento do dono do cartão"
                  type="tel"
                  name="payment.birthday"
                  validate={[FORM_RULES.required]}
                  {...dateMask}
                />
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="Digite seu e-mail"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  // placeholder="Digite seu e-mail"
                  name="payment.email"
                  validate={[FORM_RULES.required, FORM_RULES.email]}
                  helperText="Digite seu e-mail"
                  normalize={handleWhiteSpaces}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                style={{ marginTop: 12 }}
              >
                <Box style={{ marginRight: 6, flex: 1 }}>
                  <Field
                    component={InputLabelOutlined}
                    label="Expiração"
                    type="tel"
                    name={`payment.expiry`} /*
                    inputProps={{
                      maxLength: 4,
                    }} */
                    validate={[
                      FORM_RULES.required,
                      FORM_RULES.expiry,
                      FORM_RULES.max(4),
                    ]}
                    onFocus={this.handleInputFocus}
                    {...this.dateReducedMask}
                  />
                </Box>
                <Box style={{ marginLeft: 6, flex: 1 }}>
                  <Field
                    component={InputLabelOutlined}
                    label="CVC"
                    type="tel"
                    name={`payment.cvv`}
                    inputProps={{
                      maxLength: 3,
                    }}
                    validate={[
                      FORM_RULES.required,
                      FORM_RULES.cvc,
                      FORM_RULES.max(3),
                    ]}
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </Box>
              </Box>
              <Box display="flex" alignItems="center" style={{ marginTop: 12 }}>
                <Field
                  component={MaterialCheckbox}
                  label="Deseja salvar os dados desse cartão para compras futuras?"
                  name={`payment.save`}
                />
              </Box>
            </MaterialGrid>
            <MaterialGrid
              item
              xs={12}
              // md={6}
              style={{ padding: '12px 0px 12px 6px' }}
            >
              <Box>
                <Typography
                  variant="h6"
                  style={{ color: '#707070', marginBottom: 8 }}
                >
                  Endereço de cobrança
                </Typography>
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="Telefone"
                  type="tel"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  name="payment.phone"
                  validate={[FORM_RULES.required]}
                  helperText="Digite seu Telefone"
                  {...phoneMask}
                />
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="CEP"
                  type="tel"
                  name={`payment.cep`}
                  onChange={(e) => this.handleGetAdress(e)}
                  validate={[FORM_RULES.required]}
                  {...this.cepMask}
                />
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="Cidade"
                  type="text"
                  name={`payment.city`}
                  validate={[FORM_RULES.required]}
                />
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="Estado (UF)"
                  type="text"
                  name={`payment.state`}
                  validate={[FORM_RULES.required]}
                />
              </Box>
              <Box style={{ marginTop: 12 }}>
                <Field
                  component={InputLabelOutlined}
                  label="Rua"
                  type="text"
                  name={`payment.street`}
                  validate={[FORM_RULES.required]}
                />
              </Box>
              <MaterialGrid style={{ marginTop: 12 }} container>
                <MaterialGrid item xs={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="Número"
                    type="tel"
                    name={`payment.street_number`}
                    validate={[FORM_RULES.required]}
                  />
                </MaterialGrid>
              </MaterialGrid>
            </MaterialGrid>
          </MaterialGrid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    creditCards: state.creditCards.list.content,
    formValues: getFormValues('userShoppingCartForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ initializeForm, getUserAddress }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
