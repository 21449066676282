import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { BASE_API, USER_TOKEN } from '../../../../../config/consts';

const URL_POSTS = `${BASE_API}/posts`;
const URL_POSTS_IMAGE = `${BASE_API}/posts-image`;
const URL_POSTS_VIDEO = `${BASE_API}/posts-vimeo`;
const URL_LIKE = `${BASE_API}/likes-web`;
const URL_UNLIKE = `${BASE_API}/likes`;
const URL_UPLOAD_VIDEO = `${BASE_API}/upload-video`;
const VIDEO = `${BASE_API}/videos`;

export const getAllPosts = (stationId) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'POSTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_POSTS}/${stationId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'POSTS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const getAllPostsByLimit = (stationId, limit) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'POSTS_PAGE_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_POSTS}-limit/${stationId}/${limit}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'POSTS_PAGE_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendPost = (values, limit) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'POSTS_CREATE_LOAD',
        payload: true,
      },
    ]);
    axios
      .post(URL_POSTS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          getAllPostsByLimit(values.station_id, limit),
          {
            type: 'POSTS_CREATE_LOAD',
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: 'POSTS_CREATE_LOAD',
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendPostImage = (values, limit) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'POSTS_CREATE_LOAD',
        payload: true,
      },
    ]);

    const newValues = {
      base64: values.media.base64,
      imageType: values.media.type,
      user: values.user,
      description: values.description,
      station_id: values.station_id,
    };
    axios
      .post(URL_POSTS_IMAGE, newValues, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          getAllPostsByLimit(values.station_id, limit),
          {
            type: 'POSTS_CREATE_LOAD',
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: 'POSTS_CREATE_LOAD',
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const sendPostVideo = (values, limit) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'POSTS_CREATE_LOAD',
        payload: true,
      },
    ]);
    axios
      .post(URL_POSTS_VIDEO, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          getAllPostsByLimit(values.station_id, limit),
          {
            type: 'POSTS_CREATE_LOAD',
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: 'POSTS_CREATE_LOAD',
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export function uploadVideo(selectedFile, setVideo) {
  return (dispatch) => {
    const data = new FormData();
    data.append('file', selectedFile);
    axios
      .post(URL_UPLOAD_VIDEO, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'UPLOAD_VIDEO_PROGRESS',
              payload: `${parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          getVideo(response.data.id, setVideo),
          {
            type: 'UPLOAD_VIDEO_PROGRESS',
            payload: '',
          },
        ]);
      });
  };
}

export function getVideo(id, setVideo) {
  return (dispatch) => {
    axios
      .get(`${VIDEO}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([setVideo(response.data.url)]);
      });
  };
}

export const getAllPostsByLimitWithoutLoading = (stationId, limit) => {
  return (dispatch) => {
    axios
      .get(`${URL_POSTS}-limit/${stationId}/${limit}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'POSTS_PAGE_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const likePost = (user, post, limit, stationId) => {
  return (dispatch) => {
    const values = {
      user,
      post,
    };

    axios
      .post(URL_LIKE, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        // post['user_liked'] = true;
        // dispatch([
        //   getAllPostsByLimitWithoutLoading(stationId, limit),
        //   {
        //     type: 'USER_LIKED',
        //     payload: post,
        //   },
        // ]);
        console.log('liked');
      })
      .catch((e) => {
        // console.log.log(e)
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const unlikePost = (user, post, limit, stationId) => {
  return (dispatch) => {
    const values = {
      user,
      post,
    };
    axios
      .put(URL_UNLIKE, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        // post['user_liked'] = false;
        // dispatch([
        //   getAllPostsByLimitWithoutLoading(stationId, limit),
        //   {
        //     type: 'USER_LIKED',
        //     payload: post,
        //   },
        // ]);
        console.log('unliked');
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const deletePostWithId = (post_id, station_id, limit) => {
  return (dispatch) => {
    axios
      .delete(`${URL_POSTS}/${post_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([getAllPostsByLimit(station_id, limit)]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};
