/* eslint-disable react-hooks/exhaustive-deps */
import { FullButton } from 'common/components/buttons/fullButton';
import {
  CaptchaInput,
  InputLabelOutlined,
} from 'common/components/fields/fields';
import { COMPANY_STATION_ID } from 'config/consts';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { phoneMask } from '../../helpers/masks';
import './website.css';
import { submitSupport } from './websiteActions';

let SupportMessageRedForm = function (props) {
  function onSubmit(values) {
    values['station_id'] = COMPANY_STATION_ID;
    props.submitSupport(values, props.history);
  }
  function renderForm() {
    return (
      <>
        <div className="row d-flex justify-content-center">
          <form
            onSubmit={props.handleSubmit((v) => onSubmit(v))}
            noValidate
            style={{ width: isMobileOnly ? 330 : 450 }}
          >
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <Field
                  component={InputLabelOutlined}
                  label="Seu nome"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  name="name"
                  validate={[FORM_RULES.required]}
                />
              </div>
            </div>
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <Field
                  component={InputLabelOutlined}
                  label="Telefone"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  name="phone"
                  {...phoneMask}
                />
              </div>
            </div>
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <Field
                  component={InputLabelOutlined}
                  label="E-mail para contato"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  name="email"
                  validate={[FORM_RULES.required, FORM_RULES.email]}
                  normalize={handleWhiteSpaces}
                />
              </div>
            </div>
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <Field
                  component={InputLabelOutlined}
                  label="Assunto"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  limitChar={200}
                  name="subject"
                  validate={[FORM_RULES.required]}
                />
              </div>
            </div>
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <Field
                  component={InputLabelOutlined}
                  label="Mensagem"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  limitChar={600}
                  multiline
                  rows={5}
                  name="message"
                  validate={[FORM_RULES.required]}
                />
              </div>
            </div>
            <div mt={3}>
              <div className="row justify-content-start my-3">
                <div className="d-flex justify-content-start">
                  <Field
                    component={CaptchaInput}
                    inputlabelprops={{
                      shrink: true,
                    }}
                    name="captcha"
                    validate={[FORM_RULES.required]}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-start mb-4">
              <Grid item xs={5}>
                <div className="d-flex justify-content-center">
                  <FullButton classNameProp="py-3 px-4" type="submit">
                    Enviar
                  </FullButton>
                </div>
              </Grid>
            </div>
          </form>
        </div>
      </>
    );
  }
  return renderForm();
};

SupportMessageRedForm = reduxForm({ form: 'supportForm' })(
  SupportMessageRedForm
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitSupport,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportMessageRedForm);
