import _ from 'lodash';
import moment from 'moment';

export const hasKeys = (obj, attrs) => {
  let tmp = obj;

  for (let i = 0; i < attrs.length; ++i) {
    if (_.isUndefined(tmp[attrs[i]])) return false;
    // tmp = tmp[attrs[i]];
  }
  return true;
};

export const validateCNPJ = (maskedCNPJ) => {
  const unmaskedCNPJ = maskedCNPJ
    .replace('.', '')
    .replace('.', '')
    .replace('/', '')
    .replace('-', '');
  const firstValidationDigits = [
    parseInt(unmaskedCNPJ[0]),
    parseInt(unmaskedCNPJ[1]),
    parseInt(unmaskedCNPJ[2]),
    parseInt(unmaskedCNPJ[3]),
    parseInt(unmaskedCNPJ[4]),
    parseInt(unmaskedCNPJ[5]),
    parseInt(unmaskedCNPJ[6]),
    parseInt(unmaskedCNPJ[7]),
    parseInt(unmaskedCNPJ[8]),
    parseInt(unmaskedCNPJ[9]),
    parseInt(unmaskedCNPJ[10]),
    parseInt(unmaskedCNPJ[11]),
  ];

  const validationArray = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  let sumOfResultsArray = 0;

  for (let i = 0; i < firstValidationDigits.length; i += 1) {
    sumOfResultsArray += firstValidationDigits[i] * validationArray[i];
  }

  const divisionValidation = parseInt(sumOfResultsArray % 11);

  if (divisionValidation < 2) {
    if (parseInt(unmaskedCNPJ[12]) !== 0) {
      return false;
    }
  } else {
    if (parseInt(unmaskedCNPJ[12]) !== 11 - divisionValidation) {
      return false;
    }
  }

  const secondValidationDigits = [
    parseInt(unmaskedCNPJ[0]),
    parseInt(unmaskedCNPJ[1]),
    parseInt(unmaskedCNPJ[2]),
    parseInt(unmaskedCNPJ[3]),
    parseInt(unmaskedCNPJ[4]),
    parseInt(unmaskedCNPJ[5]),
    parseInt(unmaskedCNPJ[6]),
    parseInt(unmaskedCNPJ[7]),
    parseInt(unmaskedCNPJ[8]),
    parseInt(unmaskedCNPJ[9]),
    parseInt(unmaskedCNPJ[10]),
    parseInt(unmaskedCNPJ[11]),
    parseInt(unmaskedCNPJ[12]),
  ];

  const secondValidationArray = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  let secondSumOfResults = 0;

  for (let i = 0; i < secondValidationDigits.length; i += 1) {
    secondSumOfResults += secondValidationDigits[i] * secondValidationArray[i];
  }

  const secondDivisionValidation = parseInt(secondSumOfResults % 11);
  if (secondDivisionValidation < 2) {
    if (parseInt(unmaskedCNPJ[13]) !== 0) {
      return false;
    }
  } else {
    if (parseInt(unmaskedCNPJ[13]) !== 11 - secondDivisionValidation) {
      return false;
    }
  }
  return true;
};

export const tableSearch = (e, list) => {
  const newList = list.filter((item, index) => {
    let isFiltered = Object.values(item).find((item2, index2) => {
      if (!_.isNull(item2)) {
        if (
          item2.toString().toUpperCase().search(e.target.value.toUpperCase()) >
          -1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    if (!_.isUndefined(isFiltered)) {
      return true;
    } else {
      return false;
    }
  });
  return newList;
};

export const tableSort = (type, field, firstValue, secondValue) => {
  if (type === 'datetime') {
    if (
      moment(firstValue[field], 'DD-MM-YYYY HH:mm').isAfter(
        moment(secondValue[field], 'DD-MM-YYYY HH:mm'),
        'minute'
      )
    ) {
      return 1;
    } else {
      return -1;
    }
  } else if (type === 'money') {
    return (
      parseFloat(firstValue[field].split(' ', 2)[1]) -
      parseFloat(secondValue[field].split(' ', 2)[1])
    );
  }
};

export const imgToImgType = (image) => {
  let a = image.base64.indexOf('/');
  let b = image.base64.indexOf(';');
  image.base64.slice(a + 1, b);
  return image.base64.slice(a + 1, b);
};

export const imgToBase64 = (image) => {
  return image.base64;
};

export const formatReal = (value) => {
  const totalReal = value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  return totalReal;
};

export const searchTableValue = (value, defaultData, typeData = 'default') => {
  let currentData = [];
  let filtered = [];
  if (value !== '') {
    currentData = defaultData;
    filtered = currentData.filter((item) => {
      let lc;
      let lc2;
      if (typeData === 'partners') {
        lc = item['partner'].name.toLowerCase();
      } else if (typeData === 'cpf') {
        lc = item.cpf ? item.cpf.toLowerCase() : '';
      } else if (typeData === 'default') {
        lc = item.name ? item.name.toLowerCase() : '';
      } else if (typeData === 'owners' || typeData === 'subscriptions') {
        lc = item['user'].name.toLowerCase();
      } else if (typeData === 'news') {
        lc = item.title ? item.title.toLowerCase() : '';
      } else if (typeData === 'duplicateSearch') {
        lc = item.name ? item.name.toLowerCase() : '';
        lc2 = item.email ? item.email.toLowerCase() : '';
      }
      const filter = value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      if (typeData === 'duplicateSearch') {
        return (
          lc
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(filter) ||
          lc2
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(filter)
        );
      } else {
        return lc
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(filter);
      }
    });
  } else {
    filtered = defaultData;
  }
  return filtered;
};

export const limitStringSize = (string, MAX = 50) => {
  let str = string;
  if (str.length > MAX) str = `${str.substring(0, MAX)} ...`;
  return str;
};

export function formatPhone(tel) {
  const DDD = `(${tel[0]}${tel[1]})`;
  let number = '';
  if (tel.length === 11) {
    number = `${tel[2]}.${tel[3]}${tel[4]}${tel[5]}${tel[6]}-${tel[7]}${tel[8]}${tel[9]}${tel[10]}`;
  } else if (tel.length === 10) {
    number = `${tel[2]}${tel[3]}${tel[4]}${tel[5]}-${tel[6]}${tel[7]}${tel[8]}${tel[9]}`;
  }
  const newPhone = `${DDD} ${number}`;

  return newPhone;
}

export const customCurrencyChanger = (currencyData, value) => {
  const { currency_multiplier, currency_name, currency_name_position } =
    currencyData;
  const customValue = value * currency_multiplier;

  if (currency_name_position === 'before_currency') {
    return `${currency_name} ${customValue.toFixed(2).replace('.', ',')}`;
  } else {
    // if currency_name_position === after_currency
    return `${customValue.toFixed(2).replace('.', ',')} ${currency_name}`;
  }
};

export const truncateString = (string, size) => {
  const ellipsis = '...';
  return string.length >= size
    ? string.substring(0, size).concat(ellipsis)
    : string;
};

export const getNameInitials = (name) => {
  if (name) {
    const splitName = name.split(' ');
    if (splitName.length > 1 && splitName[splitName.length - 1][0]) {
      return `${splitName[0][0]}${splitName[splitName.length - 1][0]}`;
    } else {
      return name[0];
    }
  }
  return '?';
};

export const hexToRgb = (hex, opacity) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgbObject = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
  const { r, g, b } = rgbObject;

  return opacity ? `rgb(${r} ${g} ${b} / ${opacity})` : `rgb(${r} ${g} ${b})`;
};

export const maskCPF = (cpf) => {
  if (cpf) {
    return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(
      6,
      9
    )}-${cpf.substring(9, 11)}`;
  }
  return '';
};
