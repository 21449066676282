import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { String2Currency } from '../../../../helpers/dataTransformers';
import { ItemImage, Prices, Title } from './styles';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Grid, Typography } from '@material-ui/core';
import { COMPANY_FONT_FAMILY } from 'config/consts';

let ItemCard = function ({ data }) {
  const price = useMemo(() => {
    return String2Currency(data.value);
  }, [data.value]);

  const discountPrice = useMemo(() => {
    return String2Currency(data.value - data.discount_value);
  }, [data.discount_value, data.value]);

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        // alignItems="center"
        xs={12}
        style={{
          margin: '.3rem 0',
        }}
      >
        <Grid
          item
          container
          xs={3}
        >
          <ItemImage image={data.image} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          xs={9}
        >
          <Typography variant="h6">
            <Title>{data.name}</Title>
          </Typography>
          <FiMoreHorizontal />
          <Prices>
            <Typography variant="body1">
              {' '}
              <span>{discountPrice}</span>
            </Typography>
            {data.discount_value > 0 && (
              <span style={{ fontFamily: COMPANY_FONT_FAMILY }}>{price}</span>
            )}
          </Prices>
        </Grid>
      </Grid>

      {/* <Container>
      <ItemImage image={data.image} />
      <InfoContainer>
        <Title>{data.name}</Title>
        <FiMoreHorizontal />
        <section>
          <Prices>
            <span>{discountPrice}</span>
            {data.discount_value > 0 && <span>{price}</span>}
          </Prices>
        </section>
      </InfoContainer>
    </Container> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ItemCard);
