import MomentUtils from '@date-io/moment';
import { Slider, Typography } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createTheme, makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { Check } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BASE_S3 } from 'config/consts';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FontIcon } from 'react-md';
import { appColors } from 'styles/color.jsx';
import { formatReal } from '../../../helpers/utils';
import './tableComponents.css';

const theme = createTheme({
  overrides: {
    MuiInput: {
      root: {
        paddingLeft: 14,
        height: 30,
        borderRadius: 15,
        border: '1px solid #DFDFDF',
        color: 'black',
      },
    },
  },
});

const themeSec = createTheme({
  overrides: {
    MuiMenu: {
      paper: {
        width: 450,
      },
    },
  },
});
const themeDatePicker = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        height: 45,
      },
    },
  },
});

const themeColors = createTheme({
  palette: {
    primary: blue,
  },
});

const themeOptions = createTheme({
  overrides: {
    MuiMenu: {
      paper: {},
    },
    MuiListItemText: {
      root: {},
    },
    MuiListItemIcon: {
      root: {
        width: 28,
        minWidth: 0,
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function ButtonElement({
  name,
  onClick,
  color,
  disabled = false,
  center = false,
}) {
  return (
    <div
      key={name}
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: center ? 'center' : 'flex-start',
        alignItems: 'center',
      }}
    >
      <Typography
        disabled={disabled}
        variant={'body1'}
        color={'primary'}
        onClick={disabled ? () => { } : onClick}
        className="links"
        style={{
          fontSize: 14,
          color: disabled ? '#7D7D7D' : color ? color : 'default',
        }}
      >
        {name}
      </Typography>
    </div>
  );
}

export function ButtonIconElement({
  type,
  onClick,
  color,
  disabled = false,
  justifyContent,
}) {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: justifyContent,
        alignItems: 'center',
      }}
    >
      {type === 0 ? (
        <IconButton
          style={{
            color: theme.palette.success.main,
            backgroundColor: 'transparent',
          }}
          aria-label="Aceitar"
        >
          <FontIcon onClick={onClick} style={{ cursor: 'pointer' }}>
            search
          </FontIcon>
        </IconButton>
      ) : type === 1 ? (
        <IconButton
          style={{ color: theme.palette.success.main }}
          aria-label="Aceitar"
          onClick={onClick}
        >
          <Check />
        </IconButton>
      ) : null}
    </div>
  );
}

export function ActionNameElement({ actionName, image = '' }) {
  return (
    <div className="rowFlexParent cellNameActivity">
      {image ? (
        <div className="rowFlexParent" style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div
            style={{
              height: 35,
              width: 62.22,
              borderRadius: 4,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
                background: `url(${BASE_S3}/${image}) center center / cover`,
              }}
            ></div>
          </div>
        </div>
      ) : null}
      <div
        className="rowFlexParent"
        style={{
          width: image ? '70%' : '100%',
          justifyContent: actionName ? 'flex-start' : 'center',
        }}
      >
        <p
          className="cleanDefaultStyle"
          style={{ marginLeft: 12, marginRight: 4 }}
        >
          {actionName
            ? actionName.length > 18
              ? `${actionName.substring(0, 18)}...`
              : actionName
            : '-'}
        </p>
      </div>
    </div>
  );
}
export function NameElement({ name, limit = true }) {
  return (
    <div
      className="rowFlexParent"
      style={{
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        minHeight: 50,
      }}
    >
      <p
        className="cleanDefaultStyle"
        style={{
          marginLeft: 4,
          marginRight: 4,
          width: 250,
          wordWrap: 'normal',
          display: 'block',
        }}
      >
        {name
          ? limit
            ? name.length > 30
              ? `${name.substring(0, 30)}...`
              : name
            : name
          : '-'}
      </p>
    </div>
  );
}

function activityStatus(activity) {
  if (!activity.active && !activity.is_draft) return 'offline';
  if (!activity.active && activity.is_draft) return 'draft';
  if (activity.active && !activity.is_draft) {
    if (moment(new Date()).isBetween(activity.start, activity.end)) {
      return 'online';
    }
    if (moment(new Date()).isBefore(activity.start)) {
      return 'scheduled';
    }
  }
}

export function ActionStatusElement({ activity }) {
  const status = activityStatus(activity);

  return (
    <div
      className="rowFlexParent"
      style={
        {
          // border: '1px solid black',
        }
      }
    >
      <div className="rowFlexParent">
        {status !== 'scheduled' ? (
          <FiberManualRecordIcon
            style={{
              fontSize: 16,
              color:
                status === 'online'
                  ? '#29A50C'
                  : status === 'draft'
                    ? '#775A42'
                    : '#E34848',
              padding: 0,
              margin: 0,
            }}
          />
        ) : (
          <QueryBuilderIcon
            style={{
              fontSize: 16,
              color: '#00B476',
              padding: 0,
              margin: 0,
            }}
          />
        )}
      </div>
      <div
        className="rowFlexParent"
        style={{
          justifyContent: 'flex-start',
          paddingLeft: 5,
        }}
      >
        <p className="capitalize cleanDefaultStyle">
          {status === 'online'
            ? 'online'
            : status === 'draft'
              ? 'rascunho'
              : status === 'scheduled'
                ? 'programada'
                : 'encerrada'}
        </p>
      </div>
    </div>
  );
}

export function DateElement({ date, start }) {
  return (
    <div className="rowFlexParent" style={{ justifyContent: 'flex-start' }}>
      <div className="rowFlexParent" style={{ maxWidth: 72 }}>
        <p className="cleanDefaultStyle">{date ? date : 'a definir'}</p>
      </div>
    </div>
  );
}

const CssDateTimeField = withStyles({
  root: {
    flexGrow: 1,

    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
})(DateTimePicker);

function FilterElement({
  label,
  component = 'slider',
  values,
  onChangeSlider,
  onChangeSwitch,
  onChangeStartDate,
  onChangeEndDate,
  setApplyError,
}) {
  const erroStartDate =
    values.startDate && values.endDate
      ? !moment(values.startDate).isBefore(values.endDate)
      : false;
  const errorEndDate =
    values.startDate && values.endDate
      ? !moment(values.endDate).isAfter(values.startDate)
      : false;
  const classes = useStyles();

  useEffect(() => {
    if (erroStartDate || errorEndDate) {
      setApplyError(true);
    } else {
      setApplyError(false);
    }
  }, [erroStartDate, errorEndDate, setApplyError]);

  return (
    <ThemeProvider theme={themeColors}>
      <div className="colFilterOption" style={{ marginTop: 5 }}>
        <div
          className="rowFlexParent width-100"
          style={{ justifyContent: 'flex-start' }}
        >
          <p className="cleanDefaultStyle" style={{ fontSize: 14 }}>
            {label}
          </p>
          <Switch
            checked={values.checked}
            onChange={onChangeSwitch}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            color="primary"
          />
        </div>
        <div
          className="colFilterOption width-100"
          style={{ justifyContent: 'flex-start' }}
        >
          {component === 'slider' ? (
            <>
              <Slider
                color="primary"
                max={values.maxValue}
                min={values.minValue}
                step={values.maxValue / 100}
                style={{ width: '100%' }}
                disabled={!values.checked}
                value={values.value}
                onChange={(_, newValue) => onChangeSlider(newValue)}
                aria-labelledby="continuous-slider"
              />

              <p className="cleanDefaultStyle" style={{ fontSize: 14 }}>
                {values.typeData === 'quantity'
                  ? `${values.value[0]} até ${values.value[1]}`
                  : values.typeData === 'money'
                    ? `${formatReal(values.value[0])} até ${formatReal(
                      values.value[1]
                    )}`
                    : null}
              </p>
            </>
          ) : component === 'datePicker' ? (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  paddingTop: 0,
                  marginTop: 4,
                }}
              >
                <div className={classes.container} style={{ width: '40%' }}>
                  <ThemeProvider theme={themeDatePicker}>
                    <CssDateTimeField
                      error={erroStartDate}
                      helperText={
                        erroStartDate
                          ? 'Escolha uma data anterior a segunda.'
                          : ''
                      }
                      label={'Início'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.startDate}
                      onChange={onChangeStartDate}
                      inputVariant="outlined"
                      format={'DD/MM/YYYY HH:mm'}
                      disabled={!values.checked}
                      ampm={false}
                      openPickerIcon={
                        <ArrowDropDownIcon style={{ padding: 0 }} />
                      }
                      autoOk
                    />
                  </ThemeProvider>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      marginBottom: 0,
                      marginLeft: 15,
                      marginRight: 15,
                      textAlign: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    até
                  </p>
                </div>
                <div className={classes.container} style={{ width: '40%' }}>
                  <ThemeProvider theme={themeDatePicker}>
                    <CssDateTimeField
                      error={errorEndDate}
                      helperText={
                        errorEndDate
                          ? 'Escolha uma data posterior a primeira.'
                          : ''
                      }
                      label={'Fim'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.endDate}
                      onChange={onChangeEndDate}
                      inputVariant="outlined"
                      format={'DD/MM/YYYY HH:mm'}
                      ampm={false}
                      style={{ width: '35%' }}
                      ke
                      keyboardIcon={
                        <ArrowDropDownIcon style={{ padding: 0 }} />
                      }
                      autoOk
                      disabled={!values.checked}
                    />
                  </ThemeProvider>
                </div>
              </div>
            </MuiPickersUtilsProvider>
          ) : null}
        </div>
      </div>
    </ThemeProvider>
  );
}

export function MenuFiltersOptions({
  buttonRef,
  open,
  setAnchorEl,
  filterOptions,
  appliedFilters,
  setAppliedFilters,
}) {
  const setInitialState = (defaultOptions, appliedValues) => {
    const arrayValues = [...appliedValues];

    const filterStoredValue = (array, field_name) => {
      return array.find((value) => value.field === field_name);
    };

    if (defaultOptions && defaultOptions.length > 0) {
      const defaultState = defaultOptions.map((item, index) => {
        if (item.field === 'betweenDates') {
          return {
            startDate: filterStoredValue(arrayValues, item.field)
              ? filterStoredValue(arrayValues, item.field).startDate
              : null,
            endDate: filterStoredValue(arrayValues, item.field)
              ? filterStoredValue(arrayValues, item.field).endDate
              : null,
            checked: filterStoredValue(arrayValues, item.field)
              ? filterStoredValue(arrayValues, item.field).checked
              : false,
            filterFunction: item.filterFunction,
            field: item.field,
            label: item.label,
            typeData: item.typeData,
          };
        }
        return {
          value: filterStoredValue(arrayValues, item.field)
            ? filterStoredValue(arrayValues, item.field).value
            : [0, 0],
          checked: filterStoredValue(arrayValues, item.field)
            ? filterStoredValue(arrayValues, item.field).checked
            : false,
          field: item.field,
          label: item.label,
          typeData: item.typeData,
          maxValue: item.maxValue,
          minValue: item.minValue,
        };
      });
      return defaultState;
    }
    return [];
  };

  const [applyError, setApplyError] = useState(false);
  const [elementValues, setElementValues] = useState(
    setInitialState(filterOptions, appliedFilters)
  );

  const handleChangeSlider = (index, newValue) => {
    setElementValues((prevState) => {
      const newSliderValues = [...prevState];
      newSliderValues[index].value = newValue;
      return newSliderValues;
    });
  };
  const handleSwitchValues = (index) => {
    setElementValues((prevState) => {
      const newSwitchValues = [...prevState];
      newSwitchValues[index].checked = !newSwitchValues[index].checked;
      return newSwitchValues;
    });
  };

  const handleChangeDate = (index, date, field) => {
    setElementValues((prevState) => {
      const newValues = [...prevState];
      newValues[index][field] = date;
      return newValues;
    });
  };

  const handleApplyFilters = () => {
    const checkedElements = elementValues.filter(
      (item) => item.checked === true
    );
    if (checkedElements.length > 0) {
      setAppliedFilters(checkedElements);
    } else {
      setAppliedFilters([]);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={themeSec}>
      <Menu
        id="simple-menu"
        anchorEl={buttonRef}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div
          className="rowFlexParent"
          style={{
            justifyContent: 'space-between',
          }}
        >
          <p
            className="cleanDefaultStyle"
            style={{ fontSize: 18, fontWeight: 'bold', marginLeft: 30 }}
          >
            Filtrar
          </p>
          <IconButton
            style={{ backgroundColor: 'transparent' }}
            disableFocusRipple
            disableRipple
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="colFilters">
          {filterOptions &&
            filterOptions.map((item, index) => (
              <FilterElement
                key={index}
                values={elementValues[index]}
                onChangeSlider={(newValue) =>
                  handleChangeSlider(index, newValue)
                }
                onChangeSwitch={() => handleSwitchValues(index)}
                onChangeStartDate={(newDate) =>
                  handleChangeDate(index, newDate, 'startDate')
                }
                onChangeEndDate={(newDate) =>
                  handleChangeDate(index, newDate, 'endDate')
                }
                setApplyError={setApplyError}
                label={item.label}
                name={item.field}
                component={item.component}
                typeData={item.typeData}
              />
            ))}

          <div
            className="rowFlexParent width-100"
            style={{ justifyContent: 'flex-end' }}
          >
            <ButtonElement
              name="Cancelar"
              color={'#2d95ef'}
              onClick={handleClose}
            />
            <ButtonElement
              disabled={applyError}
              color={'#2d95ef'}
              name="Aplicar"
              onClick={handleApplyFilters}
            />
          </div>
        </div>
      </Menu>
    </ThemeProvider>
  );
}

export function CashElement({ value, valueSec = 0, divider = true }) {
  const num = formatReal(value);
  const deno = formatReal(valueSec);

  return (
    <p className="cleanDefaultStyle">{divider ? `${num}/${deno}` : num}</p>
  );
}

export function SearchBar(props) {
  const nameForm = useRef(null);

  const onClick = () => {
    const form = nameForm.current;
    props.setSearchValue(form['input'].value);
  };
  const onClearInput = () => {
    props.setSearchValue('');
  };

  const handleChange = (e) => {
    if (e.target.value === '') {
      e.preventDefault();
      props.setSearchValue(e.target.value);
    }
  };

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      props.setSearchValue(e.target.value);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <form ref={nameForm}>
        <Input
          style={{ width: props.small ? 160 : 240 }}
          className="input"
          defaultValue={props.defaultValue ? props.defaultValue : ''}
          type="text"
          name="input"
          fullWidth
          disableUnderline
          placeholder={props.placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          id="input-with-icon-adornment"
          endAdornment={
            props.defaultValue ? (
              <IconButton
                style={{ backgroundColor: 'transparent' }}
                disableFocusRipple
                disableRipple
                onClick={onClearInput}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                style={{ backgroundColor: 'transparent' }}
                disableFocusRipple
                disableRipple
                onClick={onClick}
              >
                <SearchIcon />
              </IconButton>
            )
          }
        />
      </form>
    </ThemeProvider>
  );
}

export function ColumnsBelowSearch(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = React.useRef();
  const handleClick = () => {
    setAnchorEl(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: props.buttons
          ? props.buttons.namedButtons.length === 1
            ? 'center'
            : 'space-evenly'
          : 'space-evenly',
        height: 50,
      }}
    >
      {props.buttons
        ? props.buttons.namedButtons.length > 0 &&
        props.buttons.namedButtons.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <ButtonElement
                name={item.name}
                color={item.color}
                onClick={item.onClick}
                disabled={item.disabled}
              />
            </div>
          );
        })
        : null}
      {props.buttons && props.buttons.iconButtons.length > 0
        ? props.buttons.iconButtons.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <IconButton
                ref={buttonRef}
                disableFocusRipple
                disableRipple
                style={{ backgroundColor: 'transparent' }}
                onClick={item.name === 'options' ? handleClick : item.onClick}
              >
                {item.name === 'options' ? (
                  <MoreHorizIcon />
                ) : item.name === 'delete' ? (
                  <DeleteOutlineIcon
                    style={{ color: item.color ? item.color : 'default' }}
                  />
                ) : null}
              </IconButton>
              {item.menuIconOptions && item.menuIconOptions.length > 0 ? (
                <MenuOptions
                  open={Boolean(anchorEl)}
                  buttonRef={() => buttonRef.current}
                  setAnchorEl={setAnchorEl}
                  menuOptions={item.menuIconOptions}
                />
              ) : null}
            </div>
          );
        })
        : null}
    </div>
  );
}

export function NumberGenericElement({
  value,
  valueSec = null,
  category,
  divider = false,
}) {
  return (
    <div className="rowFlexParent">
      <div className="rowFlexParent" style={{ width: 90 }}>
        <div className="rowFlexParent" style={{ width: '20%' }}>
          {category === 'participantes' ? (
            <PersonOutlineIcon fontSize="small" />
          ) : category === 'visualizations' ? (
            <VisibilityIcon fontSize="small" />
          ) : category === 'conversion' ? (
            <CheckCircleOutlineIcon fontSize="small" />
          ) : category === 'reach' ? (
            <CallMadeIcon fontSize="small" />
          ) : null}
        </div>
        <div
          className="rowFlexParent"
          style={{ width: '80%', justifyContent: 'flex-start', paddingLeft: 5 }}
        >
          <p className="cleanDefaultStyle" style={{ fontSize: 14 }}>
            {divider ? `${value}/${valueSec}` : value}
          </p>
        </div>
      </div>
    </div>
  );
}

export function RemainCashElement({ value = 0 }) {
  const remainCashReal = formatReal(value);

  return (
    <div className="rowFlexParent">
      <div
        className="rowFlexParent cellRemainCash"
        style={{ backgroundColor: value > 0 ? '#00b476' : '#F37C09' }}
      >
        <p
          className="cleanDefaultStyle"
          style={{ color: 'white', fontWeight: 'bold' }}
        >
          {remainCashReal}
        </p>
      </div>
    </div>
  );
}
export function ConditionalElement({
  condition,
  labelTrue = 'Sim',
  labelFalse = 'Não',
  center,
}) {
  return (
    <div className="rowFlexParent" style={{ justifyContent: 'flex-start' }}>
      <p className="cleanDefaultStyle" style={{ color: 'black' }}>
        {condition ? labelTrue : labelFalse}
      </p>
    </div>
  );
}

export function MenuOptions({ buttonRef, open, setAnchorEl, menuOptions }) {
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (item) => {
    if (item.label === 'Editar') {
      item.onClickMenuItem();
    }
    setAnchorEl(null);
  };



  return (
    <ThemeProvider theme={themeOptions}>
      <Menu
        id="simple-menu"
        anchorEl={buttonRef}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {menuOptions.length > 0
          ? menuOptions.map((item, index) => {
            return (
              <MenuItem key={index} onClick={() => handleClick(item)}>
                <ListItemIcon>
                  {item.label === 'Prorrogar' ? (
                    <QueryBuilderIcon fontSize="small" />
                  ) : item.label === 'Editar' ? (
                    <EditIcon fontSize="small" />
                  ) : item.label === 'Pausar' ? (
                    <PauseCircleOutlineIcon fontSize="small" />
                  ) : item.label === 'Encerrar' ? (
                    <HighlightOffIcon fontSize="small" />
                  ) : null}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </MenuItem>
            );
          })
          : null}
      </Menu>
    </ThemeProvider>
  );
}
