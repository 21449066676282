import styled from "styled-components";

export const BlockButton = styled.button `
  padding: .8rem 1.5rem;
  border-radius: 5;
  background: ${(props) =>
  props.background
  ? props.background
  : ''
  };
  color: ${(props) =>
  props.color
  ? props.color
  : ''
  };
  min-width: 168px;
  max-width: fit-content;
  text-align: center;

  :focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;
