import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';

const URL_SUPPORT = `${BASE_API}/enviar-suporte`;

export const submitSupport = (values, history = undefined) => {
  return (dispatch) => {
    dispatch([
      { type: 'SUBMIT_PROGRESS', payload: 1 },
      { type: 'ABOUT_BUTTON_LOAD', payload: true },
    ]);

    axios
      .post(`${URL_SUPPORT}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          { type: 'ABOUT_BUTTON_LOAD', payload: false },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);

        if (!_.isUndefined(history)) {
          history.push('/');
        }
      })
      .catch((e) => {
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          { type: 'ABOUT_BUTTON_LOAD', payload: false },
        ]);

        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `${e.response.data.error}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
