import type from './types';

const INITIAL_STATE = {
  loading: true,
  list: {
    content: [],
  },
  detail: {},
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.LIST_FETCHED:
      console.log(action);

      return {
        ...state,
        list: { content: action.payload.data },
        loading: false,
      };
    case type.LIST_LOAD:
      return { ...state, loading: action.payload };
    case type.ITEM_DETAIL:
      return {
        ...state,
        detail: action.payload.data[0],
        loading: false,
      };
    case type.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    default:
      return state;
  }
};
