import { Grid, Typography } from '@material-ui/core';
import { String2Currency } from 'helpers/dataTransformers';
import React from 'react';
import { Field } from 'redux-form';
import ItemCardMultiSelect from '../../components/ItemCard/multiSelect';
import { Subtitle } from './styles';

export class RenderProductsConfirmation extends React.Component {
  isDelivery = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return deliveryLocation.delivery;
    }
  };

  addresHelper1 = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return deliveryLocation.helper_label_field_1;
    }
  };

  addresHelper2 = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return deliveryLocation.helper_label_field_2;
    }
  };

  withdrawalAddress = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return (
        'Retirar em: ' +
        deliveryLocation.street +
        ', número ' +
        deliveryLocation.street_number +
        '. CEP: ' +
        deliveryLocation.zipcode
      );
    }
  };
  price = (actionWithProducts) => {
    if (actionWithProducts.location) {
      const deliveryLocation =
        actionWithProducts.action.delivery_locations.filter(
          (location) =>
            location.id === actionWithProducts.location.deliveryLocation
        )[0];
      return deliveryLocation.price;
    } else return 0;
  };

  render() {
    return this.props.fields.map((item, index) => {
      return (
        <>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            key={index}
          >
            {this.props.formValues['actions'][index].products.map(
              (item, productIndex) => (
                <Field
                  key={productIndex}
                  name={`actions[${index}].products[${productIndex}]`}
                  component={ItemCardMultiSelect}
                />
              )
            )}
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
            >
              <Subtitle>
                <Typography variant="h6">
                  Informações de entrega e retirada
                </Typography>
              </Subtitle>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              style={{
                padding: '.4rem 0',
              }}
            >
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
                sm={8}
                component="address"
              >
                {this.props.formValues['actions'][index].action &&
                !this.props.formValues['actions'][index].action
                  .digital_product ? (
                  <>
                    {this.props.formValues &&
                    this.props.formValues['actions'][index]
                      ? this.isDelivery(
                          this.props.formValues['actions'][index]
                        ) && (
                          <>
                            <Typography variant="body1">
                              {this.addresHelper1(
                                this.props.formValues['actions'][index]
                              )}
                              :{' '}
                              {
                                this.props.formValues['actions'][index].location
                                  .address
                              }
                            </Typography>
                            <Typography variant="body1">
                              {this.addresHelper2(
                                this.props.formValues['actions'][index]
                              )}
                              :{' '}
                              {
                                this.props.formValues['actions'][index].location
                                  .number
                              }
                            </Typography>
                            <Typography variant="body1">
                              CEP:{' '}
                              {
                                this.props.formValues['actions'][index].location
                                  .zipcode
                              }
                            </Typography>
                          </>
                        )
                      : null}
                    {this.props.formValues &&
                    this.props.formValues['actions'][index]
                      ? !this.isDelivery(
                          this.props.formValues['actions'][index]
                        ) && (
                          <label>
                            <Typography variant="body1">
                              {this.withdrawalAddress(
                                this.props.formValues['actions'][index]
                              )}
                            </Typography>
                          </label>
                        )
                      : null}
                  </>
                ) : (
                  <label className="mt-2">
                    <Typography variant="body1">
                      Esse produto é digital, portanto, não possui frete.
                    </Typography>
                  </label>
                )}
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                xs={12}
                sm={4}
              >
                <Typography variant="body1">Taxa de Entrega:</Typography>
                <Typography variant="body1" className="ml-3">
                  {String2Currency(
                    this.price(this.props.formValues['actions'][index])
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* <div className="d-flex flex-column" key={index}>
          {this.props.formValues['actions'][index].products.map(
            (item, productIndex) => (
              <Field
                key={ productIndex }
                name={ `actions[${index}].products[${productIndex}]` }
                component={ ItemCardMultiSelect }
              />
            )
          )}
          <div className="d-flex justify-content-between align-items-center flex-row">
            <label className="mt-3">
              <span>Informações de entrega e retirada</span>
            </label>
            <div className="d-flex justify-content-between align-items-center flex-row mt-3 ">
              <span style={{ color: '#7E0000' }}>Taxa de Entrega:</span>
              <span style={{ color: COMPANY_COLORS.primary }} className="ml-3">
                {String2Currency(
                  this.price(this.props.formValues['actions'][index])
                )}
              </span>
            </div>
          </div>
          {this.props.formValues['actions'][index].action &&
          !this.props.formValues['actions'][index].action.digital_product ? (
            <>
              {this.props.formValues && this.props.formValues['actions'][index]
                ? this.isDelivery(this.props.formValues['actions'][index]) && (
                    <>
                      <p>
                        {this.addresHelper1(
                          this.props.formValues['actions'][index]
                        )}
                        :{' '}
                        {
                          this.props.formValues['actions'][index].location
                            .address
                        }
                      </p>
                      <p>
                        {this.addresHelper2(
                          this.props.formValues['actions'][index]
                        )}
                        :{' '}
                        {
                          this.props.formValues['actions'][index].location
                            .number
                        }
                      </p>
                      <p>
                        CEP:{' '}
                        {
                          this.props.formValues['actions'][index].location
                            .zipcode
                        }
                      </p>
                    </>
                  )
                : null}
              {this.props.formValues && this.props.formValues['actions'][index]
                ? !this.isDelivery(this.props.formValues['actions'][index]) && (
                    <label>
                      <span>
                        {this.withdrawalAddress(
                          this.props.formValues['actions'][index]
                        )}
                      </span>
                    </label>
                  )
                : null}
            </>
          ) : (
            <label className="mt-2">
              <span>Esse produto é digital, portanto, não possui frete.</span>
            </label>
          )}
        </div> */}
        </>
      );
    });
  }
}
