import { Box, FormControl, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as CheckCircleIcon } from '../../../../../assets/icons/check-circle.svg';
import { formatPhone } from '../../../../../helpers/utils';
import { sendAppointmentRequest } from './dentalActions';
import './styles.css';

const DentalAppointmentsForm = ({ dental, sendAppointmentRequest, auth }) => {
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const [description, setDescription] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState(new Date());

  function handleSubmit() {
    const values = {
      description,
      date,
      phone: phone
        .replace('(', '')
        .replace(')', '')
        .replace(/ /g, '')
        .replace('.', '')
        .replace('-', ''),
    };
    function cleanup() {
      setWasSuccessful(true);
    }
    sendAppointmentRequest(values, cleanup);
  }

  const { user } = auth;

  return (
    <section id="dentalAppointments">
      <p className="body-title mt-4">
        Agendamento de consulta na Clínica Odontolegis
      </p>
      <p className="h4 mt-4 text-muted text-left">
        Você pode agendar sua consulta na clínica Odontolegis (610 sul) por
        aqui. Lembrando que esse espaço é exclusivo para marcação na
        Odontolegis, para demais estabelecimentos, clique aqui e confira a rede
        credenciada. (
        <a
          href="https://www.portoseguro.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.portoseguro.com.br
        </a>
        )
      </p>
      {wasSuccessful ? (
        <Box
          style={{ padding: '24px 71px 48px 71px' }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={3}
        >
          <CheckCircleIcon color={'#F98979'} height="42" width="42" />
          <p className="h3 mt-4 text-center" style={{ maxWidth: 400 }}>
            Sua solicitação foi realizada com sucesso!
          </p>
          <p className="h7 mt-3 text-center" style={{ maxWidth: 400 }}>
            Em breve entraremos em contato para confirmar os horários
            disponíveis.
          </p>
        </Box>
      ) : (
        <Box>
          <Box display="flex" mt={4} justifyContent="space-between">
            <p className="h6">Nome: {user.name.split(' ')[0]}</p>
            <p className="h6 ml-3">Celular: {formatPhone(user.tel)}</p>
          </Box>
          <Box display="flex" mt={2} justifyContent="space-between">
            <p className="h6">E-mail: {user.email}</p>
          </Box>
          <p className="h6-bold mt-5 mb-1">Telefone para contato</p>
          <InputMask
            mask="(99) 9.9999-9999"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          >
            {(inputProps) => (
              <TextField
                label="Telefone"
                variant="outlined"
                {...inputProps}
                disableUnderline
              />
            )}
          </InputMask>
          <p className="h6-bold mt-2">Preferência de dia</p>

          <FormControl variant="outlined" style={{ marginTop: 8 }}>
            <Select
              native
              value={date}
              onChange={(e) => setDate(e.target.value)}
            >
              <option aria-label="Nenhum" value={null}>
                Escolha uma opção
              </option>
              <option value={'Segunda-feira'}>Segunda-feira</option>
              <option value={'Terça-feira'}>Terça-feira</option>
              <option value={'Quarta-feira'}>Quarta-feira</option>
              <option value={'Quinta-feira'}>Quinta-feira</option>
              <option value={'Sexta-feira'}>Sexta-feira</option>
              <option value={'Sábado'}>Sábado</option>
            </Select>
          </FormControl>

          <p className="h6-bold mt-2">Descrição do assunto</p>
          <TextField
            label="Descrição"
            multiline
            rows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            style={{ width: '100%', marginTop: 8 }}
          />
          <Box display="flex" justifyContent="flex-end">
            <button
              className="mt-5"
              style={{
                backgroundColor: '#378587',
                padding: '16px 48px',
                color: '#fff',
              }}
              onClick={() => handleSubmit()}
            >
              Enviar
            </button>
          </Box>
        </Box>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    dental: state.dental,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendAppointmentRequest,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DentalAppointmentsForm);
