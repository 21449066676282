import ptBr from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { BASE_S3, GENERAL_TEXT_COLOR } from 'config/consts';
import moment from 'moment';
import React, { Component } from 'react';
import { ButtonSecondary } from '../buttons/ButtonSecondary';
import AlertDialogSlide from '../dialog/dialog';
import './calendar.css';

export default class Calendar extends Component {
  state = {
    showModal: false,
    selectedEvent: '',
    selectedDescription: '',
    selectedTitle: '',
    selectedStart: '',
    selectedEnd: '',
    selectedImage: '',
    selectedDay: '',
    open: false,
  };
  handleDateClick = (event) => {
    let eventStart = '';
    let eventEnd = '';
    let eventDay = '';

    for (let evento of this.props.schedules.list.content) {
      if (parseInt(evento['id']) === parseInt(event.event.id)) {
        eventStart = evento['start'];
        eventEnd = evento['end'];
        eventDay = evento.day.description;
      }
    }

    this.setState({
      open: true,
      selectedEvent: event.event._def.publicId,
      selectedDescription: event.event.extendedProps.description,
      selectedTitle: event.event.title,
      selectedStart: eventStart,
      selectedEnd: eventEnd,
      selectedDay: eventDay,
      selectedImage: event.event.extendedProps.image_src,
    });
  };

  onHide = () => {
    this.setState({ open: false });
  };

  handleDelete = () => {
    this.props.handleDelete(this.state.selectedEvent);
  };

  handleEdit = () => {
    this.props.history.push(
      `${this.props.history.location.pathname}/${this.state.selectedEvent}/editar`
    );
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { weekends, schedules } = this.props;
    const eventList = schedules.list.content.map((event) => {
      return {
        id: event.id,
        start: `${event.day.description}T${event.start}:10.000Z`,
        end: `${event.day.description}T${event.end}:10.000Z`,
        title: event.name,
        description: event.description,
        image_src: `${BASE_S3}/${event.image}`,
      };
    });
    return (
      <div className="container" style={{ padding: 0 }}>
        <div className="row">
          <div className="col-12" style={{ color: GENERAL_TEXT_COLOR }}>
            <FullCalendar
              defaultView="dayGridMonth"
              header={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
              }}
              plugins={[dayGridPlugin, timeGridPlugin]}
              selectable={true}
              events={eventList}
              weekends={weekends}
              eventColor={'#999999'}
              displayEventTime={true}
              displayEventEnd={true}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              timeZone="UTC"
              locale={ptBr}
              eventClick={this.handleDateClick}
              slotDuration="00:15:00"
              buttonIcons={false}
            />

            <AlertDialogSlide
              visible={this.state.open}
              onClose={this.handleClose}
              maxWidth={'sm'}
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  height: 'fit-content',
                }}
              >
                <div className="col-sm-12" style={{ padding: 0 }}>
                  <div
                    style={{
                      backgroundImage: `url(${this.state.selectedImage})`,
                      maxWidth: '100%',
                      height: 300,
                      width: 'auto',
                      borderRadius: 0,
                      overflow: 'hidden',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      position: 'relative',
                    }}
                    className="col-10"
                  />
                  <div style={{ padding: 24 }}>
                    <CardContent style={{ padding: 0 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {this.state.selectedTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {this.state.selectedDescription}
                      </Typography>
                      <br />
                      <Box display="flex" direction="row" alignItems="center">
                        <EventIcon style={{ marginRight: '5px' }} />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {moment(this.state.selectedDay).format('DD/MM/YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        direction="row"
                        alignItems="center"
                        style={{ margin: '5px 0 5px 0' }}
                      >
                        <ScheduleIcon style={{ marginRight: '5px' }} />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {`Começa:  ${this.state.selectedStart}`}
                        </Typography>
                      </Box>
                      <Box display="flex" direction="row" alignItems="center">
                        <AccessTimeIcon style={{ marginRight: '5px' }} />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {`Termina: ${this.state.selectedEnd}`}
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActions style={{ marginTop: 24, padding: 0 }}>
                      <ButtonSecondary
                        size="small"
                        type="button"
                        onClick={this.onHide}
                      >
                        Ok
                      </ButtonSecondary>
                    </CardActions>
                  </div>
                </div>
              </div>
            </AlertDialogSlide>
          </div>
        </div>
      </div>
    );
  }
}
