import styled from 'styled-components';
import {
  COMPANY_COLORS
} from 'config/consts';
import {
  shade
} from 'polished';

export const Form = styled.form `
  background: white;
  width: 100%;
`;

export const CommentsLabel = styled.h6 `
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.25px;
  color: #2b2b2b;
  margin-bottom: 0;
`;

export const Button = styled.button `
  cursor: pointer;
  background-color: ${(props) => (props.disabled ? '#999' : 'transparent')};
  height: 50px;
  width: 100px;
  color: #5759fb;
  border-radius: 20px;
  transition: background-color 0.2s;

  /* &:hover { */
  /* background-color: ${(props) =>
    props.disabled
      ? shade(0.2, COMPANY_COLORS.primary)
      : shade(0.2, '#9990')}; */
  /* } */

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input `
  padding-left: ${(props) => (props.isMobile ? '5px' : '15px')};
  width: 100%;
  max-height: 130px;
  border: transparent;
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.companyBackgroundColor};
  color: ${(props) => props.textColor};

  &::placeholder {
    color: ${(props) => props.textColor};
  }
`;

export const LikeButton = styled.button `
  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const CommentButton = styled.button `
  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const DeleteCommentButton = styled.button `
  display: ${(props) => (props.authId !== props.commentId ? 'none' : '')};

  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const CommentAnswerButton = styled.button `
  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const CommentBody = styled.p `
  word-break: break-all !important;
  border-radius: 5px;
  max-width: 100%;

  animation: highlightsLastComment;
  animation-duration: 1.2s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  @keyframes highlightsLastComment {
    0% {
      margin-left: 100%;
      width: 300%;
      overflow: initial;
    }

    70% {
      /* background-color: #9999; */
    }

    100% {
      margin-left: 0%;
      width: 100%;
      background-color: '';
      display: inherit;
    }
  }
`;

export const ModalConfirmationButton = styled.button `
  background-color: ${(props) => (props.cancel ? 'transparent;' : '#5759FB')};
  color: ${(props) => (props.cancel ? '#5759FB' : 'white')};
  padding: 1rem 1.8rem;
  margin: 0 0.3rem;
  transition: 600ms;

  &:hover {
    background-color: ${(props) => (props.cancel ? '#9999' : '#9999')};
    color: ${(props) => (props.cancel ? '#5759FB' : '#5759FB')};
  }

  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const CloseModalButton = styled.button `
  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const ConfirmationContent = styled.p `
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
`;

export const SummaryDetail = styled.div `
  transition: background-color 0.3s;
  border-radius: 5px;

  &:hover {
    /* background-color: #f4f4f4; */
  }
`;
