export default {
  LIST_FETCHED: 'CREDIT_CARDS_FETCHED',
  LIST_LOAD: 'CREDIT_CARDS_LOAD',
  REQUESTS_FETCHED: 'REQUESTS_FETCHED',
  REQUESTS_LOAD: 'REQUESTS_LOAD',
  TEMPLATES_FETCHED: 'CREDIT_CARDS_TEMPLATES_FETCHED',
  TEMPLATES_LOAD: 'CREDIT_CARDS_TEMPLATES_LOAD',
  ITEM_DETAIL: 'CREDIT_CARDS_DETAIL',
  BUTTON_LOAD: 'CREDIT_CARDS_BUTTON_LOAD',
  ADDRESS_FETCHED: 'USER_ADDRESS_FETCHED',
  ADDRESS_LOAD: 'USER_ADDRESS_LOAD',
};
