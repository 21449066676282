import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import type from './types';

const URL_SUBSCRIBE = `${BASE_API}/email-subscribe`;
const URL_UNSUBSCRIBE = `${BASE_API}/email-unsubscribe`;

export const unsubscribeEmail = (values) => {
  return (dispatch) => {
    axios
      .put(`${URL_UNSUBSCRIBE}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([{ type: type.EMAIL_SUBSCRIPTION_LOAD, payload: false }]);
        if (response.data[0] === 0) {
          dispatch([{ type: type.EMAIL_SUBSCRIPTION_ERROR, payload: true }]);
        } else {
          dispatch([{ type: type.EMAIL_SUBSCRIPTION_ERROR, payload: false }]);
        }
      })
      .catch((e) => {
        dispatch([
          { type: type.EMAIL_SUBSCRIPTION_LOAD, payload: false },
          { type: type.EMAIL_SUBSCRIPTION_ERROR, payload: true },
        ]);
      });
  };
};

export const subscribeEmail = (values) => {
  return (dispatch) => {
    axios
      .put(`${URL_SUBSCRIBE}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([{ type: type.EMAIL_SUBSCRIPTION_LOAD, payload: false }]);
        if (_.isEmpty(response.data)) {
          dispatch([{ type: type.EMAIL_SUBSCRIPTION_ERROR, payload: true }]);
        } else {
          dispatch([{ type: type.EMAIL_SUBSCRIPTION_ERROR, payload: false }]);
        }
      })
      .catch((e) => {
        dispatch([
          { type: type.EMAIL_SUBSCRIPTION_LOAD, payload: false },
          { type: type.EMAIL_SUBSCRIPTION_ERROR, payload: true },
        ]);
      });
  };
};
