import React from 'react';
import LinearDeterminate from '../progress/progress';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  NAV_FONT_FAMILY,
} from 'config/consts';
import { FieldButton } from './styles';

export function FullButton(props) {
  const { isSecondary } = props;
  const {
    background = isSecondary ? BUTTON_TEXT_COLOR : BUTTON_BACKGROUND_COLOR,
    text = isSecondary ? BUTTON_BACKGROUND_COLOR : BUTTON_TEXT_COLOR,
    border = isSecondary ? `1px solid ${BUTTON_BACKGROUND_COLOR}` : 'none',
    fontSize,
    classNameProp,
  } = props;
  if (props.progress) {
    return (
      <button
        className={`${classNameProp}`}
        style={{
          background: 'lightgray',
          display: 'block',
          color: COMPANY_COLORS.success,
          border: border,
          fontSize: fontSize,
          fontFamily: NAV_FONT_FAMILY,
          width: props.width || '100%',
        }}
        {...props}
      >
        <LinearDeterminate
          progress={props.progress}
          style={{ display: 'block' }}
        />
        {props.children}
      </button>
    );
  } else {
    return (
      <FieldButton // SC COMPONENT
        className={`${classNameProp}`}
        {...props}
        disabled={props.disabled}
        background={background}
        text={text}
        border={border}
        fontSize={fontSize}
        width={props.width}
      >
        {props.children}
      </FieldButton>
    );
  }
}
