import styled, { css } from 'styled-components';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
} from 'config/consts';
import { shade } from 'polished';
import { isMobileOnly } from 'react-device-detect';

export const CartContainer = styled.div`
  flex: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${isMobileOnly ? '30px 0' : '30px'};
  footer {
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const ForwardButton = styled.button`
  cursor: pointer;
  padding: 10px 20px;
  color: white;
  background: ${COMPANY_COLORS.primary};

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${shade(0.2, COMPANY_COLORS.primary)};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: grey;

      &:hover {
        background: ${shade(0.2, 'grey')};
      }
    `}
`;

export const BackwardsButton = styled.button`
  cursor: pointer;
  padding: 9px 20px;
  color: ${BUTTON_BACKGROUND_COLOR};
  background: ${BUTTON_TEXT_COLOR};
  border: 1px solid ${BUTTON_BACKGROUND_COLOR};

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid ${shade(0.2, BUTTON_BACKGROUND_COLOR)};
    color: ${shade(0.2, BUTTON_BACKGROUND_COLOR)};
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 41px;
  /* identical to box height */

  letter-spacing: 0.25px;
`;

export const Subtitle = styled.p`
  /* max-width: 470px; */
  margin-bottom: ${(props) =>
    props.children === 'Informações de entrega e retirada' ? '.4rem' : '1rem'};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  /* Gray838383 */
  color: #838383;
`;

export const OrangeTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  margin: 0.4rem 0 0.8rem 0;
  /* identical to box height, or 87% */

  /* Secundária */
  color: #f58a76;
`;

export const SecTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.25px;
  color: #505050;
`;
