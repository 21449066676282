import { Typography } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/svg/cartaconfimation.svg';
import { FullButton } from 'common/components/buttons/fullButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import {
  BASE_S3,
  BRB_FLA_STATION_ID,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
} from 'config/consts';
import { FORM_RULES } from 'helpers/validations';
import { getStationData } from 'modules/website/websiteActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import {
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  LINK_TEXT_COLOR,
  SINDILEGIS_STATION_ID,
} from '../../../config/consts';
import { sixDigitCodeMask } from '../../../helpers/masks';
import { resendMail, selectConfirmationMethod } from '../authActions';

// import DeleteIcon from '@material-ui/icons/Delete';

class EmailConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeftToResend: 60,
      canResend: false,
    };
  }

  componentDidMount() {
    if (!this.props.website.stationData.address) {
      this.props.getStationData(COMPANY_STATION_ID);
    }

    setTimeout(() => {
      this.setState({ timeLeftToResend: this.state.timeLeftToResend - 1 });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.timeLeftToResend !== this.state.timeLeftToResend) {
      if (this.state.timeLeftToResend === 0) {
        return this.setState({ canResend: true });
      }
      return setTimeout(() => {
        this.setState({ timeLeftToResend: this.state.timeLeftToResend - 1 });
      }, 1000);
    }
  }

  submit = (values) => {
    this.props.history.push(
      `/ativar/${values.hashCode}/${this.props.match.params?.email}`
    );
  };

  handleResend = () => {
    this.setState({ timeLeftToResend: 60, canResend: false });
    if (COMPANY_STATION_ID === SINDILEGIS_STATION_ID) {
      this.props.selectConfirmationMethod(
        this.props.history.location.state.affiliatedInfo
      );
    } else {
      this.props.resendMail(this.props.match.params?.email);
    }
  };

  render() {
    const { website } = COMPANY_COLORS;
    // const { general } = COMPANY_COLORS;
    if (this.props.auth.loadingMGM) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <LoadingProgress />
        </div>
      );
    }

    return (
      <div
        className="d-flex justify-content-center"
        style={{
          background: `url(${BASE_S3}/${website.header.background_auth_station})`,
          backgroundSize: 'cover',
          minHeight: '100vh',
        }}
      >
        <div className="col-sm-12 col-md-6 col-lg-4 align-self-center my-md-0 my-5">
          <div
            style={{
              background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              borderRadius: 4,
            }}
          >
            <div className="row justify-content-center">
              <div className="col-7 d-flex justify-content-center py-3">
                <img
                  className
                  src={`${BASE_S3}/${
                    website.navbar.station_secondary_logo ||
                    website.navbar.station_secondary_logo
                  }`}
                  alt="Logo"
                  style={{ maxWidth: '100%', height: 'auto', maxHeight: 85 }}
                />
              </div>
              <div
                className="container col-10"
                style={{
                  borderRadius: 10,
                  backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="d-flex flex-row justify-content-center mt-3">
                      <Typography variant="h4">Ative sua conta</Typography>
                    </div>

                    <div className="d-flex flex-row justify-content-center mt-2 mb-3">
                      <Typography
                        variant="body1"
                        className="text-center"
                        style={{ fontSize: 14 }}
                      >
                        Obrigado por se cadastrar.{' '}
                        {COMPANY_STATION_ID === SINDILEGIS_STATION_ID
                          ? this.props.history.location.state.affiliatedInfo
                              .type === 'email'
                            ? `Verifique sua caixa de e-mails`
                            : `Verifique suas mensagens no celular`
                          : COMPANY_STATION_ID === BRB_FLA_STATION_ID
                          ? ''
                          : `Verifique sua caixa de e-mails`}{' '}
                        e digite o código no campo abaixo.
                        {/* Obrigado por se cadastrar. Verifique sua caixa de
                        e-mails ({this.props.match.params?.email}) e digite o
                        código ou clique no link indicado. */}
                      </Typography>
                    </div>
                    <div
                      className="d-flex flex-row justify-content-center mt-2 mb-3"
                      style={{ paddingBottom: 20 }}
                    >
                      <EditIcon />
                    </div>
                    <form onSubmit={this.props.handleSubmit(this.submit)}>
                      <div className="d-flex flex-row justify-content-center mt-2 mb-2">
                        <Field
                          component={InputLabelOutlined}
                          type="tel"
                          label="Código de 6 dígitos"
                          helperText="Digite seu código de 6 dígitos"
                          name="hashCode"
                          validate={[FORM_RULES.required]}
                          {...sixDigitCodeMask}
                        />
                      </div>
                      <div className="d-flex justify-content-center py-3">
                        <FullButton classNameProp="py-3 px-4" type="submit">
                          Validar código
                        </FullButton>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="container col-10 mt-5 mb-3">
                <div className="row justify-content-center">
                  <div className="col-10">
                    <Typography
                      variant="body1"
                      className="text-center"
                      // style={{
                      //   marginBottom: 0,
                      //   fontSize: 12,
                      //   color: '#666666',
                      // }}
                    >
                      Caso não receba nenhuma mensagem em alguns segundos,
                      verifique sua conexão e tente novamente em:{' '}
                      {!this.state.canResend ? (
                        <span
                        // style={{
                        //   marginBottom: 0,
                        //   fontSize: 12,
                        //   color: '#666666',
                        // }}
                        >
                          {this.state.timeLeftToResend}
                        </span>
                      ) : (
                        <Typography
                          variant="subtitle2"
                          style={{
                            marginBottom: 0,
                            // fontSize: 12,
                            // color: general.secondaryButton.background,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={this.handleResend}
                        >
                          reenviar o código
                        </Typography>
                      )}
                      . Se desejar, você pode alterar seu método de confirmação{' '}
                      <span
                        style={{
                          marginBottom: 0,
                          // fontSize: 12,
                          color: LINK_TEXT_COLOR,
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: '/cadastrar',
                            state: {
                              stepForm: 2,
                              affiliatedInfo:
                                this.props.history.location.state
                                  .affiliatedInfo,
                            },
                          })
                        }
                      >
                        aqui.
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="container my-3">
                <div className="row justify-content-center">
                  <div
                    className="col-10 d-flex justify-content-center align-items-center p-3"
                    style={{
                      backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      component="a"
                      href="#/login"
                      className="station-font-medium"
                      style={{
                        // color: '#0E5FD6',
                        // fontSize: '0.875rem',
                        display: 'inline-block',
                        textDecorationLine: 'underline',
                      }}
                    >
                      Voltar
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailConfirmation = reduxForm({ form: 'authForm' })(EmailConfirmation);
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getStationData, resendMail, selectConfirmationMethod },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    general: state.general,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation);
