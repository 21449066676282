import React from 'react';
import { CircularProgress } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AlertDialogSlide from 'common/components/dialog/dialog';
import Quiz from './quiz';

class QuizChosen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ visible: props.visible });
  }

  render() {
    if (this.props.quizzes.loading) {
      return <CircularProgress id="quizzes" />;
    }
    return (
      <AlertDialogSlide
        visible={this.state.visible}
        title="Quiz"
        onClose={() => {
          this.setState({ visible: false });
        }}
      >
        <center>
          <Quiz
            points={this.props.points}
            quiz={this.props.quiz}
            history={this.props.history}
          />
        </center>
      </AlertDialogSlide>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quizzes: state.quizzes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizChosen);
