import { makeStyles } from '@material-ui/core/styles';
import MaterialModal from 'common/components/modal/materialModal';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
} from 'config/consts';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
const QRCode = require('qrcode.react');

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: isMobile ? '100vh' : '95vh',
  },
}));

export default function VoucherModal({ open, setOpen, item }) {
  const classes = useStyles();

  return (
    <MaterialModal
      open={open}
      onClose={() => setOpen(false)}
      showTitle
      PaperProps={{ classes: { root: classes.paper } }}
      fullScreen={isMobile ? true : false}
    >
      <div
        className="container-fluid d-flex-column justify-content-space-around h-100"
        style={{ backgroundColor: '#fff' }}
      >
        <div className="row justify-content-center mt-5">
          <div className="col-8 col-md-4">
            <img
              src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
              alt="LOGO-EMPRESA"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-auto text-center">
            <h4>{item.cupom.action.name}</h4>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-8 col-11">
            <h6 className="text-muted text-center">{`Pagamento feito em: ${moment(
              item.createdAt
            ).format('LLLL')}`}</h6>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-8 col-11">
            <h6 className="text-muted text-center">{`Valor do pagamento: R$ ${parseFloat(
              item.price
            ).toFixed(2)}`}</h6>
          </div>
        </div>
        {item.cupom.is_donation ? null : (
          <>
            {' '}
            <div className="row justify-content-center mt-4">
              <div className="col-auto">
                <QRCode
                  value={`https://parceiro.dukits.com/#/owner/validacao/${item.code}`}
                />
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <h5 className="text-muted">{item.code}</h5>
              </div>
            </div>{' '}
          </>
        )}

        <div className="row justify-content-center mt-5">
          <div className="col-md-auto col-11">
            <div
              className={'py-3 station-font-medium'}
              style={{
                background: BUTTON_BACKGROUND_COLOR,
                color: BUTTON_TEXT_COLOR,
                borderRadius: 8,
                paddingLeft: isMobile ? 40 : 140,
                paddingRight: isMobile ? 40 : 140,
                cursor: 'pointer',
                fontSize: '1rem',
                textAlign: 'center',
              }}
              onClick={() => window.print()}
            >
              {isMobile ? 'Salvar' : 'Imprimir'}
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mb-5">
          <div className="col-auto">
            <a
              href="#/homepage"
              style={{ textDecoration: 'underline', color: '#505050' }}
            >
              Página inicial
            </a>
          </div>
        </div>
      </div>
    </MaterialModal>
  );
}
