import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
//rotas de transaçoes da api
const URL_USER_INVITATIONS = `${BASE_API}/member-get-members`;

export const getUsersInvitedByUser = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'USER_INVITATIONS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_USER_INVITATIONS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        for (let i in response.data) {
          response.data[i]['createdAt'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:mm');
        }
        dispatch([
          {
            type: 'USER_INVITATIONS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {});
  };
};
