import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './types';
import { toastr } from 'react-redux-toastr';

const URL = `${BASE_API}/now-playing`;

export const getList = () => {
  return (dispatch) => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
        dispatch([
          {
            type: type.LIST_LOAD,
            payload: false,
          },
        ]);
      });
  };
};
