/* eslint-disable react-hooks/exhaustive-deps */
import { Chart } from 'primereact/chart';
import React from 'react';
import { SimpleButton } from '../../buttons/simpleButton';
import LottieControl from '../../lottie/lottie';
import DOMPurify from 'dompurify';
import { Typography } from '@material-ui/core';

export default function FeedbackContent(props) {

  function handleFirstButton(actionType) {
    if (actionType === 'route') {
      props.history.push(props.firstRoute);
      props.setFeedbackModal(false);
      props.cleanupFunction();
    } else if (actionType === 'function') {
      props.firstActionFunction();
      props.setFeedbackModal(false);
      props.cleanupFunction();
    }
  }
  const data = {
    labels:
      props.chart && props.chartColumns
        ? props.chartColumns.map((option) => option.label)
        : null,
    datasets: [
      {
        label: 'Votos há 1 minuto atrás',
        backgroundColor: '#42A5F5',
        data:
          props.chart && props.chartColumns
            ? props.chartColumns.map((option) => option.answers.length)
            : null,
      },
    ],
  };

  function handleSecondButton() {
    props.history.push(props.secondRoute);
    props.setFeedbackModal(false);
    props.cleanupFunction();
  }

  function renderPassed() {
    return (
      <div className="px-5 py-3 text-center">
        <Typography variant="h3">{props.passedTitle}</Typography>
        <Typography variant="body1">{props.passedText}</Typography>
      </div>
    );
  }

  function renderFailed() {
    return (
      <>
        <LottieControl
          height={300}
          width={300}
          loop={false}
          animation={require('../../../../assets/lottie/4970-unapproved-cross.json')}
        />
        <div className="px-5 py-3 text-center">
          <Typography variant="h3">{props.failedTitle}</Typography>
          <Typography variant="body1">{props.failedText}</Typography>
        </div>
      </>
    );
  }

  return (
    <div>
      {props.lottie.json ? (
        props.activity.type_id === 6 ? (
          props.activities.quiz.didWin ? (
            <LottieControl loop={false} animation={props.lottie.json} />
          ) : null
        ) : (
          <LottieControl loop={false} animation={props.lottie.json} />
        )
      ) : null}
      <div
        className="container-fluid"
        style={{ backgroundColor: 'transparent' }}
      >
        {props.activities ? (
          props.activity.type_id === 6 ? (
            props.activities.quiz.isModalOpen ? (
              <div className="row">
                <div className="col-12">
                  {props.activities.quiz.didWin
                    ? renderPassed()
                    : renderFailed()}
                </div>
              </div>
            ) : null
          ) : null
        ) : null}
        {props.activity.type_id !== 6 ? (
          <div className="d-flex justify-content-center">
            <Typography
              variant="body1"
              style={{ maxWidth: '35em' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activity.feedback_message),
              }}
            />
          </div>
        ) : null}

        {props.chart ? (
          <div className="d-flex justify-content-center">
            <Chart
              width={'70%'}
              style={{ position: 'relative' }}
              type="horizontalBar"
              data={data}
            />
          </div>
        ) : null}
        <div className="d-flex justify-content-center mt-4 mb-4">
          <SimpleButton
            onClick={() => handleFirstButton(props.firstActionType)}
          >
            {props.firstText}
          </SimpleButton>
          {props.secondRoute ? (
            <SimpleButton
              onClick={handleSecondButton}
            >
              {props.secondText}
            </SimpleButton>
          ) : null}
        </div>
      </div>
    </div>
  );
}
