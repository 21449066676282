import React from 'react'
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  textBlockSkeleton: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: '10px',
  },
}));

function TextBlockSkeleton(props) {
  const {
    numberOfRows,
    rowsHeight,
    animation,
    width
  } = props;
  const classes = useStyles();
  const rowsArray = Array.from(new Array(numberOfRows));

  return (
    <Grid item container xs={12}>
      {
        rowsArray.map((item, index) => (
          <Grid key={index} item container justifyContent="center" xs={12}>
            <Skeleton
              key={index}
              className={classes.textBlockSkeleton}
              width={width || '100%'}
              height={rowsHeight || 20}
              variant="text"
              animation={animation || "pulse"}
            />
          </Grid>
        ))
      }
    </Grid>
  );
}

TextBlockSkeleton.propTypes = {
  rowsHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  numberOfRows: PropTypes.number.isRequired,
  animation: PropTypes.string,
};

export default TextBlockSkeleton;
