import React from 'react';

export default function FileSVG(props) {
  return (
    <svg
      width={props.width || '16.7'}
      height={props.height || '13.3'}
      viewBox="0 0 20 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      fill={props.color || 'inherit'}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="inherit"
        fillRule="evenodd"
      >
        <g
          id="Icon-/-Folder"
          transform="translate(-2.000000, -4.000000)"
          fill="inherit"
        >
          <path
            d="M20,8.5 L9.84246355,8.5 L7.65496355,6 L4,6 L4,17.9983993 L20,17.9983993 L20,8.5 Z M20.75,6.5 C21.4403559,6.5 22,7.05964406 22,7.75 L22,18.7483993 C22,19.4387552 21.4403559,19.9983993 20.75,19.9983993 L3.25,19.9983993 C2.55964406,19.9983993 2,19.4387552 2,18.7483993 L2,5.25 C2,4.55964406 2.55964406,4 3.25,4 L7.99528972,4 C8.35574295,4 8.69865047,4.15560054 8.93601059,4.42686924 L10.75,6.5 L20.75,6.5 Z"
            id="🎨-Icon-Color"
          ></path>
        </g>
      </g>
    </svg>
  );
}
