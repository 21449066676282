import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm } from 'redux-form';
import { BASE_API, USER_TOKEN } from '../../../../../../../config/consts';

const URL_COMMENTS = `${BASE_API}/comments`;

export const getCommentsByPost = (post, setComment, loading = true) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'COMMENTS_BY_POST_LOAD',
        payload: loading,
      },
    ]);
    axios
      .get(`${URL_COMMENTS}/post/${post}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([setComment(response.data)]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const clearComments = () => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_COMMENTS',
      payload: [],
    });
  };
};

export const reset = (form) => {
  return resetForm(form);
};

export const deleteComment = (id, postId, setComment) => {
  return (dispatch) => {
    axios
      .delete(`${URL_COMMENTS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([getCommentsByPost(postId, setComment)]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const postComment = (values, setComment) => {
  return (dispatch) => {
    axios
      .post(URL_COMMENTS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([getCommentsByPost(values.post, setComment)]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};
