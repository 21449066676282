import { Typography } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import {
  InputLabelOutlined,
  InputProfilePicture,
  SelectLabel,
} from 'common/components/fields/fields';
import {
  ASTCU_STATION_ID,
  BASE_S3,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
} from 'config/consts';
import { cpfMask, dateMask, phoneMask } from 'helpers/masks';
import states from 'helpers/states.json';
import { FORM_RULES } from 'helpers/validations';
import { getStationData } from 'modules/website/websiteActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import {
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  SINDILEGIS_STATION_ID,
} from '../../../config/consts';
import { setLoading, signup, updateParticipant } from '../authActions';
import './signupParticipant.css';

states.sort(function (a, b) {
  if (a.sigla < b.sigla) {
    return -1;
  }
  if (a.sigla > b.sigla) {
    return 1;
  }
  return 0;
});

class SignupParticipant extends Component {
  constructor(props) {
    super(props);
    props.setLoading(false);
    this.state = {
      formStep: 1,
    };
  }

  componentDidMount() {
    if (!this.props.website.stationData.address) {
      this.props.getStationData(COMPANY_STATION_ID);
    }
  }

  onSubmit = (values) => {
    if (COMPANY_STATION_ID === SINDILEGIS_STATION_ID) {
      return this.props.updateParticipant(
        values,
        this.props.auth.user.id,
        this.props.history,
        true
      );
    }
    if (this.state.formStep === 2) {
      return this.props.updateParticipant(
        values,
        this.props.auth.user.id,
        this.props.history,
        true
      );
    }
    return this.nextStep();
  };
  nextStep = () => {
    this.setState({ formStep: this.state.formStep + 1 });
  };
  previousStep = () => {
    this.setState({ formStep: this.state.formStep - 1 });
  };

  render() {
    const statesList = states.map((item, index) => ({
      value: item.sigla,
      label: item.nome,
    }));
    const { formStep } = this.state;
    const { website } = COMPANY_COLORS;
    const { name } = this.props.website.stationData;

    return (
      <div
        className="d-flex justify-content-center"
        style={{
          background: `url(${BASE_S3}/${website.header.background_auth_station})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="col-sm-8 col-md-6 col-lg-4 align-self-center py-5">
          <div
            style={{
              background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              borderRadius: 4,
            }}
          >
            <div className="row justify-content-center">
              <div className="col-7 d-flex justify-content-center mt-5">
                <img
                  className
                  src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                  alt="Logo"
                  style={{ maxWidth: '100%', height: 'auto', maxHeight: 85 }}
                />
              </div>
            </div>
            <form
              className="col-12"
              onSubmit={this.props.handleSubmit((v) => this.onSubmit(v))}
              noValidate
            >
              {formStep === 0 ? (
                <>
                  <div className="d-flex row justify-content-center my-3">
                    <div className="col-9 d-flex py-3 justify-content-center">
                      <Typography
                        variant="body1"
                        style={{
                          textAlign: 'center',
                          fontSize: 32,
                          fontWeight: 'bold',
                        }}
                      >
                        Que bom que você está aqui!
                      </Typography>
                    </div>
                  </div>
                  <div className="d-flex row my-5">
                    <div className="d-flex justify-content-center px-5">
                      <Typography
                        variant="body1"
                        style={{ textAlign: 'center' }}
                      >
                        Estamos muito felizes com o que estamos criando juntos
                        com {name}.
                      </Typography>
                    </div>
                  </div>
                  <div
                    className="d-flex row justify-content-center  my-5"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="col-10">
                      <div className="d-flex justify-content-center py-5">
                        <FullButton
                          classNameProp="py-3 px-4"
                          type="submit"
                          disabled={this.props.auth.loadingRegister}
                          progress={parseInt(this.props.general.submitProgress)}
                        >
                          Vamos começar?
                        </FullButton>
                      </div>
                    </div>
                  </div>
                </>
              ) : formStep === 1 ? (
                <>
                  <div className="row justify-content-center mt-5">
                    <div className="col-10">
                      <Typography
                        variant="body1"
                        style={{
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        Vamos preencher mais alguns dados
                      </Typography>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-4">
                    <Field
                      component={InputProfilePicture}
                      name="image"
                      label="Escolha uma foto (máx: 3MB)"
                      margin="normal"
                      cols="12"
                      onDropRejected={this.props.fileRejected}
                    />
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                    <Typography
                      variant="body1"
                      style={{ margin: 0, fontSize: 12 }}
                    >
                      opcional
                    </Typography>
                  </div>
                  <div
                    className="d-flex row justify-content-center  mt-3 pt-5"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="col-10">
                      <div className="d-flex justify-content-center py-3">
                        <FullButton
                          classNameProp="py-3 px-4"
                          type="submit"
                          disabled={this.props.auth.loadingRegister}
                          progress={parseInt(this.props.general.submitProgress)}
                        >
                          {COMPANY_STATION_ID === SINDILEGIS_STATION_ID
                            ? 'Salvar e Entrar'
                            : 'Continuar'}
                        </FullButton>
                      </div>
                    </div>
                  </div>
                  <div className="container my-3">
                    <div className="row justify-content-center pb-3">
                      <div
                        className="col-10 d-flex justify-content-center align-items-center p-3"
                        style={{
                          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          component="button"
                          onClick={this.previousStep}
                          clbuttonssName="station-font-medium"
                          type="button"
                          style={{
                            // color: '#0E5FD6',
                            // fontSize: '0.875rem',
                            display: 'inline-block',
                            textDecorationLine: 'underline',
                          }}
                        >
                          Voltar
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              ) : formStep === 2 ? (
                <>
                  <div className="row justify-content-center mt-5" style={{}}>
                    <div className="col-10">
                      <Typography
                        variant="body1"
                        style={{
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        Coloque por favor seu nome completo, estado de
                        residência e data de nascimento.
                      </Typography>
                    </div>
                  </div>
                  <div className="row justify-content-center my-5" style={{}}>
                    {!this.props.auth.user.cpf ? (
                      <div className="col-10">
                        <Field
                          component={InputLabelOutlined}
                          type="tel"
                          label="CPF"
                          helperText="Digite seu CPF"
                          name="cpf"
                          validate={[FORM_RULES.required, FORM_RULES.cpf]}
                          {...cpfMask}
                        />
                      </div>
                    ) : null}

                    <div className="col-10 mt-3">
                      <Field
                        component={InputLabelOutlined}
                        label="Nome Completo"
                        name="name"
                        validate={[FORM_RULES.required]}
                        helperText="Digite seu nome completo"
                      />
                    </div>

                    <div className="col-10 mt-3">
                      <Field
                        component={InputLabelOutlined}
                        label="Telefone"
                        type="tel"
                        inputlabelprops={{
                          shrink: true,
                        }}
                        name="tel"
                        validate={[FORM_RULES.required]}
                        helperText="Digite seu Telefone"
                        {...phoneMask}
                      />
                    </div>
                    <div className="col-10 mt-3">
                      <Field
                        component={InputLabelOutlined}
                        type="tel"
                        label="Data de Nascimento"
                        name="birthday"
                        validate={[FORM_RULES.required, FORM_RULES.date]}
                        {...dateMask}
                      />
                    </div>
                    <div className="col-10 mt-3">
                      <Field
                        component={SelectLabel}
                        type="text"
                        label={`Estado de residência`}
                        name={`state`}
                        margin="normal"
                        options={statesList}
                        validate={[FORM_RULES.required]}
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex row justify-content-center  mt-2 pt-2"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="col-10">
                      <div className="d-flex justify-content-center py-3">
                        <FullButton
                          classNameProp="py-3 px-4"
                          type="submit"
                          progress={parseInt(this.props.general.submitProgress)}
                          disabled={
                            this.props.auth.loadingRegister ||
                            this.props.formValues
                              ? !this.props.formValues.name ||
                                !this.props.formValues.state ||
                                !this.props.formValues.birthday
                              : true
                          }
                        >
                          Salvar e Entrar
                        </FullButton>
                      </div>
                    </div>
                  </div>
                  <div className="container my-3">
                    <div className="row justify-content-center pb-3">
                      <div
                        className="col-10 d-flex justify-content-center align-items-center p-3"
                        style={{
                          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          component="button"
                          onClick={this.previousStep}
                          clbuttonssName="station-font-medium"
                          type="button"
                          style={{
                            // color: '#0E5FD6',
                            fontSize: '0.875rem',
                            display: 'inline-block',
                            textDecorationLine: 'underline',
                          }}
                        >
                          Voltar
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex row justify-content-center my-3">
                    <div className="col-9 d-flex py-3 justify-content-center">
                      <p
                        style={{
                          textAlign: 'center',
                          fontSize: 30,
                          fontWeight: 'bold',
                        }}
                      >
                        Você concluiu seu cadastro!
                      </p>
                    </div>
                  </div>
                  <div className="d-flex row my-5 justify-content-center">
                    <div className="d-flex justify-content-center px-4">
                      <p style={{ textAlign: 'center' }}>
                        Clique no botão abaixo para aproveitar as
                        funcionalidades da plataforma!
                      </p>
                    </div>
                  </div>
                  <div
                    className="d-flex row justify-content-center  my-5"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="col-10">
                      <div className="d-flex justify-content-center py-5">
                        <FullButton
                          classNameProp="py-3 px-4"
                          type="submit"
                          disabled={this.props.auth.loadingRegister}
                          progress={parseInt(this.props.general.submitProgress)}
                        >
                          Entrar
                        </FullButton>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

SignupParticipant = reduxForm({ form: 'signupParticipantForm' })(
  SignupParticipant
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('signupParticipantForm')(state),
    website: state.website,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      getStationData,
      setLoading,
      updateParticipant,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupParticipant);
