import { Box, Grid } from '@material-ui/core';
import React from 'react';
import TextCard from '../../../../../common/components/cards/textCard';

export default function Dental() {
  return (
    <section id="dental">
      <p className="body-title mt-4">Serviço odontológico</p>
      <p className="h4 mt-4" style={{ color: '#838383', maxWidth: 500 }}>
        Acesse os benefícios odontológicos ofertados aos filiados de todo o
        país.
      </p>

      <Grid container spacing={3} style={{ marginTop: 64 }}>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/servico-odontologico/agendamentos">
              Agendamento de consulta na Clínica Odontolegis
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/servico-odontologico/orientacoes">
              Como usar
            </TextCard>
          </Box>
        </Grid>
        {/* <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/servico-odontologico/conheca">
             Odontolegis
            </TextCard>
          </Box>
        </Grid> */}
        {/* <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/servico-odontologico/porto-seguro">
              Porto Seguro Odonto
            </TextCard>
          </Box>
        </Grid> */}
        <Grid item xs={3}>
          <Box>
            <TextCard href="/#homepage/servico-odontologico/contato">
              Equipe e contato
            </TextCard>
          </Box>
        </Grid>
        {/* <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/servico-odontologico/informacoes">
              Painel de informações
            </TextCard>
          </Box>
        </Grid> */}
        <Grid item xs={3}>
          <TextCard href="#/homepage/servico-odontologico/noticias">
            Notícias relacionadas
          </TextCard>
        </Grid>
        {/* <Grid item xs={3}>
          <TextCard href="#/homepage/servico-odontologico/eventos">
            Eventos
          </TextCard>
        </Grid> */}
      </Grid>
    </section>
  );
}
