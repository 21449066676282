import ButtonPrimary from 'common/components/buttons/ButtonPrimary';
import FeedbackContent from 'common/components/modal/content/feedbackContent';
import Answers from 'common/components/quiz/AnswersParticipant';
import Questions from 'common/components/quiz/QuestionsParticipant';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  getLottieById,
  setFeedbackQuizModal,
  submitCheckListSurvey,
} from '../activitiesActions';
import { Container } from './styles';

let PollQuizParticipant = function (props) {
  async function onSubmit(values) {
    const { auth, activities } = props;
    const newValues = {};

    newValues.user = auth.user.id;
    newValues.author = 'user';
    newValues.action = activities.detail.id;
    newValues.establishment = auth.user.establishment_participant.id;
    newValues.research = activities.detail.researches[0].id;
    newValues.answers = [];

    let marked = [];
    for (let i in values) {
      if (values[i].checked) {
        marked.push(parseInt(i.split('-')[1]));
      }
    }

    for (let option of marked) {
      newValues.answers.push({
        question: props.question.id,
        options: [option],
      });
    }

    props.submitCheckListSurvey(newValues, props.history);
  }

  return (
    <>
      {!props.activities.feedbackQuizModal ? (
        <Form onSubmit={props.handleSubmit(onSubmit)} noValidate>
          <Field
            name="question"
            component={Questions}
            question={props.question}
          />
          <Container>
            {props.options.map((item, index) => (
              <Field
                name={`option-${item.id}`}
                component={Answers}
                background={item.color ? item.color : '#2D95EF'}
                textColor={item.textColor ? item.textColor : '#FFF'}
                text={item.label ? item.label : null}
                image={item.image ? item.image : null}
                oneOnly
                checkedOptions={props.formValues ? props.formValues : null}
              />
            ))}
          </Container>
          <ButtonPrimary
            disabled={props.activities.buttonLoading}
            progress={parseInt(props.general.submitProgress)}
            type="submit"
          >
            {props.activities.buttonLoading ? 'Enviando' : 'Enviar'}
          </ButtonPrimary>
        </Form>
      ) : (
        <FeedbackContent
          visible={props.activities.feedbackQuizModal}
          onCloseRoute="/homepage"
          history={props.history}
          setFeedbackModal={props.setFeedbackQuizModal}
          firstText={'Página inicial'}
          activity={props.activities.detail}
          firstActionType="route"
          chart={true}
          chartColumns={
            props.activities.detail.researches[0].questions[0].options
          }
          firstRoute="/homepage"
          lottie={props.activities.lottieDetail}
          cleanupFunction={() => props.getUserPoints(props.auth.user)}
          passedTitle="Parabéns!"
          passedText="Você passou no quiz!"
          passedButtonText="Continuar"
          failedTitle="Que pena..."
          failedText="Infelizmente você não acertou questões o suficiente para ganhar pontos nesta atividade"
          failedButtonText="Continuar"
          {...props}
        />
      )}
    </>
  );
};

PollQuizParticipant = reduxForm({ form: 'pollQuizForm' })(PollQuizParticipant);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('pollQuizForm')(state),
    activities: state.activities,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitCheckListSurvey,
      setFeedbackQuizModal,
      getLottieById,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PollQuizParticipant);
