import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingProgress from '../../../../../common/components/progress/loading';
import { getActionComments } from '../activitiesActions';
import CreateActivityPost from './CreateActivityPost.jsx';
import Comment from './Comments/Comment.jsx';
import { CommentsLabel, SummaryDetail } from './styles';
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR, GENERAL_TEXT_COLOR } from '../../../../../config/consts';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    minWidth: '100%',
    border: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
}));

const ActivitiesFeed = (props) => {
  const { activity, getActionComments, expandsComment } = props;
  const [activityComments, setActivityComments] = useState(['']);
  const [commentsFirstLoading, setCommentsFirstLoading] = useState(true);
  const lastComment = useRef(null);
  const [scrollTrigger, setScrollTrigger] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    getActionComments(activity.detail.id);
    setActivityComments(activity.allDataComments);
    setCommentsFirstLoading(activity.getAllActionCommentsLoading);
  }, []); // eslint-disable-line

  useEffect(() => {
    setActivityComments(activity.allDataComments);
  }, [activity.allDataComments]);

  const executeScroll = () => {
    lastComment.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (scrollTrigger) {
    setTimeout(() => {
      executeScroll();
    }, 600);
    setScrollTrigger(false);
  }

  return (
    <>
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-start"
        alignItems="center"
        style={{
          marginTop: '1.7rem',
          display: isMobile ? '' : 'none',
        }}
      >
        <CommentsLabel>Comentários</CommentsLabel>
      </Grid>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <CreateActivityPost
          scrollCallback={() => setScrollTrigger(true)}
          setExpandsComment={() => props.setExpandsComment(!expandsComment)}
          expandsComment={expandsComment}
          id={props.id}
        />
        {commentsFirstLoading ? (
          <LoadingProgress style={{ padding: '3rem 0' }} />
        ) : activityComments.length === 0 ? (
          <Typography variant="h6">
            Ainda não há comentários aqui. Seja o primeiro e diga o que está
            pensando.
          </Typography>
        ) : (
          <Grid item container xs={12} alignItems="center" justifyContent="center">
            {isMobile ? (
              activityComments.map((comment) => (
                <Comment
                  ref={lastComment}
                  commentId={comment.id}
                  key={comment.id}
                />
              ))
            ) : (
              <Accordion
                onChange={() => props.setExpandsComment()}
                expanded={expandsComment}
                className={classes.accordion}
              >
                <SummaryDetail>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: GENERAL_TEXT_COLOR }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      borderBottom: `1px solid ${GENERAL_TEXT_COLOR}`,
                    }}
                  >
                    <Typography variant="body1" className={classes.heading}>
                      {expandsComment ? 'Comentários' : 'Ver comentários'}
                    </Typography>
                  </AccordionSummary>
                </SummaryDetail>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {activityComments.map((comment) => (
                      <Comment
                        commentId={comment.id}
                        key={comment.id}
                        lgScreen={true}
                      />
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        )}
      </Grid>
      <div ref={lastComment}></div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionComments,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesFeed);
