import axios from 'axios';
import { COMPANY_STATION_ID, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import BlockUserModal from '../common/components/modal/blockUserModal';
import { BASE_S3, COMPANY_COLORS } from '../config/consts';
import User from '../modules/user/main/user';
import ActivitiesRoutes from '../modules/user/pages/activities/activitiesRoutes';
import ContactUsRoutes from '../modules/user/pages/contactUs/contactUsRoutes';
import accountingRoutes from '../modules/user/pages/homepage/accounting/accountingRoutes';
import dentalRoutes from '../modules/user/pages/homepage/dental/dentalRoutes';
import downloadsRoutes from '../modules/user/pages/homepage/downloads/downloadsRoutes';
import ExtractRoutes from '../modules/user/pages/homepage/extracts/extractRoutes';
import FeedRoutes from '../modules/user/pages/homepage/feed/FeedRoutes';
import Homepage from '../modules/user/pages/homepage/homepage';
import HomepageRoutes from '../modules/user/pages/homepage/homepageRoutes';
import legalRoutes from '../modules/user/pages/homepage/legal/legalRoutes';
import newsRoutes from '../modules/user/pages/homepage/news/newsRoutes';
import plansRoutes from '../modules/user/pages/homepage/plans/plansRoutes';
import politicalRoutes from '../modules/user/pages/homepage/political/politicalRoutes';
import Profile from '../modules/user/pages/homepage/profile';
import schedulesRoutes from '../modules/user/pages/homepage/schedules/schedulesRoutes';
import trophiesRoutes from '../modules/user/pages/homepage/trophies/trophiesRoutes';
import legisClubFuelsRoutes from '../modules/user/pages/legisClubFuels/legisClubFuelsRoutes';
import search from '../modules/user/pages/search/search';
import ShoppingRoutes from '../modules/user/pages/shopping/shoppingRoutes';
import userShoppingCartRoutes from '../modules/user/pages/userShoppingCart/userShoppingCartRoutes';
import Website from '../modules/website/website';
import { NotAuthorized } from '../pages/401/401';
// import App from './app';
import { validateToken } from '../pages/auth/authActions';
import Home from '../pages/home/home';
import virtualCardRoutes from '../modules/user/pages/homepage/virtualCard/virtualCardRoutes';

const id =
  process.env.REACT_APP_API_KEY === 'test'
    ? 2
    : process.env.REACT_APP_API_KEY === 'production'
    ? 26
    : 2;

const children = (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/homepage">
      <Switch>
        <Route exact path="/homepage" component={Homepage} />
        <Route path="/homepage/perfil" component={Profile} />
        <Route path="/homepage/search">
          <Switch>
            <Route exact component={Home} path="/homepage/search" />
            <Route path="/homepage/search/:name" component={search} />
          </Switch>
        </Route>

        {ContactUsRoutes}
        {ShoppingRoutes}
        {ActivitiesRoutes}
        {FeedRoutes}
        {ActivitiesRoutes}
        {downloadsRoutes}
        {ExtractRoutes}
        {newsRoutes}
        {virtualCardRoutes}
        {schedulesRoutes}
        {trophiesRoutes}
        {userShoppingCartRoutes}
        {legalRoutes}
        {dentalRoutes}
        {accountingRoutes}
        {plansRoutes}
        {politicalRoutes}
        {HomepageRoutes}
        {COMPANY_STATION_ID === id ? legisClubFuelsRoutes : null}
      </Switch>
    </Route>
  </Switch>
);

class AuthOrApp extends Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['Accept'] = 'application/json';
  }

  componentDidMount() {
    const { user } = this.props.auth;
    if (user) {
      this.props.validateToken(
        JSON.parse(localStorage.getItem(USER_TOKEN)),
        user.id
      );
    }
  }

  renderContent = () => {
    const { user, validToken } = this.props.auth;

    let prefix = this.props.location.pathname.split('/');

    if (!user && !validToken) {
      return <Website history={this.props.history} />;
    } else if (_.isNull(user.establishment_user)) {
      return <NotAuthorized />;
    }
    const { deletedAt } = this.props.auth.user;

    if (prefix.length > 1) {
      prefix = prefix[1];
    } else {
      prefix = prefix[0];
    }
    if (prefix === '') {
      return children;
    } else if (prefix === 'homepage') {
      if (deletedAt) {
        return <BlockUserModal showBlockModal={deletedAt} {...this.props} />;
      } /* else if (isMobile) {
        return <DownloadApp showBlockModal={deletedAt} {...this.props} />;
      }  */ else {
        return <User {...this.props}>{children}</User>;
      }
    }
  };
  // isAuthenticated() {}

  render() {
    const { website } = COMPANY_COLORS;
    return (
      <>
        <Helmet>
          <meta
            property="og:image"
            content={`${BASE_S3}/${website.header.background_auth_station}`}
          />
        </Helmet>
        {this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ validateToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp);
