/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DefaultCard } from 'common/components/cards/DefaultCard';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomizedInputBase } from '../../../../../common/components/fields/fields';
import CarouselSkeleton from '../../../../../common/components/skeleton/carouselSkeleton';
import { Trophy } from '../../../../../common/components/trophy/trophy';
import { BASE_S3 } from '../../../../../config/consts';
import { getList, setLoading } from './trophiesActions';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '0rem',
    border: 'transparent',
  },
  trophyData: {},
}));
export function Trophies(props) {
  const classes = useStyles();
  const { trophies } = props;
  const [trophyInput, setTrophyInput] = useState('');

  useEffect(() => {
    props.getList();
  }, []);

  const splitData = (createdAt) => {
    const dataArray = createdAt.substring(0, 10).split('-').reverse();
    return `${dataArray[0]}/${dataArray[1]}/${dataArray[2]}`;
  };

  // filtra pelo nome, por enquanto
  const filter = () => {
    const lowerCaseTrophyInput = trophyInput.toLowerCase();
    const filteredTrophies = trophies.list.content.filter((element) =>
      element.description.toLowerCase().includes(lowerCaseTrophyInput)
    );
    return filteredTrophies;
  };

  if (trophies.loading) {
    return (
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        xs={12}
        spacing={2}
        style={{
          minHeight: '100vh',
        }}
      >
        {
          Array.from(new Array(6)).map((el) =>
            <Grid item container alignItems="center" justifyContent="center" xs={12}>
              <CarouselSkeleton
                elementsHeight={150}
              />
            </Grid>
          )
        }
      </Grid>
    );
  }

  if (_.isEmpty(trophies.list.content)) {
    return (
      <section id="trophies-list" style={{ minHeight: '100vh' }}>
                  <div
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: 'transparent',
            height: '248px',
          }}
        >
        <Typography variant="h5">Meus troféus</Typography>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
          
            backgroundColor: 'transparent',
          }}
        >
          <Typography variant="h6">Você ainda não possui troféus</Typography>
        </div>
      </section>
    );
  }

  return (
    <>
      <Grid
        container
        xs={12}
        direction="column"
        style={{
          marginTop: '1.5rem',
          padding: '.4rem',
        }}
      >
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{
            marginBottom: '2rem',
          }}
        >
          <Grid item xs={12} md={9}>
            <Typography variant="h5">
              Meus troféus
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} justifyContent="center">
            <Box component="div" style={{ maxWidth: '300px' }}>
              {/* <SearchInput
                  value={trophyInput}
                  onChange={(search) => setTrophyInput(search)}
                /> */}

              <CustomizedInputBase
                value={trophyInput}
                onChange={(e) => setTrophyInput(e.target.value)}
                placeholder="Pesquisar troféu"
                name="search"
                margin="normal"
              // onkeyUp={() => filter()}
              // onKeyDown={(e) => handleChange(e)}
              // onClick={(e) => handleChange(e)}
              // meta={{}}
              // required
              />
            </Box>
            {/* <span>icon</span> */}
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12} mt={5}>
          {trophyInput.length === 0
            ? trophies.list.content.map((item, index) => (
              <Grid item xs={12} md={3} justifyContent="space-evenly">
                {/* <Paper
                    component="div"
                    elevation="2"
                    variant="outlined"
                    style={{
                      borderColor: 'transparent',
                    }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={5} md={4}>
                        <Tooltip title={item.description} arrow>
                          <Trophy
                            style={{
                              height: 100,
                              width: 100,
                              borderRadius: '6px',
                            }}
                            src={`${BASE_S3}/${item.trophy_image}`}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid
                        Item
                        container
                        xs={7}
                        md={6}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Typography variant="h5" className={classes.trophyData}>
                          {item.description}
                        </Typography>
                        <Typography
                          variant="h7"
                          gutterBottom
                          className={classes.trophyData}
                        >
                          Recebido em {splitData(item.createdAt)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper> */}
                <DefaultCard
                  imgSource={`${BASE_S3}/${item.trophy_image}`}
                  actionName={item.description}
                  description={`Recebido em ${splitData(item.createdAt)}`}
                />
              </Grid>
            ))
            : filter().map((item, index) => (
              <Grid item xs={12} md={4} justifyContent="space-evenly">
                <Paper
                  component="div"
                  elevation="2"
                  variant="outlined"
                  style={{
                    borderColor: 'transparent',
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={5} md={4}>
                      <Tooltip title={item.description} arrow>
                        <Trophy
                          style={{
                            height: 100,
                            width: 100,
                            borderRadius: '6px',
                          }}
                          src={`${BASE_S3}/${item.trophy_image}`}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid
                      Item
                      container
                      xs={7}
                      md={6}
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Typography variant="h5" className={classes.trophyData}>
                        {item.description}
                      </Typography>
                      <Typography
                        variant="h7"
                        gutterBottom
                        className={classes.trophyData}
                      >
                        Recebido em {splitData(item.createdAt)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
}

Trophies.propTypes = {
  auth: PropTypes.object.isRequired,
  trophies: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  trophies: state.trophies,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Trophies);
