import { differenceInYears, parse } from 'date-fns';
import _ from 'lodash';
import { validate } from './cpf';
var valid = require('card-validator');

export const FORM_RULES = {
  required: (value) => {
    return !_.isEmpty(value) || _.isNumber(value)
      ? undefined
      : 'Esse campo é obrigatório';
  },
  age18: (value) => {
    if (value) {
      if (
        differenceInYears(new Date(), parse(value, 'dd/MM/yyyy', new Date())) <
        18
      ) {
        return 'É necessário ser maior de 18 anos.';
      }
      return undefined;
    }
    return undefined;
  },
  max: (max) => (value) =>
    value && value.length > max
      ? `Esse campo deve possuir no máximo ${max} caracteres`
      : undefined,
  min: (min) => (value) =>
    value && value.length < min
      ? `Esse campo deve possuir no minimo ${min} caracteres`
      : undefined,
  number: (value) =>
    value && isNaN(Number(value)) ? 'Este campo só aceita números' : undefined,
  minValue: (min) => (value) =>
    value && value < min ? `O valor deve ser maior que ${min}` : undefined,
  maxValue: (max) => (value) =>
    value && value < max ? `O valor deve ser menor que ${max}` : undefined,
  password: (value) =>
    value && !/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,20})/i.test(value)
      ? 'Senha precisa ter: uma letra maiúscula, uma letra minúscula, um número e tamanho entre 8 - 20.'
      : undefined,
  email: (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Email inválido'
      : undefined,
  tooOld: (value) => (value) =>
    value && value > 65 ? 'You might be too old for this' : undefined,
  alphaNumeric: (value) =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
      ? 'Somente caracteres alfanuméricos'
      : undefined,
  lowerCase: (value) =>
    value && /[^a-z]/i.test(value)
      ? 'Apenas letras minúsculas sem acento'
      : undefined,
  subdomain: (value) =>
    value && /[^a-zA-Z {3}*]/i.test(value)
      ? 'Apenas letras sem acento'
      : undefined,
  requiredCheckbox: (value) => {
    return value ? undefined : 'Esse campo é obrigatório';
  },
  cpf: (value) => {
    if (!validate(value)) {
      return 'CPF inválido';
    } else return undefined;
  },
  cep: (value) => {
    if (value.length < 8) {
      return 'CEP inválido';
    } else return undefined;
  },
  card_number: (value) => {
    var numberValidation = valid.number(value);
    if (!numberValidation.isValid) {
      return 'Número inválido';
    } else return undefined;
  },
  cvc: (value) => {
    var numberValidation = valid.cvv(value);
    if (!numberValidation.isValid) {
      return 'CVV inválido';
    } else return undefined;
  },
  expiry: (value) => {
    var numberValidation = valid.expirationDate(value);
    if (!numberValidation.isValid) {
      return 'Data inválida';
    } else return undefined;
  },
  maxValueTransfer: (value) => {
    const vFloat = parseFloat(value);
    if (vFloat > 100) {
      return 'Valor máximo permitido por tranferencia de R$ 100,00.';
    } else if (vFloat < 1.5) {
      return 'Valor mínimo requerido de R$ 1,50';
    } else return undefined;
  },
  maxValueDelivery: (value) => {
    const vFloat = parseFloat(value);
    if (vFloat > 500) {
      return 'Valor máximo permitido por tranferencia de R$ 500,00.';
    } else if (vFloat < 1.5) {
      return 'Valor mínimo requerido de R$ 1,50';
    } else return undefined;
  },
  maxValueDonation: (value) => {
    const vFloat = parseFloat(value);
    if (vFloat > 10000) {
      return 'Valor máximo permitido de R$ 10.000,00.';
    } else if (vFloat < 0.5) {
      return 'Valor mínimo requerido de R$ 0,50';
    } else return undefined;
  },
  date: (mydata) => {
    let day = parseInt(mydata.substring(0, 2));
    let month = parseInt(mydata.substring(3, 5));
    let year = parseInt(mydata.substring(6, 10));
    //verifica o dia valido para cada mes
    if (
      day < 1 ||
      ((day < 1 || day > 30) &&
        (month === 4 || month === 6 || month === 9 || month === 11)) ||
      day > 31
    ) {
      return 'Data Inválida!';
    }
    //verifica se o mes eh valido
    if (month < 1 || month > 12) {
      return 'Data Inválida!';
    }
    //verifica se o ano eh bissexto
    if (
      month === 2 &&
      (day < 1 || day > 29 || (day > 28 && parseInt(year / 4) !== year / 4))
    ) {
      return 'Data Inválida!';
    }
    if (year.toString().length < 4) {
      return 'Data Inválida!';
    }
    return undefined;
  },
};

export const MESSAGES = {
  required: 'Este campo é obrigatório',
  whitespace: 'Este campo não pode ser vazio',
  mail: 'Digite um email válido',
  number: 'Este campo deve conter apenas números',
  url: 'Digite uma url válida',
};

export const FORMRULES = {
  required: {
    required: true,
    message: MESSAGES.required,
  },
  whitespace: {
    whitespace: true,
    message: MESSAGES.whitespace,
  },
  number: {
    pattern: /\d+/,
    message: MESSAGES.number,
  },
  mail: {
    pattern:
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: MESSAGES.mail,
  },
  url: {
    pattern:
      /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/,
    message: MESSAGES.url,
  },
};
