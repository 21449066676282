import React, { useEffect, useState } from 'react'
import { LikeButton } from '../styles'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { sendCommentDislike, sendCommentLike } from '../../activitiesActions';
import { GENERAL_TEXT_COLOR } from '../../../../../../config/consts';
import { Typography } from '@material-ui/core';

const CommentLike = (props) => {
  const {
    activity,
    auth,
    commentData,
    sendCommentDislike,
    sendCommentLike,
  } = props;
  const [didLike, setDidLike] = useState(false);
  const likeHeart = () => <FavoriteBorderIcon style={{color: GENERAL_TEXT_COLOR}} />;
  const likeFilledHeart = () => <FavoriteIcon style={{ color: "red" }} />;
  const [localLikeCount, setLocalLikeCount] = useState(0);


  useEffect(() => {
    function verifiesDidLike() {
      if (commentData.likes) {
        !!commentData.likes.find(el => el.user_id === auth.user.id) && (
          setDidLike(!!commentData.likes.find(el => el.user_id === auth.user.id))
        )
      }
    };
    commentData.likes && setLocalLikeCount(commentData.likes.length);
    verifiesDidLike();
  }, [commentData.likes, auth.user])

  function handleLikeCommentClick() {
    const likeData = {
      user: auth.user.id,
      comment_id: commentData.id,
    };
    const dislikeData = {
      action: null,
      user: auth.user.id,
      comment_id: commentData.id,
    };
    if (!didLike) {
      sendCommentLike(likeData, activity.detail.id);
      setDidLike(!didLike);
      setLocalLikeCount(localLikeCount + 1);
    } else {
      sendCommentDislike(dislikeData, activity.detail.id)
      setDidLike(!didLike);
      setLocalLikeCount(localLikeCount - 1);
    }
  }

  return (
    <div style={{ marginRight: '.5rem' }}>
      <LikeButton onClick={() => handleLikeCommentClick()}>
        {
          didLike
            ? (
              likeFilledHeart()
            ) : (
              likeHeart()
            )
        }
        <Typography variant="body1" component="span" style={{color: GENERAL_TEXT_COLOR}}>
          {localLikeCount}
        </Typography>
      </LikeButton>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendCommentLike,
      sendCommentDislike,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentLike);
