const INITIAL_STATE = {
  isLoading: 'idle',
  detail: {},
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @return {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USER_CARD_LOAD':
      return { ...state, isLoading: 'pending' };
    case 'USER_CARD_DONE':
      return { ...state, isLoading: 'resolved', detail: action.payload };
      default:
      return state;
  }
};
