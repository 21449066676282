import React from 'react';

// import { Container } from './styles';

function PoliticalCoffeeVideos() {
  return (
    <div>
      <p className="body-title mt-4 mb-3">Café com Política #Lives</p>
      <iframe
        width="800"
        height="450"
        src="https://www.youtube.com/embed/videoseries?list=PLAQjx6GeNW4iM6FhbeNgoSkeQm5aMpJLS"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default PoliticalCoffeeVideos;
