import styled from 'styled-components';

export const Container = styled.div`
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 13.3043px;
    line-height: 16px;
    /* identical to box height */

    text-align: center;

    color: #000000;
  }

  span {
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 13.3043px;
    line-height: 16px;
    /* identical to box height */

    color: #757575;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 25px;
`;

export const CardContainer = styled.div`
  margin-right: 25px;
  margin-top: 25px;
  width: 328px;
  background: #fff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  img {
    width: 328px;
    height: 185px;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    padding-top: 25px;
    padding-left: 25px;
    margin: 0;
  }

  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.5;
  }
`;

export const DatesWrapper = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 13.3043px;
    line-height: 16px;
    color: #000000;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13.3043px;
    line-height: 16px;
    color: #000000;
  }
`;
