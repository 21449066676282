import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  default as DialogTitle,
  default as MuiDialogTitle,
} from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide(props) {
  const { onCloseButton } = props;
  return (
    <div>
      <Dialog
        open={props.visible}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            minHeight: props.height || null,
            minWidth: props.maxWidth === 'xl' ? '100%' : null,
            backgroundColor: props.backgroundColor || '#fff',
          },
        }}
        onClose={props.onClose}
        style={{
          padding: '0px important!',
        }}
        fullWidth={true}
        maxWidth={props.maxWidth || 'md'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {onCloseButton ? (
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={props.onClose}
            style={{
              backgroundColor: 'white',
              justifyContent: 'flex-end',
              border: '0px solid',
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {props.title ? (
          <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        ) : null}
        {props.maxWidth === 'xl' ? (
          <MuiDialogTitle disableTypography>
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
        ) : null}
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}

export default AlertDialogSlide;
