import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../../pages/auth/authActions';

function BlockUserModal(props) {
  const handleClose = () => {
    props.logout();
    props.history.push('/suporte');
  };

  return (
    <div>
      <Dialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={props.showBlockModal}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: window.innerWidth * 0.38,
            height: window.innerHeight * 0.5,
            padding: 25,
            borderRadius: 0,
          }}
        >
          <ReportProblemOutlinedIcon
            style={{ fontSize: 40, marginBottom: 20, color: '#FDCD31' }}
          />
          <div style={{ paddingLeft: 60, paddingRight: 60 }}>
            <Typography gutterBottom align="center">
              O seu acesso está supenso. Caso tenha dúvidas entre em contato
              pelo
              <br />
              <button
                clbuttonssName="station-font-medium"
                type="button"
                style={{
                  color: '#0E5FD6',
                  display: 'inline-block',
                }}
                onClick={() => handleClose()}
              >
                fale conosco.
              </button>
            </Typography>
          </div>
        </div>
        {/* </DialogContent> */}
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout }, dispatch);
};
const mapStateToProps = null;
export default connect(mapStateToProps, mapDispatchToProps)(BlockUserModal);
