import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import Button from 'common/components/buttons/button';
import {
  InputLabelOutlined,
  MaterialCheckbox,
} from 'common/components/fields/fields';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import { dateReducedMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, reduxForm } from 'redux-form';
import { appColors } from 'styles/color';
import { purchasePlan } from './plansActions';

const {
  v2: { black },
} = appColors;

let PlansEditPaymentForm = function ({
  plans,
  history,
  auth,
  handleSubmit,
  general,
  purchasePlan,
}) {
  const {
    user: {
      subscription: { card },
    },
  } = auth;

  function onSubmit(values) {
    function cleanup() {
      history.push('/owner/profile');
    }

    purchasePlan(values, 'put', cleanup, null);
  }

  return (
    <section id="plans-form" style={{ marginTop: 64 }}>
      <div className="card">
        <div className="card-body" style={{ padding: '32px 64px' }}>
          <Form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box display="flex" alignItems="center" mb={5}>
              <p className="h4-bold" style={{ marginRight: 24, color: black }}>
                Editando informações de pagamento{' '}
                <EditIcon style={{ marginLeft: 16 }} />
              </p>
            </Box>
            <Box mb={5}>
              <p className="h4" style={{ textTransform: 'capitalize' }}>
                {card.brand} ****** ****
                {card.last_digits}
              </p>
              <p className="h6">
                Expira em {card.expiration_date[0]}
                {card.expiration_date[1]}/{card.expiration_date[2]}
                {card.expiration_date[3]}
              </p>
            </Box>

            <MaterialGrid container spacing={3} style={{ marginBottom: 32 }}>
              <MaterialGrid item xs>
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={MaterialCheckbox}
                      label="Deseja salvar os dados desse cartão para compras futuras?"
                      name={`save`}
                    />
                  </MaterialGrid>
                  <MaterialGrid item md={12} lg={6}>
                    <Box>
                      <p
                        className="h4-bold"
                        style={{ marginBottom: 24, color: black }}
                      >
                        Dados do cartão
                      </p>
                    </Box>
                    <Box style={{ marginTop: 12 }}>
                      <Field
                        component={InputLabelOutlined}
                        label="Número do cartão"
                        type="tel"
                        name={`card_number`}
                        normalize={handleWhiteSpaces}
                        inputProps={{
                          maxLength: 16,
                        }}
                        validate={[FORM_RULES.required, FORM_RULES.card_number]}
                      />
                    </Box>
                    <Box style={{ marginTop: 12 }}>
                      <Field
                        component={InputLabelOutlined}
                        label="Nome escrito no cartão"
                        type="text"
                        name={`card_holder_name`}
                        validate={[FORM_RULES.required]}
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      style={{ marginTop: 12 }}
                    >
                      <Box style={{ marginRight: 6, flex: 1 }}>
                        <Field
                          component={InputLabelOutlined}
                          label="Expiração"
                          type="tel"
                          name={`card_expiration_date`} /*
                    inputProps={{
                      maxLength: 4,
                    }} */
                          validate={[
                            FORM_RULES.required,
                            FORM_RULES.expiry,
                            FORM_RULES.max(4),
                          ]}
                          {...dateReducedMask()}
                        />
                      </Box>
                      <Box style={{ marginLeft: 6, flex: 1 }}>
                        <Field
                          component={InputLabelOutlined}
                          label="CVC"
                          type="tel"
                          name={`card_cvv`}
                          inputProps={{
                            maxLength: 3,
                          }}
                          validate={[
                            FORM_RULES.required,
                            FORM_RULES.cvc,
                            FORM_RULES.max(3),
                          ]}
                        />
                      </Box>
                    </Box>
                  </MaterialGrid>
                </MaterialGrid>
              </MaterialGrid>
            </MaterialGrid>

            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                onClick={() => history.push('/homepage/planos')}
                label="Cancelar"
                type="secondary"
                buttonStyle={{ marginRight: 16 }}
                disabled={plans.buttonLoading}
                buttonType="button"
              />
              <Button
                label="Salvar"
                disabled={plans.buttonLoading}
                progress={parseInt(general.submitProgress)}
                buttonType={'submit'}
              />
            </Box>
          </Form>
        </div>
      </div>
    </section>
  );
};

PlansEditPaymentForm = reduxForm({ form: 'plansEditPaymentForm' })(
  PlansEditPaymentForm
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plans: state.plans,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      purchasePlan,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansEditPaymentForm);
