import { Grid } from '@material-ui/core';
import React from 'react';

export default function ActionExpired() {
  return (
    <>
    <Grid item container justifyContent="center" alignItems="center" xs={12}>
      <h2 className="text-center">Erro!</h2>
      <p>Esta atividade não está disponível.</p>
    </Grid>
    {/*
      <div className="d-flex h-75 justify-content-center align-items-center">
        <div className="col-auto">
        </div>
      </div>
    */}
    </>
  );
}
