import MaterialTable from 'material-table';
import React from 'react';
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR, GENERAL_TEXT_COLOR } from '../../../config/consts';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function Table6(props) {
  const { title, data, columns, add, remove, edit } = props;
  const [newColumns2] = React.useState(setInitialNewColumns(props));
  const [columnsWithStyle] = React.useState(setInitialColumnsStyle(props));

  function setInitialNewColumns(props) {
    const index = props.columns.findIndex((item) => item.field === 'base64');
    const itemFound = props.columns.find((item) => item.field === 'base64');
    if (index >= 0) {
      let columnsWithImg = [...columns];

      columnsWithImg[index] = {
        ...itemFound,
        render: (rowData) => (
          <img
            src={rowData.base64}
            style={{ width: 50, borderRadius: '50%' }}
            alt="Imagem da Tabela"
          />
        ),
      };
      return columnsWithImg;
    } else {
      return props.columns;
    }
  }

  function setInitialColumnsStyle(props) {
    return newColumns2.map((item) => ({
      ...item,
      headerStyle: {
        fontWeight: 500,
        fontSize: 14,
        color: 'white',
        backgroundColor: '#ababab',
      },
    }));
  }

  return (
    <MaterialTable
      icons={tableIcons}
      components={{
        Toolbar: (props) => (
          <div style={{ backgroundColor: '#ababab', color: 'white' }}></div>
        ),
      }}
      style={{ boxShadow: 'none', border: 'none', borderRadius: 0 }}
      //Necessário para realizar tradução do placeholder. Retirar deleteTooltip bugará.
      localization={{
        grouping: {
          groupedBy: 'Agrupar por:',
          placeholder:
            'Clique e arraste até aqui os títulos das colunas para agrupar',
        },
        toolbar: {
          searchPlaceholder: 'Procurar',
        },
        body: {
          editRow: {
            saveTooltip: 'Salvar',
            cancelTooltip: 'Cancelar',
            deleteText:
              props.deleteText || 'Tem certeza que deseja apagar esta linha?',
          },
          addTooltip: 'Adicionar',
          deleteTooltip: 'Deletar',
          editTooltip: 'Editar',
          emptyDataSourceMessage: 'Não há dados para visualização',
        },
        header: { actions: 'Atividades' },
        pagination: {
          labelRowsPerPage: 'Linhas por página',
          labelRowsSelect: 'linhas',
        },
      }}
      title={title}
      columns={[...columnsWithStyle]}
      onRowClick={props.onRowClick}
      data={data}
      options={{
        rowStyle: {
          height: '64px',
        },
        filtering: true,
        actionsColumnIndex: -1,
        searchFieldStyle: {
          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
          color: GENERAL_TEXT_COLOR
        },
      }}
      editable={{
        ...(add
          ? {
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  {
                    const data = this.state.data;
                    data.push(newData);
                    this.setState({ data }, () => resolve());
                  }
                  resolve();
                }, 1000);
              }),
          }
          : {}),
        ...(edit
          ? {
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  {
                    const data = this.state.data;
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    this.setState({ data }, () => resolve());
                  }
                  resolve();
                }, 1000);
              }),
          }
          : {}),
        ...(remove
          ? {
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                props.handleDelete(oldData.id, resolve, reject);
              }),
          }
          : {}),
      }}
    />
  );
}
