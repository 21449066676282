import styled from 'styled-components';
import { hexToRgb } from '../../../../helpers/utils';

export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.bgColor ? props.bgColor : '#fff' };
  /* box-shadow: ${(props) => props.boxShadowColor ? `5px 5px 20px ${props.boxShadowColor}` : '5px 5px 20px lightgrey'}; */
  box-shadow: ${(props) => props.boxShadowColor ? `0 1px 30px 0 ${hexToRgb(props.boxShadowColor, '20%')}` : '0 1px 30px 0 lightgrey'};
  padding: 24px;
  margin: 0 16px 16px 16px;
  min-height: 240px;
  min-width: 380;
  max-width: ${props => (props.mgm ? '500px' : '380px')};
  border-radius: 6px;
`;

export const ContainerShare = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.bgColor ? props.bgColor : '#fff' };
  /* box-shadow: ${(props) => props.boxShadowColor ? `5px 5px 20px ${props.boxShadowColor}` : '5px 5px 20px lightgrey'}; */
  box-shadow: ${(props) => props.boxShadowColor ? `0 1px 30px 0 ${hexToRgb(props.boxShadowColor, '20%')}` : '0 1px 30px 0 lightgrey'};
  padding: 24px;
  margin: 0 16px 16px 16px;
  min-height: 240px;
  min-width: 380px;
  max-width: 600px;
  border-radius: 6px;
`;


// /* boxShadow: `0 1px 30px 0 ${hexToRgb(GENERAL_TEXT_COLOR, '20%')}`, */
