import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
// import { GENERAL_TEXT_COLOR } from '../../../../../config/consts';
import { customCurrencyChanger } from '../../../../../helpers/utils';
import { Container, ContentContainer, ExtraInfo, Divider } from './styles';

function ShoppingAbstract({ item, productsBought, stationData }) {
  const currencyData = {
    currency_multiplier: stationData.currency_multiplier,
    currency_name: stationData.currency_name,
    currency_name_position: stationData.currency_name_position,
  };

  function ToCurrency(value) {
    return parseFloat(value).toFixed(2).replace('.', ',');
  }
  const cashback = useMemo(() => {
    if (item.action.points && item.action.additional_cashback) {
      return (
        parseFloat(item.action.points) +
        parseFloat(item.action.additional_cashback)
      )
        .toFixed(2)
        .replace('.', ',');
    } else if (item.action.points && !item.action.additional_cashback) {
      return parseFloat(item.action.points).toFixed(2).replace('.', ',');
    } else if (!item.action.points && item.action.additional_cashback) {
      return parseFloat(item.action.additional_cashback)
        .toFixed(2)
        .replace('.', ',');
    } else {
      return 0;
    }
  }, [item.action.additional_cashback, item.action.points]);

  const shoppingInfo = useMemo(() => {
    return {
      subtotal: ToCurrency(item.product_items_sum_value),
      delivery: ToCurrency(item.delivery_tax_value),
      discount: ToCurrency(item.additional_discount_value),
      wallet: ToCurrency(item.wallet_value),
      total:
        parseFloat(item.credit_card_value) > 0
          ? ToCurrency(item.credit_card_value)
          : ToCurrency(item.final_value),
    };
  }, [
    item.additional_discount_value,
    item.credit_card_value,
    item.delivery_tax_value,
    item.final_value,
    item.product_items_sum_value,
    item.wallet_value,
  ]);

  return (
    <Container>
      <ContentContainer style={{ paddingRight: 0 }}>
        <section>
          <Typography variant="body1">Soma dos itens</Typography>
          <div>
            <Typography variant="body1">R$ {shoppingInfo.subtotal}</Typography>
          </div>
        </section>
        <section>
          <Typography variant="body1">Desconto</Typography>
          <div>
            <Typography variant="body1">R$ {shoppingInfo.discount}</Typography>
          </div>
        </section>
        {parseFloat(item.wallet_value) > 0 && (
          <section>
            <Typography variant="body1">Complemento de carteira</Typography>
            <Typography variant="body1" style={{ textAlign: 'right' }}>
              {shoppingInfo.wallet !== 0
                ? customCurrencyChanger(
                  currencyData,
                  parseFloat(shoppingInfo.wallet.replace(',', '.'))
                )
                : shoppingInfo.wallet}
            </Typography>
          </section>
        )}
        <ExtraInfo>
          <section>
            <Typography variant="body1">Entrega / retirada</Typography>
            <div>
              <Typography variant="body1">
                R$ {shoppingInfo.delivery}
              </Typography>
            </div>
          </section>
          <Typography variant="body1" component="span">
            {item.delivery_location && <p>{item.delivery_location.name}</p>}
          </Typography>
        </ExtraInfo>
        <section style={{ fontWeight: 550, marginTop: 25 }}>
          <Typography variant="body1">Pagamento</Typography>
          <div style={{ fontSize: 20 }}>
            <Typography variant="body1" style={{ fontSize: 20 }}>
              R$ {shoppingInfo.total}
            </Typography>
          </div>
        </section>
        <Divider />
        <section>
          <Typography variant="body1">Recebeu de volta</Typography>
          <div>
            <Typography variant="body1">
              {cashback !== 0
                ? customCurrencyChanger(
                  currencyData,
                  parseFloat(cashback.replace(',', '.'))
                )
                : cashback}
            </Typography>
          </div>
        </section>
      </ContentContainer>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    stationData: state.website.stationData,
  };
};

export default connect(mapStateToProps, null)(ShoppingAbstract);
