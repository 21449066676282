import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { String2Currency } from 'helpers/dataTransformers';
import { COMPANY_COLORS } from 'config/consts';

export default function GroceryList({
  input: { onChange, value },
  items,
  action,
  locationPrice,
}) {
  const [groceryList, setGroceryList] = useState({});
  const [itemsPrice, setItemsPrice] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const list = {};
    const prices = {};

    for (let i of items) {
      list[`${i.name}`] = 0;
      prices[`${i.name}`] = i.value;
    }
    setItemsPrice(prices);
    setGroceryList(list);
  }, [items]);

  const listOfProducts = Object.keys(groceryList);

  function addCart(item) {
    const newValue = groceryList[`${item}`] + 1;
    if (newValue <= (items.filter((i) => i.name === item)[0].limit || 10)) {
      const cash = total + itemsPrice[`${item}`];
      setTotal(cash);
      setGroceryList({ ...groceryList, [item]: newValue });
      onChange({
        ...value,
        [item]: newValue,
      });
    }
  }

  function removeCart(item) {
    const newValue = groceryList[`${item}`] - 1;
    if (newValue >= 0) {
      const cash = total - itemsPrice[`${item}`];
      setTotal(cash);
      setGroceryList({ ...groceryList, [item]: newValue });
      onChange({
        ...value,
        [item]: newValue,
      });
    }
  }

  return (
    <>
      <Typography>Lista de compras</Typography>
      <Box
        style={{
          border: '1px solid rgba(0, 0, 0, 0.5)',
          borderRadius: 4,
          padding: 14,
          minWidth: '100%',
        }}
      >
        <table style={{ width: '100%', marginBottom: 10 }}>
          <tr
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <th
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                textAlign: 'center',
              }}
            >
              Produto
            </th>
            <th
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                textAlign: 'center',
              }}
            >
              Preço estimado
            </th>
            <th
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                textAlign: 'center',
              }}
            >
              Quantidade
            </th>
          </tr>

          {listOfProducts.map((item) => (
            <tr
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 5,
              }}
            >
              <td
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                <Typography>{item.replace(/_/g, ' ')}</Typography>
              </td>
              <td
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                <Typography>
                  {String2Currency(itemsPrice[`${item}`])}
                </Typography>
              </td>
              <td
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <RemoveCircleIcon
                    onClick={() => removeCart(item)}
                    style={{
                      marginRight: 10,
                      fontSize: 24,
                      color: COMPANY_COLORS.primary,
                      cursor: 'pointer',
                    }}
                  />
                  <span>{groceryList[`${item}`]}</span>
                  <AddCircleIcon
                    onClick={() => addCart(item)}
                    style={{
                      marginLeft: 10,
                      fontSize: 24,
                      color: COMPANY_COLORS.primary,
                      cursor: 'pointer',
                    }}
                  />
                </Box>
              </td>
            </tr>
          ))}
        </table>
        {locationPrice && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <strong style={{ marginRight: 10 }}>
              Frete: {`R$ ${locationPrice}`}
            </strong>
          </Box>
        )}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <strong style={{ marginRight: 10 }}>
            Estimativa total da compra:
          </strong>
          <Typography>
            {locationPrice
              ? String2Currency(
                  action
                    ? !(
                        action.discount_delivery &&
                        parseFloat(action.discount_delivery) > 0
                      )
                      ? total + locationPrice
                      : parseFloat(total) -
                          parseFloat(action.discount_delivery) <
                        0
                      ? 0
                      : parseFloat(total) -
                        parseFloat(action.discount_delivery) +
                        locationPrice
                    : total + locationPrice
                )
              : String2Currency(total)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
