import { Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';

export default class Spinner extends Component {
  render() {
    return (
      <>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            minHeight: '100vh',
          }}
        >
          <div role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <Typography variant="body1" className="px-2 m-0">Aguarde um momento...</Typography>
        </Grid>
      </>
    );
  }
}
