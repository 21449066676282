import { Typography } from '@material-ui/core';
import React from 'react';

const SocialLink = ({ url, label, icon: Icon, textColor }) => {
  return (
    <a target="_blank" href={url} rel="noopener noreferrer">
      <Typography
        variant="subtitle1"
        style={{
          color: textColor,
          overflowWrap: 'break-word',
          overflow: 'auto',
        }}
      >
        <Icon style={{ marginRight: 4 }} />
      </Typography>
    </a>
  );
};

export default SocialLink;
