import styled from 'styled-components';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

export const Form = styled.form`
  background: white;
  padding: 20px;
  border-radius: 10px;

  header {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }

    input {
      flex: 1;
      height: 40px;
      border: 1px solid grey;
      border-radius: 20px;
      padding-left: 15px;

      &::placeholder {
        color: #999;
      }
    }
  }
`;

export const CommentLine = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 30px;
  width: fit-content;

  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 15px;
  }
  section {
    p {
      color: #777777;
      margin: 0;
    }
  }
`;
