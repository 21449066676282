import { Box } from '@material-ui/core';
import React from 'react';

function AccountingProcessesCard({
  onClick,
  header,
  status,
  body,
  footer,
}) {
  return (
    <button
      style={{
        border: '1px solid #C4C4C4',
        borderRadius: 8,
        padding: 16,
        width: '100%',
      }}
      onClick={onClick}
    >
      <Box display="flex" justifyContent="space-between">
        <p className="h6" style={{ color: '#838383' }}>
          {header}
        </p>
        <div
          style={{
            backgroundColor: '#F98979',
            padding: '4px 12px',
            color: '#fff',
            borderRadius: 5,
          }}
        >
          {status}
        </div>
      </Box>
      <p className="h6 mt-2" style={{ fontWeight: '700' }}>
        {body}
      </p>
      <p className="h6 mt-2" style={{ color: '#838383' }}>
        {footer}
      </p>
    </button>
  );
}

export default AccountingProcessesCard;
