import ButtonPrimary from 'common/components/buttons/ButtonPrimary';
import FeedbackContent from 'common/components/modal/content/feedbackContentPersonality';
import Answers from 'common/components/quiz/AnswersParticipant';
import Questions from 'common/components/quiz/QuestionsParticipant';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  getLottieById,
  setFeedbackQuizModal,
  submitCheckListSurvey,
} from '../activitiesActions';
import { Container } from './styles';

let ChecklistPersonality = function (props) {
  const [feedback, setFeedback] = useState(undefined);

  async function onSubmit(values) {
    const { auth, activities } = props;
    const newValues = {};

    newValues.user = auth.user.id;
    newValues.author = 'user';
    newValues.action = activities.detail.id;
    newValues.establishment = auth.user.establishment_participant.id;
    newValues.research = activities.detail.researches[0].id;

    let marked = [];
    for (let i in values) {
      if (values[i].checked) {
        marked.push({
          option: parseInt(i.split('-')[1]),
          question: parseInt(i.split('-')[2]),
        });
      }
    }

    const questions = props.questions.map((item) => item.id);
    const answers = [];
    for (let question of questions) {
      const optionsPartial = marked.filter(
        (item) => item.question === question
      );
      answers.push({
        question: question,
        options: optionsPartial.map((item) => item.option),
      });
    }
    newValues.answers = answers;
    const feedbacks = [];
    for (let answer of answers) {
      for (let option of answer.options) {
        const feedback_option = props.questions
          .filter((item) => item.id === answer.question)[0]
          .options.filter((item) => item.id === option)[0].quiz_feedback_id;
        feedbacks.push(feedback_option);
      }
    }

    for (let answer of newValues.answers) {
      if (answer.options.length < 1) {
        return toastr.error(
          'Erro no envio',
          'Uma pergunta não foi respondida!'
        );
      }
    }

    function mode(arr) {
      return arr
        .sort(
          (a, b) =>
            arr.filter((v) => v === a).length -
            arr.filter((v) => v === b).length
        )
        .pop();
    }

    const result = mode(feedbacks);

    setFeedback(
      props.activities.detail.researches[0].quiz_feedbacks.filter(
        (item) => item.id === result
      )[0]
    );

    props.submitCheckListSurvey(newValues, props.history);
  }

  return (
    <>
      {!props.activities.feedbackQuizModal ? (
        <Form onSubmit={props.handleSubmit(onSubmit)} noValidate>
          {props.questions.map((question, index) => (
            <>
              <Field
                name="question"
                component={Questions}
                question={question}
              />
              <Container>
                {question.options.map((item, index) => (
                  <Field
                    name={`option-${item.id}-${question.id}`}
                    component={Answers}
                    questionId={question.id}
                    multipleQuestions
                    background={item.color ? item.color : '#2D95EF'}
                    textColor={item.textColor ? item.textColor : '#FFF'}
                    text={item.label ? item.label : null}
                    image={item.image ? item.image : null}
                    oneOnly
                    checkedOptions={props.formValues ? props.formValues : null}
                  />
                ))}
              </Container>
            </>
          ))}
          <ButtonPrimary
            disabled={props.activities.buttonLoading}
            progress={parseInt(props.general.submitProgress)}
            type="submit"
          >
            {props.activities.buttonLoading ? 'Enviando' : 'Enviar'}
          </ButtonPrimary>
        </Form>
      ) : (
        <FeedbackContent
          visible={props.activities.feedbackQuizModal}
          onCloseRoute="/homepage"
          history={props.history}
          htmlFeedback={feedback ? feedback.feedback : null}
          lottieId={feedback ? feedback.feedback_lottie_id : null}
          lottie={props.activities.lottieDetail}
          setFeedbackQuizModal={props.setFeedbackQuizModal}
        />
      )}
    </>
  );
};

ChecklistPersonality = reduxForm({ form: 'checklistPersonalityForm' })(
  ChecklistPersonality
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('checklistPersonalityForm')(state),
    activities: state.activities,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitCheckListSurvey,
      setFeedbackQuizModal,
      getLottieById,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistPersonality);
