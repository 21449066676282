import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLottieById } from '../../../../modules/user/pages/activities/activitiesActions';
import LottieControl from '../../lottie/lottie';
import DOMPurify from 'dompurify';

let FeedbackContent = function (props) {
  useEffect(() => {
    if (props.lottieId) props.getLottieById(props.lottieId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lottieId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ width: '100%', height: '95vh' }}
    >
      {props.lottie.json ? (
        <LottieControl loop={false} animation={props.lottie.json} />
      ) : null}
      <div
        style={{ maxWidth: '35em' }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.htmlFeedback),
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLottieById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContent);
