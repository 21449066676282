import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ActivityPublic from '../activities/activityPublic';
import CategoryActions from './categoryActions';
import creditCardsRoutes from './creditCards/creditCardsRoutes';
import giveawayNumbersRoutes from './giveawayNumbers/giveawayNumbersRoutes';
import productOrdersRoutes from './productOrders/productOrdersRoutes';
import Quizzes from './sections/earnPoints/quizzes/quizzes';
import quizzesRoutes from './sections/earnPoints/quizzes/quizzesRoutes';
import surveysRoutes from './sections/surveys/surveysRoutes';
import userInvitationsRoutes from './sections/userInvitations/userInvitationsRoutes';
import userCouponsRoutes from './userCoupons/userCouponsRoutes';
import vouchersRoutes from './vouchers/vouchersRoutes';

export default (
  <Switch>
    <Route path="/homepage/categoria/:id" component={CategoryActions} />
    <Route path="/homepage/quizzes" component={Quizzes} />
    <Route path="/homepage/atividade/:id" component={ActivityPublic} />
    {quizzesRoutes}
    {surveysRoutes}
    {vouchersRoutes}
    {userCouponsRoutes}
    {creditCardsRoutes}
    {giveawayNumbersRoutes}
    {userInvitationsRoutes}
    {productOrdersRoutes}
  </Switch>
);
