/* eslint-disable react-hooks/exhaustive-deps */
import CallToActionCard from 'common/components/cards/callToActionCard';
import FeedbackContent from 'common/components/modal/content/feedbackContent';
import MaterialModal from 'common/components/modal/materialModal';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getActionById,
  getLottieById,
  setDetail,
  setFeedbackModal,
  setFeedbackQuizModal,
  setQuizResultsModal,
  setSubscribed,
  subscribe,
} from './activitiesActions';
import ChecklistCorrectAnswer from './checklistCorrectAnswer';
import ChecklistPersonality from './checklistPersonality';
import CheckListQuizParticipant from './checklistSurvey';
import PollQuiz from './pollQuiz';
import SurveyAnswerForm from './surveyAnswerForm';
import './vimeo.css';
import DOMPurify from 'dompurify';
import ReactPlayer from 'react-player';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR } from 'config/consts';

const useStyles = makeStyles(() => ({
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  },
}));

function ActivitySurvey(props) {
  const [surveyModal, setSurveyModal] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    props.getLottieById(props.activities.detail.feedback_lottie_id);
  }, []);

  function handleSurveyAnswerFormModal(state) {
    setSurveyModal(state);
  }

  function handleClose() {
    if (
      !props.activities.feedbackModal &&
      !props.activities.feedbackQuizModal
    ) {
      handleSurveyAnswerFormModal(false);
    } else if (props.activities.feedbackModal) {
      props.history.push('/homepage');
      props.setFeedbackModal(false);
      if (props.cleanupFunction) {
        props.cleanupFunction();
      }
    } else if (props.activities.feedbackQuizModal) {
      props.history.push('/homepage');
      props.setFeedbackQuizModal(false);
    }
  }

  return (
    <>
      <Grid item container justifyContent="center" alignItems="center" xs={12}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <Typography
            variant="body1"
            style={{
              width: '100%',
              margin: '1.5rem 0',
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.activities.detail.description),
            }}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          {props.activities.detail.action_videos.length > 0 ? (
            <Grid
              item
              container
              xs={12}
              justyfy="center"
              alignItems="center"
              style={{ margin: '.5rem 0 1rem 0' }}
            >
              <ReactPlayer
                width={'100%'}
                height={isMobile ? '17rem' : '30rem'}
                controls
                className={classes.reactPlayer}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <CallToActionCard
            public={props.public || false}
            website={props.website}
            auth={props.auth}
            history={props.history}
            activity={props.activities.detail}
            onClick={
              props.callToActionLink
                ? () => props.history.push(props.callToActionLink)
                : () => setSurveyModal(true)
            }
          />
        </Grid>
      </Grid>
      <MaterialModal
        open={surveyModal}
        maxWidth={
          props.activities.feedbackModal || props.activities.feedbackQuizModal
            ? isMobile
              ? 'xl'
              : 'md'
            : 'md'
        }
        style={{ color: 'orange' }}
        showTitle
        fullWidth={true}
        onClose={() => handleClose()}
        PaperProps={
          isMobile
            ? { style: { overflowY: 'scroll' } }
            : { style: { backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR } }
        }
      >
        {!props.activities.feedbackModal ? (
          <div className="container-fluid py-3 py-md-5 px-3 px-md-5 col-8">
            {props.activities.detail.type_id === 9 ? (
              <CheckListQuizParticipant
                question={props.activities.detail.researches[0].questions[0]}
                options={
                  props.activities.detail.researches[0].questions[0].options
                }
                history={props.history}
              />
            ) : props.activities.detail.type_id === 10 ? (
              <PollQuiz
                question={props.activities.detail.researches[0].questions[0]}
                options={
                  props.activities.detail.researches[0].questions[0].options
                }
                history={props.history}
              />
            ) : props.activities.detail.type_id === 11 ? (
              <ChecklistCorrectAnswer
                questions={props.activities.detail.researches[0].questions}
                history={props.history}
              />
            ) : props.activities.detail.type_id === 12 ? (
              <ChecklistPersonality
                questions={props.activities.detail.researches[0].questions}
                history={props.history}
              />
            ) : (
              <SurveyAnswerForm history={props.history} />
            )}
          </div>
        ) : (
          <>
            <FeedbackContent
              visible={props.activities.feedbackModal}
              onCloseRoute="/homepage"
              history={props.history}
              setFeedbackModal={props.setFeedbackModal}
              firstText={'Página inicial'}
              activity={props.activities.detail}
              firstActionType="route"
              firstRoute="/homepage"
              lottie={props.activities.lottieDetail}
              cleanupFunction={() => props.getUserPoints(props.auth.user)}
              passedTitle="Parabéns!"
              passedText="Você acertou mais que 50%"
              passedButtonText="Continuar"
              failedTitle="Não foi dessa vez"
              failedText="Você errou mais que 50%!"
              failedButtonText="Continuar"
              {...props}
            />
          </>
        )}
      </MaterialModal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      subscribe,
      setSubscribed,
      getLottieById,
      setQuizResultsModal,
      setFeedbackModal,
      setFeedbackQuizModal,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySurvey);
