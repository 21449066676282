import { Grid, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import VideoCamIcon from '@material-ui/icons/Videocam';
import { BASE_S3, BUTTON_BACKGROUND_COLOR, IS_CAIXA } from 'config/consts';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import {
  getAllPostsByLimit,
  sendPost,
  sendPostImage,
  sendPostVideo,
  uploadVideo,
} from '../../FeedActions';
import { Button, Form, IconBackground, Input, Media } from './styles';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '2.4em',
    height: '2.4em',
    margin: '1rem 0',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

let CreatePostForm = function (props) {
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [description, setDescription] = useState('');
  const classes = useStyles();

  function checkFileType(selectedFile) {
    let file = selectedFile;
    let fileType = file.path.slice(-4, file.path.length);
    const types = ['.mov', '.mp4', '.wmw'];
    if (types.every((type) => fileType !== type)) {
      console.log('Formato não suportado!');
      return false;
    }
    return true;
  }

  function checkFileSize(selectedFile) {
    let file = selectedFile;
    const size = 200000000;
    if (file.size > size) {
      console.log('Escolha um arquivo de tamanho menor!');
      return false;
    }
    return true;
  }

  const dropzoneImageProps = {
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDropAccepted: onDropImage,
  };

  const dropzoneVideoProps = {
    accept: 'video/mov, video/mp4, video/wmw',
    multiple: false,
    onDropAccepted: onDropVideo,
    maxSize: 200000000, // 200MB
  };

  function onDropImage(imageFiles) {
    let reader = new FileReader();
    reader.readAsDataURL(imageFiles[0]);
    reader.onload = function (e) {
      setImage(e.target.result);
      setVideo(null);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  }

  async function onDropVideo(videoFiles) {
    let file = videoFiles[0];
    let reader = new FileReader();
    reader.readAsDataURL(videoFiles[0]);
    reader.onload = async function (e) {
      await props.uploadVideo(e.target.result, setVideo);
      setImage(null);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
    if (checkFileSize(file) && checkFileType(file)) {
      await props.uploadVideo(file, setVideo);
    }
  }

  const submit = async (e) => {
    e.preventDefault();

    const values = {
      user: props.auth.user.id,
      station_id: props.auth.user.station_id,
      description,
    };
    if (image) {
      values['media'] = {
        type: 'image',
        base64: image,
      };
      await props.sendPostImage(values, props.limit);
      setDescription('');
      setImage(null);
    } else if (video) {
      values['media_type'] = 'video';
      values['media'] = video;
      await props.sendPostVideo(values, props.limit);
      setDescription('');
      setVideo(null);
    } else {
      await props.sendPost(values, props.limit);
      setDescription('');
    }
  };

  return (
    <Form onSubmit={submit} disabled={props.feed.uploadProgress !== ''}>
      <Grid
        container
        justifyContent={isMobile ? 'center' : 'space-between'}
        alignItems="center"
        xs={12}
        component="section"
      >
        {/* AVATAR */}
        <Grid
          item
          container
          xs={12}
          sm={1}
          justifyContent={isMobile ? 'flex-start' : 'flex-start'}
          alignItems="center"
        >
          <Avatar
            alt={props.auth.user.name.split(' ')[0]}
            className={`${isMobile ? classes.small : classes.large}`}
            src={
              props.auth.user.image
                ? `${BASE_S3}/${props.auth.user.image}`
                : 'https://s3.amazonaws.com/fermento.app.files/files/default_profile.png'
            }
          />
          {isMobile && (
            <span style={{ paddingLeft: '1rem' }}>{props.auth.user.name}</span>
          )}
        </Grid>
        {/* POST CONTENT INPUT */}
        <Grid
          item
          container
          xs={8}
          sm={8}
          alignItems="center"
          justifyContent="center"
        >
          <Input
            type="text"
            maxlength={320}
            placeholder={
              isMobile
                ? 'Compartilhe o que pensa!'
                : 'Compartilhe o que está pensando!'
            }
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required={!image && !video}
            isMobile={isMobile}
          />
        </Grid>
        {/* SUBMIT BUTTON */}
        <Grid
          item
          container
          xs={4}
          sm={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button type="submit" disabled={props.feed.uploadProgress !== ''}>
            Publicar
          </Button>
        </Grid>
      </Grid>

      <Media>
        {image && <img src={image} alt="post" />}
        {props.feed.uploadProgress !== '' && <p>Subindo o vídeo...</p>}
        {video && (
          <p>
            O seu vídeo foi carregado com sucesso. Ele pode demorar alguns
            minutinhos para estar disponível no feed. Pode publicar e assim que
            possível ele aparecerá para os seus colegas
          </p>
        )}
      </Media>

      <ul>
        {!IS_CAIXA && (
          <li>
            <Dropzone {...dropzoneVideoProps}>
              {({ getRootProps, getInputProps }) => (
                <IconBackground {...getRootProps()}>
                  <Typography variant="subtitle1">
                    <VideoCamIcon style={{ color: BUTTON_BACKGROUND_COLOR }} />
                    Adicione um vídeo
                    <input {...getInputProps()} />
                  </Typography>
                </IconBackground>
              )}
            </Dropzone>
          </li>
        )}
        <li>
          <Dropzone {...dropzoneImageProps}>
            {({ getRootProps, getInputProps }) => (
              <IconBackground {...getRootProps()}>
                <Typography variant="subtitle1">
                  <ImageIcon style={{ color: BUTTON_BACKGROUND_COLOR }} />
                  Adicione uma imagem
                  <input {...getInputProps()} />
                </Typography>
              </IconBackground>
            )}
          </Dropzone>
        </li>
      </ul>
    </Form>
  );
};

CreatePostForm = reduxForm({ form: 'createPostForm' })(CreatePostForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    feed: state.feed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { sendPost, sendPostImage, getAllPostsByLimit, uploadVideo, sendPostVideo },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatePostForm);
