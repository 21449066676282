import moment from 'moment';

export const Bool2Number = (data) => {
  if (data === 1) {
    return true;
  }
  return false;
};

export const ShowBoolean = (data, params) => {
  if (data === 1) {
    return params.hasOwnProperty('true') ? params.true : 'Sim';
  }
  return params.hasOwnProperty('false') ? params.false : 'Não';
};

export const String2Float = (data) => {
  return parseFloat(data);
};

export const changeDateToFormatWithDot = (date) => {
  const itemDate = date.split('.')[0];
  return moment(itemDate).format('DD/MM/YYYY HH:mm');
};

export const changeDateToFormat = (date) => {
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const String2Currency = (num) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(num);
};

export const String2PhoneNumber = (str) => {
  const cleaned = ('' + str).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
  const match2 = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  if (match2) {
    return (
      '(' + match2[1] + ') ' + match2[2] + ' ' + match2[3] + '-' + match2[4]
    );
  }

  return str;
};

export const handleWhiteSpaces = (value) =>
  value && value.split(' ').join('').toLowerCase();

export const formatCNPJ = (string) => {
  return (
    string.substring(0, 2) +
    '.' +
    string.substring(2, 5) +
    '.' +
    string.substring(5, 8) +
    '/' +
    string.substring(8, 12) +
    '-' +
    string.substring(12, 14)
  );
};
