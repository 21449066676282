/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import AlertDialogSlide from 'common/components/dialog/generalDialog';
import Spinner from 'common/components/spinner/spinner';
import { BASE_S3, COMPANY_SIGNED_OFF_BACKGROUND_COLOR } from 'config/consts';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GeneralTitle } from '../../../../../common/styles/styles';
import { COMPANY_STATION_ID } from '../../../../../config/consts';
import PrivacyPolicy from '../../../../../pages/auth/policies/privacyPolicy';
import UseTerms from '../../../../../pages/auth/policies/usePolicy';
import NavigationBar from '../../../../common/components/Header/navigationBar';
import Footer from '../../../../website/modules/footer';
import { getAboutList } from '../../../../website/websiteActions';
import { getListById } from './aboutActions';
import './style.css';

const useStyles = makeStyles({
  root: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    maxWidth: '1250px',
  },
});

let AboutDetail = function (props) {
  const { history } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openModalSec, setOpenModalSec] = useState(false);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles();

  const { id } = useParams();

  useEffect(() => {
    props.getListById(id);
    props.getAboutList(COMPANY_STATION_ID);
  }, []);
  useEffect(() => {
    props.getListById(id);
  }, [id]);

  if (props.about.loading) {
    return (
      <Grid container alignItems="center" justifyContent="center" xs={12}>
        <Spinner />
      </Grid>
    );
  }

  return (
    <Box
      minHeight="100vh"
      style={{ backgroundColor: COMPANY_SIGNED_OFF_BACKGROUND_COLOR }}
    >
      <NavigationBar
        isMainPage={false}
        website={props.website}
        history={history}
      />
      <Grid container alignItems="center" justifyContent="center" xs={12}>
        <Grid
          component="main"
          item
          container
          alignItems="center"
          justifyContent="center"
          xs={12}
          className={classes.root}
        >
          <Grid
            component="section"
            item
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            xs={12}
            sm={10}
            md={8}
          >
            <Grid item component="article">
              <GeneralTitle xs={xs} sm={sm} marginBottom={xs ? '18px' : '10px'}>
                <Typography variant="h4">{props.about.detail.name}</Typography>
              </GeneralTitle>
            </Grid>
            <Grid item component="article">
              <img
                style={{
                  borderRadius: '8px',
                }}
                className="img-fluid"
                src={`${BASE_S3}/${
                  props.about.detail.internal_image || props.about.detail.image
                }`}
                alt="Imagem Sobre"
              />
            </Grid>
            <Grid item component="article">
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    props.about.detail.html_description
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer
        setOpenModal={setOpenModal}
        setOpenModalSec={setOpenModalSec}
        website={props.website}
        history={props.history}
      />
      <AlertDialogSlide
        onClose={() => {
          setOpenModal(false);
        }}
        visible={openModal}
        titleBackground={"#FFFF"}
        backgroundColor={"#FFFF"}
      >
        <PrivacyPolicy />
      </AlertDialogSlide>
      <AlertDialogSlide
        onClose={() => {
          setOpenModalSec(false);
        }}
        visible={openModalSec}
        titleBackground={"#FFFF"}
        backgroundColor={"#FFFF"}
      >
        <UseTerms />
      </AlertDialogSlide>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    about: state.about,
    website: state.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListById,
      getAboutList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutDetail);
