import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LegalAppointments from './legalAppointments';
import LegalProcesses from './legalProcesses';
import Legal from './legal';
import LegalContacts from './legalContacts';
import LegalEvents from './legalEvents';
import LegalInfo from './legalInfo';
import LegalVideos from './legalVideos';
import LegalNews from './legalNews';
import LegalNewsDetail from './legalNewsDetail';
import LegalGeneralInfo from './legalGeneralInfo';

export default (
  <Route path="/homepage/consultoria-juridica">
    <Switch>
      <Route component={Legal} exact path="/homepage/consultoria-juridica" />
      <Route
        path="/homepage/consultoria-juridica/processos"
        component={LegalProcesses}
      />
      <Route
        path="/homepage/consultoria-juridica/orientacoes"
        component={LegalGeneralInfo}
      />
      <Route
        path="/homepage/consultoria-juridica/contato"
        component={LegalContacts}
      />
      <Route
        path="/homepage/consultoria-juridica/informacoes"
        component={LegalInfo}
      />
      <Route
        path="/homepage/consultoria-juridica/videos"
        component={LegalVideos}
      />
      <Route
        path="/homepage/consultoria-juridica/noticias"
        component={LegalNews}
      />
      <Route
        path="/homepage/consultoria-juridica/noticias/detalhe"
        component={LegalNewsDetail}
      />
      <Route
        path="/homepage/consultoria-juridica/eventos"
        component={LegalEvents}
      />
      <Route
        path="/homepage/consultoria-juridica/agendamentos"
        component={LegalAppointments}
      />
    </Switch>
  </Route>
);
