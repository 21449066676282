/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import { SimpleButton } from 'common/components/buttons/simpleButton';
import AlertDialogSlide from 'common/components/dialog/generalDialog';
import GeneralTabs from 'common/components/navigation/actionsTab/tabs';
// import NavBarBootstrap from 'common/components/navigation/navBarBootstrap';
import { BASE_S3, COMPANY_SIGNED_OFF_BACKGROUND_COLOR } from 'config/consts';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Spinner from '../../../../common/components/spinner/spinner';
import { GeneralTitle } from '../../../../common/styles/styles';
import { COMPANY_STATION_ID } from '../../../../config/consts';
import PrivacyPolicy from '../../../../pages/auth/policies/privacyPolicy';
import UseTerms from '../../../../pages/auth/policies/usePolicy';
import NavigationBar from '../../../common/components/Header/navigationBar';
import Footer from '../../../website/modules/footer';
import { getAboutList } from '../../../website/websiteActions';
import {
  changePublicActionsModal,
  getPublicActionById,
  setDetail,
  setSubscribed,
  subscribe,
} from './activitiesActions';
import './activity.css';
import ActivityCoupon from './activityCoupon';
import ActivityDonation from './activityDonation';
import ActivityMemberGetMember from './activityMemberGetMember';
import ActivityPresentialCourse from './activityPresentialCourse';
import ActivityProductDelivery from './activityProductDelivery';
import ActivityRawContentPublic from './activityRawContentPublic';
import ActivityShare from './activityShare';
import ActivitySignupAndWin from './activitySignupAndWin';
import ActivitySurvey from './activitySurvey';
import {
  ActivityImageContainer,
  DomPurifyContentContainer,
  PublicActivityActionOption,
} from './styles';

const useStyles = makeStyles((props) => ({
  root: {
    padding: 0,
    minHeight: '100vh',
    backgroundColor: COMPANY_SIGNED_OFF_BACKGROUND_COLOR,
  },
  activityTitle: {
    fontSize: isMobile ? '1.5rem' : '2rem',
    fontWeight: 700,
    lineHeight: isMobile ? '1.7rem' : '2.5rem',
    margin: '0 0 .5rem 0',
  },
  commentsAndLikesArea: {
    padding: '1rem .5rem',
    backgroundColor: '#F7F7F7cc',
    borderRadius: '5px',
    margin: '1rem 0',
    minWidth: '100%',
  },
  footerLogo: {
    maxWidth: '90%',
    height: 'auto',
    maxHeight: 100,
  },
}));

function ActivityPublic(props) {
  const { history } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles(props);

  const { id: actionId } = useParams();

  useEffect(() => {
    if (actionId) {
      props.getPublicActionById(actionId);
    }
  }, [actionId]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalSec, setOpenModalSec] = useState(false);
  const [id] = useState(COMPANY_STATION_ID);

  useEffect(() => {
    props.getAboutList(id);
  }, []);

  function renderStart(props) {
    return (
      <>
        <Grid
          container
          xs={12}
          justifyContent="center"
          alignContent="center"
          component="main"
        >
          <Grid item container xs={12} justifyContent="center" alignItems="center">
            {props.activities.detail.type_id === 3 && (
              <ActivityCoupon
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
            {props.activities.detail.type_id === 4 && (
              <ActivityPresentialCourse
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
            {props.activities.detail.type_id === 2 && (
              <ActivitySignupAndWin
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
            {props.activities.detail.type_id === 1 && (
              <ActivityMemberGetMember
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
            {props.activities.detail.type_id === 5 && (
              <ActivityShare
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
            {(props.activities.detail.type_id === 7 ||
              props.activities.detail.type_id === 8 ||
              props.activities.detail.type_id === 9 ||
              props.activities.detail.type_id === 10 ||
              props.activities.detail.type_id === 11 ||
              props.activities.detail.type_id === 12 ||
              props.activities.detail.type_id === 6) && (
              <ActivitySurvey
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
            {props.activities.detail.type_id === 14 && (
              <ActivityProductDelivery
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
            {props.activities.detail.type_id === 16 && (
              <ActivityRawContentPublic
                activities={props.activities}
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
            {props.activities.detail.type_id === 17 && (
              <ActivityDonation
                activities={props.activities}
                history={props.history}
                public
                callToActionLink={'/cadastrar'}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  function renderRules(props) {
    return (
      <Grid item container alignItems="center" xs={12}>
        <Typography variant="body1">
          <DomPurifyContentContainer
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.activities.detail.rules),
            }}
          />
        </Typography>
      </Grid>
    );
  }

  if (props.activities.loading && actionId) {
    return (
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Spinner />
      </Grid>
    );
  }

  return (
    <>
      <Container maxWidth={false} className={classes.root} component="main">
        <NavigationBar
          isMainPage={false}
          website={props.website}
          history={history}
        />
        <Grid container justifyContent="center" alignItems="center" xs={12}>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            component="section"
            style={{
              maxWidth: '1250px',
            }}
          >
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              sm={10}
              md={8}
            >
              <Grid item container justifyContent="center" alignItems="center" xs={12}>
                <ActivityImageContainer
                  xs={xs}
                  sm={sm}
                  BASE_S3={BASE_S3}
                  imagePath={props.activities.detail.image}
                />
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                xs={11}
                sm={12}
              >
                <Typography variant="h1">
                  <GeneralTitle xs={xs} sm={sm}>
                    {props.activities.detail.name}
                  </GeneralTitle>
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                xs={11}
                sm={12}
                style={{
                  paddingBottom: '.8rem',
                }}
              >
                <GeneralTabs
                  start={renderStart(props)}
                  rules={renderRules(props)}
                  hiddenTabs={
                    props.activities.detail.type_id === 16 ||
                    !props.activities.detail.has_rules
                  }
                />
              </Grid>
              {props.activities.detail.comments_and_likes_enabled ? (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.commentsAndLikesArea}
                  >
                    <Typography variant="body1" textAlign="center">
                      Para comentar,&nbsp;
                      <PublicActivityActionOption
                        onClick={() => props.history.push('/login')}
                      >
                        <Typography variant="subtitle2" textAlign="center">
                          faça login&nbsp;
                        </Typography>
                      </PublicActivityActionOption>
                      ou&nbsp;
                      <PublicActivityActionOption
                        onClick={() => props.history.push('/cadastrar')}
                      >
                        <Typography variant="subtitle2" textAlign="center">
                          cadastre-se
                        </Typography>
                      </PublicActivityActionOption>
                      .
                    </Typography>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Footer
          setOpenModal={setOpenModal}
          setOpenModalSec={setOpenModalSec}
          website={props.website}
          history={props.history}
        />
        <AlertDialogSlide
          onClose={() => {
            setOpenModal(false);
          }}
          visible={openModal}
          titleBackground={"#FFFF"}
          backgroundColor={"#FFFF"}
        >
          <PrivacyPolicy />
        </AlertDialogSlide>
        <AlertDialogSlide
          onClose={() => {
            setOpenModalSec(false);
          }}
          visible={openModalSec}
          titleBackground={"#FFFF"}
          backgroundColor={"#FFFF"}
        >
          <UseTerms />
        </AlertDialogSlide>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPublicActionById,
      setDetail,
      subscribe,
      setSubscribed,
      changePublicActionsModal,
      getAboutList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPublic);
