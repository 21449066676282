import { Box, Switch } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FullButton } from 'common/components/buttons/fullButton';
import {
  CaptchaInput,
  InputLabelOutlined,
} from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { BASE_S3, COMPANY_COLORS, COMPANY_STATION_ID } from 'config/consts';
import { cpfMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import { getStationData } from 'modules/website/websiteActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';
import { handleWhiteSpaces } from '../../../helpers/dataTransformers';
import { login, validateToken } from '../authActions';
import './login.css';

class Login2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
    this.props.initializeForm();
  }

  componentDidMount() {
    const { user } = this.props.auth;
    if (user) {
      this.props.validateToken(user.token);
    }
    if (
      !this.props.website.stationData.address ||
      !this.props.website.stationData.banner_image
    ) {
      this.props.getStationData(COMPANY_STATION_ID);
    }
    this.isAuthenticated();
  }

  isAuthenticated() {
    const { user, validToken } = this.props.auth;

    if (user && validToken) {
      this.props.history.push('/');
    }
    /////console.log(user, validToken);
  }

  onSubmit = (values) => {
    const body = {
      password: values.password,
      email_or_cpf: values.email_or_cpf ? 'email' : 'cpf',
    };
    if (!values.email_or_cpf && values.cpf) {
      body['cpf'] = values.cpf;
    } else if (values.email_or_cpf && values.email) {
      body['email'] = values.email;
    }
    const { login, history } = this.props;
    login(body, history);
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  renderEyeShowPassword = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
        >
          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  isDisabled = () => {
    if (this.props.formValues) {
      if (this.props.formValues.email_or_cpf) {
        return !this.props.formValues.email || !this.props.formValues.password;
      } else if (!this.props.formValues.email_or_cpf) {
        return !this.props.formValues.cpf || !this.props.formValues.password;
      }
    } else return true;
  };
  render() {
    const { website } = COMPANY_COLORS;

    if (this.props.website.stationDataLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <LoadingProgress />
        </Box>
      );
    }
    return (
      <div
        className="d-flex justify-content-center"
        style={{
          background: `url(${BASE_S3}/${website.header.background_auth_station})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="col-sm-12 col-md-6 col-lg-4 align-self-center my-md-0 my-5">
          <div style={{ background: '#ffffff', borderRadius: 4 }}>
            <div className="row justify-content-center">
              <div className="col-7 d-flex justify-content-center py-3">
                <a href="/">
                  <img
                    className
                    src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                    alt="Logo"
                    style={{
                      maxWidth: '90%',
                      height: 'auto',
                      maxHeight: 85,
                    }}
                  />
                </a>
              </div>
            </div>
            <Field component={EmailOrCPF} name="email_or_cpf" />
            <div className="row justify-content-center">
              <div className="col-12">
                <form
                  onSubmit={this.props.handleSubmit((v) => this.onSubmit(v))}
                  noValidate
                >
                  {this.props.formValues &&
                  !this.props.formValues.email_or_cpf ? (
                    <div mt={3}>
                      <div className="row justify-content-center my-3">
                        <div className="col-10">
                          <Field
                            component={InputLabelOutlined}
                            type="tel"
                            label="CPF"
                            helperText="Digite seu CPF"
                            name="cpf"
                            validate={[FORM_RULES.required, FORM_RULES.cpf]}
                            {...cpfMask}
                          />
                        </div>
                      </div>
                    </div>
                  ) : this.props.formValues &&
                    this.props.formValues.email_or_cpf ? (
                    <div mt={3}>
                      <div className="row justify-content-center my-3">
                        <div className="col-10">
                          <Field
                            component={InputLabelOutlined}
                            label="E-mail"
                            inputlabelprops={{
                              shrink: true,
                            }}
                            // placeholder="Digite seu e-mail"
                            name="email"
                            validate={[FORM_RULES.required, FORM_RULES.email]}
                            helperText="Digite seu e-mail"
                            normalize={handleWhiteSpaces}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div mt={3}>
                    <div className="row justify-content-center my-3">
                      <div className="col-10">
                        <Field
                          component={InputLabelOutlined}
                          endAdornment={this.renderEyeShowPassword()}
                          type={this.state.showPassword ? 'text' : 'password'}
                          label="Senha"
                          inputlabelprops={{
                            shrink: true,
                          }}
                          name="password"
                          validate={[FORM_RULES.required]}
                          helperText="Digite sua senha"
                        />
                      </div>
                    </div>
                  </div>
                  {process.env.REACT_APP_API_KEY === 'production' ? (
                    <div mt={3}>
                      <div className="row justify-content-center my-3">
                        <div className="col-10">
                          <div className="d-flex justify-content-center">
                            <Field
                              component={CaptchaInput}
                              inputlabelprops={{
                                shrink: true,
                              }}
                              name="captcha"
                              validate={[FORM_RULES.required]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row justify-content-center mb-4">
                    <div className="col-10">
                      <div className="d-flex justify-content-center">
                        <FullButton
                          classNameProp="py-3 px-4"
                          type="submit"
                          disabled={
                            this.isDisabled() || this.props.auth.loadingLogin
                          }
                        >
                          {this.props.auth.loadingLogin
                            ? 'Carregando...'
                            : 'Entrar'}
                        </FullButton>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login2 = reduxForm({ form: 'loginForm' })(Login2);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    formValues: getFormValues('loginForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { login, validateToken, getStationData, initializeForm },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login2);

const EmailOrCPF = ({ input }) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-row">
      <span>CPF</span>
      <Switch
        {...input}
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
      />
      <span>E-mail</span>
    </div>
  );
};

const initializeForm = () => {
  return (dispatch) => {
    dispatch([initialize('loginForm', { email_or_cpf: 0 })]);
  };
};
