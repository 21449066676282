import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

export const getListTransform = (data) => {
  if (!_.isUndefined(data)) {
    return {
      content: data,
    };
  }
  return undefined;
};

export const getListTransformUser = (data) => {
  if (!_.isUndefined(data)) {
    return {
      content: data.content.data,
      pagination: {
        current_page: data.content.current_page,
        last_page: data.content.last_page,
        path: data.content.path,
        per_page: data.content.per_page,
        total: data.content.total,
      },
    };
  }
  return undefined;
};

export const getDetailTransform = (data) => {
  if (!_.isUndefined(data)) {
    return data[0];
  }
  return undefined;
};

export const handleError = (e) => {
  try {
    if (e.response.data.errors) {
      if (e.response.data.errors.length) {
        e.response.data.errors.forEach((error) => toastr.error('Erro', error));
      } else {
        for (const i in e.response.data.errors) {
          for (const j in e.response.data.errors[i]) {
            toastr.error(i, e.response.data.errors[i][j]);
          }
        }
      }
    } else {
      if (e.response.data.response.content.messages) {
        for (const i in e.response.data.response.content.messages) {
          e.response.data.response.content.messages[i].map((item) => {
            return toastr.error(i, item);
          });
        }
      } else if (e.response.data.response.content.message) {
        toastr.error('Erro', e.response.data.response.content.message);
      } else {
        for (const i in e.response.data) {
          for (const j in e.response.data[i]) {
            toastr.error(i, e.response.data[i][j]);
          }
        }
      }
    }
  } catch (error) {
    toastr.error('Erro', 'Erro interno no servidor');
  }
};
