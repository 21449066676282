import styled from "styled-components";

export const NavigationButton = styled.a `
cursor: pointer;
margin: 0 .6rem;
color: ${(props) =>
  props.color
  ? props.color + '!important'
  : ''
};
font-size: ${(props) =>
  props.fontSize
  ? props.fontSize
  : ''
};

:hover {
  text-decoration: none;
}

:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const SignUpButton = styled.button `
  padding: 8px 24px;
  border-radius: 6px;
  height: 48px;
  width: 168px;

  border: 1px solid ${(props) =>
    props.border
    ? props.border
    : ''
  };

  background: ${(props) =>
    props.background
    ? props.background
    : ''
  };

  color: ${(props) =>
    props.text
    ? props.text
    : ''
  };

:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;
