import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

const URL_EARN_POINTS = `${BASE_API}/user-earn-points/`;

export const finishQuiz = (values, history = undefined) => {
  return (dispatch) => {
    axios
      .post(URL_EARN_POINTS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        if (!_.isUndefined(history)) {
          history.push('/homepage');
          toastr.success(
            `Parabéns, você completou o Quiz e ganhou ${values.points} pontos.`
          );
        }
      })
      .catch((e) => {
        toastr.error('Erro interno no servidor');
      });
  };
};
