import React, { useState, useEffect } from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { likePost, unlikePost } from '../../FeedActions';

import { Container } from './styles';
import { Typography } from '@material-ui/core';

function Like(props) {
  const [likes, setLikes] = useState(0);
  const [active, setActive] = useState(false);

  const likePost = async (id) => {
    setLikes(likes + 1);
    setActive(true);
    await props.likePost(
      props.auth.user.id,
      id,
      props.limit,
      props.auth.user.station_id
    );
  };

  const unlikePost = async (id) => {
    setLikes(likes - 1);
    setActive(false);
    await props.unlikePost(
      props.auth.user.id,
      id,
      props.limit,
      props.auth.user.station_id
    );
  };

  const buttonLike = (userLiked, post) => {
    if (userLiked) {
      return unlikePost(post);
    } else if (!userLiked) {
      return likePost(post);
    }
  };

  useEffect(() => {
    setLikes(props.likes.length);
    setActive(props.userLiked);
  }, [props.likes, props.userLiked]);

  return (
    <Container
      type="button"
      onClick={() => buttonLike(active, props.postId)}
      active={active}
    >
      <FavoriteIcon style={{ color: active ? '#F47A63' : 'lightgray' }} />
      <Typography variant="body1">{likes}</Typography>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ likePost, unlikePost }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Like);
