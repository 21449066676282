import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Downloads from './downloads';
import DownloadsDetail from './downloadsDetail';

export default (
  <Route path="/homepage/downloads">
    <Switch>
      <Route exact path="/homepage/downloads" component={Downloads} />
      <Route path="/homepage/downloads/:id" component={DownloadsDetail} />
    </Switch>
  </Route>
);
