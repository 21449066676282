import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Activities from './activities';
import Activity from './activity';

export default (
  <Route path="/homepage/atividades">
    <Switch>
      <Route exact path="/homepage/atividades/:id" component={Activity} />
      <Route path="/homepage/atividades" component={Activities} />
    </Switch>
  </Route>
);
