import {
  BASE_S3,
  BRB_FLA_STATION_ID,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
} from 'config/consts';
import { getStationData } from 'modules/website/websiteActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingProgress from '../../../common/components/progress/loading';
import { setLoading, signup } from '../authActions';
import BrbFlaUsePolicyText from './nacaoBrbFla/brbFlaPrivacyPolicyText';
import UsePolicyText from './usePolicyText';
import DOMPurify from 'dompurify';

class UsePolicy extends Component {
  constructor(props) {
    super(props);
    props.setLoading(false);
    props.getStationData(COMPANY_STATION_ID);
  }

  render() {
    const { website } = COMPANY_COLORS;

    return (
      <div className="col-12" style={{ background: '#FFFF', borderRadius: 4 }}>
        <div style={{ background: '#FFFF', borderRadius: 4 }}>
          <div className="row justify-content-center">
            <div className="col-7 d-flex justify-content-center py-3">
              <img
                // className
                src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                alt="Logo"
                style={{ maxWidth: '100%', height: 'auto', maxHeight: 85 }}
              />
            </div>
          </div>
          {this.props.website.stationData?.terms_of_use_html ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  this.props.website.stationData?.terms_of_use_html
                ),
              }}
            />
          ) : (
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="row justify-content-center my-2">
                  <div className="col-8">
                    <span
                      className="station-font-medium text-center"
                      style={{
                        color: COMPANY_COLORS.general.text,
                      }}
                    >
                      {this.props.website.stationDataLoading ? (
                        <LoadingProgress />
                      ) : COMPANY_STATION_ID === BRB_FLA_STATION_ID ? (
                        <>
                          {/* no flamengo */}
                          <BrbFlaUsePolicyText />
                        </>
                      ) : (
                        <UsePolicyText
                          station={this.props.website.stationData}
                          stationName={this.props.website.stationData.name}
                          siteAddress={this.props.website.stationData.url}
                          cnpj={
                            this.props.website.stationData.establishment.cnpj
                          }
                          socialName={
                            this.props.website.stationData.establishment
                              .full_social_name
                          }
                          address={
                            this.props.website.stationData.establishment.address
                          }
                          createdAt={this.props.website.stationData.createdAt}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row justify-content-center mb-3">
            <div className="col-10">
              <div
                style={{
                  background: 'lightgray',
                  height: 1,
                  width: '100%',
                  opacity: 0.5,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      getStationData,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UsePolicy);
