import React, { Component } from 'react';
import Axios from 'axios';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Image from '@material-ui/icons/ImageOutlined';
import 'react-image-crop/dist/ReactCrop.css';
import { SimpleButton } from '../../buttons/styles';
import AlertDialogSlide from '../../dialog/dialog';
import { BASE_S3, USER_TOKEN, BASE_API } from 'config/consts';
import {
  BUTTON_BACKGROUND_COLOR,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
} from 'config/consts';

const UPLOAD_IMAGE = `${BASE_API}/upload-image-to-s3`;

function uploadImageToS3(base64) {
  return Axios.post(
    `${UPLOAD_IMAGE}`,
    { base64 },
    {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
    }
  ).then((response) => {
    return response.data.url;
  });
}
export default class InputImage extends Component {
  state = {
    open: false,
    src: null,
    crop: {
      unit: '%',
      width: 50,
      height: 50,
      aspect: this.props.aspect,
    },
    imageLoading: false,
  };

  onSelectFile = (files) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result, open: true })
      );
      reader.readAsDataURL(files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImgBase64 = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png'
      );
      this.setState({ croppedImgBase64 });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      const base64Image = canvas.toDataURL('image/png');
      resolve(base64Image);
    });
  }
  uploadImage = async () => {
    const url = await uploadImageToS3(this.state.croppedImgBase64);
    this.props.input.onChange(url);
    this.setState({ imageLoading: false });
  };
  render() {
    function dropzoneProps() {
      return {
        accept: 'image/jpeg, image/png, image/gif',
        multiple: false,
        maxSize: 1000000,
        onDropRejected: () =>
          meta.dispatch({
            type: 'SNACKBAR',
            payload: {
              message: 'Erro: Imagem maior que 1MB',
              variant: 'error',
              open: true,
            },
          }),
      };
    }
    const { crop, croppedImgBase64, src } = this.state;
    const {
      meta: { touched, error },
      input: { value },
      label,
      meta,
    } = this.props;

    return (
      <>
        <Backdrop
          style={{
            zIndex: 1500 + 1,
            color: BUTTON_BACKGROUND_COLOR,
          }}
          open={this.state.imageLoading}
        >
          <CircularProgress color="inherit" />{' '}
          <Typography variant="body1" style={{ marginLeft: 10 }}>
            Fazendo upload da imagem...
          </Typography>
        </Backdrop>
        <div className="App">
          <Box display="flex">
            <Dropzone onDropAccepted={this.onSelectFile} {...dropzoneProps()}>
              {({ getRootProps, getInputProps }) => (
                <Box
                  display="flex"
                  flexGrow={1}
                  {...getRootProps()}
                  style={{
                    height: 'auto',
                    width: '100%',
                    margin: 'auto',
                    borderWidth: 1,
                    borderColor: touched && error ? 'red' : GENERAL_TEXT_COLOR,
                    borderStyle: 'dashed',
                    borderRadius: 5,
                    cursor: 'pointer',
                    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                    justifyContent: 'center',
                  }}
                >
                  {croppedImgBase64 ? (
                    <>
                      <input {...getInputProps()} />
                      <img
                        src={croppedImgBase64}
                        className="img-fluid"
                        alt="Ícone do Troféu"
                      />
                    </>
                  ) : value ? (
                    <>
                      <input {...getInputProps()} />
                      <div
                        style={{
                          position: 'relative',
                          textAlign: 'center',
                          color: 'white',
                          background: 'black',
                        }}
                      >
                        <img
                          src={`${BASE_S3}/${value}`}
                          className="img-fluid"
                          alt="Ícone do Troféu"
                          style={{
                            width: '100%',
                            opacity: '0.7',
                          }}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        >
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <Image style={{ width: 50, color: 'white' }} />
                            <span>Trocar imagem</span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Box
                      display="flex"
                      flexGrow={1}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      p={3}
                    >
                      <input {...getInputProps()} />
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          borderRadius: '50%',
                          backgroundColor: BUTTON_BACKGROUND_COLOR,
                          height: 70,
                          width: 70,
                        }}
                      >
                        <CloudUploadIcon
                          style={{
                            fontSize: 16,
                            color:
                              touched && error
                                ? 'red'
                                : COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                          }}
                          className=""
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        align="left"
                        style={{
                          textAlign: 'left',
                          marginTop: 20,
                          color: touched && error ? 'red' : GENERAL_TEXT_COLOR,
                        }}
                      >
                        {label}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Dropzone>
          </Box>
          {src && (
            <AlertDialogSlide
              visible={this.state.open}
              onClose={() => this.setState({ open: false })}
              maxWidth="sm"
            >
              <div
                style={{
                  backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                  padding: 30,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" style={{ marginBottom: 20 }}>
                  {`Corte da forma que preferir`}
                </Typography>
                <div>
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </div>
                <div style={{ textAlign: 'center', paddingTop: 20 }}>
                  <SimpleButton
                    onClick={() => {
                      this.setState({ imageLoading: true, open: false }, () => {
                        this.uploadImage();
                      });
                    }}
                  >
                    Finalizar Edição
                  </SimpleButton>
                </div>
              </div>
            </AlertDialogSlide>
          )}
        </div>
      </>
    );
  }
}
