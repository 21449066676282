import { Grid, makeStyles, Typography } from '@material-ui/core';
// import { String2Currency } from 'helpers/dataTransformers';
import { GENERAL_TEXT_COLOR } from 'config/consts';
import React from 'react';
import { customCurrencyChanger } from '../../../../../../helpers/utils';
import { OrangeTitle } from './styles';

const useStyles = makeStyles(() => ({
  paymentInfoItem: {
    margin: '.2rem 0',
    fontWeight: 'bold',
  },
  paymentInfoValue: {
    margin: '.2rem 0',
    color: GENERAL_TEXT_COLOR,
  },
  bdStyle: {
    borderBottom: '1px solid rgba(131, 131, 131, 0.5)',
  },
}));

const paymentInfoItems = [
  { label: 'Total do pedido', id: 1 },
  { label: 'Créditos a receber', id: 2 },
  { label: 'Conclusão da compra', id: 3 },
];

export function AdditionalInfo({
  shooppingInfo,
  auth,
  cashback,
  timeLeft,
  formValues,
  creditCards,
  currencyData,
}) {
  const classes = useStyles();

  function getCreditCard() {
    const filtered = creditCards.filter(
      (item) => item.id === formValues.payment.selected_card
    )[0];
    if (filtered) {
      return filtered;
    } else if (!filtered && formValues.payment.number) {
      return {
        brand: 'Novo cartão',
        last_digits: formValues.payment.number.substring(
          formValues.payment.number.length - 4
        ),
      };
    }
  }

  return (
    <>
      <Grid item container justifyContent="center" alignItems="center" xs={12}>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          className={classes.bdStyle}
        >
          <OrangeTitle>
            <Typography variant="h6">Forma de pagamento</Typography>
          </OrangeTitle>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          className={classes.bdStyle}
        >
          {parseFloat(shooppingInfo.difference.toFixed(2)) > 0.0 ? (
            <>
              {auth.points > 0 ? (
                <>
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    {formValues.payment.type === 'credit_card' ? (
                      <Typography variant="body1">
                        {getCreditCard().brand.toUpperCase()}:{' '}
                        {`**** **** **** ${getCreditCard().last_digits}`}
                      </Typography>
                    ) : (
                      <Typography variant="body1">PIX</Typography>
                    )}

                    <Typography variant="body1">
                      {shooppingInfo.currency}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Typography variant="body1">Carteira Virtual</Typography>
                    <Typography variant="body1">
                      {/* {String2Currency(auth.points)} */}
                      {/* {console.log(auth.points)} */}
                      {customCurrencyChanger(currencyData, auth.points)}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  xs={12}
                >
                  {formValues.payment.type === 'credit_card' ? (
                    <Typography variant="body1">
                      {getCreditCard().brand.toUpperCase()}:{' '}
                      {`**** **** **** ${getCreditCard().last_digits}`}
                    </Typography>
                  ) : (
                    <Typography variant="body1">PIX</Typography>
                  )}
                  <Typography variant="body1">{shooppingInfo.total}</Typography>
                </Grid>
              )}
            </>
          ) : (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              <Typography variant="body1">Carteira Virtual</Typography>
              <Typography variant="body1">{shooppingInfo.total}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            margin: '.5rem 0',
          }}
        >
          <Grid
            item
            container
            xs={6}
            sm={9}
            md={10}
            direction="column"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            {paymentInfoItems.map((item) => (
              <Typography
                variant="body1"
                key={item.id} // bad key
                className={classes.paymentInfoItem}
              >
                {item.label}
              </Typography>
            ))}
          </Grid>
          <Grid
            item
            container
            xs={6}
            sm={3}
            md={2}
            direction="column"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Typography variant="body1" className={classes.paymentInfoValue}>
              {shooppingInfo.total}
            </Typography>
            <Typography variant="body1" className={classes.paymentInfoValue}>
              {/* {String2Currency(cashback)} */}
              {customCurrencyChanger(currencyData, cashback)}
            </Typography>
            <Typography variant="body1" className={classes.paymentInfoValue}>
              {timeLeft.minutes}:{timeLeft.seconds}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
