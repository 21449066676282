import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { BASE_S3 } from '../../../config/consts';
import { limitStringSize } from '../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  activityCardImage: {
    borderRadius: 6,
    maxWidth: '100%',
    minWidth: '100%',
    width: 'auto',
    height: 'auto',
    display: 'block',
    objectFit: 'cover',
    objectPosition: '50% 50%',
  },
  activityDescription: (props) => {
    return {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      maxWidth: 267.02,
      lineHeight: '20px',
      letterSpacing: '-0.1px',
      padding: '.7rem',
      color: props.textColor,
      display: 'none',
    };
  },
}));

export const DefaultCard = (props) => {
  const {
    onClick,
    imgCloudSource = undefined,
    actionName,
    key,
    description,
  } = props;
  let { imgSource } = props;
  const classes = useStyles(props);

  const cloudTo = () => {
    imgSource = `${BASE_S3}/${imgCloudSource}`;
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        onClick={onClick}
        key={key}
        style={{ cursor: 'pointer' }}
      >
        {/* IMAGE */}
        <Grid xs={12} item>
          {imgCloudSource ? (
            <>
              {cloudTo()}
              <img
                className={classes.activityCardImage}
                src={imgSource}
                alt="cloud"
              />
            </>
          ) : (
            <img
              className={classes.activityCardImage}
              src={imgSource}
              alt="cloud"
            />
          )}
        </Grid>
        {/* DESCRIPTION */}
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
        >
          <Typography variant="body1" className={classes.activityDescription}>
            {limitStringSize(actionName, 62)}
          </Typography>
        </Grid>
        {/* ADDITIONAL DESCRIPTION */}
        {description && (
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
          >
            <Typography variant="body2">
              {limitStringSize(description, 62)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};
