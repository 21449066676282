import { Box } from '@material-ui/core';
import { String2Currency } from 'helpers/dataTransformers';
import React from 'react';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR, GENERAL_TEXT_COLOR } from '../../../../config/consts';
import MoneyPig from '../../svg/moneyPig';
import { Container } from './styles';

export default function ProductCard(props) {
  return (
    <Container
      bgColor={COMPANY_SIGNED_IN_BACKGROUND_COLOR}
      boxShadowColor={GENERAL_TEXT_COLOR}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span style={{ color: '#707070', fontSize: 12 }}>Valor</span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            width: 60,
            height: 60,
            background: '#fff',
          }}
        >
          <MoneyPig color={props.secudaryColor} />
        </div>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ height: 'fit-content', margin: 'auto 0px' }}
      >
        {props.products && props.products.length <= 1 ? (
          props.products[0] ? (
            props.products[0].value && props.products[0].discount_value ? (
              props.products[0].value > props.products[0].discount_value ? (
                <>
                  <span style={{ color: '#a1a1a1', fontSize: 12 }}>
                    De R${' '}
                    <span
                      style={{
                        fontSize: 16,
                        textDecoration: 'line-through',
                      }}
                    >
                      {parseFloat(props.products[0].value)
                        .toFixed(2)
                        .replace('.', ',')}
                    </span>{' '}
                    por
                  </span>
                  <strong style={{ fontSize: 24, color: props.secudaryColor }}>
                    {String2Currency(
                      props.products[0].value - props.products[0].discount_value
                    )}
                  </strong>{' '}
                </>
              ) : (
                <strong style={{ fontSize: 24, color: props.secudaryColor }}>
                  {props.products[0].value === props.products[0].discount_value
                    ? 'Grátis'
                    : String2Currency(props.products[0].value)}
                </strong>
              )
            ) : props.products[0].value ? (
              <strong style={{ fontSize: 24, color: props.secudaryColor }}>
                {String2Currency(props.products[0].value)}
              </strong>
            ) : (
              <strong style={{ fontSize: 24, color: props.secudaryColor }}>
                Grátis
              </strong>
            )
          ) : null
        ) : (
          <strong
            style={{
              fontSize: 20,
              color: props.secudaryColor,
              lineHeight: 'normal',
            }}
          >
            Clique e descubra as nossas promoções!
          </strong>
        )}
      </Box>
      {props.products && props.products.length <= 1 && (
        <span style={{ fontSize: 16, color: '#707070' }}>
          Este é o valor a ser pago para a empresa parceira na contratação.
        </span>
      )}
    </Container>
  );
}
