import {
  ACTIVITY_POST_CREATE_LOAD,
  ALL_COMMENTS_DATA,
  ALL_COMMENTS_FETCHED,
  DELETE_ACTION_COMMENT_LOAD,
  GET_ACTIVITY_LIKES,
  GET_ACTIVITY_LIKES_LOADING,
  GET_ALL_ACTION_COMMENTS_LOAD,
  SEND_DISLIKE_LOADING,
  SEND_LIKE_LOADING
} from './activitiesActions';
import type from './types';

const INITIAL_STATE = {
  loading: true,
  list: {
    content: [],
  },
  listNameActions: {
    content: [],
  },
  detail: {},
  subscribed: false,
  code: '',
  createdAt: '',
  buttonLoading: false,
  loadingPrivateActions: true,
  loadingNameActions: true,
  privateActions: [],
  isPublicActionsModalOpen: false,
  feedbackModal: false,
  feedbackQuizModal: false,
  lottieDetail: {},
  lottieLoading: false,
  short_link_mgm: '',
  isQuizModalOpen: false,
  quiz: { isModalOpen: false, didWin: false },
  shareLink: null,
  
  createPostLoading: false,
  getAllActionCommentsLoading: false,
  actionComments: [],
  allDataComments: [],
  deleteActionCommentLoad: false,

  likeLoading: false,
  dislikeLoading: false,

  actonLikes: [],
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.LIST_FETCHED:
      return {
        ...state,
        list: { content: action.payload.data },
        loading: false,
      };
    case type.ITEM_DETAIL:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      };
    case type.LIST_LOAD:
      return { ...state, loading: action.payload };

    case type.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    case type.SUBSCRIBED:
      return {
        ...state,
        subscribed: action.payload,
      };
    case 'DATA_CODE':
      return {
        ...state,
        code: action.payload,
      };
    case 'DATA_CREATED_AT':
      return {
        ...state,
        createdAt: action.payload,
      };
    case 'FEATURED_PRIVATE_ACTIVITIES_LOAD':
      return {
        ...state,
        loadingPrivateActions: action.payload,
      };
    case 'FEATURED_PRIVATE_ACTIVITIES_FETCHED':
      return {
        ...state,
        privateActions: action.payload,
        loadingPrivateActions: false,
      };
    case 'ACTIVITIES_BY_NAME_LOAD':
      return {
        ...state,
        loadingNameActions: action.payload,
      };
    case 'ACTIVITIES_BY_NAME_FETCHED':
      return {
        ...state,
        listNameActions: { content: action.payload },
        loadingNameActions: false,
      };
    case 'PUBLIC_ACTIONS_MODAL':
      return {
        ...state,
        isPublicActionsModalOpen: action.payload,
      };
    case 'FEEDBACK_MODAL':
      return {
        ...state,
        feedbackModal: action.payload,
      };
    case 'FEEDBACK_QUIZ_MODAL':
      return {
        ...state,
        feedbackQuizModal: action.payload,
      };
    case 'QUIZ_MODAL':
      return {
        ...state,
        quiz: { ...state.quiz, isModalOpen: action.payload },
      };
    case 'QUIZ_MODAL_DID_WIN':
      return {
        ...state,
        quiz: { ...state.quiz, didWin: action.payload },
      };
    case 'LOTTIE_LOAD':
      return {
        ...state,
        lottieLoading: action.payload,
      };
    case 'LOTTIE_DETAIL':
      return {
        ...state,
        lottieDetail: action.payload,
        lottieLoading: false,
      };
    case 'SHORT_LINK':
      return {
        ...state,
        short_link_mgm: action.payload,
      };
    case 'SHARE_VALIDATED':
      return {
        ...state,
        shareLink: action.payload,
      };
    case ACTIVITY_POST_CREATE_LOAD:
      return {
        ...state,
        createPostLoading: action.payload,
      }
    case GET_ALL_ACTION_COMMENTS_LOAD:
      return {
        ...state,
        getAllActionCommentsLoading: action.payload
      }
    case ALL_COMMENTS_FETCHED:
    return {
      ...state,
      actionComments: [ ...action.payload],
    }
    case ALL_COMMENTS_DATA:
      return {
        ...state,
        allDataComments: [ ...action.payload],
      }
    case SEND_LIKE_LOADING:
      return {
        ...state,
        likeLoading: action.payload,
      }
    case SEND_DISLIKE_LOADING:
      return {
        ...state,
        dislikeLoading: action.payload,
      }
      case GET_ACTIVITY_LIKES_LOADING:
        return {
          ...state,
          actionLikesLoading: action.payload,
        }
      case GET_ACTIVITY_LIKES:
        return {
          ...state,
          actionLikes: [...action.payload],
        }
      case DELETE_ACTION_COMMENT_LOAD:
        return {
          ...state,
          deleteActionCommentLoad: action.payload,
        }
    default:
      return state;
  }
};
