import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { FullButton } from '../../../common/components/buttons/fullButton';
import { InputLabelOutlined } from '../../../common/components/fields/fields';
import LoadingProgress from '../../../common/components/progress/loading';
import {
  COMPANY_ID,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  COMPANY_STATION_ID,
  SINDILEGIS_STATION_ID,
} from '../../../config/consts';
import { signupParticipant } from '../authActions';
import './signupStepForm.css';

class PassStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepBool: true,
      visible: false,
      checked: false,
      showPassword: false,
      privacy: false,
      useTerms: false,
    };
    // if (props.match.params?.code_mgm) {
    //   props.validateMGM(props.match.params?.code_mgm);
    // }
  }

  cleanup = (values) => {
    if (COMPANY_STATION_ID === SINDILEGIS_STATION_ID) {
      this.props.setAffiliatedInfo(values);
      this.props.setStepForm(2);
    } else {
      this.props.history.push(`/confirmacao/${values.email}`);
    }
  };

  onSubmit = (values) => {
    let newValues = {};
    if (this.props.match.params?.code_mgm) {
      newValues = {
        ...values,
        station_id: COMPANY_STATION_ID,
        establishment_id: COMPANY_ID,

        member_get_member: {
          action_id: this.props.auth.mgm.memberGetMemberRule.action.id,
          inviter_id: this.props.match.params?.code_mgm
            .split('U')[1]
            .split('-E')[0],
        },
      };
    } else {
      newValues = {
        ...values,
        station_id: COMPANY_STATION_ID,
        establishment_id: COMPANY_ID,
      };
    }
    if (COMPANY_STATION_ID === SINDILEGIS_STATION_ID) {
      newValues.email = this.props.affiliatedInfo.email;
    }
    this.props.signupParticipant(newValues, this.cleanup);
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  renderEyeShowPassword = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
        >
          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  render() {
    if (this.props.auth.loadingMGM) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <LoadingProgress />
        </div>
      );
    }

    return (
      <div>
        {/* <div className="row justify-content-center">
            <div className="col-7 d-flex justify-content-center py-3">
              <a href="/">
                <img
                  className
                  src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                  alt="Logo"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    maxHeight: 85,
                  }}
                />
              </a>
            </div>
          </div> */}
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row justify-content-center my-2">
              <div className="col-10">
                <Typography
                  variant="body1"
                  className="station-font-medium text-center"
                  // style={{ color: GENERAL_TEXT_COLOR }}
                >
                  Faça uma senha com números e letras!
                </Typography>
              </div>
            </div>
            <div className="row justify-content-center">
              <form
                className="col-12"
                onSubmit={this.props.handleSubmit((v) => this.onSubmit(v))}
                noValidate
              >
                <div className="row justify-content-center">
                  <div className="col-10 my-2">
                    <Field
                      component={InputLabelOutlined}
                      type={this.state.showPassword ? 'text' : 'password'}
                      label="Senha"
                      name="password"
                      endAdornment={this.renderEyeShowPassword()}
                      validate={[FORM_RULES.required, FORM_RULES.password]}
                      helperText="Senha precisa ter: uma letra maiúscula, uma letra minúscula, um número e tamanho entre 8 - 20."
                    />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10">
                    <Field
                      component={InputLabelOutlined}
                      type={this.state.showPassword ? 'text' : 'password'}
                      label="Confirme sua senha"
                      name="password_confirmation"
                      endAdornment={this.renderEyeShowPassword()}
                      validate={[FORM_RULES.required, FORM_RULES.password]}
                      helperText="Digite a mesma senha que digitou acima"
                    />
                  </div>
                </div>
                <div className="row justify-content-center  my-3">
                  <div className="col-10">
                    <div className="d-flex justify-content-center">
                      <FullButton
                        borderRadius={6}
                        classNameProp="py-3 px-4"
                        type="submit"
                        disabled={
                          this.props.auth.loadingRegister ||
                          (this.props.formValues
                            ? !this.props.formValues.password
                            : true) ||
                          (this.props.formValues
                            ? !this.props.formValues.password_confirmation
                            : true)
                        }
                        progress={parseInt(this.props.general.submitProgress)}
                      >
                        Continuar
                      </FullButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container my-3 pb-4">
          <div className="row justify-content-center">
            <div
              className="col-10 d-flex justify-content-center align-items-center p-3"
              style={{
                backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              }}
            >
              <Typography
                variant="subtitle2"
                component="a"
                href="#/login"
                className="station-font-medium"
                style={{
                  // color: '#0E5FD6',
                  // fontSize: '0.875rem',
                  display: 'inline-block',
                  textDecorationLine: 'underline',
                }}
              >
                Voltar
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PassStepForm = reduxForm({ form: 'signupStepForm' })(PassStepForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('signupStepForm')(state),
    website: state.website,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signupParticipant,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PassStepForm);
