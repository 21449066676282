import { Grid, Typography } from '@material-ui/core';
import GenericTabs from 'common/components/navigation/actionsTab/genericTabs';
import React from 'react';
import { connect } from 'react-redux';
import ExtractUsedCredits from './extractUsedCredits';
import ExtractUserCredits from './extractUserCredits';

function ExtractGeneral() {
  return (
    
    <Grid >

      <div
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: 'transparent',
            height: '248px',
          }}
        >
        <Typography variant="h5">Meu extrato</Typography>
        </div>


      <GenericTabs
        style={{
          padding: 0
        }}
        tabs={[

          {
            label: `Créditos de Premiação`,
            screen: <ExtractUserCredits></ExtractUserCredits>,
          },
          {
            label: `Créditos Utilizados`,
            screen: <ExtractUsedCredits></ExtractUsedCredits>,
          },
        ]}
      />
    </Grid>
  );
}

export default connect()(ExtractGeneral);
