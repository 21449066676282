import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FullButton } from 'common/components/buttons/fullButton';
import { COMPANY_COLORS } from 'config/consts';
import { String2Currency } from 'helpers/dataTransformers';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import { GeneralTitle } from '../../../../../../common/styles/styles';
import { productOrder } from '../../userShoppingCartActions';
import { AdditionalInfo } from './additionalInfo';
import { RenderProductsConfirmation } from './renderProducts';
import { OrangeTitle, Subtitle } from './styles';

const useStyles = makeStyles(() => ({
  flexForm: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minWidth: '100%',
  },
  paymentInfoItem: {
    margin: '.2rem 0',
    color: '#7E0000',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: '0.25px',
  },
  paymentInfoValue: {
    margin: '.2rem 0',
    color: COMPANY_COLORS.primary,
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '0.25px',
  },
}));

let Cart = function ({
  setStep,
  formValues,
  handleSubmit,
  productOrder: orderProducts,
  auth,
  history,
  userShoppingCart: { stepLoad },
  creditCards,
  timeLeft,
  stationData,
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles();
  const currencyData = {
    currency_multiplier: stationData.currency_multiplier,
    currency_name: stationData.currency_name,
    currency_name_position: stationData.currency_name_position,
  };

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const retryCallback = (message) => {
    console.log('retry callback', message);
    setErrorMessage(message);
    setOpen(true);
  };

  const handleBackwards = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  function subtotalProdutos() {
    let subtotal = 0;
    if (formValues && formValues.products) {
      for (let item of formValues.products) {
        if (parseFloat(item.detail.discount_value) > 0) {
          subtotal =
            parseFloat(item.detail.value) * parseInt(item.quantity) -
            parseFloat(item.detail.discount_value) * parseInt(item.quantity) +
            subtotal;
        } else if (parseFloat(item.detail.value) > 0) {
          subtotal =
            parseFloat(item.detail.value) * parseInt(item.quantity) + subtotal;
        }
      }
    }
    return subtotal;
  }

  function subtotalFrete() {
    let subtotal = 0;
    if (formValues && formValues.actions) {
      for (let item of formValues.actions) {
        if (item.location && item.location.deliveryLocation) {
          const deliveryLocationPrice = item.action.delivery_locations.filter(
            (deliveryLocation) =>
              parseInt(deliveryLocation.id) ===
              parseInt(item.location.deliveryLocation)
          )[0].price;

          subtotal = subtotal + parseFloat(deliveryLocationPrice);
        }
      }
    }
    return subtotal;
  }
  function hasCompleteValue() {
    if (auth.points < subtotalProdutos() + subtotalFrete()) {
      return true;
    }

    return false;
  }

  // console.log(formValues.payment.type);

  const shooppingInfo = useMemo(() => {
    function subtotalProdutosMemo() {
      let subtotal = 0;
      if (formValues && formValues.products) {
        for (let item of formValues.products) {
          if (parseFloat(item.detail.discount_value) > 0) {
            subtotal =
              parseFloat(item.detail.value) * parseInt(item.quantity) -
              parseFloat(item.detail.discount_value) * parseInt(item.quantity) +
              subtotal;
          } else if (parseFloat(item.detail.value) > 0) {
            subtotal =
              parseFloat(item.detail.value) * parseInt(item.quantity) +
              subtotal;
          }
        }
      }
      return subtotal;
    }

    function subtotalFreteMemo() {
      let subtotal = 0;
      if (formValues && formValues.actions) {
        for (let item of formValues.actions) {
          if (item.location && item.location.deliveryLocation) {
            const deliveryLocationPrice = item.action.delivery_locations.filter(
              (deliveryLocation) =>
                parseInt(deliveryLocation.id) ===
                parseInt(item.location.deliveryLocation)
            )[0].price;

            subtotal = subtotal + parseFloat(deliveryLocationPrice);
          }
        }
      }
      return subtotal;
    }
    function subtotalMemo() {
      return subtotalFreteMemo() + subtotalProdutosMemo();
    }

    return {
      difference: subtotalMemo() - parseFloat(auth.points),
      total: String2Currency(subtotalMemo()),
      currency: String2Currency(subtotalMemo() - parseFloat(auth.points)),
    };
  }, [auth.points, formValues]);

  function cleanup() {
    setStep((step) => step + 1);
  }

  function submit(values) {
    //Função para remover ações repetidas do array
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const actionsIds = values.products.map(
      (product) => product.detail.action.id
    );

    const actionsIdsNotRepeat = actionsIds.filter(onlyUnique);
    const cart = [];

    for (let action of actionsIdsNotRepeat) {
      const obj = {};
      const productOrders = values.products.filter(
        (item) => parseInt(item.detail.action.id) === parseInt(action)
      );
      const actionLocation = values.actions.filter(
        (item) => parseInt(item.action.id) === parseInt(action)
      )[0].location;
      for (let productOrder of productOrders) {
        obj[`product-${productOrder.detail.id}`] = productOrder.quantity;
      }
      obj[`action_id`] = action;
      if (!productOrders[0].detail.action.digital_product) {
        obj[`deliveryLocation`] = actionLocation.deliveryLocation;
        obj[`zipcode`] = actionLocation.zipcode;
        obj[`address`] = actionLocation.address;
        obj[`number`] = actionLocation.number;
      }
      cart.push(obj);
    }

    const submitBody = {
      user_id: auth.user.id,
      cart,
    };

    if (hasCompleteValue()) {
      submitBody['payment'] = {
        ...values.payment,
        save: values.payment.save || values.payment.selected_card !== 0,
      };
    }

    orderProducts(submitBody, history, cleanup, retryCallback);
  }

  function submitToFail(values) {
    //Função para remover ações repetidas do array
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const actionsIds = values.products.map(
      (product) => product.detail.action.id
    );

    const actionsIdsNotRepeat = actionsIds.filter(onlyUnique);
    const cart = [];

    for (let action of actionsIdsNotRepeat) {
      const obj = {};
      const productOrders = values.products.filter(
        (item) => parseInt(item.detail.action.id) === parseInt(action)
      );
      const actionLocation = values.actions.filter(
        (item) => parseInt(item.action.id) === parseInt(action)
      )[0].location;
      for (let productOrder of productOrders) {
        obj[`product-${productOrder.detail.id}`] = productOrder.quantity;
      }
      obj[`action_id`] = action;
      if (!productOrders[0].detail.action.digital_product) {
        obj[`deliveryLocation`] = actionLocation.deliveryLocation;
        obj[`zipcode`] = actionLocation.zipcode;
        obj[`address`] = actionLocation.address;
        obj[`number`] = actionLocation.number;
      }
      cart.push(obj);
    }

    const submitBody = {
      user_id: auth.user.id,
      cart,
      shouldFail: true,
    };

    if (hasCompleteValue()) {
      submitBody['payment'] = {
        ...values.payment,
        save: values.payment.save || values.payment.selected_card !== 0,
      };
    }

    orderProducts(submitBody, history, cleanup, retryCallback);
  }

  function cashback() {
    let cashback = 0;
    if (formValues && formValues.actions) {
      for (let item of formValues.actions) {
        if (parseFloat(item.action.points) > 0) {
          cashback = parseFloat(item.action.points) + cashback;
        }
        if (parseFloat(item.action.additional_cashback) > 0) {
          cashback = parseFloat(item.action.additional_cashback) + cashback;
        }
      }
    }
    return cashback.toFixed(2);
  }

  return (
    <>
      <Grid
        // item
        container
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
        style={{ minHeight: '100vh' }}
      >
        {/* MAIN CONTENT */}
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          // sm={10}
          // md={8}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
          >
            <GeneralTitle 
            xs={xs} 
            sm={sm} 
            marginBottom={xs ? '24px' : '48px'}
            style={{
              marginTop: '90px',
            }}
            
            
            >
              <Typography variant="h4">Confira seu pedido</Typography>
            </GeneralTitle>
            <Subtitle>
              <Typography variant="body1">
                Verifique os itens e as informações do seu pedido, para fazer o
                pagamento.
              </Typography>
            </Subtitle>
          </Grid>
          <Form
            role="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            className={classes.flexForm}
          >
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              // alignItems="center"
              xs={12}
              style={
                {
                  // backgroundColor: 'white'
                }
              }
            >
              <OrangeTitle>
                <Typography variant="h6">Produtos Selecionados</Typography>
              </OrangeTitle>
              <FieldArray
                name="actions"
                component={RenderProductsConfirmation}
                formValues={formValues}
              />
            </Grid>
            <Grid
              item
              container
              // justifyContent="center"
              // alignItems="center"
              xs={12}
            >
              <AdditionalInfo
                currencyData={currencyData}
                formValues={formValues}
                shooppingInfo={shooppingInfo}
                auth={auth}
                cashback={cashback()}
                timeLeft={timeLeft}
                creditCards={creditCards}
              />
            </Grid>
            <Grid
              item
              container
              justifyContent={sm || xs ? 'center' : 'space-between'}
              alignItems="center"
              xs={12}
              style={{
                padding: '1.7rem 1.5rem',
                borderRadius: sm || xs ? '10px' : '0 0 10px 10px',
                marginTop: '.6rem',
              }}
            >
              <Grid
                item
                container
                justifyContent={sm || xs ? 'center' : 'flex-start'}
                alignItems="flex-start"
                xs={12}
                md={7}
                style={{
                  marginBottom: sm || xs ? '.5rem' : null,
                  height: sm || xs ? '60px' : '',
                }}
              >
                {sm || xs ? (
                  <Typography
                    gutterBottom={true}
                    component="p"
                    style={{ textAlign: 'start', maxWidth: '80%' }}
                  >
                    Confirme seu pedido e aguarde
                  </Typography>
                ) : (
                  <Typography
                    gutterBottom={true}
                    component="p"
                    style={{ textAlign: 'start', maxWidth: '80%' }}
                  >
                    Agora é só efetuar seu pedido e aguardar a confirmação do pagamento.

                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                justifyContent={sm || xs ? 'center' : 'flex-end'}
                alignItems="center"
                spacing={1}
                xs={12}
                md={5}
              >

                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={8}
                  style={{ marginTop: sm || xs ? '.6rem' : '' }}
                >
                  <FullButton
                    classNameProp="py-3 px-4"
                    progress={stepLoad}
                    disabled={stepLoad}
                    type="submit"
                  >
                    {formValues.payment?.type === 'pix'
                      ? 'Gerar código para pagamento'
                      : 'Efetuar pedido'}
                  </FullButton>
                  {process.env.REACT_APP_API_KEY !== 'production' && (
                    <FullButton
                      classNameProp="py-3 px-4"
                      progress={stepLoad}
                      disabled={stepLoad}
                      type="button"
                      onClick={() => submitToFail(formValues)}
                    >
                      Comprar com falha
                    </FullButton>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={4}
                  style={{ marginTop: sm || xs ? '.6rem' : null }}
                >
                  <FullButton
                    classNameProp="py-3 px-4"
                    type="button"
                    onClick={handleBackwards}
                    isSecondary
                  >
                    Voltar
                  </FullButton>
                </Grid>


              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Dialog
          open={Boolean(open && errorMessage)}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              'Não foi possível completar sua transação. Por favor, tente novamente.'
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ padding: '16px 24px' }}
            >
              Motivo: {errorMessage}. Deseja tentar outra forma de pagamento?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => setStep(2)} color="primary" autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

Cart = reduxForm({
  form: 'userShoppingCartForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Cart);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities.detail,
    formValues: getFormValues('userShoppingCartForm')(state),
    userShoppingCart: state.userShoppingCart,
    creditCards: state.creditCards.list.content,
    stationData: state.website.stationData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      productOrder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
