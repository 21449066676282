/* eslint-disable react-hooks/exhaustive-deps */
import CallToActionCard from 'common/components/cards/callToActionCard';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FeedbackModal from '../../../../common/components/modal/feedbackModal';
import {
  getActionById,
  getLottieById,
  setDetail,
  setFeedbackModal,
  setSubscribed,
  submitEmails,
  subscribe,
  validateShare,
} from './activitiesActions';
import DOMPurify from 'dompurify';
import ReactPlayer from 'react-player';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DomPurifyContentContainer } from './styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  },
}));

function ActivityShare(props) {
  const classes = useStyles();

  useEffect(() => {
    props.getLottieById(props.activities.detail.feedback_lottie_id);
  }, []);

  return (
    <>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <Typography variant="body1">
            <DomPurifyContentContainer
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activities.detail.description),
              }}
            />
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12}>
          {props.activities.detail.action_videos.length > 0 ? (
            <Grid item container xs={12} justyfy="center" alignItems="center">
              <ReactPlayer
                width={'100%'}
                height={isMobile ? '17rem' : '30rem'}
                controls
                className={classes.reactPlayer}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12}>
          <CallToActionCard
            public={props.public || false}
            website={props.website}
            auth={props.auth}
            activity={props.activities.detail}
            onClick={
              props.callToActionLink
                ? () => props.history.push(props.callToActionLink)
                : () => props.setFeedbackModal(true)
            }
            history={props.history}
          />
        </Grid>
      </Grid>
      {props.activities.feedbackModal ? (
        <FeedbackModal
          visible={props.activities.feedbackModal}
          onCloseRoute="/homepage"
          history={props.history}
          setFeedbackModal={props.setFeedbackModal}
          firstText={'Página Inicial'}
          activity={props.activities.detail}
          firstActionType="route"
          firstRoute="/homepage"
          lottie={props.activities.lottieDetail}
          cleanupFunction={() => props.getUserPoints(props.auth.user)}
        />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      subscribe,
      setSubscribed,
      submitEmails,
      validateShare,
      setFeedbackModal,
      getLottieById,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityShare);
