import { Box, Grid } from '@material-ui/core';
import React from 'react';
import TextCard from '../../../../../common/components/cards/textCard';

export default function Legal() {
  return (
    <section id="legal">
      <p className="body-title mt-4">Consultoria jurídica</p>
      <p className="h4 mt-4" style={{ color: '#838383', maxWidth: 500 }}>
        Os serviços da Consultoria Jurídica do Sindilegis auxiliam e representam
        nossos filiados em ações judiciais individuais e coletivas em diversas
        áreas do direito. Clique nas abas abaixo e veja os serviços oferecidos e
        como utilizá-los.
      </p>

      <Grid container spacing={3} style={{ marginTop: 64 }}>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-juridica/orientacoes">
              Como usar
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-juridica/processos">
              Processos e procedimentos
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-juridica/agendamentos">
              Agendamento de consultas
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="/#homepage/consultoria-juridica/contato">
              Equipe e contato
            </TextCard>
          </Box>
        </Grid>
        {/* <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-juridica/informacoes">
              Painel de informações
            </TextCard>
          </Box>
        </Grid> */}
        <Grid item xs={3}>
          <TextCard href="#/homepage/consultoria-juridica/noticias">
            Notícias
          </TextCard>
        </Grid>
        <Grid item xs={3}>
          <TextCard href="#/homepage/consultoria-juridica/videos">
            Vídeos
          </TextCard>
        </Grid>
        {/* <Grid item xs={3}>
          <TextCard href="#/homepage/consultoria-juridica/eventos">
            Eventos
          </TextCard>
        </Grid> */}
      </Grid>
    </section>
  );
}
