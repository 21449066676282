import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Items from './creditCards';
// import CreditCardForm from './creditCardForm';
import BuyCredits from './buyCredits';

export default (
  <Route path="/homepage/meios-de-pagamento">
    <Switch>
      <Route exact path="/homepage/meios-de-pagamento" component={Items} />
      <Route
        path="/homepage/meios-de-pagamento/comprar"
        component={BuyCredits}
      />
    </Switch>
  </Route>
);
