import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Option, TextImage, CheckBox } from './styles';

export default function Options({
  background,
  text,
  textColor,
  image,
  oneOnly,
  multipleQuestions,
  questionId,
  checkedOptions,
  input: { onChange },
}) {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);

  function AllCheckedAreFalse(checkedOptions) {
    if (!multipleQuestions) {
      if (checkedOptions) {
        for (let option in checkedOptions) {
          if (checkedOptions[option].checked) {
            return false;
          }
        }
        return true;
      }
    } else {
      if (checkedOptions) {
        for (let option in checkedOptions) {
          if (
            checkedOptions[option].checked &&
            option.split('-')[2] === questionId
          ) {
            return false;
          }
        }
        return true;
      }
    }
  }

  const handleChange = () => {
    if (oneOnly) {
      if (multipleQuestions) {
        if (checkedOptions) {
          if (checked) {
            setChecked(!checked);
            onChange({
              checked: !checked,
            });
          } else if (AllCheckedAreFalse(checkedOptions)) {
            setChecked(!checked);
            onChange({
              checked: !checked,
            });
          } else {
            setError(true);
          }
        } else {
          setChecked(!checked);
          onChange({
            checked: !checked,
          });
        }
      } else {
        if (checkedOptions) {
          if (checked) {
            setChecked(!checked);
            onChange({
              checked: !checked,
            });
          } else if (AllCheckedAreFalse(checkedOptions)) {
            setChecked(!checked);
            onChange({
              checked: !checked,
            });
          } else {
            setError(true);
          }
        } else {
          setChecked(!checked);
          onChange({
            checked: !checked,
          });
        }
      }
    } else {
      setChecked(!checked);
      onChange({
        checked: !checked,
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 2000);
  }, [error]);

  return (
    <>
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        <Box>
          <Option
            onClick={handleChange}
            background={background}
            textColor={textColor}
            image={image}
            checked={checked}
          >
            {!image ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ width: '100%', height: '100%' }}
              >
                <p
                  readonly
                  disabled
                  spellcheck="false"
                  rows="2"
                  cols="50"
                  maxlength="40"
                  name="text"
                >
                  {text}
                </p>
              </Box>
            ) : null}
            {checked ? (
              <CheckBox style={{ fontSize: '4.5em', color: '#333' }} />
            ) : null}
          </Option>
        </Box>
        <Box>
          {image ? (
            <TextImage textColor={textColor === '#FFF' ? '#000' : textColor}>
              {text}
            </TextImage>
          ) : null}
          {error ? (
            <p style={{ color: 'red', alignSelf: 'center', margin: 5 }}>
              Apenas uma opção por pergunta
            </p>
          ) : null}
        </Box>
      </Box>
    </>
  );
}
