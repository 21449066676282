import React from 'react';
import { COMPANY_COLORS } from 'config/consts';

export const ButtonSecondary = (props) => {
  return (
    <button
      className={`py-3 px-5 station-font-medium ${props.className}`}
      style={{
        background: props.disabled
          ? '#666'
          : COMPANY_COLORS.general.secondaryButton.background,
        color: COMPANY_COLORS.general.secondaryButton.text,
        ...props.style,
      }}
      {...props}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};
