/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import Spinner from 'common/components/spinner/spinner';
import _ from 'lodash';
import React, { useEffect } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './creditCard.css';
import { getListByUserId, setLoading } from './creditCardsActions';

export function CreditCards(props) {
  useEffect(() => {
    props.getListByUserId(props.auth.user.id);
  }, []);

  if (props.creditCards.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );
  }

  if (_.isEmpty(props.creditCards.list.content)) {
    return (
      <section
        id="creditCards-list"
        style={{ backgroundColor: 'transparent', height: '100vh' }}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ minHeight: '92vh', backgroundColor: 'transparent' }}
        >
          <Typography align="center" variant="h6">
            Você ainda não possui meios de pagamento. Você poderá recarregar sua
            wallet a partir desses meios.
          </Typography>
          <FullButton
            width={100}
            onClick={() =>
              props.history.push('/homepage/meios-de-pagamento/criar')
            }
            classNameProp="py-3 px-2 mt-3"
            type="button"
          >
            ADICIONAR
          </FullButton>
        </div>
      </section>
    );
  }

  return (
    <>
      <section
        id="creditCards-list"
        style={{ backgroundColor: 'transparent', height: '100vh' }}
      >
        <div className="container-fluid px-4 pt-4">
          <div className="row justify-content-end align-items-center">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-2">
              <FullButton
                onClick={() =>
                  props.history.push('/homepage/meios-de-pagamento/criar')
                }
                classNameProp="py-3 px-4"
                type="button"
              >
                Adicionar
              </FullButton>
            </div>
          </div>
          <div className="container-fluid my-4">
            <div className="row d-flex justify-content-start align-items-center">
              {props.creditCards.list.content.map((item, index) => {
                return (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                    <Cards
                      name={`*******`}
                      number={`************s${item.last_digits}`}
                      expiry={'****'}
                      issuer={item.issuing}
                      preview={true}
                      style={{ marginTop: 20 }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    creditCards: state.creditCards,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByUserId,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCards);
