import { Typography } from '@material-ui/core';
import { ViewCarouselOutlined } from '@material-ui/icons';
import LocalGasStation from '@material-ui/icons/LocalGasStation';
import OwnerNavigation from 'common/components/navigation/ownerNavigation/ownerNavigation';
import ResponsiveDrawer from 'common/components/navigation/responsiveDrawer';
import BarChart from 'common/components/svg/bar_chart';
import CalendarSVG from 'common/components/svg/calendar';
import FileSVG from 'common/components/svg/file';
import FormatList from 'common/components/svg/format_list';
import GiftCardSvg from 'common/components/svg/giftCard';
import {
  BRB_FLA_STATION_ID,
  COMPANY_COLORS,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  COMPANY_SIGNED_IN_BACKGROUND_IMAGE,
  COMPANY_STATION_ID,
  GENERAL_TEXT_COLOR,
  IS_CAIXA,
} from 'config/consts';
import React, { Component } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { FontIcon } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BASE_S3, COMPANY_INFO } from '../../../config/consts';
import { customCurrencyChanger } from '../../../helpers/utils';
import { getStationData } from '../../website/websiteActions';
import { getList as getCategoriesList } from '../pages/categories/categoriesActions';
import { getUserPoints } from '../pages/homepage/homepageActions';
import AccountIcon from './icons/AccountIcon';
import HomeIcon from './icons/HomeIcon';
import SectionsIcon from './icons/SectionsIcon';
import ShoppingCartIcon from './icons/ShoppingCartIcon';
import SupportIcon from './icons/SupportIcon';
import TimelineIcon from './icons/TimelineIcon';

const formatCNPJ = (string) => {
  return (
    string.substring(0, 2) +
    '.' +
    string.substring(2, 5) +
    '.' +
    string.substring(5, 8) +
    '/' +
    string.substring(8, 12) +
    '-' +
    string.substring(12, 14)
  );
};
const id =
  process.env.REACT_APP_API_KEY === 'test'
    ? 2
    : process.env.REACT_APP_API_KEY === 'production'
    ? 26
    : 2;

function favicon(href) {
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = `https://s3.amazonaws.com/fermento.app.files/${href}`;
  document.getElementsByTagName('head')[0].appendChild(link);
}

const navItems = [
  {
    key: 'home',
    primaryText: 'Início',
    icon: <HomeIcon />,
    active: true,
    route: '/homepage',
    id: 1,
  },

  {
    key: 'support',
    primaryText: 'Fale conosco',
    icon: <SupportIcon />,
    iconType: `materialIcon`,
    active: true,
    route: '/homepage/support',
    id: 13,
  },
];

class User extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;
    this.props.getUserPoints(auth.user);
    this.props.getStationData(COMPANY_STATION_ID);
    props.getCategoriesList(COMPANY_STATION_ID, this.props.auth.user.id);

    if (this.props.website.stationData.plans?.length > 0) {
      const item = navItems.find((item) => item.key === 'plans');
      if (!item)
        navItems.push({
          key: 'plans',
          primaryText: 'Planos',
          icon: <ViewCarouselOutlined />,
          active: true,
          route: '/homepage/planos',
          id: 7,
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.website.stationData.plans?.length !==
      this.props.website.stationData.plans?.length
    ) {
      if (this.props.website.stationData.plans?.length > 0) {
        const item = navItems.find((item) => item.key === 'plans');
        if (!item) {
          navItems.push({
            key: 'plans',
            primaryText: 'Planos',
            icon: <ViewCarouselOutlined />,
            active: true,
            route: '/homepage/planos',
            id: 7,
          });
        }
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.website.stationData.favicon_image) {
      favicon(props.website.stationData.favicon_image);
    }
  }

  renderPoints = () => {
    const currencyData = {
      currency_multiplier: this.props.website.stationData.currency_multiplier,
      currency_name: this.props.website.stationData.currency_name,
      currency_name_position:
        this.props.website.stationData.currency_name_position,
    };
    if (isMobileOnly) {
      if (this.props.auth.loadingPoints) {
        return 'Carregando...';
      }
      return parseFloat(this.props.auth.points).toFixed(2).replace('.', ',');
    }
    if (this.props.auth.loadingPoints) {
      return (
        <Typography
          style={{
            color: `${COMPANY_COLORS.homepage.navbar.title}`,
            fontSize: '1rem',
          }}
          className={'station-font-regular'}
          variant="body1"
        >
          Carregando...
        </Typography>
      );
    } else {
      if (parseFloat(this.props.auth.points) > 0) {
        return (
          <Typography
            variant="body1"
            style={{
              fontSize: '1rem',
              color: GENERAL_TEXT_COLOR,
            }}
            className={'station-font-regular'}
          >
            Saldo:{' '}
            {/* R${' '}
            {parseFloat(this.props.auth.points).toFixed(2).replace('.', ',')} */}
            {customCurrencyChanger(
              currencyData,
              parseFloat(this.props.auth.points)
            )}
          </Typography>
        );
      } else {
        return;
      }
    }
  };

  componentDidMount() {
    const {
      feed_access,
      events_access,
      files_access,
      checkout_access,
      my_account_access,
    } = this.props.auth.user.station;

    function renderDynamicNav() {
      if (my_account_access) {
        const childitems = [
          {
            key: 'vouchers',
            primaryText: 'Meus pedidos',
            icon: <GiftCardSvg />,
            active: true,
            route: '/homepage/pedidos',
            id: 8,
          },
        ];
        if (!IS_CAIXA) {
          childitems.push([
            {
              key: 'trophy',
              primaryText: 'Meus troféus',
              icon: <FormatList />,
              active: true,
              route: '/homepage/trofeus',
              id: 10,
            },
            {
              key: 'extract',
              primaryText: 'Extratos',
              icon: <BarChart />,
              active: true,
              iconType: `materialIcon`,
              id: 11,
              route: '/homepage/extrato/extratos-totais',
            },
            {
              key: 'creditCards',
              primaryText: 'Meus convites',
              icon: <FormatList />,
              active: true,
              route: '/homepage/convites-do-usuario',
              id: 12,
            },
          ]);
        }
        if (COMPANY_STATION_ID === parseInt(id)) {
          navItems.splice(2, 0, {
            key: 'vouchers',
            primaryText: 'Minha conta',
            icon: <AccountIcon />,
            active: true,
            id: 6,
            children: childitems,
          });

          navItems.splice(3, 0, {
            key: 'fuels',
            primaryText: 'Abastecimento',
            icon: <LocalGasStation />,
            iconType: `materialIcon`,
            active: true,
            route: '/homepage/legisclub-gasolina',
            id: navItems.length + 2,
          });
        } else {
          const hasVouchers = navItems.find((item) => item.key === 'vouchers');
          const childItems = [
            {
              key: 'vouchers',
              primaryText: 'Meus vouchers',
              icon: <GiftCardSvg />,
              active: true,
              route: '/homepage/vouchers',
              id: 7,
            },
          ];
          if (!IS_CAIXA) {
            childItems.push(
              {
                key: 'vouchers',
                primaryText: 'Meus pedidos',
                icon: <GiftCardSvg />,
                active: true,
                route: '/homepage/pedidos',
                id: 8,
              },
              {
                key: 'trophy',
                primaryText: 'Meus troféus',
                icon: <FormatList />,
                active: true,
                route: '/homepage/trofeus',
                id: 10,
              },
              {
                key: 'extract',
                primaryText: 'Meu extrato',
                icon: <BarChart />,
                active: true,
                iconType: `materialIcon`,
                id: 11,
                route: '/homepage/extrato/extratos-totais',
              },
              {
                key: 'creditCards',
                primaryText: 'Meus convites',
                icon: <FormatList />,
                active: true,
                route: '/homepage/convites-do-usuario',
                id: 12,
              }
            );
          }
          if (!hasVouchers) {
            navItems.splice(2, 0, {
              key: 'vouchers',
              primaryText: 'Minha conta',
              icon: <AccountIcon />,
              active: true,
              id: 6,
              children: childItems,
            });
          }
        }
        if (checkout_access) {
          const hasCart = navItems.find((item) => item.key === 'cart');

          if (!hasCart) {
            navItems.splice(2, 0, {
              key: 'cart',
              primaryText: 'Carrinho',
              icon: <ShoppingCartIcon />,
              iconType: `materialIcon`,
              active: true,
              route: '/homepage/carrinho',
              id: 19,
            });
          }
        }
        if (files_access) {
          const hasDownloads = navItems.find(
            (item) => item.key === 'downloads'
          );

          if (!hasDownloads) {
            navItems.splice(2, 0, {
              key: 'downloads',
              primaryText: 'Arquivos',
              icon: <FileSVG />,
              active: true,
              route: '/homepage/downloads',
              id: 5,
            });
          }
        }

        if (events_access) {
          const hasCalendar = navItems.find((item) => item.key === 'calendar');

          if (!hasCalendar) {
            navItems.splice(2, 0, {
              key: 'calendar',
              primaryText: 'Eventos',
              icon: <CalendarSVG />,
              active: true,
              route: '/homepage/calendario',
              id: 4,
            });
          }
        }

        if (feed_access) {
          const hasFeed = navItems.find((item) => item.key === 'feed');

          if (!hasFeed) {
            navItems.splice(2, 0, {
              key: 'feed',
              primaryText: 'Mural',
              icon: <TimelineIcon />,
              active: true,
              iconType: `materialIcon`,
              route: '/homepage/feed',
              id: 3,
            });
          }
        }
      }
    }

    /* [
      feed_access,
      events_access,
      files_access,
      checkout_access,
      my_account_access,
    ]
      .filter((item) => item === true)
      .map((_, index) => RenderAccess(index + 1)); */
    renderDynamicNav();
  }

  renderCategories = () => {
    if (!this.props.categories.simpleListLoading) {
      if (this.props.categories.listCategories.content.length > 0) {
        return {
          key: 'categories',
          primaryText: 'Categorias',
          icon: <SectionsIcon />,
          active: true,
          id: 20,
          children: this.props.categories.listCategories.content.map(
            (item, index) => {
              return {
                key: 'category',
                primaryText: item.name,
                active: true,
                icon: (
                  <FontIcon
                    style={{ color: COMPANY_COLORS.general.general_color }}
                  >
                    {item.icon}
                  </FontIcon>
                ),
                route: '/homepage/categoria/' + item.id,
                id: 20 + index,
              };
            }
          ),
        };
      } else {
        return {
          key: 'category',
          primaryText: 'Atualizar pagina',
          active: true,
          route: '/',
          id: 20,
        };
      }
    } else {
      return {
        key: 'category',
        primaryText: 'Carregando categorias...',
        active: true,
        route: '/',
        id: 20,
      };
    }
  };

  renderFooter = () => {
    const { footer } = COMPANY_COLORS.website;

    return (
      <div
        className="row py-3 py-md-5 px-3 px-md-5 justify-content-center"
        style={{
          background:
            this.props.website.stationData.main_brand_color ||
            footer.background,
        }}
      >
        <div className="col-12 col-md-10">
          <div
            className={`row ${
              isMobileOnly
                ? 'justify-content-center'
                : 'justify-content-between'
            }`}
          >
            <div
              className="d-flex col-6 col-sm-4 col-md-2 col-lg-2 align-self-center mb-3 mt-1 mb-sm-0"
              style={{
                textAlign: isMobileOnly ? 'center' : 'initial',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <img
                style={{
                  maxWidth: '90%',
                  height: 'auto',
                  maxHeight: 100,
                }}
                src={`${BASE_S3}/${footer.company_logo}`}
                alt="LOGO-EMPRESA"
              />
            </div>

            <div className={`col-12 ${isTablet ? 'col-md-8' : ''} col-sm-8`}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: !isMobileOnly ? 'space-between' : 'center',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  className={`d-flex flex-column ${
                    isMobileOnly ? 'align-items-center' : ''
                  }`}
                >
                  <div
                    className={`d-flex ${
                      !isMobileOnly ? '' : 'justify-content-center'
                    }  align-items-center`}
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        color: footer.text,
                        overflowWrap: 'break-word',
                        overflow: 'auto',
                      }}
                      className={`station-footer-info ${
                        !isMobileOnly ? 'ml-3 mb-3' : 'mb-3'
                      } `}
                    >
                      Política de privacidade
                    </span>
                  </div>
                  <div className="d-flex">
                    <span
                      style={{
                        cursor: 'pointer',
                        color: footer.text,
                        overflowWrap: 'break-word',
                        overflow: 'auto',
                      }}
                      className={`station-footer-info ${
                        !isMobileOnly ? 'ml-3 mb-3' : 'mb-3'
                      } `}
                    >
                      Termos de uso
                    </span>
                  </div>

                  <div className="d-flex">
                    <p
                      style={{
                        color: footer.text,
                        overflowWrap: 'break-word',
                        overflow: 'auto',
                      }}
                      className="ml-3"
                    >
                      {this.props.website.stationData
                        ? this.props.website.stationData.establishment
                          ? `Cnpj: ${formatCNPJ(
                              this.props.website.stationData.establishment.cnpj
                            )}`
                          : null
                        : null}
                    </p>
                  </div>
                </div>
                <div
                  className={`d-flex flex-column ${
                    isMobileOnly ? 'align-items-center' : ''
                  }`}
                >
                  <div
                    className={`d-flex ${
                      !isMobileOnly ? '' : 'justify-content-center'
                    }  align-items-center`}
                  >
                    <span
                      onClick={() => this.props.history.push('/suporte')}
                      style={{
                        cursor: 'pointer',
                        color: footer.text,
                        overflowWrap: 'break-word',
                        overflow: 'auto',
                      }}
                      className={`station-footer-info ${
                        !isMobileOnly ? 'ml-3 mb-3' : 'mb-3'
                      } `}
                    >
                      Fale Conosco
                    </span>
                  </div>
                  <div className="d-flex">
                    <p
                      style={{
                        color: footer.text,
                        overflowWrap: 'break-word',
                        overflow: 'auto',
                      }}
                      className="station-footer-info ml-3"
                    >
                      {COMPANY_INFO.address}
                    </p>
                  </div>

                  <div className="d-flex">
                    <p
                      style={{
                        color: footer.text,
                        overflowWrap: 'break-word',
                        overflow: 'auto',
                      }}
                      className="ml-3"
                    >
                      {COMPANY_INFO.mail}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (isMobileOnly) {
      return (
        <ResponsiveDrawer
          navButton={COMPANY_COLORS.homepage.navbar.button}
          background={COMPANY_COLORS.homepage.navbar.background}
          title={this.renderPoints()}
          routes={this.props.routes}
          navItems={[...navItems, this.renderCategories()]}
          user={this.props.auth.user}
          history={this.props.history}
        >
          {this.props.children}
        </ResponsiveDrawer>
      );
    }

    return (
      <div
        style={{
          backgroundImage:
            COMPANY_SIGNED_IN_BACKGROUND_IMAGE &&
            this.props.location.pathname === '/homepage'
              ? `url(${BASE_S3}/${COMPANY_SIGNED_IN_BACKGROUND_IMAGE})`
              : 'none',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
          minHeight: '100vh',
        }}
      >
        <OwnerNavigation
          points={this.renderPoints(this)}
          navBarItems={
            COMPANY_STATION_ID === BRB_FLA_STATION_ID
              ? [
                  {
                    key: 'giveaways',
                    primaryText: 'Números da sorte',
                    icon: <SupportIcon />,
                    iconType: `materialIcon`,
                    active: true,
                    route: '/homepage/numeros-da-sorte',
                    id: 25,
                  },
                ]
              : [...navItems, this.renderCategories()]
          }
          history={this.props.history}
        />
        <div
          style={{
            backgroundColor: 'transparent',
            width: '100%',
            // minHeight: '110vh',
          }}
        >
          <div className="container" style={{ maxWidth: 1180 }}>
            <div className="col-12">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    categories: state.categories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getUserPoints, getStationData, getCategoriesList },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
