import categoriesReducer from '../user/pages/categories/categoriesReducer';
import websiteReducer from '../website/websiteReducer';
import generalReducer from './generalReducer';
import activitiesReducer from './pages/activities/activitiesReducer';
import journeyReducer from './pages/activities/journey/journeyReducer';
import nowPlayingReducer from './pages/activities/nowPlaying/nowPlayingReducer';
import emailSubscriptionReducer from './pages/emailSubscription/emailSubscriptionReducer';
import aboutReducers from './pages/homepage/about/aboutReducer';
import accountingReducer from './pages/homepage/accounting/accountingReducer';
import creditCardsReducers from './pages/homepage/creditCards/creditCardsReducers';
import deliveryReducer from './pages/homepage/delivery/deliveryReducer';
import dentalReducer from './pages/homepage/dental/dentalReducer';
import downloadsReducer from './pages/homepage/downloads/downloadsReducer';
import extractReducer from './pages/homepage/extracts/extractReducer';
import commentsReducer from './pages/homepage/feed/components/comment/commentsReducer';
import FeedReducer from './pages/homepage/feed/FeedReducer';
import giveawayNumbersReducer from './pages/homepage/giveawayNumbers/giveawayNumbersReducer';
import legalReducer from './pages/homepage/legal/legalReducer';
import newsReducer from './pages/homepage/news/newsReducer';
import plansReducer from './pages/homepage/plans/plansReducer';
import politicalReducer from './pages/homepage/political/politicalReducer';
import productOrdersReducer from './pages/homepage/productOrders/productOrdersReducer';
import schedulesReducer from './pages/homepage/schedules/schedulesReducer';
import couponsReducer from './pages/homepage/sections/coupons/couponsReducer';
import quizzesReducer from './pages/homepage/sections/earnPoints/quizzes/quizzesReducer';
import surveysReducer from './pages/homepage/sections/surveys/surveysReducer.js';
import userDoesActionsReducer from './pages/homepage/sections/userDoesActions/userDoesActionsReducer';
import userInvitationsReducer from './pages/homepage/sections/userInvitations/userInvitationsReducer';
import trophiesReducer from './pages/homepage/trophies/trophiesReducer';
import userCouponsReducer from './pages/homepage/userCoupons/userCouponsReducer';
import vouchersReducers from './pages/homepage/vouchers/vouchersReducers';
import legisClubFuelsReducer from './pages/legisClubFuels/legisClubFuelsReducer';
import shoppingReducer from './pages/shopping/shoppingReducer';
import userShoppingCartReducer from './pages/userShoppingCart/userShoppingCartReducer';
import virtualCardReducer from './pages/homepage/virtualCard/virtualCardReducer';

export default {
  about: aboutReducers,
  accounting: accountingReducer,
  activities: activitiesReducer,
  categories: categoriesReducer,
  comments: commentsReducer,
  coupons: couponsReducer,
  creditCards: creditCardsReducers,
  deliveryOrders: deliveryReducer,
  dental: dentalReducer,
  downloads: downloadsReducer,
  emailSubscription: emailSubscriptionReducer,
  extract: extractReducer,
  feed: FeedReducer,
  general: generalReducer,
  giveawayNumbers: giveawayNumbersReducer,
  journey: journeyReducer,
  legal: legalReducer,
  legisClubFuels: legisClubFuelsReducer,
  news: newsReducer,
  nowPlaying: nowPlayingReducer,
  plans: plansReducer,
  productOrders: productOrdersReducer,
  political: politicalReducer,
  quizzes: quizzesReducer,
  schedules: schedulesReducer,
  shopping: shoppingReducer,
  surveys: surveysReducer,
  trophies: trophiesReducer,
  userCoupons: userCouponsReducer,
  userDoesActions: userDoesActionsReducer,
  userInvitation: userInvitationsReducer,
  userShoppingCart: userShoppingCartReducer,
  vouchers: vouchersReducers,
  website: websiteReducer,
  virtualCardUser: virtualCardReducer,
};
