import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRightOutlined } from '@material-ui/icons';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import Button from 'common/components/buttons/button';
import { SelectLabel } from 'common/components/fields/fields';
import PerfilForm from 'common/components/navigation/perfilForm';
import { format, parseISO } from 'date-fns';
import { getNameInitials } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialModal from '../../../../../common/components/modal/materialModal';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  COMPANY_STATION_ID,
  GENERAL_TEXT_COLOR,
  IS_CAIXA,
  SIGNED_IN_NAV_TEXT_COLOR,
} from '../../../../../config/consts';
import { appColors } from '../../../../../styles/color';
import { cancelSubscription } from '../plans/plansActions';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 180,
    height: 180,
    cursor: 'pointer',
  },
}));

function ProfileItemCard({ title, routeName, history }) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={3}
      py={5}
      alignItems="center"
      onClick={() => history.push(routeName)}
      style={{ cursor: 'pointer' }}
    >
      <Typography variant="subtitle1" style={{ fontSize: 20 }}>
        {title}
      </Typography>
      <ChevronRightOutlined style={{ color: SIGNED_IN_NAV_TEXT_COLOR }} />
    </Box>
  );
}

function Profile({ auth, history, cancelSubscription, plans, general }) {
  const [showProfile, setShowProfile] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState(false);
  const classes = useStyles();
  const { my_account_access } = auth.user.station;

  useEffect(() => {
    return () => {
      setIsCancelModalVisible(false);
    };
  }, []);

  const handleCloseProfile = () => {
    setShowProfile(false);
  };

  const handleOpenProfile = () => {
    setShowProfile(true);
  };

  const handleClickOpenCancelModal = () => {
    setIsCancelModalVisible(true);
  };

  const handleClickCloseCancelModal = () => {
    setIsCancelModalVisible(false);
  };

  function handleCancelSubscription() {
    function cleanup() {
      handleClickCloseCancelModal();
    }
    cancelSubscription({ cancelReason }, cleanup);
  }

  const {
    user: { subscription },
  } = auth;

  return (
    <section id="profile" className="mt-4">
      <Box display="flex" alignItems="center" flexDirection="column" mt={3}>
        <Box style={{ position: 'relative' }}>
          {auth.user.image ? (
            <Avatar
              alt="Foto de perfil"
              src={`${BASE_S3}/${auth.user.image}`}
              className={classes.avatar}
              onClick={() => handleOpenProfile()}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: BUTTON_BACKGROUND_COLOR,
                cursor: 'pointer',
                height: 180,
                width: 180,
                borderRadius: '50%',
                fontSize: 90,
                color: BUTTON_TEXT_COLOR,
                fontFamily: BUTTON_FONT_FAMILY,
              }}
              onClick={handleOpenProfile}
            >
              {getNameInitials(auth.user.name)}
            </Box>
          )}

          <Box
            style={{
              position: 'absolute',
              right: 14,
              bottom: 9,
              width: 36,
              height: 36,
              borderRadius: '50%',
              backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
              cursor: 'pointer',
            }}
            alignItems="center"
            justifyContent="center"
            display="flex"
            onClick={handleOpenProfile}
          >
            <EditIcon
              style={{
                width: 24,
                height: 'auto',
                color: GENERAL_TEXT_COLOR,
                backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              }}
            />
          </Box>
        </Box>

        <Typography variant="h4" className="mt-3">
          {auth.user.name.split(' ')[0]}
        </Typography>
        {auth.user.birthday && (
          <Typography variant="body1" className="h5 mt-2">
            {format(parseISO(auth.user.birthday), 'dd/MM/yyyy')} -{' '}
            {auth.user.addresses[0].state}
          </Typography>
        )}
      </Box>
      {subscription?.plan ? (
        <div
          className="card mt-5"
          style={{ border: `1px solid ${appColors.v2.gray}` }}
        >
          <div className="card-body">
            <p className="title">
              Plano{' '}
              <span className="title" style={{ color: appColors.v2.blue }}>
                {subscription.plan.name}
              </span>
            </p>
            <p className="h4" style={{ marginTop: 64 }}>
              Sua próxima cobrança será no dia{' '}
              {format(parseISO(subscription.current_period_end), 'dd/MM/yyyy')},
              no valor de R$
              {subscription.plan.amount.toFixed(2).replace('.', ',')}.
            </p>
            <Box
              display="flex"
              justifyContent="space-between"
              mt={8}
              alignItems="center"
            >
              <Box>
                <p className="h4" style={{ textTransform: 'capitalize' }}>
                  {subscription.card.brand} ****** ****
                  {subscription.card.last_digits}
                </p>
                <p className="h6">
                  Expira em {subscription.card.expiration_date[0]}
                  {subscription.card.expiration_date[1]}/
                  {subscription.card.expiration_date[2]}
                  {subscription.card.expiration_date[3]}
                </p>
              </Box>

              <Box display="flex">
                <Button
                  onClick={() =>
                    history.push('/homepage/planos/editar-pagamento')
                  }
                  label="Editar informações de pagamento"
                  type="secondary"
                  buttonStyle={{ marginRight: 16, padding: 16 }}
                  buttonType="button"
                />
                <Button
                  onClick={() => history.push(`/homepage/planos`)}
                  label="Mudar de plano"
                  buttonType="button"
                  buttonStyle={{ marginRight: 16, padding: 16 }}
                />
                <Button
                  onClick={handleClickOpenCancelModal}
                  label="Cancelar assinatura"
                  buttonType="button"
                  buttonStyle={{
                    backgroundColor: '#FF2F59',
                    padding: '16px',
                  }}
                />
              </Box>
            </Box>
          </div>
        </div>
      ) : null}
      {my_account_access && (
        <Box mt={4} style={{ backgroundColor: 'transparent' }}>
          {!IS_CAIXA && (
            <>
              <ProfileItemCard
                title="Carteirinha virtual"
                history={history}
                routeName="/homepage/carteirinha"
              />
              <ProfileItemCard
                title="Meu extrato"
                history={history}
                routeName="/homepage/extrato/extratos-totais"
              />
              <ProfileItemCard
                title="Meus pedidos"
                history={history}
                routeName="/homepage/pedidos"
              />
              <>
                {COMPANY_STATION_ID === 1 && (
                  <ProfileItemCard
                    title="Meus números da sorte"
                    history={history}
                    routeName="/homepage/numeros-da-sorte"
                  />
                )}
              </>
              <ProfileItemCard
                title="Meus troféus"
                history={history}
                routeName="/homepage/trofeus"
              />
              <ProfileItemCard
                title="Meus convites"
                history={history}
                routeName="/homepage/convites-do-usuario"
              />
            </>
          )}

          <ProfileItemCard
            title="Meus vouchers"
            history={history}
            routeName="/homepage/vouchers"
          />
        </Box>
      )}
      <MaterialModal
        open={showProfile}
        onClose={handleCloseProfile}
        PaperProps={isMobile ? { style: { overflowY: 'unset' } } : {}}
      >
        <PerfilForm />
      </MaterialModal>
      {subscription?.plan && (
        <Dialog
          open={isCancelModalVisible}
          onClose={handleClickCloseCancelModal}
          aria-labelledby="Cancelamento de plano"
          aria-describedby="Formulário para preencher motivo de cancelamento"
        >
          <DialogTitle
            id="Cancelamento de plano"
            style={{ paddingTop: 24 }}
          >{`Cancelamento do plano ${subscription.plan.name}`}</DialogTitle>

          <DialogContent>
            <Box style={{ padding: '0px 24px' }}>
              <DialogContentText id="alert-dialog-description">
                Para melhorar nossos serviços, gostaríamos de saber o motivo
                para o cancelamento de sua assinatura. Por favor, selecione a
                opção adequada.
              </DialogContentText>
              <SelectLabel
                component={SelectLabel}
                options={[
                  {
                    label: 'Prefiro não mencionar',
                    value: 'Prefiro não mencionar',
                  },
                  {
                    label: 'Não acho um bom custo-benefício',
                    value: 'Não acho um bom custo-benefício',
                  },
                  {
                    label: 'Não utilizo mais o produto',
                    value: 'Não utilizo mais o produto',
                  },
                  { label: 'Outro', value: 'Outro' },
                ]}
                onChange={(e) => console.log(e)}
                label="Escolha uma opção"
                input={{
                  onChange: (e) => setCancelReason(e.target.value),
                  value: cancelReason,
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={handleClickCloseCancelModal}
              type="secondary"
              label="Fechar"
              disabled={plans.subscriptionsLoading}
              progress={parseInt(general.submitProgress)}
            />
            <Button
              onClick={handleCancelSubscription}
              autoFocus
              label="Realizar cancelamento"
              buttonType="button"
              disabled={plans.subscriptionsLoading}
              progress={parseInt(general.submitProgress)}
            />
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plans: state.plans,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ cancelSubscription }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
