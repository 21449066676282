/* eslint-disable react-hooks/exhaustive-deps */
import Spinner from 'common/components/spinner/spinner';
import Table6 from 'common/components/table/table6';
import { tableSort } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserRequests } from './creditCardsActions';

function TransferRequests(props) {
  const [columns] = useState([
    {
      title: 'Data',
      field: 'createdAt',
      customSort: (a, b) => tableSort('datetime', 'createdAt', a, b),
    },
    {
      title: 'Valor',
      field: 'points',
      customSort: (a, b) => tableSort('money', 'points', a, b),
    },
    {
      title: 'Status',
      field: 'status.name',
    },
  ]);

  useEffect(() => {
    console.log(props.auth.user.id);
    props.getUserRequests(props.auth.user.id);
  }, []);

  if (props.creditCards.loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );

  return (
    <section
      id="extract"
      style={{ backgroundColor: 'transparent', minHeight: '100vh' }}
    >
      <div className="p-4">
        <Table6 columns={columns} data={props.creditCards.requests} />
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    creditCards: state.creditCards,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserRequests }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferRequests);
