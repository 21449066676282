import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { finishQuiz } from './quizActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incorrectAnswer: false,
      correctAnswer: false,
      showNextQuestionButton: false,
      endQuiz: false,
      currentQuestionIndex: 0,
      buttons: {},
      buttonClasses: {},
      correct: [],
      incorrect: [],
      filteredValue: 'all',
    };
  }

  finishQuiz = () => {
    const body = {
      cardid: this.props.auth.user.cpf,
      accountnumber: this.props.auth.user.cpf,
      points: this.props.points,
      establishment: this.props.auth.user.establishment_participant.id,
    };
    this.props.finishQuiz(body, this.props.history);
  };

  checkAnswer = (index, correctAnswer) => {
    const { correct, incorrect, currentQuestionIndex } = this.state;
    if (index === correctAnswer) {
      if (
        incorrect.indexOf(currentQuestionIndex) < 0 &&
        correct.indexOf(currentQuestionIndex) < 0
      ) {
        correct.push(currentQuestionIndex);
      }

      this.setState({
        correctAnswer: true,
        incorrectAnswer: false,
        showNextQuestionButton: true,
        correct: correct,
      });

      let disabledAll = {
        0: { disabled: true },
        1: { disabled: true },
        2: { disabled: true },
        3: { disabled: true },
      };

      this.setState((prevState) => {
        const buttons = Object.assign({}, prevState.buttons, {
          ...disabledAll,
          [index - 1]: {
            className: index === correctAnswer ? 'correct' : '',
          },
        });
        return { buttons };
      });
    } else {
      if (
        correct.indexOf(currentQuestionIndex) < 0 &&
        incorrect.indexOf(currentQuestionIndex) < 0
      ) {
        incorrect.push(currentQuestionIndex);
      }

      this.setState({
        incorrectAnswer: true,
        correctAnswer: false,
        incorrect: incorrect,
      });
      this.setState((prevState) => {
        const buttons = Object.assign({}, prevState.buttons, {
          [index - 1]: {
            disabled: !prevState.buttons[index - 1],
          },
        });
        return { buttons };
      });
    }
  };

  nextQuestion = (currentQuestionIndex) => {
    const { questions } = this.props;

    var initState = {
      incorrectAnswer: false,
      correctAnswer: false,
      showNextQuestionButton: false,
      buttons: {},
    };

    if (currentQuestionIndex + 1 === questions.length) {
      this.setState({
        ...initState,
        endQuiz: true,
      });
    } else {
      this.setState({
        ...initState,
        currentQuestionIndex: currentQuestionIndex + 1,
      });
    }
  };

  renderMessageforCorrectAnswer = (question) => {
    const defaultMessage = 'Você acertou! Clique em próximo para continuar';
    return question.messageForCorrectAnswer || defaultMessage;
  };

  renderMessageforIncorrectAnswer = (question) => {
    const defaultMessage = 'Você errou! Favor, tentar novamente.';
    return question.messageForIncorrectAnswer || defaultMessage;
  };

  renderExplanation = (question, isResultPage) => {
    const explanation = question.explanation;
    if (!explanation) {
      return null;
    }

    if (isResultPage) {
      return <div className="explaination">{explanation}</div>;
    }

    return (
      <div>
        <br />
        {explanation}
      </div>
    );
  };

  handleChange = (event) => {
    this.setState({ filteredValue: event.target.value });
  };

  renderQuizResultFilter = () => {
    return (
      <div className="quiz-result-filter">
        <select value={this.state.filteredValue} onChange={this.handleChange}>
          <option value="all">Todas</option>
          <option value="correct">As que acertei</option>
          <option value="incorrect">As que errei</option>
        </select>
      </div>
    );
  };

  renderQuizResultQuestions = () => {
    const { filteredValue } = this.state;
    let questions = this.props.questions;

    if (filteredValue !== 'all') {
      questions = questions.filter((question, index) => {
        return this.state[filteredValue].indexOf(index) !== -1;
      });
    }

    return questions.map((question, questionIdx) => {
      return (
        <div className="result-answer-wrapper" key={questionIdx + 1}>
          <h3>
            Q{questionIdx + 1}: {question.question}
          </h3>
          <div className="result-answer">
            {question.answers.map((answer, index) => {
              return (
                <div>
                  <button
                    disabled={true}
                    className={
                      'answerBtn btn' +
                      (index + 1 === question.correctAnswer ? ' correct' : '')
                    }
                  >
                    {question.questionType === 'text' && <span>{answer}</span>}
                    {question.questionType === 'photo' && (
                      <img alt="alt-img" src={answer} />
                    )}
                  </button>
                </div>
              );
            })}
          </div>
          {this.renderExplanation(question, true)}
        </div>
      );
    });
  };

  render() {
    const { questions } = this.props;
    let question = questions[this.state.currentQuestionIndex];
    return (
      <div className="questionWrapper">
        {!this.state.endQuiz && (
          <div className="questionWrapperBody">
            <div className="questionModal">
              {this.state.incorrectAnswer && (
                <div className="alert incorrect">
                  {this.renderMessageforIncorrectAnswer(question)}
                </div>
              )}
              {this.state.correctAnswer && (
                <div className="alert correct">
                  {this.renderMessageforCorrectAnswer(question)}
                  {this.renderExplanation(question, false)}
                </div>
              )}
            </div>
            <div>Question {this.state.currentQuestionIndex + 1}:</div>
            <h3>{question.question}</h3>
            {question.answers.map((answer, index) => {
              if (this.state.buttons[index] !== undefined) {
                return (
                  <button
                    key={index}
                    disabled={this.state.buttons[index].disabled || false}
                    className={`${this.state.buttons[index].className} answerBtn btn`}
                    onClick={() =>
                      this.checkAnswer(index + 1, question.correctAnswer)
                    }
                  >
                    {question.questionType === 'text' && <span>{answer}</span>}
                    {question.questionType === 'photo' && (
                      <img alt="img-alt" src={answer} />
                    )}
                  </button>
                );
              } else {
                return (
                  <button
                    key={index}
                    onClick={() =>
                      this.checkAnswer(index + 1, question.correctAnswer)
                    }
                    className="answerBtn btn"
                  >
                    {question.questionType === 'text' && answer}
                    {question.questionType === 'photo' && (
                      <img alt="img-alt" src={answer} />
                    )}
                  </button>
                );
              }
            })}
            {this.state.showNextQuestionButton && (
              <div>
                <button
                  onClick={() =>
                    this.nextQuestion(this.state.currentQuestionIndex)
                  }
                  className="nextQuestionBtn btn"
                >
                  Próxima
                </button>
              </div>
            )}
          </div>
        )}
        {this.state.endQuiz && (
          <div className="card-body">
            <h2>
              Você terminou o quiz!. Você acertou {this.state.correct.length} de{' '}
              {questions.length} questões. <br />
            </h2>
            {this.renderQuizResultFilter()}
            {this.renderQuizResultQuestions()}
            <button
              type="button"
              onClick={() => this.finishQuiz()}
              className="btn btn-primary"
            >
              Finalizar!
            </button>
          </div>
        )}
      </div>
    );
  }
}

Question.propTypes = {
  questions: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quizzes: state.quizzes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ finishQuiz }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
