import { Typography } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import {
  CaptchaInput,
  InputLabelOutlined,
  MaterialCheckbox,
  SelectLabel,
} from 'common/components/fields/fields';
import {
  BASE_S3,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
  LINK_FONT_FAMILY,
  LINK_TEXT_COLOR,
} from 'config/consts';
import { cpfMask, dateMask, phoneMask } from 'helpers/masks';
import states from 'helpers/states.json';
import { FORM_RULES } from 'helpers/validations';
import { getStationData } from 'modules/website/websiteActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { setLoading, signup } from '../authActions';
import './signupParticipant.css';

states.sort(function (a, b) {
  if (a.sigla < b.sigla) {
    return -1;
  }
  if (a.sigla > b.sigla) {
    return 1;
  }
  return 0;
});

const whiteSpace = (value) => value && value.trim();

class SignupParticipant extends Component {
  constructor(props) {
    super(props);
    props.setLoading(false);
  }

  componentDidMount() {
    if (!this.props.website.stationData.address) {
      this.props.getStationData(COMPANY_STATION_ID);
    }
  }

  onSubmit = (values) => {
    let newValues = {};
    if (this.props.match.params?.code_mgm) {
      newValues = {
        ...values,
        station: COMPANY_STATION_ID,

        member_get_member: {
          action_id: this.props.auth.mgm.memberGetMemberRule.action.id,
          inviter_id: this.props.match.params?.code_mgm
            .split('U')[1]
            .split('-E')[0],
        },
      };
    } else {
      newValues = {
        ...values,
        station: COMPANY_STATION_ID,
      };
    }
    newValues['birthday'] = values.birthday + ' 00:00:00';
    this.props.signup(newValues, this.props.history);
  };

  render() {
    const statesList = states.map((item, index) => ({
      value: item.sigla,
      label: item.nome,
    }));

    const { website } = COMPANY_COLORS;

    return (
      <section
        className="container-fluid"
        style={{
          background: `url(${BASE_S3}/${website.header.background_back_header})`,
          backgroundSize: 'cover',
          minHeight: '100vh',
        }}
      >
        <div
          className="row justify-content-center"
          style={{
            background: `url(${BASE_S3}/${website.header.background_auth_station})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '20% 0%',
            minHeight: '100vh',
          }}
        >
          <div className="col-md-6 col-lg-4 align-self-center py-5">
            <div style={{ background: '#ffffff', borderRadius: 4 }}>
              <div className="row justify-content-center">
                <div className="col-7 d-flex justify-content-center py-3">
                  <img
                    className
                    src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                    alt="Logo"
                    style={{ maxWidth: '100%', height: 'auto', maxHeight: 85 }}
                  />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="row justify-content-center my-2">
                    <div className="col-8">
                      <p
                        className="station-font-medium text-center"
                        style={{ color: COMPANY_COLORS.general.text }}
                      >
                        {this.props.match.params?.code_mgm
                          ? 'Cadastre-se agora e ganhe ' +
                            this.props.auth.mgm.memberGetMemberRule
                              .points_invited +
                            'pontos'
                          : 'Só falta um pouquinho'}
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <form
                        onSubmit={this.props.handleSubmit((v) =>
                          this.onSubmit(v)
                        )}
                        noValidate
                      >
                        <div className="row justify-content-center my-4">
                          <div className="col-10">
                            <Field
                              component={InputLabelOutlined}
                              type="tel"
                              label="CPF"
                              helperText="Digite seu CPF"
                              name="cpf"
                              validate={[FORM_RULES.required, FORM_RULES.cpf]}
                              {...cpfMask}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center my-4">
                          <div className="col-10">
                            <Field
                              component={InputLabelOutlined}
                              type="tel"
                              label="Data de Nascimento"
                              name="birthday"
                              validate={[FORM_RULES.required]}
                              {...dateMask}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center my-4">
                          <div className="col-10">
                            <Field
                              component={SelectLabel}
                              type="text"
                              label={`Estado de residência`}
                              name={`state`}
                              margin="normal"
                              options={statesList}
                              validate={[FORM_RULES.required]}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center my-4">
                          <div className="col-10">
                            <Field
                              component={InputLabelOutlined}
                              label="Primeiro Nome"
                              name="firstName"
                              normalize={whiteSpace}
                              validate={[FORM_RULES.required]}
                              helperText="Digite seu primeiro nome"
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center my-4">
                          <div className="col-10">
                            <Field
                              component={InputLabelOutlined}
                              label="Último Nome"
                              name="lastName"
                              normalize={whiteSpace}
                              validate={[FORM_RULES.required]}
                              helperText="Digite seu sobrenome"
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center my-4">
                          <div className="col-10">
                            <Field
                              component={InputLabelOutlined}
                              label="Telefone"
                              type="text"
                              name="phoneNumber"
                              validate={[FORM_RULES.required]}
                              {...phoneMask}
                            />
                          </div>
                        </div>
                        {process.env.REACT_APP_API_KEY === 'dev' ? null : (
                          <div mt={3}>
                            <div className="row justify-content-center my-3">
                              <div className="col-10">
                                <div className="d-flex justify-content-center">
                                  <Field
                                    component={CaptchaInput}
                                    inputlabelprops={{
                                      shrink: true,
                                    }}
                                    name="captcha"
                                    validate={[FORM_RULES.required]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row justify-content-center my-3">
                          <div className="col-10">
                            <Field
                              component={MaterialCheckbox}
                              name="checkbox"
                              label={
                                <Typography
                                  variant="body1"
                                  className="text-muted"
                                  style={{ color: COMPANY_COLORS.dark }}
                                >
                                  Li e aceito os{' '}
                                  <a
                                    href={`/#/termos-de-uso`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: LINK_TEXT_COLOR,
                                      fontFamily: LINK_FONT_FAMILY,
                                    }}
                                  >
                                    Termos de Uso
                                  </a>{' '}
                                  e{' '}
                                  <a
                                    href={`/#/politica-de-privacidade`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: LINK_TEXT_COLOR,
                                      fontFamily: LINK_FONT_FAMILY,
                                    }}
                                  >
                                    Política de Privacidade
                                  </a>{' '}
                                </Typography>
                              }
                              type="checkbox"
                              validate={[FORM_RULES.requiredCheckbox]}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center my-4 mb-4">
                          <div className="col-10">
                            <div className="d-flex justify-content-center">
                              <FullButton
                                classNameProp="py-3 px-4"
                                type="submit"
                                disabled={this.props.auth.loadingRegister}
                                progress={parseInt(
                                  this.props.general.submitProgress
                                )}
                              >
                                Enviar
                              </FullButton>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mb-3">
                <div className="col-10">
                  <div
                    style={{
                      background: 'lightgray',
                      height: 1,
                      width: '100%',
                      opacity: 0.5,
                    }}
                  ></div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 text-center">
                  <Typography variant="body1" style={{ fontSize: '0.875rem' }}>
                    Já tem sua conta?{' '}
                    <a href="#/login">
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontSize: '0.875rem',
                          display: 'inline-block',
                        }}
                      >
                        Entre por aqui
                      </Typography>
                    </a>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

SignupParticipant = reduxForm({ form: 'signupParticipantForm' })(
  SignupParticipant
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('signupParticipantForm')(state),
    website: state.website,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      getStationData,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupParticipant);
