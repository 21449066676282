/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import ActivitiesCard from 'common/components/cards/activitiesCard';
import Spinner from 'common/components/spinner/spinner';
import { BASE_S3 } from 'config/consts';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { COMPANY_STATION_ID } from '../../../../../config/consts';
import { getListByStationId, setLoading } from './downloadsActions';

export function Downloads(props) {
  useEffect(() => {
    props.getListByStationId(COMPANY_STATION_ID);
  }, []);

  function selectItem(item) {
    /* props.setLoading(true); */
    window.location.href = `${BASE_S3}/${item.file}`;
  }

  if (props.downloads.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );
  }

  if (_.isEmpty(props.downloads.list.content)) {
    return (
      <section id="downloads-list">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '92vh', backgroundColor: 'transparent' }}
        >
          <p>Não há arquivos para download</p>
        </div>
      </section>
    );
  }

  return (
    <section
      id="downloads-list"
      style={{ backgroundColor: 'transparent', height: '100vh' }}
    >
      <div className="container-fluid px-4 pt-4">
        <div className="row px-4 pb-3">
          <Typography variant="h4">
            Arquivos
          </Typography>
        </div>
        <div className="row">
          {props.downloads.list.content.map((item, index) => {
            return (
              <div
                className="col-xs-12 col-sm-6 col-md-4 mb-3"
                style={{ cursor: 'pointer' }}
                onClick={() => selectItem(item)}
              >
                <ActivitiesCard
                  haveImage={false}
                  icon={false}
                  style={{ width: '100%', height: '100%' }}
                  title={item.name}
                  text={item.description}
                  callToAction="Acessar Arquivo"
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    downloads: state.downloads,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByStationId,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);
