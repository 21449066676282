import {
  Box,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ReactComponent as CheckIcon } from 'assets/icons/vector-stroke.svg';
import classNames from 'classnames';
import Button from 'common/components/buttons/button';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  GENERAL_TEXT_COLOR,
  LINK_FONT_FAMILY,
  LINK_TEXT_COLOR,
} from 'config/consts';
import React from 'react';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR } from '../../../../../config/consts';

const useStyles = makeStyles({
  root: (props) => ({
    maxWidth: '100%',
    padding: '1rem',
    border: props.is_highlighted ? `2px solid ${props.color}` : '',
    borderRadius: 4,
    boxShadow: ' 0px 2.6px 9px rgba(0, 0, 0, 0.15)',
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR
  }),
  cardName: (props) => ({
    fontWeight: 700,
    fontSize: '32px',
  }),
  cardDescription: {
    maxWidth: '100%',
    fontSize: '18px',
    lineHeight: '22px',
    margin: '20px 0 25px 0',
    height: 70,
  },
  benefitsSection: {
    marginBottom: '5rem',
  },
  cardFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  allTexts: {
    textAlign: 'center',
  },
});

const PlansOptionsCard = (props) => {
  const {
    name,
    benefits,
    amount,
    onClick,
    is_highlighted,
    color = GENERAL_TEXT_COLOR,
    buttonLabel = 'Adquirir',
    buttonLabelStyle,
    days,
    description,
    onDetailClick,
    buttonDisabled,
    enterprisePlan,
  } = props;
  const slicedBenefits = benefits ? benefits.slice(0, 5) : [];
  const classes = useStyles(props);

  return (
    <>
      {is_highlighted && (
        <Box textAlign="center" style={{ marginTop: '-24px' }}>
          <span
            className="h5-bold"
            style={{
              backgroundColor: BUTTON_BACKGROUND_COLOR,
              padding: '4px 32px',
              color: BUTTON_TEXT_COLOR,
              fontFamily: BUTTON_FONT_FAMILY,
            }}
          >
            Mais popular
          </span>
        </Box>
      )}
      <Card className={classes.root}>
        <CardContent className={classes.cardFlex}>
          <Typography
            variant="h6"
            className={classNames(classes.cardName, classes.allTexts)}
          >
            {name}
          </Typography>
          <Typography
            variant="body1"
            className={classNames(classes.cardDescription, classes.allTexts)}
          >
            {description}
          </Typography>
          <Box
            height={250}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            overflow="hidden"
            className={classes.benefitsSection}
          >
            {slicedBenefits &&
              slicedBenefits
                .filter((item) => item)
                .map((item) => (
                  <Typography
                    variant="body1"
                    key={item.id}
                    className="text-left mt-3"
                  >
                    <CheckIcon
                      stroke={color}
                      fill={color}
                      style={{ marginRight: 8 }}
                    />
                    {item.benefit}
                  </Typography>
                ))}
            {benefits && benefits.length > 6 && (
              <Typography variant="body1" className="text-left mt-3">
                <CheckIcon
                  stroke={color}
                  fill={color}
                  style={{ marginRight: 8 }}
                />
                E muito mais!
              </Typography>
            )}
          </Box>
          {amount ? (
            <>
              <Typography
                variant="body1"
                className="text-center mt-5"
                style={{ fontSize: 48 }}
              >
                R$ {amount}
              </Typography>
              <Typography
                variant="body1"
                className="text-center"
                style={{ fontSize: 32 }}
              >
                /{days === 30 ? 'mês' : days === 183 ? 'semestre' : 'ano'}
              </Typography>
            </>
          ) : (
            <Typography variant="body1" className="text-center mt-5">
              A negociar
            </Typography>
          )}
        </CardContent>
        <CardActions className={classes.cardFlex}>
          <Button
            label={buttonLabel}
            onClick={onClick}
            buttonType="button"
            buttonStyle={{
              width: '70%',
              marginBottom: 5,
              backgroundColor: BUTTON_BACKGROUND_COLOR,
            }}
            labelStyle={{
              color: BUTTON_TEXT_COLOR,
              fontFamily: BUTTON_FONT_FAMILY,
            }}
            disabled={buttonDisabled}
          />
          {enterprisePlan ? (
            <Button
              disabled
              // label={'Mais detalhes'}
              buttonStyle={{
                margin: 0,
                width: '100%',
                backgroundColor: 'transparent',
              }}
              labelStyle={{
                color: LINK_TEXT_COLOR,
                fontFamily: LINK_FONT_FAMILY,
                ...buttonLabelStyle,
              }}
            />
          ) : (
            <Button
              label={'Mais detalhes'}
              onClick={onDetailClick}
              buttonType="button"
              buttonStyle={{
                margin: 0,
                width: '100%',
                backgroundColor: 'transparent',
              }}
              labelStyle={{
                color: LINK_TEXT_COLOR,
                fontFamily: LINK_FONT_FAMILY,
                ...buttonLabelStyle,
              }}
            />
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default PlansOptionsCard;
