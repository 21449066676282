import { USER_TOKEN, BASE_API } from '../../../../../config/consts';
import axios from 'axios';

const URL_USER_GET = `${BASE_API}/users`;

export const getUser = (id) => {
  return (dispatch) => {
    dispatch([{ type: 'USER_CARD_LOAD', payload: true }])
    axios
      .get(`${URL_USER_GET}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'USER_CARD_DONE',
            payload: response.data[0],
          },
        ]);
      })
      .catch(() => {
        dispatch({ type: 'USER_CARD_LOAD', payload: false });
      });
  };
};
