const INITIAL_STATE = {
  loading: false,
  loadingEarned: false,
  list: {
    content: [],
  },
  error: false,
  errors: [],
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USED_CREDITS_FETCHED':
      return { ...state, list: { content: action.payload }, loading: false };
    case 'USED_CREDITS_LOAD':
      return { ...state, loadingEarned: action.payload };
    case 'EARNED_CREDITS_FETCHED':
      return {
        ...state,
        list: { content: action.payload },
        loadingEarned: false,
      };
    case 'EARNED_CREDITS_LOAD':
      return { ...state, loading: action.payload };
    case 'PURCHASED_CREDITS_FETCHED':
      return { ...state, list: { content: action.payload }, loading: false };
    case 'PURCHASED_CREDITS_LOAD':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
