import { Typography } from '@material-ui/core';
import Calendar from 'common/components/calendar/calendar';
import Spinner from 'common/components/spinner/spinner';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { COMPANY_STATION_ID } from '../../../../../config/consts';
import { getListByStationId } from './schedulesActions';

export function Schedules({ schedules, history, getListByStationId }) {
  useEffect(() => {
    getListByStationId(COMPANY_STATION_ID);
  }, [getListByStationId]);

  if (schedules.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="container-fluid" style={{ minHeight: '120vh' }}>
      <Typography variant="h4">Calendário</Typography>
      <div className="d-flex justify-content-center pt-5">
        <Calendar schedules={schedules} weekends={true} history={history} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    schedules: state.schedules,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByStationId,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
