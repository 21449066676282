import React from 'react';
export default function Icon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.05306 22C2.88989 22.0087 2.72708 21.9772 2.57887 21.9084C2.43067 21.8396 2.30158 21.7355 2.20291 21.6053C2.10424 21.475 2.03898 21.3226 2.01286 21.1613C1.98674 21 2.00054 20.8347 2.05306 20.68L3.82306 15.37C3.30035 14.1475 3.03811 12.8295 3.05306 11.5C3.05832 8.98552 4.05816 6.57524 5.83429 4.79536C7.61043 3.01547 10.0186 2.01056 12.5331 2H13.0531C15.3927 2.11496 17.6069 3.09203 19.2687 4.74282C20.9306 6.39361 21.9225 8.60122 22.0531 10.94V11.5C22.0478 14.0179 21.0452 16.4312 19.2647 18.2117C17.4843 19.9921 15.071 20.9947 12.5531 21C11.2199 21.0004 9.9015 20.721 8.68306 20.18L3.37306 22C3.26688 22.0144 3.15924 22.0144 3.05306 22ZM12.5331 4C11.2567 4.00344 10.0024 4.33255 8.88881 4.95616C7.7752 5.57977 6.83917 6.47725 6.1693 7.56366C5.49943 8.65007 5.11789 9.88946 5.06081 11.1645C5.00372 12.4396 5.27296 13.7081 5.84306 14.85C5.90361 14.968 5.9401 15.0968 5.9504 15.229C5.9607 15.3612 5.94461 15.4941 5.90306 15.62L4.63306 19.42L8.43306 18.15C8.55897 18.1085 8.6919 18.0924 8.82409 18.1027C8.95628 18.113 9.0851 18.1495 9.20306 18.21C10.2366 18.748 11.388 19.0196 12.5531 19C14.5422 19 16.4498 18.2098 17.8564 16.8033C19.2629 15.3968 20.0531 13.4891 20.0531 11.5V11C19.9424 9.16942 19.1614 7.44374 17.8591 6.15251C16.5568 4.86128 14.8245 4.09499 12.9931 4H12.5331Z"
        fill="#282828"
      />
    </svg>
  );
}
