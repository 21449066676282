import styled from 'styled-components';

export const PublicActivityActionOption = styled.button`
  color: #5759fb;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 0;

  :focus {
    border: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const DomPurifyContentContainer = styled.div`
  width: 100%;
  line-height: 2rem;
  font-size: 1.15rem;
  text-align: none !important;
  margin: 0.3rem 0;

  > p {
    margin: 1.5rem 0;
  }

  > ul {
    padding-left: 3rem;
  }
`;

export const ActivityImageContainer = styled.div`
  background-image: ${(props) => `url(${props.BASE_S3}/${props.imagePath})`};
  width: 100%;
  max-width: 100%;
  min-height: 200;
  padding: 0 0 56.25% 0;
  border-radius: 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  margin: 1rem 0;
  border-radius: ${(props) => (props.xs ? null : '8px')};
`;

// REACT PLAYER CONTAINER HERE
// export const ReactPlayerContainer = styled.div`
//   width: 100%;
//   height: 400px;
//   margin: .5rem 0 1rem 0;
//   background-color: black;
// `;
