/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import FeedbackModal from 'common/components/modal/feedbackModal';
import DOMPurify from 'dompurify';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, getFormValues, reduxForm } from 'redux-form';
import {
  buyCoupon,
  donate,
  getActionById,
  getLottieById,
  setDetail,
  setFeedbackModal,
  setSubscribed,
} from './activitiesActions';
import { DomPurifyContentContainer } from './styles';

const useStyles = makeStyles(() => ({
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: 20,
  },
}));

function ActivityDonation(props) {
  const classes = useStyles();

  useEffect(() => {
    props.getLottieById(props.activities.detail.feedback_lottie_id);
  }, []);

  function onSubmit(formValues) {
    const values = {
      action_id: props.activities.detail.id,
      points: formValues.points,
    };
    props.donate(values, props.history, props.getUserPoints, props.auth.user);
  }

  return (
    <>
      <Grid item container justifyContent="center" alignItems="center" xs={12}>
        {/* DOM PURIFY CONTENT GRID */}
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <Typography variant="body1">
            <DomPurifyContentContainer
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activities.detail.description),
              }}
            />
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12}>
          {props.activities.detail.action_videos.length > 0 ? (
            <Grid
              item
              container
              xs={12}
              justyfy="center"
              alignItems="center"
              style={{ margin: '.5rem 0 1rem 0' }}
            >
              <ReactPlayer
                width={'100%'}
                height={isMobile ? '17rem' : '30rem'}
                controls
                className={classes.reactPlayer}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={4}
        >
          <Form
            onSubmit={props.handleSubmit(onSubmit)}
            noValidate
            className={classes.form}
          >
            <Grid item container justifyContent="center" alignItems="center" xs={12}>
              <Grid item container justifyContent="center" alignItems="center" xs={12}>
                <FullButton
                  classNameProp="py-3 px-4"
                  type={props.public ? 'button' : 'submit'}
                  onClick={() =>
                    (window.location.href =
                      props.activities.detail.redirect_links[0].url)
                  }
                >
                  {props.activities.detail.click_me_label}
                </FullButton>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      {props.activities.feedbackModal ? (
        <FeedbackModal
          visible={props.activities.feedbackModal}
          onCloseRoute="/homepage/minhas-compras"
          history={props.history}
          setFeedbackModal={props.setFeedbackModal}
          firstRoute="/homepage/minhas-compras"
          secondRoute="/homepage"
          firstText={'Ir para minhas compras'}
          secondText="Voltar para a página inicial"
          activity={props.activities.detail}
          firstActionType="route"
          lottie={props.activities.lottieDetail}
          cleanupFunction={() => props.getUserPoints(props.auth.user)}
          {...props}
        />
      ) : null}
    </>
  );
}

let DonationForm = reduxForm({ form: 'donationForm' })(ActivityDonation);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    formValues: getFormValues('donationForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      getUserPoints,
      donate,
      buyCoupon,
      setSubscribed,
      setFeedbackModal,
      getLottieById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DonationForm);
