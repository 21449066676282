import React, { useEffect, useState } from 'react';
import {
  Grid,
  Backdrop,
  Fade,
  Modal,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BASE_S3, COMPANY_COLORS } from '../../../config/consts';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: (props) => ({
    backgroundColor: props.footer.background,
    border: 'none',
    position: 'absolute',
    alignContent: 'center',
    maxWidth: props.xs ? '95%' : '541px',
    height: '462px',
    borderRadius: '14px',
  }),
  actionButton: (props) => ({
    // backgroundColor: props.navbar.button.background,
    color: props.navbar.button.text,
    margin: '.5rem 0',
    borderRadius: '6px',
    width: '205px',
    height: '48px',
  }),
  acceptTermsButton: (props) => ({
    backgroundColor: props.general.secondaryButton.background,
  }),
  footerLogo: {
    maxWidth: '90%',
    height: 'auto',
    maxHeight: 100,
  },
  modalDescription: (props) => ({
    margin: '1rem 0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: props.footer.text,
  }),
}));

function MajorityAgeTerm() {
  const [open, setOpen] = useState(true);
  const { navbar, footer } = COMPANY_COLORS.website;
  const { general } = COMPANY_COLORS;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ navbar, footer, xs, general });

  useEffect(() => {
    if (localStorage.getItem('majorityAgeTerm')) {
      setOpen(false);
    }
  }, []);

  function acceptTermButton() {
    localStorage.setItem('majorityAgeTerm', true);
    setOpen(false);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Grid
          className={classes.paper}
          container
          alignItems="center"
          justifyContent="center"
          spacing={3}
          xs={12}
        >
          <Grid item container alignItems="center" justifyContent="center" xs={12}>
            <img
              className={classes.footerLogo}
              src={`${BASE_S3}/${footer.company_logo}`}
              alt="LOGO-EMPRESA"
            />
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            xs={12}
            component="p"
            className={classes.modalDescription}
          >
            Você tem mais de 18 anos?
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            xs={12}
          >
            <button
              className={clsx(classes.actionButton, classes.acceptTermsButton)}
              onClick={() => acceptTermButton()}
            >
              Sim, quero continuar
            </button>
            <a href="https://www.google.com.br/">
              <button className={classes.actionButton}>
                Não! Tire-me daqui.
              </button>
            </a>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
}

export default MajorityAgeTerm;
