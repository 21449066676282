import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ProductDescription from 'common/components/dialog/dialog';
import { String2Currency } from 'helpers/dataTransformers';
import React, { useCallback, useMemo, useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { COMPANY_FONT_FAMILY } from 'config/consts';
import {
  DescriptionContainer,
  FinalPrice,
  ItemImage,
  Prices,
  Quantity,
  SeeMore,
  Title,
} from './styles';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '1rem',
    // height: isMobile ? '160px' : '150px',
  },
}));

let ItemCard = function ({ data, formValues, input: { onChange } }) {
  const [visible, setVisible] = useState(false);
  const [items, setItems] = useState(() => {
    for (let item in formValues) {
      if (parseInt(item.split('-')[1]) === parseInt(data.id)) {
        return formValues[item];
      }
    }
    return 0;
  });
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles();

  const showItems = useMemo(() => {
    if (formValues) {
      if (formValues[`product-${data.id}`])
        return formValues[`product-${data.id}`];
      return 0;
    } else {
      return 0;
    }
  }, [data.id, formValues]);

  const price = useMemo(() => {
    return String2Currency(data.value);
  }, [data.value]);

  const availableStock = useMemo(() => {
    return data.stock - data.single_product_orders.length;
  }, [data.single_product_orders.length, data.stock]);

  const discountPrice = useMemo(() => {
    return String2Currency(data.value - data.discount_value);
  }, [data.discount_value, data.value]);

  const finalPrice = useMemo(() => {
    return ((data.value - data.discount_value) * items)
      .toFixed(2)
      .replace('.', ',');
  }, [data.value, data.discount_value, items]);

  const onAdd = useCallback(() => {
    if (items < data.limit && items < availableStock) {
      onChange(items + 1);
      setItems((item) => item + 1);
    }
  }, [availableStock, data.limit, items, onChange]);

  const onSubtract = useCallback(() => {
    if (items > 0) {
      onChange(items - 1);
      setItems((item) => item - 1);
    }
  }, [items, onChange]);

  return (
    <Grid item container justifyContent="center" xs={12} className={classes.root}>
      <Grid
        item
        container
        justifyContent={xs || sm ? 'flex-start' : 'flex-start'}
        xs={12}
        md={8}
        style={{
          minHeight: '80px',
          padding: xs || sm ? '.8rem 0' : '0',
        }}
      >
        <Grid
          item
          container
          justifyContent={xs || sm ? 'flex-start' : 'flex-start'}
          alignItems="center"
          xs={6}
          md={3}
          style={{ marginRight: xs || sm ? '.4rem' : '.8rem' }}
        >
          <ItemImage image={data.image} onClick={() => setVisible(true)} />
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="flex-start"
          direction="column"
          xs={5}
          md={7}
        >
          <Grid item style={{ paddingLeft: '' }}>
            <Typography variant="body1">
              <Title onClick={() => setVisible(true)} isMobile={xs || sm}>
                {data.name}
              </Title>
            </Typography>
            <Typography variant="subtitle2">
              <SeeMore onClick={() => setVisible(true)}>Ver mais</SeeMore>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Prices>
                <span>{discountPrice}</span>
                {data.discount_value > 0 && (
                  <span style={{ fontFamily: COMPANY_FONT_FAMILY }}>{price}</span>
                )}
              </Prices>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems={xs || sm ? 'center' : 'flex-end'}
        xs={12}
        md={4}
        style={{
          padding: xs || sm ? '.8rem 0' : '0',
          minHeight: '100%',
        }}
      >
        <Grid
          item
          container
          justifyContent={xs || sm ? 'flex-start' : 'flex-end'}
          alignItems="center"
          xs={6}
          md={5}
        >
          <Quantity isMobile={xs || sm}>
            <FiMinus onClick={() => onSubtract()} size={14} />
            <p>{showItems}</p>
            <FiPlus onClick={() => onAdd()} size={14} />
          </Quantity>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems={xs || sm ? 'flex-end' : 'flex-end'}
          direction="column"
          xs={6}
          md={7}
          style={{
            minHeight: '100%',
          }}
        >
          <FinalPrice>
            <Typography variant="body1">R$ {finalPrice}</Typography>
          </FinalPrice>
        </Grid>
      </Grid>
      <ProductDescription
        visible={visible}
        onClose={() => setVisible(false)}
        maxWidth={'sm'}
        fullWidth
      >
        <ItemImage description image={data.image} isRounded={false} />
        <DescriptionContainer>
          <p>{data.name}</p>
          <Prices>
            <span>{discountPrice}</span>
            {data.discount_value > 0 && <span>{price}</span>}
          </Prices>
          <span>{data.description}</span>
        </DescriptionContainer>
      </ProductDescription>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('shoppingForm')(state),
  };
};

export default connect(mapStateToProps)(ItemCard);
