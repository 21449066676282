/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionById, rawContentView, setDetail } from './activitiesActions';
import DOMPurify from 'dompurify';
import ReactPlayer from 'react-player';
import { Grid, Typography } from '@material-ui/core';
import { DomPurifyContentContainer } from './styles';
import { makeStyles } from '@material-ui/styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  reactPlayer: {
    backgroundColor: 'black',
    cssFloat: 'left',
  },
}));

function ActivityRawContent(props) {
  const classes = useStyles();

  useEffect(() => {
    props.rawContentView({ action_id: props.activities.detail.id });
  }, []);

  return (
    <>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <Typography variant="body1">
            <DomPurifyContentContainer
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activities.detail.description),
              }}
            />
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center" alignItems="center">
          {props.activities.detail.action_videos.length > 0 ? (
            <Grid item container xs={12} justyfy="center" alignItems="center">
              <ReactPlayer
                width={'100%'}
                height={isMobile ? '17rem' : '30rem'}
                controls
                className={classes.reactPlayer}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      rawContentView,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityRawContent);
