import axios from 'axios';
import { destroy, initialize } from 'redux-form';
import type from './types';
import { toastr } from 'react-redux-toastr';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import { String2Currency } from 'helpers/dataTransformers';

const URL_CREDIT_CARDS = `${BASE_API}/user-credit-cards`;
const URL_BUY_POINTS = `${BASE_API}/user-buy-points-transaction`;
const URL_ADD_CARD = `${BASE_API}/create-user-credit-card`;
const URL_USER_ADDRESS = `${BASE_API}/get-address-zip-code`;
const URL_TRANSFER = `${BASE_API}/transfer-request`;

export const getListByUserId = (id) => {
  return (dispatch) => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL_CREDIT_CARDS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const setLoading = (value) => {
  return (dispatch) => {
    dispatch([{ type: type.LIST_LOAD, payload: value }]);
  };
};

export const createCreditCard = (values) => {
  return (dispatch) => {
    axios
      .post(`${URL_ADD_CARD}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        // toastr.success('Sucesso', 'Cartão salvo com sucesso');
      })
      .catch((e) => {
        try {
          if (e.response.data.errorValidate) {
            toastr.error('Erro', e.response.data.errorValidate);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const userBuyPoints = (values, history = undefined) => {
  return (dispatch) => {
    dispatch([
      { type: type.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(`${URL_BUY_POINTS}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
        history.push('/homepage/extrato/extratos-totais');
        window.location.reload();
      })
      .catch((e) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getUserAddress = (values, oldValues) => {
  return (dispatch) => {
    dispatch(
      initializeForm({ city: `Carregando...`, street: `Carregando...` })
    );

    axios
      .post(`${URL_USER_ADDRESS}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.ADDRESS_FETCHED,
            payload: response,
          },
          initializeForm({ ...oldValues, ...response.data }),
        ]);
      })
      .catch((e) => {
        dispatch(
          initializeForm({ city: `CEP inválido`, street: `CEP inválido` })
        );
      });
  };
};

export const initializeForm = (values) => {
  return initialize('creditCardsForm', values);
};

export const reset = () => {
  return destroy('creditCardsForm');
};

export const transferRequest = (values) => {
  return (dispatch) => {
    dispatch([{ type: type.BUTTON_LOAD, payload: true }]);
    axios
      .post(URL_TRANSFER, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([{ type: type.BUTTON_LOAD, payload: false }, reset()]);
      })
      .catch((e) => {
        dispatch([{ type: type.BUTTON_LOAD, payload: false }]);
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getUserRequests = (id) => {
  return (dispatch) => {
    dispatch([{ type: type.REQUESTS_LOAD, payload: true }]);
    axios
      .get(`${URL_TRANSFER}/?where[user_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        response.data.forEach((item) => {
          item['points'] = String2Currency(item['points']);
          item['createdAt'] = moment(item['createdAt']).format('DD/MM/YYYY');
        });
        dispatch([{ type: type.REQUESTS_FETCHED, payload: response.data }]);
      })
      .catch((e) => {
        dispatch([{ type: type.REQUESTS_LOAD, payload: false }]);
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
