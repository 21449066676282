import { Grid, makeStyles, Typography } from '@material-ui/core';
import DOMPurify from 'dompurify';
import React from 'react';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { COMPANY_COLORS } from '../../../../../../config/consts';
import { DomPurifyContentContainer } from '../../../activities/styles';

const useStyles = makeStyles(() => ({
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  },
  activityTitle: {
    fontSize: isMobile ? '1.5rem' : '2.5rem',
    fontWeight: 700,
    lineHeight: isMobile ? '1.7rem' : '2.5rem',
    margin: '0 0 .5rem 0',
    color: COMPANY_COLORS.font.gray,
  },
}));

function ActivityAbstract({ activity, ...props }) {
  const classes = useStyles();

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12}>
      <Grid item container justifyContent="center" xs={12}>
        <Typography variant="body1">
          <DomPurifyContentContainer
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(activity.description),
            }}
          />
        </Typography>
      </Grid>
      <Grid item container justifyContent="center" alignItems="center" xs={12} md={10}>
        {activity.action_videos.length > 0 ? (
          <Grid item container xs={12} justyfy="center" alignItems="center">
            <ReactPlayer
              width={'100%'}
              height={'400px'}
              config={{
                file: { attributes: { controlsList: 'nodownload' } },
              }}
              controls
              className={classes.reactPlayer}
              key={props.activities.detail.action_videos[0].video.url}
              url={props.activities.detail.action_videos[0].video.url}
            />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    activity: state.activities.detail,
  };
};

export default connect(mapStateToProps)(ActivityAbstract);
