import { FullButton } from 'common/components/buttons/fullButton';
import { SelectLabel } from 'common/components/fields/fields';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { Field } from 'redux-form';
import { Container } from './styles';

export function CampaignListFuel({ list, disabled }) {
  const campaignList = list.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  return (
    <>
      <Container>
        <h1>{list[0].campanha.nomeCampanha}</h1>
        <span
          dangerouslySetInnerHTML={{ __html: list[0].campanha.descricao }}
        ></span>
      </Container>
      <div className="mt-5" style={{ width: 319 }}>
        <Field
          component={SelectLabel}
          type="text"
          label={`Escolha uma opção`}
          name={`fuel_id`}
          margin="normal"
          options={campaignList}
          validate={[FORM_RULES.required]}
        />
      </div>
      <div className="mt-3" style={{ width: 230 }}>
        <FullButton classNameProp="py-3 px-4" type="submit" disabled={disabled}>
          {disabled ? 'Gerando voucher...' : 'Gerar Voucher'}
        </FullButton>
      </div>
    </>
  );
}
