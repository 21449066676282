import CheckIcon from '@material-ui/icons/Check';
import styled from 'styled-components';
import { BASE_S3 } from 'config/consts';

export const Option = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  padding: 20px 25px;
  background: ${(props) =>
    !props.image
      ? props.background
        ? props.background
        : '#333'
      : `url(${BASE_S3}/${props.image})`};
  background-position: center;
  background-size: cover;
  opacity: ${(props) => (props.checked ? '0.7' : '1')};

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }

  p {
    cursor: pointer;
    color: ${(props) => props.textColor};
    text-align: center;
    font-size: 18px;
    margin: 0;
    height: fit-content;
    border: 0;
    resize: none;
    background: none;
    word-break: break-word;
  }
`;

export const TextImage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.textColor};
  width: 100%;
  min-height: 40px;
  height: fit-content;
  font-size: 16px;
  text-align: center;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 1px;
  transition: transform 0.2s;
  word-break: break-word;
`;

export const CheckBox = styled(CheckIcon)`
  position: absolute;
`;
