import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FullButton } from 'common/components/buttons/fullButton';
import {
  CaptchaInput,
  InputLabelOutlined,
} from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { BASE_S3, COMPANY_COLORS, COMPANY_STATION_ID } from 'config/consts';
import { cpfMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import { getStationData } from 'modules/website/websiteActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import {
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  SINDILEGIS_STATION_ID,
} from '../../../config/consts';
import { handleWhiteSpaces } from '../../../helpers/dataTransformers';
import { login, validateToken } from '../authActions';
import './login.css';
class Login2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    if (user) {
      this.props.validateToken(user.token);
    }
    if (
      !this.props.website.stationData.address ||
      !this.props.website.stationData.banner_image
    ) {
      this.props.getStationData(COMPANY_STATION_ID);
    }
    this.isAuthenticated();
  }

  isAuthenticated() {
    const { user, validToken } = this.props.auth;

    if (user && validToken) {
      this.props.history.push('/');
    }
    /////console.log(user, validToken);
  }

  onSubmit = (values) => {
    const { login, history } = this.props;
    const newValues = {
      ...values,
    };
    if (values.cpf) {
      newValues.cpf = values.cpf
        .replace('.', '')
        .replace('.', '')
        .replace('-', '');
    }
    login(newValues, history);
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  renderEyeShowPassword = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
        >
          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  render() {
    const { website } = COMPANY_COLORS;

    if (this.props.website.stationDataLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <LoadingProgress />
        </Box>
      );
    }
    return (
      <div
        className="d-flex justify-content-center"
        style={{
          background: `url(${BASE_S3}/${website.header.background_auth_station})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="col-sm-12 col-md-6 col-lg-4 align-self-center my-md-0 my-5">
          <div
            style={{
              background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              borderRadius: 4,
            }}
          >
            <div className="row justify-content-center">
              <div className="col-7 d-flex justify-content-center py-3">
                <a href="/">
                  <img
                    className
                    src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                    alt="Logo"
                    style={{
                      maxWidth: '90%',
                      height: 'auto',
                      maxHeight: 85,
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="row justify-content-center">
              <Typography variant="h6">Faça login para continuar</Typography>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <form
                  onSubmit={this.props.handleSubmit((v) => this.onSubmit(v))}
                  noValidate
                >
                  {COMPANY_STATION_ID === SINDILEGIS_STATION_ID ? (
                    <div mt={3}>
                      <div className="row justify-content-center my-3">
                        <div className="col-10">
                          <Field
                            component={InputLabelOutlined}
                            type="tel"
                            label="CPF"
                            helperText="Digite seu CPF"
                            name="cpf"
                            validate={[FORM_RULES.required, FORM_RULES.cpf]}
                            {...cpfMask}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div mt={3}>
                      <div className="row justify-content-center my-3">
                        <div className="col-10">
                          <Field
                            component={InputLabelOutlined}
                            label="E-mail"
                            inputlabelprops={{
                              shrink: true,
                            }}
                            // placeholder="Digite seu e-mail"
                            name="email"
                            validate={[FORM_RULES.required, FORM_RULES.email]}
                            helperText="Digite seu e-mail"
                            normalize={handleWhiteSpaces}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div mt={3}>
                    <div className="row justify-content-center my-3">
                      <div className="col-10">
                        <Field
                          component={InputLabelOutlined}
                          endAdornment={this.renderEyeShowPassword()}
                          type={this.state.showPassword ? 'text' : 'password'}
                          label="Senha"
                          inputlabelprops={{
                            shrink: true,
                          }}
                          name="password"
                          validate={[FORM_RULES.required]}
                          helperText="Digite sua senha"
                        />
                      </div>
                    </div>
                  </div>
                  {process.env.REACT_APP_API_KEY === 'production' ? (
                    <div mt={3}>
                      <div className="row justify-content-center my-3">
                        <div className="col-10">
                          <div className="d-flex justify-content-center">
                            <Field
                              component={CaptchaInput}
                              inputlabelprops={{
                                shrink: true,
                              }}
                              name="captcha"
                              validate={[FORM_RULES.required]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="d-flex justify-content-center my-3">
                    <a href="#/esqueci-minha-senha">
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontSize: '0.875rem',
                          textDecorationLine: 'underline',
                        }}
                      >
                        Esqueceu seus dados de acesso?
                      </Typography>
                    </a>
                  </div>

                  <div className="row justify-content-center mb-4">
                    <div className="col-10">
                      <div className="d-flex justify-content-center">
                        <FullButton
                          classNameProp="py-3 px-4"
                          type="submit"
                          disabled={
                            this.props.formValues
                              ? (!this.props.formValues.email &&
                                  COMPANY_STATION_ID !==
                                    SINDILEGIS_STATION_ID) ||
                                (!this.props.formValues.cpf &&
                                  COMPANY_STATION_ID ===
                                    SINDILEGIS_STATION_ID) ||
                                !this.props.formValues.password
                              : true
                          }
                        >
                          Entrar
                        </FullButton>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="container my-3 pb-4">
              <div className="row justify-content-center">
                <div
                  className="col-10 d-flex justify-content-center align-items-center p-3"
                  style={{
                    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: '0.875rem',
                      marginBottom: 0,
                    }}
                  >
                    Não tem acesso?{' '}
                    <a href="#/cadastrar">
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontSize: '0.875rem',
                          display: 'inline-block',
                          textDecorationLine: 'underline',
                        }}
                      >
                        Crie gratuitamente
                      </Typography>
                    </a>
                  </Typography>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login2 = reduxForm({ form: 'loginForm' })(Login2);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    formValues: getFormValues('loginForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ login, validateToken, getStationData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login2);
