import axios from 'axios';
import { reset as resetForm, initialize } from 'redux-form';
import type from './types';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';

const URL_ABOUT = `${BASE_API}/station-sections`;
const URL_BENEFITS = `${BASE_API}/station-benefits`;
const URL_STATION_DATA = `${BASE_API}/stations`;
const URL_PUBLIC_ACTIONS = `${BASE_API}/public-actions`;
const URL_SUPPORT = `${BASE_API}/enviar-suporte`;

export const getAboutList = (id) => {
  return (dispatch) => {
    dispatch({ type: 'ABOUT_LOAD', payload: true });
    axios
      .get(`${URL_ABOUT}/?where[station_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'ABOUT_FETCHED',
            payload: response,
          },
        ]);
      });
  };
};

export const getPublicActions = (id) => {
  return (dispatch) => {
    dispatch({ type: 'STATION_PUBLIC_ACTIONS_LOAD', payload: true });
    axios.get(`${URL_PUBLIC_ACTIONS}/${id}`).then((response) => {
      dispatch([
        {
          type: 'STATION_PUBLIC_ACTIONS_FETCHED',
          payload: response.data.response,
        },
      ]);
    });
  };
};

export const getBenefitsList = (id) => {
  return (dispatch) => {
    dispatch({ type: 'BENEFITS_LOAD', payload: true });
    axios
      .get(`${URL_BENEFITS}/?where[station_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })

      .then((response) => {
        if (response.data[0]) {
          const newValues = {
            ...response.data[0],
          };
          if (response.data[0].type === 'video') {
            newValues['media'] = response.data[0].media.replace(
              'watch?v=',
              'embed/'
            );
          }
          dispatch([
            {
              type: 'BENEFITS_FETCHED',
              payload: { data: [newValues] },
            },
          ]);
        } else {
          dispatch({ type: 'BENEFITS_LOAD', payload: false });
        }
      });
  };
};

export const getStationData = (id) => {
  return (dispatch) => {
    dispatch({ type: 'STATION_DATA_LOAD', payload: true });
    axios.get(`${URL_STATION_DATA}/${id}`).then((response) => {
      dispatch([
        {
          type: 'STATION_DATA_FETCHED',
          payload: response.data,
        },
      ]);
    });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const initialValues = {
          ...response.data[0],
        };
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: response.data[0],
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: type.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
    ]);
  };
};

export const initializeForm = (values) => {
  return initialize('newsForm', values);
};

export const reset = () => {
  return resetForm('newsForm');
};

export const create = (values, history, firstTime) => {
  return submit(values, 'post', history, firstTime);
};

export const update = (values, history) => {
  return submit(values, 'put', history);
};

function submit(values, method, history = undefined, firstTime) {
  const id = values.id ? `${values.id}/` : '';
  return (dispatch) => {
    dispatch([
      { type: type.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](`${URL}/${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: (progressEvent) =>
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: `${Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )} %`,
          },
        ]),
    })
      .then((response) => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);

        if (!_.isUndefined(history)) {
          if (firstTime) {
            history.push('/owner');
          } else {
            history.push('/owner/content/noticias');
          }
        }
      })
      .catch((e) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}

export const setLoading = (bool) => {
  return (dispatch) => {
    dispatch({
      type: type.LIST_LOAD,
      payload: false,
    });
  };
};

export const fileRejected = () => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: 'Erro: Imagem maior que 1MB',
        variant: 'error',
        open: true,
      },
    });
  };
};

export const changeHeaderIndex = (index) => {
  return (dispatch) => {
    dispatch({ type: 'CHANGE_HEADER_INDEX', payload: index });
  };
};

export const submitSupport = (values, history = undefined) => {
  return (dispatch) => {
    dispatch([
      { type: type.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(`${URL_SUPPORT}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);

        if (!_.isUndefined(history)) {
          history.push('/');
        }
      })
      .catch((e) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `${e.response.data.error}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
