import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  COMPANY_SIGNED_IN_BACKGROUND_IMAGE,
  SIGNED_IN_NAV_TEXT_COLOR,
} from '../../../../../../config/consts';
import ActivityAbstract from '../../components/ActivityAbstract';
import ItemCardMultiSelect from '../../components/ItemCard/multiSelect';
import ItemCardRadioSelect from '../../components/ItemCard/radioSelect';
import { addProductToCart } from '../../shoppingActions';

let Cart = function ({
  products,
  setStep,
  formValues,
  step,
  activity,
  handleSubmit,
  addProductToCart,
  history,
}) {
  const [myProductId, setmyProductId] = useState(null);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('xs'));

  const checkLimitPerUser = useMemo(() => {
    if (parseInt(activity.limitPerUser) <= activity.userActions.length) {
      return false;
    } else return true;
  }, [activity.limitPerUser, activity.userActions.length]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const productsWithStock = useMemo(
    () =>
      products.filter((item) => item.single_product_orders.length < item.stock),
    [products]
  );

  async function onSubmit(values) {
    const products = [];
    for (let product in values) {
      const id = product.split('product-')[1];
      const quantity = values[product];
      products.push({ id, quantity });
    }
    addProductToCart(products, history);
  }

  const getProductId = (id) => {
    setmyProductId(id);
  };

  const onSubmitOnlyOneProduct = (values) => {
    const id = myProductId;
    const quantity = 1;
    addProductToCart([{ id, quantity }], history);
  };

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12}>
      <Form
        role="form"
        noValidate
        onSubmit={
          activity.only_one_product
            ? handleSubmit((v) => onSubmitOnlyOneProduct(v))
            : handleSubmit((v) => onSubmit(v))
        }
        style={{
          minWidth: '100%',
        }}
      >
        <Grid container justifyContent="center" alignItems="center" xs={12}>
          <Grid item container justifyContent="center" alignItems="center" xs={12}>
            <ActivityAbstract />
          </Grid>
          {activity.participationStart && activity.participationEnd ? (
            moment().isBetween(
              activity.participationStart,
              activity.participationEnd
            ) ? (
              checkLimitPerUser ? (
                productsWithStock.length > 0 ? (
                  productsWithStock.map((product) => (
                    <Field
                      key={product.id}
                      name={`product-${product.id}`}
                      data={product}
                      callBack={getProductId}
                      component={
                        activity.only_one_product
                          ? ItemCardRadioSelect
                          : ItemCardMultiSelect
                      }
                    />
                  ))
                ) : (
                  <Typography variant="body1" style={{ marginTop: 10 }}>
                    O estoque desse(s) produto(s) está esgotado no momento.
                  </Typography>
                )
              ) : (
                  <Typography variant="body1" style={{ marginTop: 10 }}>
                  Olá, você já ultrapassou o limite de participações nessa
                  atividade!
                </Typography>
              )
            ) : (
              <>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={4}
                >
                  <Typography variant="body1" style={{ marginBottom: 32 }}>
                    Você pode verificar os produtos desta atividade entre os dias{' '}
                    {moment(activity.participationStart).format('DD/MM/YYYY')} às{' '}
                    {moment(activity.participationStart).format('HH:mm')} e{' '}
                    {moment(activity.participationEnd).format('DD/MM/YYYY')} às{' '}
                    {moment(activity.participationEnd).format('HH:mm')}.
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={4}
                >
                  <FullButton
                    classNameProp="py-3 px-4"
                    type="button"
                    onClick={() => history.push('/homepage')}
                    style={{
                      height: 52,
                      minWidth: '100%',
                    }}
                    isSecondary
                  >
                    Voltar
                  </FullButton>
                </Grid>
              </>
            )
          ) : (
            'Houve um problema com a data de publicação desta atividade.'
          )}
        </Grid>

        {checkLimitPerUser ? (
          productsWithStock.length > 0 ? (
            activity.participationStart && activity.participationEnd ? (
              moment().isBetween(
                activity.participationStart,
                activity.participationEnd
              ) ? (
                <>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    style={{
                      backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_IMAGE,
                      border: `1px solid ${SIGNED_IN_NAV_TEXT_COLOR}`,
                      padding: '1rem 1rem',
                      marginTop:'24px',
                      marginBottom:'24px',
                      borderRadius:'10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      justifyContent={xs || sm ? 'center' : 'flex-start'}
                      alignItems="flex-start"
                      xs={12}
                      md={6}
                      style={{
                        marginBottom: xs || sm ? '.5rem' : null,
                        height: xs || sm ? '60px' : '',
                      }}
                    >
                      <Typography
                        gutterBottom={true}
                        component="p"
                        style={{ textAlign: 'start', maxWidth: '90%' }}
                        variant="body1"
                      >
                        Escolha os produtos e adicione ao carrinho de compras
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      xs={12}
                      md={6}
                      style={{
                        marginTop: xs || sm ? '.3rem' : null,
                      }}
                    >
 
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        md={8}
                      >
                        <FullButton
                          fontSize="0.875rem"
                          classNameProp="py-3 px-4"
                          type="submit"
                        >
                          Adicionar ao carrinho
                        </FullButton>
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        md={4}
                      >
                        <FullButton
                          classNameProp="py-3 px-4"
                          type="button"
                          onClick={() => history.push('/homepage')}
                          style={{
                            height: 52,
                            minWidth: '100%',
                          }}
                          isSecondary
                        >
                          Voltar
                        </FullButton>
                      </Grid>



                    </Grid>
                  </Grid>
                </>
              ) : null
            ) : null
          ) : null
        ) : null}
      </Form>
    </Grid>
  );
};

Cart = reduxForm({
  form: 'shoppingForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(Cart);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities.detail,
    formValues: getFormValues('shoppingForm')(state),
    shopping: state.shopping,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addProductToCart }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
