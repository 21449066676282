import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import LoadingProgress from 'common/components/progress/loading';
import { BASE_S3, COMPANY_COLORS } from 'config/consts';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { InputLabelOutlined } from '../../../common/components/fields/fields';
import {
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  COMPANY_STATION_ID,
  GENERAL_TEXT_COLOR,
  LINK_TEXT_COLOR,
} from '../../../config/consts';
import { getStationData } from '../../../modules/website/websiteActions';
import { requestPasswordReset, setLoading } from '../authActions';

class ForgotPassword2 extends Component {
  componentDidMount() {
    if (!this.props.website.stationData.address) {
      this.props.getStationData(COMPANY_STATION_ID);
    }
  }

  onSubmit = (values) => {
    this.props.setLoading(true);
    const newValues = {
      ...values,
      type: 2,
      station: COMPANY_STATION_ID,
    };
    this.props.requestPasswordReset(newValues, this.props.history);
  };

  render() {
    const { website } = COMPANY_COLORS;
    if (this.props.website.stationDataLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <LoadingProgress />
        </Box>
      );
    }

    if (isMobileOnly) {
      return (
        <div
          className="d-flex justify-content-center"
          style={{
            background: `url(${BASE_S3}/${website.header.background_auth_station})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            minHeight: '100vh',
          }}
        >
          <div className="col-md-6 col-lg-4 align-self-center">
            <div
              style={{
                backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                borderRadius: 4,
              }}
            >
              <div className="row justify-content-center py-4">
                <div className="col-10">
                  <div className="row justify-content-between">
                    <div
                      className="col-5"
                      style={{
                        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                      }}
                    >
                      <a href="#/login">
                        <FontAwesomeIcon
                          style={{
                            fontSize: 16,
                            color: COMPANY_COLORS.general.link,
                          }}
                          icon={faChevronLeft}
                        />
                        <span
                          className="station-font-medium ml-3"
                          style={{
                            color: LINK_TEXT_COLOR,
                            fontSize: '0.875rem',
                          }}
                        >
                          voltar
                        </span>
                      </a>
                    </div>

                    <div className="col-5">
                      <a href="/">
                        <img
                          className
                          src={`${BASE_S3}/${
                            website.navbar.station_secondary_logo
                            // website.navbar.station_primary_logo
                          }`}
                          alt="Logo"
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="row justify-content-center">
                    <div className="col-8">
                      <h4
                        className="station-font-medium text-center"
                        style={{
                          fontSize: '1.25rem',
                          color: GENERAL_TEXT_COLOR,
                        }}
                      >
                        Esqueceu sua senha?
                      </h4>
                    </div>
                    <div className="col-12">
                      <p
                        className="station-font-medium text-center"
                        style={{
                          fontSize: '0.875rem',
                          color: COMPANY_COLORS.general.text,
                        }}
                      >
                        Não tem problema. Escreva seu email abaixo e a gente já
                        resolve isso.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center pb-3">
                <div className="col-12">
                  <form
                    onSubmit={this.props.handleSubmit((v) => this.onSubmit(v))}
                    noValidate
                  >
                    <div mt={3}>
                      <div className="row justify-content-center my-3 pb-3">
                        <div className="col-10">
                          <Field
                            component={InputLabelOutlined}
                            label="Seu e-mail"
                            inputlabelprops={{
                              shrink: true,
                            }}
                            name="email"
                            validate={[FORM_RULES.required, FORM_RULES.email]}
                            helperText="Digite seu e-mail corporativo"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center pb-5">
                      <div className="col-10">
                        <div className="d-flex justify-content-center">
                          <FullButton
                            classNameProp="py-3 px-4"
                            type="submit"
                            disabled={
                              this.props.formValues
                                ? !this.props.formValues.email
                                : true
                            }
                          >
                            Enviar link mágico
                          </FullButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="d-flex justify-content-center"
        style={{
          background: `url(${BASE_S3}/${website.header.background_auth_station})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="col-md-6 col-lg-4 align-self-center">
          <div
            style={{
              background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              borderRadius: 4,
            }}
          >
            <div className="row justify-content-center py-4">
              <div className="col-10">
                <div className="row justify-content-between">
                  <div className="col-5">
                    <a href="#/login">
                      <FontAwesomeIcon
                        style={{
                          fontSize: 16,
                          color: COMPANY_COLORS.general.link,
                        }}
                        icon={faChevronLeft}
                      />
                      <span
                        className="station-font-medium ml-3"
                        style={{
                          color: LINK_TEXT_COLOR,
                          fontSize: '0.875rem',
                        }}
                      >
                        voltar
                      </span>
                    </a>
                  </div>

                  <div className="col-5">
                    <a href="/">
                      <img
                        className
                        src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                        alt="Logo"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="row justify-content-center">
                  <div className="col-8">
                    <Typography
                      variant="h5"
                      className="station-font-medium text-center"
                      // style={{
                      //   fontSize: '1.25rem',
                      //   color: COMPANY_COLORS.general.text,
                      // }}
                    >
                      Esqueceu sua senha?
                    </Typography>
                  </div>
                  <div className="col-12">
                    <Typography
                      variant="body1"
                      className="station-font-medium text-center"
                      // style={{
                      //   fontSize: '0.875rem',
                      //   color: COMPANY_COLORS.general.text,
                      // }}
                    >
                      Não tem problema. Escreva seu email abaixo e a gente já
                      resolve isso.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center pb-3">
              <div className="col-12">
                <form
                  onSubmit={this.props.handleSubmit((v) => this.onSubmit(v))}
                  noValidate
                >
                  <div mt={3}>
                    <div className="row justify-content-center my-3 pb-3">
                      <div className="col-10">
                        <Field
                          component={InputLabelOutlined}
                          label="Seu e-mail"
                          inputlabelprops={{
                            shrink: true,
                          }}
                          name="email"
                          validate={[FORM_RULES.required, FORM_RULES.email]}
                          helperText="Digite seu e-mail corporativo"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center pb-5">
                    <div className="col-10">
                      <div className="d-flex justify-content-center">
                        <FullButton
                          classNameProp="py-3 px-4"
                          type="submit"
                          disabled={
                            this.props.formValues
                              ? !this.props.formValues.email
                              : true
                          }
                        >
                          Enviar link mágico
                        </FullButton>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword2 = reduxForm({ form: 'authForm' })(ForgotPassword2);
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { requestPasswordReset, setLoading, getStationData },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    formValues: getFormValues('authForm')(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword2);
