import mail_check from 'assets/img/mail-check.svg';
import GeneralButton from 'common/components/buttons/generalButton';
import {
  InputCheckbox,
  InputLabel2,
  SelectLabel,
} from 'common/components/fields/fields';
import { COMPANY_DARK_COLOR } from 'config/consts';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import React, { Component } from 'react';
import { CircularProgress } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { getSurveyById, submitSurvey } from './surveysActions';

class SurveysForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepBool: true,
      visible: false,
    };
    this.props.getSurveyById(this.props.match.params?.surveyId);
  }

  onSubmit = (values) => {
    const { auth, match } = this.props;
    // adicionar estabelecimento
    values.author = 'user';
    values.user = auth.user.id;
    values.action = match.params.actionId;
    values.establishment = auth.user.establishment_participant.id;
    values.research = match.params.surveyId;
    let arr = [];
    for (let prop in values) {
      if (prop.indexOf('contactSurveyAnswer') > -1) {
        if (!_.isArray(values[prop]) && prop.split('-').length < 3) {
          arr.push({
            answer: values[prop],
            question: prop.split('-')[1],
          });
        } else if (prop.split('-').length > 2) {
          arr.push({
            options: [values[prop]],
            question: prop.split('-')[1],
          });
        } else {
          arr.push({
            options: values[prop],
            question: prop.split('-')[1],
          });
        }
        delete values[`${prop}`];
      }
    }
    values.answers = arr;

    return this.props.submitSurvey(values);
  };

  handleClick = () => {
    return this.setState({ stepBool: false });
  };

  handleModal = () => {
    return this.setState({ visible: true });
  };

  handleHide = () => {
    return this.props.showModal();
  };

  handleGoBack = () => {
    return this.setState({ stepBool: true });
  };

  onHide = () => {
    return this.setState({ visible: false });
  };

  renderStep1 = () => {
    return (
      <div>
        <div className="row justify-content-center">
          {!_.isUndefined(this.props.surveys.list.content[0]) ? (
            <div className="col-auto text-center signup-text my-4">
              {this.props.surveys.list.content[0].name}
            </div>
          ) : null}
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
              {!_.isUndefined(this.props.surveys.list.content[0])
                ? this.props.surveys.list.content[0].questions.map(
                    (item, index) => {
                      if (parseInt(item.type_id) === 3) {
                        return (
                          <div className="row justify-content-center my-n2">
                            <Field
                              component={InputLabel2}
                              type="text"
                              placeholder={`${item.question}`}
                              label={`${item.question}`}
                              name={`contactSurveyAnswer-${item.id}`}
                              cols="12 12 4 4"
                              validate={[FORM_RULES.required]}
                              style={{
                                border: `1px solid ${COMPANY_DARK_COLOR} `,
                                fontSize: 16,
                                borderRadius: 0,
                                padding: '23px 10px',
                                fontWeight: 400,
                              }}
                              borderColor={COMPANY_DARK_COLOR}
                            />
                          </div>
                        );
                      } else if (parseInt(item.type_id) === 2) {
                        return (
                          <div className="row justify-content-center my-n2">
                            <Field
                              component={InputCheckbox}
                              options={item.options.map((item) => {
                                return {
                                  value: item.id,
                                  label: item.label,
                                };
                              })}
                              cols="12 12 4 4"
                              name={`contactSurveyAnswer-${item.id}`}
                              label={`${item.question} (Selecione uma ou mais)`}
                              style={{
                                fontSize: 16,
                                borderRadius: 0,
                                padding: '23px 10px',
                                fontWeight: 400,
                              }}
                              borderColor={COMPANY_DARK_COLOR}
                            />
                          </div>
                        );
                      } else if (parseInt(item.type_id) === 1) {
                        return (
                          <div className="row justify-content-center my-n2">
                            <Field
                              component={SelectLabel}
                              type="text"
                              placeholder={`${item.question}`}
                              label={`${item.question} (Selecione apenas uma)`}
                              name={`contactSurveyAnswer-${item.id}-1`}
                              cols="12 12 4 4"
                              validate={[FORM_RULES.required]}
                              options={item.options.map((item) => {
                                return {
                                  value: item.id,
                                  label: item.label,
                                };
                              })}
                              style={{
                                fontSize: 16,
                                borderRadius: 0,
                                padding: '23px 10px',
                                fontWeight: 400,
                              }}
                              borderColor={COMPANY_DARK_COLOR}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }
                  )
                : null}

              <div className="row justify-content-center">
                <GeneralButton
                  style={{
                    backgroundColor: COMPANY_DARK_COLOR,
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: '700',
                    letterSpacing: '0.1em',
                  }}
                  label="ENVIAR"
                  history={this.props.history}
                  type="submit"
                  onClick={this.handleModal}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.surveys.loading) {
      return (
        <div className="container-fluid" style={{ paddingBottom: 50 }}>
          <CircularProgress id="loading" />
        </div>
      );
    } else {
      return (
        <div className="container-fluid" style={{ paddingBottom: 50 }}>
          <div className="row justify-content-center">
            <div className="col-auto text-center signup-img"></div>
          </div>
          {this.state.stepBool ? this.renderStep1() : this.renderStep2()}
          <Dialog
            className="signup-modal"
            header="Confirmação"
            visible={this.props.surveys.surveySent}
            style={{ width: '80vw' }}
            modal={true}
            onHide={() => this.props.history.push('/homepage')}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-auto">
                  <img alt="atl-img" src={mail_check} />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: 22,
                      color: '#2B2D6B',
                    }}
                  >
                    {' '}
                    Sua pesquisa foi enviada com sucesso! Obrigado.
                  </span>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      );
    }
  }
}

SurveysForm = reduxForm({ form: 'surveysForm' })(SurveysForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('surveysForm')(state),
    surveys: state.surveys,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSurveyById,
      submitSurvey,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveysForm);
