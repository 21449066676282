import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
} from '../../../config/consts';
import LinearDeterminate from '../progress/progress';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  input: {
    display: 'none',
  },
  primary: {
    color: BUTTON_TEXT_COLOR,
    backgroundColor: BUTTON_BACKGROUND_COLOR,
    '&:hover': {
      backgroundColor: BUTTON_BACKGROUND_COLOR,
    },
    '&:focus': {
      outline: 'none',
    },
    fontFamily: BUTTON_FONT_FAMILY,
  },
  disabledLabel: {
    color: COMPANY_COLORS.font.gray,
  },
  loadingLabel: {
    display: 'block',
    color: COMPANY_COLORS.success,
  },
}));

export default function ButtonPrimary(props) {
  const classes = useStyles();
  const { disabled } = props;

  if (props.progress) {
    return (
      <Button
        variant="contained"
        size="large"
        classes={{ label: classes.loadingLabel }}
        className={clsx(props.noMargin ? '' : classes.button, classes.primary)}
        {...props}
      >
        <LinearDeterminate
          progress={props.progress}
          style={{ display: 'block' }}
        />
        {props.children}
      </Button>
    );
  } else {
    return (
      <Button
        variant="contained"
        classes={{ label: disabled ? classes.disabledLabel : 'default' }}
        className={clsx(props.noMargin ? '' : classes.button, classes.primary)}
        {...props}
      >
        {props.children}
      </Button>
    );
  }
}
