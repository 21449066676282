import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PlansEditPaymentForm from './plansEditPaymentForm';
import PlansPurchaseForm from './plansPurchaseForm';
import Plans from './plans';
import CustomPlan from './customPlan/index';
export default (
  <Route path="/homepage/planos">
    <Switch>
      <Route exact path="/homepage/planos" component={Plans} />
      <Route
        path="/homepage/planos/:id/:action"
        component={PlansPurchaseForm}
      />
      <Route
        path="/homepage/planos/editar-pagamento"
        component={PlansEditPaymentForm}
      />
      <Route path="/homepage/planos/sobconsulta" component={CustomPlan} />
    </Switch>
  </Route>
);
