import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullButton } from 'common/components/buttons/fullButton';
import { BASE_S3, COMPANY_COLORS } from 'config/consts';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { InputLabelOutlined } from '../../../common/components/fields/fields';
import LoadingProgress from '../../../common/components/progress/loading';
import { COMPANY_STATION_ID } from '../../../config/consts';
import { getStationData } from '../../../modules/website/websiteActions';
import {
  requestPasswordReset,
  setLoading,
  updatePassword,
} from '../authActions';

class NewPassword extends Component {
  componentDidMount() {
    if (!this.props.website.stationData.address) {
      this.props.getStationData(COMPANY_STATION_ID);
    }
  }

  onSubmit = (values) => {
    const newValues = {
      ...values,
      hash: this.props.match.params?.userHash,
      email: this.props.match.params?.userEmail,
    };
    this.props.updatePassword(newValues, this.props.history);
  };

  render() {
    const { website } = COMPANY_COLORS;
    if (this.props.auth.loadingMGM) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <LoadingProgress />
        </div>
      );
    }
    return (
      <section
        className="container-fluid"
        style={{
          background: `url(${BASE_S3}/${website.header.background_back_header})`,
          backgroundSize: 'cover',
          minHeight: '100vh',
        }}
      >
        <div
          className="row justify-content-center"
          style={{
            background: `url(${BASE_S3}/${website.header.background_auth_station})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '20% 0%',
            minHeight: '100vh',
          }}
        >
          <div className="col-md-6 col-lg-4 align-self-center">
            <div style={{ background: '#ffffff', borderRadius: 4 }}>
              <div className="row justify-content-center py-4">
                <div className="col-10">
                  <div className="row justify-content-between">
                    <div className="col-5">
                      <a href="#/login">
                        <FontAwesomeIcon
                          style={{
                            fontSize: 16,
                            color: COMPANY_COLORS.general.link,
                          }}
                          icon={faChevronLeft}
                        />
                        <span
                          className="station-font-medium ml-3"
                          style={{
                            color: COMPANY_COLORS.general.link,
                            fontSize: '0.875rem',
                          }}
                        >
                          voltar
                        </span>
                      </a>
                    </div>

                    <div className="col-5">
                      <img
                        className
                        src={`${BASE_S3}/${
                          this.props.website.stationData.logo_image ||
                          website.navbar.station_primary_logo
                        }`}
                        alt="Logo"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="row justify-content-center">
                    <div className="col-8">
                      <h4
                        className="station-font-medium text-center"
                        style={{
                          fontSize: '1.25rem',
                          color: COMPANY_COLORS.general.text,
                        }}
                      >
                        Redefinição de senha
                      </h4>
                    </div>
                    <div className="col-12">
                      <p
                        className="station-font-medium text-center"
                        style={{
                          fontSize: '0.875rem',
                          color: COMPANY_COLORS.general.text,
                        }}
                      >
                        Digite sua nova senha abaixo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center pb-3">
                <div className="col-12">
                  <form
                    onSubmit={this.props.handleSubmit((v) => this.onSubmit(v))}
                    noValidate
                  >
                    <div mt={3}>
                      <div className="row justify-content-center my-3 pb-3">
                        <div className="col-10">
                          <Field
                            component={InputLabelOutlined}
                            label="Nova senha"
                            name="password"
                            type="password"
                            helperText="Digite sua nova senha"
                            validate={[FORM_RULES.required]}
                          />
                          <div className="mt-2">
                            <Field
                              component={InputLabelOutlined}
                              label="Confirme sua senha"
                              type="password"
                              name="password_confirmation"
                              helperText="Confirme sua nova senha"
                              validate={[FORM_RULES.required]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center pb-5">
                      <div className="col-10">
                        <div className="d-flex justify-content-center">
                          <FullButton classNameProp="py-3 px-4" type="submit">
                            Redefinir
                          </FullButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

NewPassword = reduxForm({ form: 'authForm' })(NewPassword);
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { requestPasswordReset, setLoading, getStationData, updatePassword },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
