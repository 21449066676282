import { Typography } from '@material-ui/core';
import Spinner from 'common/components/spinner/spinner';
import { COMPANY_STATION_ID } from 'config/consts';
import {
  getActivitiesByName,
  setLoading,
} from 'modules/user/pages/activities/activitiesActions.js';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DefaultCard } from '../../../../common/components/cards/DefaultCard';
import {
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
} from '../../../../config/consts';
import { useParams } from 'react-router-dom';

function SearchBar({
  setLoading,
  auth,
  activities,
  getActivitiesByName,
  history,
}) {
  function selectActivity(id) {
    setLoading(true);
    history.push(`/homepage/atividades/${id}`);
  }

  const { name } = useParams();

  useEffect(() => {
    getActivitiesByName(name, auth.user.id, COMPANY_STATION_ID);
  }, [name, auth.user.id, getActivitiesByName]);

  if (activities.loadingNameActions) {
    return (
      <div className="d-flex" style={{ marginTop: 40, marginLeft: 20 }}>
        <Spinner />
      </div>
    );
  }
  return (
    <div
      className="container-fluid px-4"
      style={{
        marginTop: 40,
        background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
        minHeight: '100vh',
      }}
    >
      <div className="row">
        <div className="col">
          <Typography variant="h4">
            {activities.listNameActions.content.length} resultado
            {activities.listNameActions.content.length > 1 ? `s` : null} da
            busca "{name}"
          </Typography>
        </div>
      </div>
      <div
        className="row"
        style={{
          border: `1px solid ${GENERAL_TEXT_COLOR}`,
          marginTop: 20,
          marginBottom: 30,
        }}
      ></div>
      <div className="row justify-content-start" style={{ minHeight: 400 }}>
        {activities.listNameActions.content.map((item, index) => (
          <>
            <div className="mb-4 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <DefaultCard
                onClick={() => selectActivity(item.id)}
                imgCloudSource={item.image}
                actionName={item.name}
                labelClick={item.click_me_label || 'Participe'}
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoading,
      getActivitiesByName,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
