import DeepLink from 'pages/auth/deepLink/deepLink';
import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import WebFont from 'webfontloader';
import {
  COMPANY_FONT_FAMILY,
  COMPANY_STATION_ID,
  DUKITS_STATION_ID,
  HEADER_FONT_FAMILY,
  NAV_FONT_FAMILY,
} from '../config/consts';
import ActivityPublic from '../modules/user/pages/activities/activityPublic';
import EmailSubscription from '../modules/user/pages/emailSubscription/emailSubscription';
import NotAutorized from '../modules/user/pages/errors/actionExpired';
import AboutDetail from '../modules/user/pages/homepage/about/aboutDetail';
import PlansLoggedOffDetail from '../modules/user/pages/homepage/plans/plansLoggedOffDetail';
import EnterprisePLanMessage from '../modules/website/EnterprisePLanMessage';
import supportMessage from '../modules/website/supportMessage';
import ActivateUser from '../pages/auth/activateUser/activateUser';
import feiraoLegisClubLogin from '../pages/auth/feiraoLegisClubLogin/login';
import ForgotPassword from '../pages/auth/forgotPassword/forgotPassword';
import NewPassword from '../pages/auth/forgotPassword/newPassword';
import Login from '../pages/auth/login/login';
import loginToken from '../pages/auth/loginToken/loginToken';
import Logout from '../pages/auth/logout/logout';
import privacyPolicy from '../pages/auth/policies/privacyPolicy';
import usePolicy from '../pages/auth/policies/usePolicy';
import EmailConfirmation from '../pages/auth/signup/emailConfirmation';
import newSignupParticipant from '../pages/auth/signup/newSignupParticipant';
import newSignupParticipantFan from '../pages/auth/signup/newSignupParticipantFan';
import signupParticipant from '../pages/auth/signup/signupParticipant';
import SignupStepForm from '../pages/auth/signup/signupStepForm';
import ShareValidation from '../pages/shareValidation';
import authOrApp from './authOrApp';
import qrcodeValidation from '../pages/auth/qrcodeValidation/qrcodeValidation';
const id =
  process.env.REACT_APP_API_KEY === 'test'
    ? 2
    : process.env.REACT_APP_API_KEY === 'production'
    ? 26
    : 2;
export default class Routes extends Component {
  componentDidMount() {
    WebFont.load({
      google: {
        families: [COMPANY_FONT_FAMILY, NAV_FONT_FAMILY, HEADER_FONT_FAMILY],
      },
    });
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route
            path="/login"
            component={COMPANY_STATION_ID === id ? feiraoLegisClubLogin : Login}
          />

          <Route path="/suporte-enterprise" component={EnterprisePLanMessage} />
          <Route path="/suporte" component={supportMessage} />
          <Route path="/planos/:id" component={PlansLoggedOffDetail} />
          <Route path="/ativar/:hash/:email" component={ActivateUser} />
          <Route path="/login-token/:token" component={loginToken} />
          <Route path="/sobre/:id" component={AboutDetail} />
          <Route
            path="/deep-link/:resource/:type/:actionId"
            component={DeepLink}
          />
          <Route
            path="/signup"
            component={
              DUKITS_STATION_ID === COMPANY_STATION_ID
                ? newSignupParticipantFan
                : newSignupParticipant
            }
          />
          <Route path="/termos-de-uso" component={usePolicy} />
          <Route path="/politica-de-privacidade" component={privacyPolicy} />
          <Route path="/signup/:code_mgm" component={signupParticipant} />
          <Route path="/cadastrar/:code_mgm" component={SignupStepForm} />
          <Route path="/not-autorized" component={NotAutorized} />
          <Route path="/confirmacao/:email" component={EmailConfirmation} />
          <Route path="/esqueci-minha-senha" component={ForgotPassword} />
          <Route path="/compartilhamento" component={ShareValidation} />
          <Route
            path="/qr-code-user-validator/:id"
            component={qrcodeValidation}
          />
          <Route
            path="/redefinir-senha/:userHash/:userEmail"
            component={NewPassword}
          />
          <Route path="/cadastrar" component={SignupStepForm} />
          <Route path="/logout" component={Logout} />
          <Route
            path="/email/:type/:userId/:userHash"
            component={EmailSubscription}
          />
          <Route path="/atividade/:id" component={ActivityPublic} />
          <Route path="/" component={authOrApp} />
        </Switch>
      </HashRouter>
    );
  }
}
