import type from './types';
import {
  getListTransform,
  getDetailTransform,
} from 'helpers/transformResponse';

const INITIAL_STATE = {
  aboutList: {
    content: [],
  },
  benefitsList: {
    content: [],
  },
  detail: {},
  stationData: {},
  stationDataLoading: true,
  publicActions: [],
  publicActionsLoading: true,
  aboutLoading: true,
  benefitsLoading: true,
  buttonLoading: false,
  headerIndex: 0,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ABOUT_FETCHED':
      return {
        ...state,
        aboutList: getListTransform(action.payload.data) || INITIAL_STATE.list,
        aboutLoading: false,
      };
    case 'BENEFITS_FETCHED':
      return {
        ...state,
        benefitsList:
          getListTransform(action.payload.data) || INITIAL_STATE.list,
        benefitsLoading: false,
      };
    case type.ITEM_DETAIL:
      return {
        ...state,
        detail: getDetailTransform(action.payload.data) || INITIAL_STATE.detail,
        loading: false,
      };
    case 'ABOUT_LOAD':
      return {
        ...state,
        aboutLoading: action.payload,
      };
    case 'BENEFITS_LOAD':
      return {
        ...state,
        benefitsLoading: action.payload,
      };
    case 'STATION_DATA_LOAD':
      return {
        ...state,
        stationDataLoading: action.payload,
      };
    case 'STATION_DATA_FETCHED':
      return {
        ...state,
        stationData: action.payload,
        stationDataLoading: false,
      };
    case 'STATION_PUBLIC_ACTIONS_LOAD':
      return {
        ...state,
        publicActionsLoading: action.payload,
      };
    case 'STATION_PUBLIC_ACTIONS_FETCHED':
      return {
        ...state,
        publicActions: action.payload,
        publicActionsLoading: false,
      };
    case type.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    case 'CHANGE_HEADER_INDEX':
      return {
        ...state,
        headerIndex: action.payload,
      };
    default:
      return state;
  }
};
