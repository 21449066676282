import React from 'react';
import { stylesheets } from 'styles/stylesheets.jsx';
import { Card, CardTitle, CardText, CircularProgress } from 'react-md';
import Content from 'common/components/content/content';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getQuizzes, dispatchChosenQuiz } from './quizzesActions';
import moment from 'moment';
import { getUserPoints } from '../../../homepageActions';

class Quizzes extends React.Component {
  constructor(props) {
    super(props);
    this.props.getQuizzes(this.props.auth.user.establishment_participant);
    this.props.getUserPoints(this.props.auth.user);
  }

  jsonnify = (item) => {
    const questions = JSON.parse(item.questions);
    delete item['questions'];
    item['questions'] = questions;
    for (var i in item.questions) {
      item.questions[i]['questionType'] = 'text';
    }
    return item;
  };

  dispatchQuizChosen = (quiz) => {
    this.props.dispatchChosenQuiz(this.jsonnify(quiz), this.props.history);
  };

  renderQuizzes = () => {
    return this.props.quizzes.list.map((item, index) => (
      <div
        style={{
          ...stylesheets.flexboxChild,
          minWidth: '30vw',
          margin: 15,
        }}
      >
        <Card>
          <CardTitle
            key={index}
            title={item.name}
            subtitle={`Participe e ganhe ${item.points} pontos.`}
          />
          <CardText>
            <p>{item.description}</p>
            <p>Válido até: {moment(item.expiration).format('DD/MM/YYYY')}</p>
            <button
              onClick={() => this.dispatchQuizChosen(item)}
              className="btn btn-primary"
              type="button"
            >
              Participar
            </button>
          </CardText>
        </Card>
      </div>
    ));
  };

  render() {
    if (this.props.quizzes.loading) {
      return <CircularProgress id="quizzes" />;
    }
    return (
      <Content>
        <div style={{ ...stylesheets.flexboxParent }}>
          {this.renderQuizzes()}
        </div>
      </Content>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quizzes: state.quizzes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getQuizzes, dispatchChosenQuiz, getUserPoints },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Quizzes);
