import { Box, Dialog, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { ReactComponent as CheckCircleIcon } from '../../../../../assets/icons/check-circle.svg';
import { formatPhone } from '../../../../../helpers/utils';

// import { Container } from './styles';

function PoliticalThemesFormModal({
  onClose,
  isOpen,
  onSubmit,
  wasSuccessful,
  user,
}) {
  const [description, setDescription] = useState(null);

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="sm">
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon
          onClick={onClose}
          style={{
            cursor: 'pointer',
            fontSize: 32,
            marginTop: 12,
            marginRight: 12,
          }}
        />
      </Box>
      {wasSuccessful ? (
        <Box
          style={{ padding: '24px 71px 48px 71px' }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={3}
        >
          <CheckCircleIcon color={'#F98979'} height="42" width="42" />
          <p className="h3 mt-4 text-center" style={{ maxWidth: 400 }}>
            Sua solicitação foi realizada com sucesso!
          </p>
          <p className="h7 mt-3 text-center" style={{ maxWidth: 400 }}>
            Em breve entraremos em contato para confirmar os horários
            disponíveis.
          </p>
        </Box>
      ) : (
        <Box style={{ padding: '24px 71px' }}>
          <p className="h4-bold text-center">Sugestão de tema</p>
          <Box display="flex" mt={4} justifyContent="space-between">
            <p className="h6">Nome: {user.name.split(' ')[0]}</p>
            <p className="h6 ml-3">Celular: {formatPhone(user.tel)}</p>
          </Box>
          <Box display="flex" mt={2} justifyContent="space-between">
            <p className="h6">E-mail: {user.email}</p>
          </Box>
          <p className="h6-bold mt-4">Descrição do tema</p>
          <TextField
            label="Descrição"
            multiline
            rows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            style={{ width: '100%', marginTop: 8 }}
          />
          <Box display="flex" justifyContent="flex-end">
            <button
              className="mt-5"
              style={{
                backgroundColor: '#378587',
                padding: '16px 48px',
                color: '#fff',
              }}
              onClick={() => onSubmit({ description })}
            >
              Enviar
            </button>
          </Box>
        </Box>
      )}
    </Dialog>
  );
}

export default PoliticalThemesFormModal;
