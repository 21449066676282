import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './types';

const URL_VOUCHERS = `${BASE_API}/product-orders`;
const URL_VOUCHERS_USER = `${BASE_API}/product-orders-user`;

export const getListByUserId = (id) => {
  return (dispatch) => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL_VOUCHERS_USER}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getListById = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: type.ITEM_DETAIL_LOAD, payload: true });
    axios
      .get(`${URL_VOUCHERS}/?where[id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: response,
          },
        ]);
        if (callback) callback(response.data[0]);
      });
  };
};

export const setLoading = (value) => {
  return (dispatch) => {
    dispatch([{ type: type.LIST_LOAD, payload: value }]);
  };
};
