import { Box, Grid, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray, Form, reduxForm } from 'redux-form';
import {
  COMPANY_COLORS,
  COMPANY_NAME,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  COMPANY_STATION_ID,
  LINK_TEXT_COLOR,
} from '../../../config/consts';
import { short_url } from '../../../modules/user/pages/activities/activitiesActions';
import AlertDialogSlideCoupon from '../../components/dialog/buyCouponDialog';
import ActivityButton from '../buttons/activityButton';
import ButtonPrimary from '../buttons/ButtonPrimary';
import AlertDialogSlide from '../dialog/generalDialog';
import { emailForms } from '../forms/forms';
import GeneralGrid from '../layout/grid/GeneralGrid';
import IF from '../operador/if';
import './moduleCard/moduleCard.css';
import CashbackCard from './secudaryActivitiesCards/cashbackCard';
import PriceCard from './secudaryActivitiesCards/priceCard';
import ShareCard from './secudaryActivitiesCards/shareCard';
import ProductCard from './secudaryActivitiesCards/productsCards';
import moment from 'moment';

const dispatchError = () => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: 'Limite de contatos alcançado',
        variant: 'error',
        open: true,
      },
    });
  };
};

const checkLimitPerUser = (action) => {
  if (parseInt(action.limitPerUser) <= action.userActions.length) {
    return false;
  } else return true;
};

let CallToActionCard = function (props) {
  const [codeVisible, setCodeVisible] = useState(false);
  const [emailVisible, setEmailVisible] = useState(false);
  const [isCupomModalOpen, setIsCupomModalOpen] = useState(false);
  const currencyData = {
    currency_multiplier: props.stationData?.currency_multiplier,
    currency_name: props.stationData?.currency_name,
    currency_name_position: props.stationData?.currency_name_position,
  };

  const { participationStart } = props.activities.detail;
  const { participationEnd } = props.activities.detail;

  const textAreaRef = useRef(null);
  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  }

  if (props.activity.type_id === 1) {
    let link;
    if (!props.public) {
      link = `${props.website.stationData.url}/#/cadastrar/${props.activity.member_get_members[0].code}-U${props.auth.user.id}-E${COMPANY_STATION_ID}`;
    }

    if (process.env.REACT_APP_API_KEY === 'test' && !props.public) {
      link = link.replace('.app', '.site');
    }

    props.short_url(link);

    function callToActionFunction() {
      if (props.onClick) {
        props.onClick();
      } else {
        setCodeVisible(true);
        setEmailVisible(true);
      }
    }

    function onSubmit(values) {
      props.submitEmails(
        props.auth.user.name,
        values,
        props.activities.short_link_mgm,
        COMPANY_NAME,
        props.activity.member_get_members[0].points_invited,
        props.history,
        props.auth.user.station.id
      );
    }

    function handleMgmModal() {
      setCodeVisible(false);
    }

    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          <CashbackCard
            currencyData={currencyData}
            type={props.activity.type_id}
            cashback={props.activity.member_get_members[0].points_inviter}
            invitedCashback={
              props.activity.member_get_members[0].points_invited
            }
            secudaryColor={COMPANY_COLORS.general.general_color}
          />
        </Box>
        {participationStart && participationEnd ? (
          moment().isBetween(participationStart, participationEnd) ? (
            props.public ? (
              <ActivityButton
                onClick={() => props.history.push('/cadastrar')}
                disabled={props.activities.buttonLoading}
                progress={parseInt(props.general.submitProgress)}
                label={'Gerar convite'}
              />
            ) : checkLimitPerUser(props.activity) ? (
              <>
                <ActivityButton
                  onClick={callToActionFunction}
                  label={'Gerar convite'}
                />
                <AlertDialogSlide
                  onClose={handleMgmModal}
                  visible={codeVisible}
                  backgroundColor={COMPANY_SIGNED_IN_BACKGROUND_COLOR}
                >
                  <div>
                    <Typography
                      variant="body1"
                      className="text-left"
                      // style={{ color: COMPANY_COLORS.font.gray }}
                    >
                      Copie e compartilhe o link ou envie por e-mail abaixo:
                    </Typography>
                    <GeneralGrid container spacing={2}>
                      <GeneralGrid item xs={10}>
                        <textarea
                          className="text-link"
                          spellcheck="false"
                          style={{
                            resize: 'none',
                            color: LINK_TEXT_COLOR,
                            backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                          }}
                          ref={textAreaRef}
                          value={props.activities.short_link_mgm}
                        />
                      </GeneralGrid>
                      <GeneralGrid item xs={2}>
                        <Fab
                          onClick={copyToClipboard}
                          style={{
                            boxShadow: 'none',
                            background: 'transparent',
                            border: 'none',
                          }}
                        >
                          <FileCopyIcon />
                        </Fab>
                      </GeneralGrid>
                    </GeneralGrid>
                  </div>
                  <IF test={emailVisible}>
                    <Form role="form" onSubmit={props.handleSubmit(onSubmit)}>
                      <FieldArray
                        name="emails"
                        component={emailForms}
                        props={props}
                      />
                      <GeneralGrid container spacing={2}>
                        <ButtonPrimary type="submit" color="primary">
                          Enviar Convite
                        </ButtonPrimary>
                      </GeneralGrid>
                    </Form>
                  </IF>
                </AlertDialogSlide>
              </>
            ) : (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                xs={12}
                spacing={2}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <Typography variant="body1">
                    Olá, você já ultrapassou o limite de participações nessa
                    atividade!
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <ActivityButton
                    onClick={() => props.history.push('/homepage/vouchers')}
                    label={'Meus Vouchers'}
                    style={{ marginTop: 15 }}
                  />
                </Grid>
              </Grid>
            )
          ) : (
            <Typography variant="body1">
              {`Você pode participar entre os dias ${moment(
                participationStart
              ).format('DD/MM/YYYY')}
              às ${moment(participationStart).format('HH:mm')} e
              ${moment(participationEnd).format('DD/MM/YYYY')}
              às ${moment(participationEnd).format('HH:mm')}.`}
            </Typography>
          )
        ) : props.public ? (
          <ActivityButton
            onClick={() => props.history.push('/cadastrar')}
            disabled={props.activities.buttonLoading}
            progress={parseInt(props.general.submitProgress)}
            label={'Gerar convite'}
          />
        ) : checkLimitPerUser(props.activity) ? (
          <>
            <ActivityButton
              onClick={callToActionFunction}
              label={'Gerar convite'}
            />
            <AlertDialogSlide onClose={handleMgmModal} visible={codeVisible}>
              <div>
                <p
                  className="text-left"
                  style={{ color: COMPANY_COLORS.font.gray }}
                >
                  Copie e compartilhe o link ou envie por e-mail abaixo:
                </p>
                <GeneralGrid container spacing={2}>
                  <GeneralGrid item xs={10}>
                    <textarea
                      className="text-link"
                      spellcheck="false"
                      style={{
                        resize: 'none',
                      }}
                      ref={textAreaRef}
                      value={props.activities.short_link_mgm}
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={2}>
                    <Fab
                      onClick={copyToClipboard}
                      style={{
                        boxShadow: 'none',
                        background: 'transparent',
                        border: 'none',
                      }}
                    >
                      <FileCopyIcon />
                    </Fab>
                  </GeneralGrid>
                </GeneralGrid>
              </div>

              <IF test={emailVisible}>
                <Form role="form" onSubmit={props.handleSubmit(onSubmit)}>
                  <FieldArray
                    name="emails"
                    component={emailForms}
                    props={props}
                  />
                  <GeneralGrid container spacing={2}>
                    <ButtonPrimary type="submit" color="primary">
                      Enviar convite
                    </ButtonPrimary>
                  </GeneralGrid>
                </Form>
              </IF>
            </AlertDialogSlide>
          </>
        ) : (
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Typography variant="body1">
              Olá, você já ultrapassou o limite de participações nessa
              atividade!
            </Typography>
            <ActivityButton
              onClick={() => props.history.push('/homepage/vouchers')}
              label={'Ir para meus Vouchers!'}
              style={{ marginTop: 15 }}
            />
          </Box>
        )}
      </>
    );
  } else if (props.activity.type_id === 2) {
    return props.activity.points > 0 || props.activity.additional_cashback ? (
      <div className="d-flex flex-column px-md-5 py-md-4 px-3 py-4">
        <CashbackCard
          currencyData={currencyData}
          type={props.activity.type_id}
          cashback={
            parseFloat(props.activity.points) +
            (props.activity.additional_cashback
              ? parseFloat(props.activity.additional_cashback)
              : 0.0)
          }
          secudaryColor={COMPANY_COLORS.general.general_color}
        />
        <ButtonPrimary onClick={() => props.history.push('/cadastrar')}>
          Cadastrar
        </ButtonPrimary>
      </div>
    ) : (
      <div className="d-flex flex-column px-md-5 py-md-4 px-3 py-4" style={{}}>
        <ButtonPrimary onClick={() => props.history.push('/cadastrar')}>
          Cadastrar
        </ButtonPrimary>
      </div>
    );
  } else if (props.activity.type_id === 3) {
    const values = {
      presential_course: props.activity.cupons[0].id,
    };

    function handleOpenModal() {
      setIsCupomModalOpen(true);
    }

    function handleCloseModal() {
      setIsCupomModalOpen(false);
    }

    function handleBuy() {
      props.onClick(values);
    }

    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          {props.activity.products.length > 0 ? (
            <ProductCard
              price={props.activity.products}
              secudaryColor={COMPANY_COLORS.general.general_color}
            />
          ) : null}
          {props.activity.points > 0 || props.activity.additional_cashback ? (
            <CashbackCard
              currencyData={currencyData}
              type={props.activity.type_id}
              cashback={
                parseFloat(props.activity.points) +
                (props.activity.additional_cashback
                  ? parseFloat(props.activity.additional_cashback)
                  : 0.0)
              }
              secudaryColor={COMPANY_COLORS.general.general_color}
            />
          ) : null}
        </Box>
        {participationStart && participationEnd ? (
          moment().isBetween(participationStart, participationEnd) ? (
            props.public ? (
              <ActivityButton
                onClick={() => props.onClick(values)}
                disabled={props.activities.buttonLoading || props.noCredits}
                progress={parseInt(props.general.submitProgress)}
                label={'Pagar'}
              />
            ) : checkLimitPerUser(props.activity) ? (
              <ActivityButton
                onClick={handleOpenModal}
                disabled={props.activities.buttonLoading || props.noCredits}
                progress={parseInt(props.general.submitProgress)}
                label={
                  props.noCredits
                    ? `Faltam R$${
                        props.activity.cupons[0].newPrice - props.auth.points
                      }`
                    : 'Pagar'
                }
              />
            ) : (
              <Box display="flex" alignItems="center" flexDirection="column">
                <Typography variant="body1">
                  Olá, você já ultrapassou o limite de participações nessa
                  atividade!
                </Typography>
                <div style={{ marginTop: 40 }}>
                  <ActivityButton
                    onClick={() => props.history.push('/homepage/vouchers')}
                    label={'Ir para meus Vouchers!'}
                  />
                </div>
              </Box>
            )
          ) : (
            <Typography variant="body1">
              {`Você pode participar entre os dias ${moment(
                participationStart
              ).format('DD/MM/YYYY')}
              às ${moment(participationStart).format('HH:mm')} e
              ${moment(participationEnd).format('DD/MM/YYYY')}
              às ${moment(participationEnd).format('HH:mm')}.`}
            </Typography>
          )
        ) : props.public ? (
          <ActivityButton
            onClick={() => props.onClick()}
            disabled={props.activities.buttonLoading || props.noCredits}
            progress={parseInt(props.general.submitProgress)}
            label={'Pagar'}
          />
        ) : checkLimitPerUser(props.activity) ? (
          <ActivityButton
            onClick={() =>
              props.history.push(`/homepage/shopping/${props.activity.id}`)
            }
            disabled={props.activities.buttonLoading || props.noCredits}
            progress={parseInt(props.general.submitProgress)}
            label={'Comprar'}
          />
        ) : (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="body1">
              Olá, você já ultrapassou o limite de participações nessa
              atividade!
            </Typography>
            <div style={{ marginTop: 40 }}>
              <ActivityButton
                onClick={() => props.history.push('/homepage/pedidos')}
                label={'Ir para meus Vouchers!'}
              />
            </div>
          </Box>
        )}
        {isCupomModalOpen ? (
          <AlertDialogSlideCoupon
            maxWidth="sm"
            title="Confirmação"
            onClose={handleCloseModal}
            visible={isCupomModalOpen}
          >
            <div className="px-4 pb-4">
              <Typography style={{ marginBottom: 20 }}>
                Tem certeza que deseja pagar o seguinte item?
              </Typography>
              <Typography
                style={{ color: COMPANY_COLORS.primary, fontWeight: 450 }}
                variant="h6"
              >
                Atividade:
              </Typography>
              <Typography variant="subtitle1">{props.activity.name}</Typography>
              <Typography
                style={{ color: COMPANY_COLORS.primary, fontWeight: 450 }}
                variant="h6"
              >
                Custo:
              </Typography>
              <Typography variant="subtitle1">
                {`R$ ${parseFloat(props.activity.cupons[0].newPrice)
                  .toFixed(2)
                  .replace('.', ',')} `}
              </Typography>
              <ButtonPrimary
                onClick={() => handleBuy()}
                disabled={props.activities.buttonLoading || props.noCredits}
                progress={parseInt(props.general.submitProgress)}
                style={{
                  color: COMPANY_COLORS.website.navbar.button.text,
                  marginTop: 20,
                }}
                noMargin
              >
                {props.noCredits
                  ? `Faltam R$${(
                      parseFloat(props.activity.cupons[0].newPrice) -
                      parseFloat(props.auth.points)
                    )
                      .toFixed(2)
                      .replace('.', ',')}`
                  : props.activities.buttonLoading
                  ? 'Pagando'
                  : 'Pagar'}
              </ButtonPrimary>
            </div>
          </AlertDialogSlideCoupon>
        ) : null}
      </>
    );
  } else if (props.activity.type_id === 4) {
    const values = {
      presential_course: props.activity.presential_courses[0].id,
    };
    return (
      <Grid item container justifyContent="center" alignItems="center" xs={12}>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          {parseFloat(
            props.activity.presential_courses[0].discount_price
          ).toFixed(2) > 0.0 ? (
            <PriceCard
              price={props.activity.presential_courses[0].price}
              newPrice={props.activity.presential_courses[0].discount_price}
              secudaryColor={COMPANY_COLORS.general.general_color}
              allowance={props.activity.allowance}
            />
          ) : null}
          {props.activity.points > 0 || props.activity.additional_cashback ? (
            <CashbackCard
              currencyData={currencyData}
              type={props.activity.type_id}
              cashback={
                parseFloat(props.activity.points) +
                (props.activity.additional_cashback
                  ? parseFloat(props.activity.additional_cashback)
                  : 0.0)
              }
              secudaryColor={COMPANY_COLORS.general.general_color}
            />
          ) : null}
        </Box>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          {participationStart && participationEnd ? (
            moment().isBetween(participationStart, participationEnd) ? (
              props.public ? (
                <ActivityButton
                  presential_course
                  onClick={() => props.onClick(values)}
                  disabled={props.activities.buttonLoading}
                  progress={parseInt(props.general.submitProgress)}
                  label={'Eu quero!'}
                />
              ) : checkLimitPerUser(props.activity) ? (
                <ActivityButton
                  presential_course
                  onClick={({ should_contact }) =>
                    props.onClick({ ...values, should_contact })
                  }
                  disabled={props.activities.buttonLoading}
                  progress={parseInt(props.general.submitProgress)}
                  label={
                    props.noCredits
                      ? `Faltam R$${
                          props.activity.cupons[0].newPrice - props.auth.points
                        }`
                      : props.activities.buttonLoading
                      ? 'Inscrevendo...'
                      : 'Eu quero!'
                  }
                />
              ) : (
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Typography variant="body1">
                    Olá, você já ultrapassou o limite de participações nessa
                    atividade!
                  </Typography>
                  <div style={{ marginTop: 40 }}>
                    <ActivityButton
                      onClick={() => props.history.push('/homepage/vouchers')}
                      label={'Ir para meus Vouchers!'}
                    />
                  </div>
                </Box>
              )
            ) : (
              <Typography variant="body1">
                {`Você pode participar entre os dias ${moment(
                  participationStart
                ).format('DD/MM/YYYY')}
                às ${moment(participationStart).format('HH:mm')} e
                ${moment(participationEnd).format('DD/MM/YYYY')}
                às ${moment(participationEnd).format('HH:mm')}.`}
              </Typography>
            )
          ) : props.public ? (
            <ActivityButton
              presential_course
              onClick={() => props.onClick()}
              disabled={props.activities.buttonLoading}
              progress={parseInt(props.general.submitProgress)}
              label={'Eu quero!'}
            />
          ) : checkLimitPerUser(props.activity) ? (
            <ActivityButton
              presential_course
              onClick={({ should_contact }) =>
                props.onClick({ ...values, should_contact })
              }
              disabled={props.activities.buttonLoading}
              progress={parseInt(props.general.submitProgress)}
              label={
                props.noCredits
                  ? `Faltam R$${
                      props.activity.cupons[0].newPrice - props.auth.points
                    }`
                  : props.activities.buttonLoading
                  ? 'Inscrevendo...'
                  : 'Eu quero!'
              }
            />
          ) : (
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="body1">
                Olá, você já ultrapassou o limite de participações nessa
                atividade!
              </Typography>
              <div style={{ marginTop: 40 }}>
                <ActivityButton
                  onClick={() => props.history.push('/homepage/vouchers')}
                  label={'Ir para meus Vouchers!'}
                />
              </div>
            </Box>
          )}
        </Grid>
      </Grid>
    );
  } else if (props.activity.type_id === 5) {
    const newLink =
      process.env.REACT_APP_API_KEY === 'development'
        ? `http://localhost:3000/#/compartilhamento?source=${props.auth?.user?.id}&campaign=${props.activity?.id}`
        : `${props.website.stationData?.url}/#/compartilhamento?source=${props.auth?.user?.id}&campaign=${props.activity?.id}`;

    return props.public ? (
      <ShareCard
        currencyData={currencyData}
        cashback={
          parseFloat(props.activity.points) +
          (props.activity.additional_cashback
            ? parseFloat(props.activity.additional_cashback)
            : 0.0)
        }
        public={props.public || false}
        history={props.history}
        url={newLink}
        title={props.activities.detail.contents[0].text}
        secudaryColor={COMPANY_COLORS.general.general_color}
      />
    ) : (
      <ShareCard
        currencyData={currencyData}
        cashback={
          parseFloat(props.activity.points) +
          (props.activity.additional_cashback
            ? parseFloat(props.activity.additional_cashback)
            : 0.0)
        }
        public={props.public || false}
        history={props.history}
        onClick={() => props.onClick()}
        url={newLink}
        title={props.activities.detail.contents[0].text}
        activity={checkLimitPerUser(props.activity)}
        secudaryColor={COMPANY_COLORS.general.general_color}
      />
    );
  } else if (
    props.activity.type_id === 6 ||
    props.activity.type_id === 7 ||
    props.activity.type_id === 8 ||
    props.activity.type_id === 9 ||
    props.activity.type_id === 10 ||
    props.activity.type_id === 11 ||
    props.activity.type_id === 12 ||
    props.activity.type_id === 14
  ) {
    const values = {
      user: props.auth.user ? props.auth.user.id : null,
    };

    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{
            width: '100%',
            marginBottom: 40,
          }}
        >
          {props.activity.points > 0 || props.activity.additional_cashback ? (
            <CashbackCard
              currencyData={currencyData}
              type={props.activity.type_id}
              cashback={
                parseFloat(props.activity.points) +
                (props.activity.additional_cashback
                  ? parseFloat(props.activity.additional_cashback)
                  : 0.0)
              }
              secudaryColor={COMPANY_COLORS.general.general_color}
            />
          ) : null}
        </Box>
        {participationStart && participationEnd ? (
          moment().isBetween(participationStart, participationEnd) ? (
            props.public ? (
              <ActivityButton
                onClick={() => props.onClick(values)}
                agreement={props.activity.type_id === 15 ? true : false}
                disabled={props.activities.buttonLoading || props.noCredits}
                progress={parseInt(props.general.submitProgress)}
                label={props.activityButtonLabel || 'Responder'}
                checkboxLabel={props.activityCheckbox}
              />
            ) : checkLimitPerUser(props.activity) ? (
              <ActivityButton
                onClick={() => props.onClick(values)}
                agreement={props.activity.type_id === 15 ? true : false}
                disabled={props.activities.buttonLoading || props.noCredits}
                progress={parseInt(props.general.submitProgress)}
                label={props.activityButtonLabel || 'Responder'}
                checkboxLabel={props.activityCheckbox}
              />
            ) : (
              <Typography variant="body1">
                Olá, você já ultrapassou o limite de participações nessa
                atividade!
              </Typography>
            )
          ) : (
            <Typography variant="body1">
              {`Você pode participar entre os dias ${moment(
                participationStart
              ).format('DD/MM/YYYY')}
              às ${moment(participationStart).format('HH:mm')} e
              ${moment(participationEnd).format('DD/MM/YYYY')}
              às ${moment(participationEnd).format('HH:mm')}.`}
            </Typography>
          )
        ) : props.public ? (
          <ActivityButton
            onClick={() => props.onClick(values)}
            agreement={props.activity.type_id === 15 ? true : false}
            disabled={props.activities.buttonLoading || props.noCredits}
            progress={parseInt(props.general.submitProgress)}
            label={props.activityButtonLabel || 'Responder'}
            checkboxLabel={props.activityCheckbox}
          />
        ) : checkLimitPerUser(props.activity) ? (
          <ActivityButton
            onClick={() => props.onClick(values)}
            agreement={props.activity.type_id === 15 ? true : false}
            disabled={props.activities.buttonLoading || props.noCredits}
            progress={parseInt(props.general.submitProgress)}
            label={props.activityButtonLabel || 'Responder'}
            checkboxLabel={props.activityCheckbox}
          />
        ) : (
          <Typography variant="body1">
            Olá, você já ultrapassou o limite de participações nessa atividade!
          </Typography>
        )}
      </>
    );
  } else if (props.activity.type_id === 15) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{
            width: '100%',
            marginBottom: 40,
          }}
        >
          {props.activity.points > 0 || props.activity.additional_cashback ? (
            <CashbackCard
              currencyData={currencyData}
              type={props.activity.type_id}
              cashback={
                parseFloat(props.activity.points) +
                (props.activity.additional_cashback
                  ? parseFloat(props.activity.additional_cashback)
                  : 0.0)
              }
              secudaryColor={COMPANY_COLORS.general.general_color}
            />
          ) : null}
        </Box>
        <Grid item container xs={12} md={8} justifyContent="center">
          {participationStart && participationEnd ? (
            moment().isBetween(participationStart, participationEnd) ? (
              props.public ? (
                <ActivityButton
                  onClick={() => props.onClick()}
                  agreement
                  disabled={props.activities.buttonLoading || props.noCredits}
                  progress={parseInt(props.general.submitProgress)}
                  label={props.activityButtonLabel || 'Responder'}
                  checkboxLabel={props.activityCheckbox}
                />
              ) : checkLimitPerUser(props.activity) ? (
                <ActivityButton
                  onClick={() =>
                    props.onClick(
                      { action_id: props.activity.id },
                      props.history
                    )
                  }
                  agreement
                  disabled={props.activities.buttonLoading || props.noCredits}
                  progress={parseInt(props.general.submitProgress)}
                  label={props.activityButtonLabel || 'Responder'}
                  checkboxLabel={props.activityCheckbox}
                />
              ) : (
                <Typography variant="body1">
                  Olá, você já ultrapassou o limite de participações nessa
                  atividade!
                </Typography>
              )
            ) : (
              <Typography variant="body1">
                {`Você pode participar entre os dias ${moment(
                  participationStart
                ).format('DD/MM/YYYY')}
                às ${moment(participationStart).format('HH:mm')} e
                ${moment(participationEnd).format('DD/MM/YYYY')}
                às ${moment(participationEnd).format('HH:mm')}.`}
              </Typography>
            )
          ) : props.public ? (
            <ActivityButton
              onClick={() => props.onClick()}
              agreement
              disabled={props.activities.buttonLoading || props.noCredits}
              progress={parseInt(props.general.submitProgress)}
              label={props.activityButtonLabel || 'Responder'}
              checkboxLabel={props.activityCheckbox}
            />
          ) : checkLimitPerUser(props.activity) ? (
            <ActivityButton
              onClick={() =>
                props.onClick({ action_id: props.activity.id }, props.history)
              }
              agreement
              disabled={props.activities.buttonLoading || props.noCredits}
              progress={parseInt(props.general.submitProgress)}
              label={props.activityButtonLabel || 'Responder'}
              checkboxLabel={props.activityCheckbox}
            />
          ) : (
            <Typography variant="body1">
              Olá, você já ultrapassou o limite de participações nessa
              atividade!
            </Typography>
          )}
        </Grid>
      </>
    );
  } else return null;
};

CallToActionCard = reduxForm({ form: 'emailForms' })(CallToActionCard);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    general: state.general,
    stationData: state.website.stationData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ dispatchError, short_url }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CallToActionCard);
