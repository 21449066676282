import { Box, Grid } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getThemesList, sendThemeSuggestion } from './politicalActions';
import PoliticalThemesCard from './politicalThemesCard';
import PoliticalThemesDetailModal from './politicalThemesDetailModal';
import PoliticalThemesFormModal from './politicalThemesFormModal';
import './styles.css';

const PoliticalThemes = ({
  getThemesList,
  political,
  auth,
  sendThemeSuggestion,
}) => {
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [wasSuccessful, setWasSuccessful] = useState(false);

  function handleClose() {
    setSelectedTheme(null);
    setIsDetailDialogOpen(false);
  }

  function handleOpen(themes) {
    setSelectedTheme(themes);
    setIsDetailDialogOpen(true);
  }

  function handleCloseForm() {
    setIsFormDialogOpen(false);
    setWasSuccessful(false);
  }

  function handleOpenForm(themes) {
    setIsFormDialogOpen(true);
  }

  function handleSubmit(values) {
    function cleanup() {
      setWasSuccessful(true);
    }
    sendThemeSuggestion(values, cleanup);
  }

  useEffect(() => {
    getThemesList();
  }, [getThemesList]);

  const { list, loading } = political;

  return (
    <section id="themes">
      <Box display="flex" justifyContent="space-between" mt={3}>
        <p className="body-title mt-4">Temas de interesse</p>
        <button
          style={{
            backgroundColor: '#378587',
            padding: '16px 32px',
            color: '#fff',
          }}
          onClick={() => handleOpenForm()}
        >
          Sugerir tema
        </button>
      </Box>
      {loading ? (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      ) : list.length > 0 ? (
        <Grid container spacing={3} style={{ marginTop: 32 }}>
          {list.map((item) => (
            <Grid item xs={4}>
              <PoliticalThemesCard
                onClick={() => handleOpen(item)}
                header={item.tipo_tema}
                body={item.titulo}
                footer={item.interessados.join(', ')}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <p className="h4 mt-4">Não foram encontrados temas.</p>
      )}
      {selectedTheme && (
        <PoliticalThemesDetailModal
          onClose={handleClose}
          isOpen={isDetailDialogOpen}
          title={selectedTheme.tipo_tema}
          type={selectedTheme.tipo}
          body={selectedTheme.titulo}
          footer1={selectedTheme.interessados.join(', ')}
          selected={selectedTheme}
        />
      )}
      {isFormDialogOpen && (
        <PoliticalThemesFormModal
          onClose={handleCloseForm}
          isOpen={isFormDialogOpen}
          onSubmit={handleSubmit}
          wasSuccessful={wasSuccessful}
          user={auth.user}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    political: state.political,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getThemesList,
      sendThemeSuggestion,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PoliticalThemes);
