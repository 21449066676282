import { Box } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { CampaignListFuel } from './components/campaignList';
import ModalDetail from './components/modalDetail';
import Tabs from './components/tabs';
import { VouchersList } from './components/vouchersList';
import {
  generateVoucher,
  getCampaignList,
  getVouchersList,
} from './legisClubFuelsActions';

function LegisClubFuels(props) {
  const [openModal, setOpenModal] = useState(false);
  const [item, setItem] = useState(undefined);
  const {
    handleSubmit,
    legisClubFuels: {
      list,
      loading,
      buttonLoading,
      listVouchers,
      loadingVouchers,
    },
    generateVoucher,
    auth: {
      user: { id: userId },
    },
    getCampaignList,
    getVouchersList,
  } = props;

  useEffect(() => {
    getCampaignList();
    getVouchersList(userId);
  }, [userId, getVouchersList, getCampaignList]);

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      ></section>
    );
  }

  if (loading || loadingVouchers) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }

  function submit(values) {
    values['user_id'] = userId;
    generateVoucher(values);
  }

  return (
    <div className="mt-3 ml-5">
      <form onSubmit={handleSubmit(submit)}>
        <Tabs
          tabs={[
            {
              label: `Campanhas`,
              screen: (
                <CampaignListFuel
                  list={list.content}
                  disabled={buttonLoading}
                />
              ),
            },
            {
              label: `Meus Vouchers`,
              screen: (
                <VouchersList
                  list={listVouchers.content}
                  image={list.content[0].campanha.imagem}
                  onClickItem={(item) => {
                    setItem(item);
                    setOpenModal(true);
                  }}
                />
              ),
            },
          ]}
        />
        {item ? (
          <ModalDetail open={openModal} setOpen={setOpenModal} item={item} />
        ) : null}
      </form>
    </div>
  );
}

const LegisClubFuelsForm = reduxForm({ form: 'legisClubFuelsForm' })(
  LegisClubFuels
);
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    legisClubFuels: state.legisClubFuels,
    formValues: getFormValues('legisClubFuelsForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCampaignList,
      generateVoucher,
      getVouchersList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LegisClubFuelsForm);
