import React, { Component } from 'react';
import axios from 'axios';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Image from '@material-ui/icons/ImageOutlined';
import 'react-image-crop/dist/ReactCrop.css';
import { SimpleButton } from '../../buttons/styles';
import AlertDialogSlide from '../../dialog/dialog';
import { BASE_API, BASE_S3, USER_TOKEN } from 'config/consts';
import {
  BUTTON_BACKGROUND_COLOR,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
} from 'config/consts';

const UPLOAD_IMAGE_FILE = `${BASE_API}/files/actions`;

export function uploadImageFileToS3(file) {
  const formData = new FormData();

  formData.append('file', file);
  return axios
    .post(`${UPLOAD_IMAGE_FILE}`, formData, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    });
}

export default class InputEasyCutImage extends Component {
  state = {
    open: false,
    src: null,
    fileType: null,
    crop: {
      unit: '%',
      width: 50,
      height: 50,
      aspect: this.props.aspect,
    },
    imageLoading: false,
    maxSize: this.props.maxSize || 1000000,
    acceptVideo: this.props.acceptVideo || false,
  };

  uploadImage = async (file = this.state.file) => {
    const data = await uploadImageFileToS3(file);
    this.props.input.onChange(data);
    this.setState({ imageLoading: false });
  };

  onSelectFile = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type.includes('image')) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.setState({
            src: reader.result,
            fileType: 'image',
            open: true,
          });
        });
        reader.readAsDataURL(files[0]);
      } else if (file.type.includes('video')) {
        this.setState({
          fileType: 'video',
          imageLoading: true,
        });
        this.uploadImage(file);
      }
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const { base64: croppedImgBase64, file } = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png'
      );
      this.setState({ croppedImgBase64, file });
    }
  }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      const base64Image = canvas.toDataURL('image/jpeg', 1);
      var file = this.dataURLtoFile(base64Image, 'image.jpeg');
      resolve({ base64: base64Image, file });
    });
  }

  render() {
    const { crop, croppedImgBase64, src, fileType, maxSize, acceptVideo } =
      this.state;
    const {
      meta: { touched, error },
      input: { value },
      label,
      meta,
    } = this.props;

    function dropzoneProps() {
      const fileFormats = `image/jpeg, image/png, image/gif${
        acceptVideo ? ', video/*' : ''
      }`;

      return {
        accept: fileFormats,
        multiple: false,
        maxSize,
        onDropRejected: () =>
          meta.dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: Imagem/Vídeo maior que ${maxSize / 1000000} MB`,
              variant: 'error',
              open: true,
            },
          }),
      };
    }

    return (
      <>
        <Backdrop
          style={{
            zIndex: 1500 + 1,
            color: BUTTON_BACKGROUND_COLOR,
          }}
          open={this.state.imageLoading}
        >
          <CircularProgress color="inherit" />{' '}
          <Typography variant="body1" style={{ marginLeft: 10 }}>
            Fazendo upload...
          </Typography>
        </Backdrop>
        <div className="App">
          <Box display="flex">
            <Dropzone onDropAccepted={this.onSelectFile} {...dropzoneProps()}>
              {({ getRootProps, getInputProps }) => (
                <Box
                  display="flex"
                  flexGrow={1}
                  {...getRootProps()}
                  style={{
                    height: 'auto',
                    width: '100%',
                    margin: 'auto',
                    borderWidth: 1,
                    borderColor: touched && error ? 'red' : GENERAL_TEXT_COLOR,
                    borderStyle: 'dashed',
                    borderRadius: 5,
                    cursor: 'pointer',
                    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                    justifyContent: 'center',
                  }}
                >
                  {croppedImgBase64 ? (
                    <>
                      <input {...getInputProps()} />

                      <img
                        src={croppedImgBase64}
                        className="img-fluid"
                        alt="Imagem enviada"
                      />
                    </>
                  ) : value ? (
                    fileType === 'video' ? (
                      <>
                        <input {...getInputProps()} />
                        <div
                          style={{
                            position: 'relative',
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="body1">
                            {value.original_name}
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <>
                        <input {...getInputProps()} />
                        <div
                          style={{
                            position: 'relative',
                            textAlign: 'center',
                          }}
                        >
                          <img
                            src={`${BASE_S3}/${value}`}
                            className="img-fluid"
                            alt="Ícone do Troféu"
                            style={{
                              width: '100%',
                              opacity: '0.7',
                            }}
                          />
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                            }}
                          >
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <Image style={{ width: 50, color: 'white' }} />
                              <span>Trocar imagem</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <Box
                      display="flex"
                      flexGrow={1}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      p={3}
                    >
                      <input {...getInputProps()} />
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          borderRadius: '50%',
                          backgroundColor: BUTTON_BACKGROUND_COLOR,
                          height: 70,
                          width: 70,
                        }}
                      >
                        <CloudUploadIcon
                          style={{
                            fontSize: 16,
                            color:
                              touched && error
                                ? 'red'
                                : COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                          }}
                        />
                      </Box>

                      <Typography
                        variant="caption"
                        align="left"
                        style={{
                          textAlign: 'left',
                          marginTop: 20,
                          color: touched && error ? 'red' : GENERAL_TEXT_COLOR,
                        }}
                      >
                        {label}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Dropzone>
          </Box>
          {src && fileType === 'image' && (
            <AlertDialogSlide
              visible={this.state.open}
              onClose={() => this.setState({ open: false })}
              maxWidth="sm"
            >
              <div
                style={{
                  backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                  padding: 30,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" style={{ marginBottom: 20 }}>
                  {`Edite e corte da forma que preferir, na proporção ${this.props.aspectLabel}`}
                </Typography>
                <div>
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </div>
                <div>
                  <SimpleButton
                    onClick={() => {
                      this.setState({ imageLoading: true, open: false }, () => {
                        this.uploadImage();
                      });
                    }}
                  >
                    Finalizar Edição
                  </SimpleButton>
                </div>
              </div>
            </AlertDialogSlide>
          )}
        </div>
      </>
    );
  }
}
