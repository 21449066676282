/* eslint-disable react-hooks/exhaustive-deps */
import { FullButton } from 'common/components/buttons/fullButton';
import AlertDialogSlide from 'common/components/dialog/generalDialog';
import {
  CaptchaInput,
  InputLabelOutlined,
} from 'common/components/fields/fields';
import { COMPANY_COLORS, COMPANY_STATION_ID } from 'config/consts';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { phoneMask } from '../../helpers/masks';
import PrivacyPolicy from '../../pages/auth/policies/privacyPolicy';
import UseTerms from '../../pages/auth/policies/usePolicy';
import NavigationBar from '../common/components/Header/navigationBar';
import { changePublicActionsModal } from '../user/pages/activities/activitiesActions';
import Footer from './modules/footer';
import './website.css';
import {
  changeHeaderIndex,
  getAboutList,
  getBenefitsList,
  getPublicActions,
  submitSupport,
} from './websiteActions';

let EnterprisePLanMessage = function (props) {
  const { history } = props;
  function favicon(href) {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `https://s3.amazonaws.com/fermento.app.files/${href}`;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  const [openModal, setOpenModal] = useState(false);
  const [openModalSec, setOpenModalSec] = useState(false);

  function onSubmit(values) {
    values['subject'] = 'Consulta por plano enterprise';
    values['station_id'] = COMPANY_STATION_ID;
    props.submitSupport(values, props.history);
  }

  favicon(COMPANY_COLORS.general.favicon);
  const [id] = useState(COMPANY_STATION_ID);
  useEffect(() => {
    props.getAboutList(id);
  }, []);

  function renderForm() {
    const { body } = COMPANY_COLORS.website;
    return (
      <>
        <div className="row justify-content-center mt-3">
          <div className="col-auto mb-3 mb-md-3">
            <h1
              style={{
                fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
                textAlign: isMobileOnly ? 'center' : '',
                color: body.text,
              }}
            >
              {'Plano Enterprise'}
            </h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto mb-3 mb-md-5">
            <h5
              style={{
                fontSize: isMobileOnly ? '1rem' : '1.0rem',
                textAlign: isMobileOnly ? 'center' : 'center',
                color: '#666',
              }}
            >
              {'Responderemos no seu e-mail ou telefone o mais rápido possível'}
            </h5>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <form
            onSubmit={props.handleSubmit((v) => onSubmit(v))}
            noValidate
            style={{ width: isMobileOnly ? 330 : 450 }}
          >
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <div className="col-12">
                  <Field
                    component={InputLabelOutlined}
                    label="Seu nome"
                    inputlabelprops={{
                      shrink: true,
                    }}
                    name="name"
                    validate={[FORM_RULES.required]}
                  />
                </div>
              </div>
            </div>{' '}
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <div className="col-12">
                  <Field
                    component={InputLabelOutlined}
                    label="Telefone"
                    inputlabelprops={{
                      shrink: true,
                    }}
                    name="phone"
                    {...phoneMask}
                  />
                </div>
              </div>
            </div>
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <div className="col-12">
                  <Field
                    component={InputLabelOutlined}
                    label="E-mail para contato"
                    inputlabelprops={{
                      shrink: true,
                    }}
                    name="email"
                    validate={[FORM_RULES.required, FORM_RULES.email]}
                  />
                </div>
              </div>
            </div>
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <div className="col-12">
                  <Field
                    component={InputLabelOutlined}
                    label="Conte-nos um pouco sobre sua empresa e necessidade"
                    inputlabelprops={{
                      shrink: true,
                    }}
                    limitChar={600}
                    multiline
                    rows={5}
                    name="message"
                    validate={[FORM_RULES.required]}
                  />
                </div>
              </div>
            </div>
            <div mt={3}>
              <div className="row justify-content-center my-3">
                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <Field
                      component={CaptchaInput}
                      inputlabelprops={{
                        shrink: true,
                      }}
                      name="captcha"
                      validate={[FORM_RULES.required]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mb-4">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <FullButton
                    classNameProp="py-3 px-4"
                    type="submit"
                    disabled={
                      !props.formValues?.email ||
                      !props.formValues?.name ||
                      !props.formValues?.message ||
                      !props.formValues?.captcha
                    }
                  >
                    Enviar
                  </FullButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }

  return (
    <div className="container-fluid station-container">
      {/* {renderMain()} */}
      <NavigationBar
        isMainPage={false}
        website={props.website}
        history={history}
      />
      <article id="contato">{renderForm()}</article>
      <Footer
        setOpenModal={setOpenModal}
        setOpenModalSec={setOpenModalSec}
        website={props.website}
        history={props.history}
      />
      <AlertDialogSlide
        onClose={() => {
          setOpenModal(false);
        }}
        visible={openModal}
        titleBackground={"#FFFF"}
        backgroundColor={"#FFFF"}
      >
        <PrivacyPolicy />
      </AlertDialogSlide>
      <AlertDialogSlide
        onClose={() => {
          setOpenModalSec(false);
        }}
        visible={openModalSec}
        titleBackground={"#FFFF"}
        backgroundColor={"#FFFF"}
      >
        <UseTerms />
      </AlertDialogSlide>
    </div>
  );
};

EnterprisePLanMessage = reduxForm({ form: 'supportForm' })(
  EnterprisePLanMessage
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    formValues: getFormValues('supportForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAboutList,
      getBenefitsList,
      getPublicActions,
      changePublicActionsModal,
      changeHeaderIndex,
      submitSupport,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterprisePLanMessage);
