import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Items from './productOrders';
import ProductOrder from './productOrder';

export default (
  <Route path="/homepage/pedidos">
    <Switch>
      <Route exact path="/homepage/pedidos" component={Items} />
      <Route path="/homepage/pedidos/:id" component={ProductOrder} />
    </Switch>
  </Route>
);
