import { makeStyles } from '@material-ui/core/styles';
import MaterialModal from 'common/components/modal/materialModal';
import { BUTTON_BACKGROUND_COLOR, BUTTON_TEXT_COLOR } from 'config/consts';
import React from 'react';
import { isMobile } from 'react-device-detect';
const QRCode = require('qrcode.react');

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: isMobile ? '100vh' : '95vh',
  },
}));

export default function ModalDetail({ open, setOpen, item }) {
  const classes = useStyles();

  return (
    <MaterialModal
      open={open}
      onClose={() => setOpen(false)}
      showTitle
      PaperProps={{ classes: { root: classes.paper } }}
      fullScreen={isMobile ? true : false}
    >
      <div
        className="container-fluid d-flex-column justify-content-space-around h-100"
        style={{ backgroundColor: '#fff' }}
      >
        <div className="row justify-content-center mt-3">
          <div className="col-8 col-md-4">
            <span>ID: {item.id}</span>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-auto text-center">
            <h4>{item.nomeCampanha}</h4>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-auto text-center">
            <span>
              Tipo: {item.tipoVoucher.split('-')[0].toUpperCase()}{' '}
              {item.tipoVoucher.split('-')[1].toUpperCase()}{' '}
            </span>
          </div>
        </div>
        <div className="row justify-content-center mt-3 w-100">
          <div className="col-auto text-center">
            <span>Volume: {item.volume} Litros</span>
          </div>
        </div>
        <div className="row justify-content-center mt-3 w-100">
          <div className="col-auto text-center">
            <span>Valor: {item.valorVoucher}</span>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-auto">
            <QRCode value={item.codigo} />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-auto">
            <h5 className="text-muted">{item.codigo}</h5>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-auto col-11">
            <span className="text-muted mb-0 text-center">
              Voucher gerado em: {item.dataEmissao}
            </span>
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <div className="col-md-auto col-11">
            <span className="text-muted mb-0 text-center">
              Válido até: {item.dataValidade}
            </span>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-md-auto col-11">
            <div
              className={'py-3 station-font-medium'}
              style={{
                background: BUTTON_BACKGROUND_COLOR,
                color: BUTTON_TEXT_COLOR,
                borderRadius: 8,
                paddingLeft: isMobile ? 40 : 140,
                paddingRight: isMobile ? 40 : 140,
                cursor: 'pointer',
                fontSize: '1rem',
                textAlign: 'center',
              }}
              onClick={() => window.print()}
            >
              {isMobile ? 'Salvar' : 'Imprimir'}
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mb-5">
          <div className="col-auto">
            <a
              href="#/homepage"
              style={{ textDecoration: 'underline', color: '#505050' }}
            >
              Página inicial
            </a>
          </div>
        </div>
      </div>
    </MaterialModal>
  );
}
