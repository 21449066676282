import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { BASE_API, USER_TOKEN } from 'config/consts';

const URL_USER_POINTS = `${BASE_API}/users-points`;

export const getUserPoints = (user) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'USER_POINTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_USER_POINTS}/${user.id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'USER_POINTS_FETCHED',
            payload: response.data.points,
          },
        ]);
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro ');
        } catch (e) {
          console.log(e);
        }
      });
  };
};
