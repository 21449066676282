import { BASE_S3, COMPANY_COLORS } from 'config/consts';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { isMobile } from 'react-device-detect';
import CupomAbstract from './CupomAbstract';

export default function OrderModal({ open, onClose, item }) {
  const itemsId = item.single_product_orders.map((i) => i.product_id);
  const uniqueItemsId = Array.from(new Set(itemsId));

  function renderList() {
    const newArray = [];
    for (let productId of uniqueItemsId) {
      const product = item.single_product_orders.filter(
        (i) => i.product_id === productId
      );
      newArray.push({
        product: product[0].product.name,
        quantity: product.length,
        delivery_location: item.delivery_location
          ? item.delivery_location.name
          : null,
        date: moment(product.createdAt).format('DD-MM-YYYY HH:mm'),
      });
    }
    return (
      <DataTable value={newArray} style={{ width: 600 }}>
        <Column
          style={{ textAlign: 'center' }}
          field="quantity"
          header="Quantidade"
        />
        <Column
          style={{ textAlign: 'center' }}
          field="product"
          header="Produto"
        />
        {item.delivery_location && (
          <Column
            style={{ textAlign: 'center' }}
            field="delivery_location"
            header="Local de entrega"
          />
        )}
        <Column
          style={{ textAlign: 'center' }}
          field="date"
          header="Data da compra"
        />
      </DataTable>
    );
  }

  function renderFullPrice() {
    if (item.action.type_id === 3) {
      if (item.single_product_orders && item.delivery_location) {
        const valuesArray = item.single_product_orders.map((item) => ({
          value: item.product.value,
          discount_value: item.product.discount_value,
        }));
        let finalPrice = 0;
        for (let price of valuesArray) {
          finalPrice +=
            parseFloat(price.value) - parseFloat(price.discount_value);
        }
        finalPrice = finalPrice + parseFloat(item.delivery_location.price);
        return (
          <div className="row justify-content-center mt-3">
            <div className="col-md-8 col-11">
              <h6 className="text-muted text-center">
                Preço final: R$ {finalPrice.toFixed(2).replace('.', ',')}
              </h6>
            </div>
          </div>
        );
      } else return null;
    } else if (item.action.type_id === 14) {
      if (item.single_product_orders && item.delivery_location) {
        const pricesArray = item.single_product_orders.map(
          (item) => item.product.value
        );
        pricesArray.push(item.delivery_location.price);
        let finalPrice = 0;
        for (let price of pricesArray) {
          finalPrice += parseFloat(price);
        }
        finalPrice = finalPrice - parseFloat(item.action.discount_delivery);
        return (
          <div className="row justify-content-center mt-3">
            <div className="col-md-8 col-11">
              <h6 className="text-muted text-center">
                Preço final: R$ {finalPrice.toFixed(2).replace('.', ',')}
              </h6>
            </div>
          </div>
        );
      } else return null;
    }
  }

  if (item.action.type_id === 3) {
    return <CupomAbstract item={item} />;
  }

  return (
    <div
      className="container-fluid d-flex-column justify-content-space-around h-100"
      style={{ backgroundColor: '#fff' }}
    >
      <div className="row justify-content-center mt-5">
        <div className="col-8 col-md-4">
          <img
            src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
            alt="LOGO-EMPRESA"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-auto text-center">
          <h4>{item.action.name}</h4>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-md-8 col-11">
          <h6 className="text-muted text-center">{`Pedido gerado em: ${moment(
            item.createdAt
          ).format('LLLL')}`}</h6>
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        <div className="col-auto">
          <h5 className="text-muted">Pedido #{item.id}</h5>
        </div>
      </div>
      <div className="row justify-content-center mt-3">{renderList()}</div>
      {renderFullPrice()}
      <div className="row justify-content-center mt-5">
        <div className="col-md-auto col-11">
          <div
            className={'py-3 station-font-medium'}
            style={{
              background: COMPANY_COLORS.general.secondaryButton.background,
              color: COMPANY_COLORS.general.secondaryButton.text,
              borderRadius: 8,
              paddingLeft: isMobile ? 40 : 140,
              paddingRight: isMobile ? 40 : 140,
              cursor: 'pointer',
              fontSize: '1rem',
              textAlign: 'center',
            }}
            onClick={() => window.print()}
          >
            {isMobile ? 'Salvar' : 'Imprimir'}
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-2 mb-5">
        <div className="col-auto">
          <a
            href="#/homepage"
            style={{ textDecoration: 'underline', color: '#505050' }}
          >
            Página inicial
          </a>
        </div>
      </div>
    </div>
  );
}
