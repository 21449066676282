import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import TuneIcon from '@material-ui/icons/Tune';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR, GENERAL_TEXT_COLOR } from '../../../config/consts';
import { customCurrencyChanger, formatReal } from '../../../helpers/utils';
import { searchTableValue } from '../../../helpers/utils.js';
import { MenuFiltersOptions, SearchBar } from './tableComponents';
import './tableGeneral.css';
import { tableIcons } from './tableIcons';
import { Grid } from '@material-ui/core';

const tableTheme = createTheme({
  overrides: {
    MuiPaper: {
      root:{
        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
      },
    },
    MuiSelect: {
      root:{
        color: GENERAL_TEXT_COLOR,
      },
    },
    MuiSvgIcon: {
      root:{
        color: GENERAL_TEXT_COLOR,
      }
    },
    MuiTypography: {
      root:{
        color: GENERAL_TEXT_COLOR,
      }
    },
    MuiTableSortLabel: {
      root: {
        color: GENERAL_TEXT_COLOR,
        "&:hover": {
          color: GENERAL_TEXT_COLOR,
        }
      },
      active: {
        color: GENERAL_TEXT_COLOR,
        "&:hover": {
          color: GENERAL_TEXT_COLOR,
        }
      }
    },
    MuiTablePagination: {
      selectIcon:{
        color: GENERAL_TEXT_COLOR,
      }
    },
    MuiMenu: {
      paper: {
        color: GENERAL_TEXT_COLOR,
        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
      },
    },
  }
});

const themeSec = createTheme({
  overrides: {
    MuiSelect: {
      root: {
        width: 95,
        height: 60,
        borderRadius: 10,
        border: '1px solid #DFDFDF',
        padding: 10,
        margin: 0,
      },
      outlined: {
        height: 60,
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 6,
        border: '1px solid #DFDFDF',
        padding: 0,
        marginRight: 5,
        marginLeft: 15,
      },
    },
  },
});

export default function TableGeneral(props) {
  const {
    title,
    subtitle,
    toolbarSearch = false,
    compressedTable,
    data: defaultData,
    columns,
    dataType,
    hasToolbar,
    filters,
    searchPlaceholder,
    add,
    edit,
    remove,
    onChangeOption,
    currencyData,
    isUserCredits,
    isUsedCredits
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = React.useRef();
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [appliedFilters, setAppliedFilters] = useState([]);

  useEffect(() => {
    const filteredByName = searchTableValue(searchValue, defaultData, dataType);
    const filteredBySelectors = filterTableData(appliedFilters, filteredByName);
    setFilteredData(filteredBySelectors);
  }, [searchValue, appliedFilters, dataType, defaultData]);

  const filterTableData = (appliedFilters, backupData) => {
    let currentData = [];
    let filtered = [];
    if (appliedFilters.length > 0) {
      currentData = backupData;
      filtered = currentData.filter((item, index) => {
        const array = [];
        for (let i = 0; i < appliedFilters.length; i++) {
          if (appliedFilters[i].field === 'betweenDates') {
            if (appliedFilters[i].filterFunction === 0) {
              if (
                moment(item['participationStart']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                ) &&
                moment(item['participationEnd']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            } else if (appliedFilters[i].filterFunction === 1) {
              if (
                moment(item['action']['participationStart']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                ) &&
                moment(item['action']['participationEnd']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            }
          } else {
            if (
              appliedFilters[i].value[0] <= item[appliedFilters[i].field] &&
              item[appliedFilters[i].field] <= appliedFilters[i].value[1]
            ) {
              array.push(true);
            }
          }
        }
        if (array.length === appliedFilters.length) return true;
        return false;
      });
    } else {
      filtered = backupData;
    }

    return filtered;
  };

  const handleClick = () => {
    setAnchorEl(true);
  };

  const [newColumns2] = React.useState(setInitialNewColumns(props));
  const [columnsWithStyle] = React.useState(
    setInitialColumnsStyle(props)
  );
  const [optionSelected, setOptionSelected] = React.useState(
    props.defaultSelected ? props.defaultSelected : ''
  );

  const handleSelectChange = (event) => {
    setOptionSelected(event.target.value);
  };

  const handleDelete = (chipField) => {
    setAppliedFilters((chips) =>
      chips.filter((chip) => chip.field !== chipField)
    );
  };

  useEffect(() => {
    if (onChangeOption) onChangeOption(optionSelected);
  }, [optionSelected, onChangeOption]);

  useEffect(() => {
    setFilteredData(defaultData);
  }, [defaultData]);

  function setInitialNewColumns(props) {
    const index = props.columns.findIndex((item) => item.field === 'base64');
    const itemFound = props.columns.find((item) => item.field === 'base64');
    if (index >= 0) {
      let columnsWithImg = [...columns];

      columnsWithImg[index] = {
        ...itemFound,
        render: (rowData) => (
          <img
            src={rowData.base64}
            style={{ width: 50, borderRadius: '50%' }}
            alt="Imagem da Tabela"
          />
        ),
      };
      return columnsWithImg;
    } else {
      return props.columns;
    }
  }

  function setInitialColumnsStyle(props) {
    const array = newColumns2.map((item) => {
      if (item.field === 'actions') {
        return {
          ...item,
          title: '',
          headerStyle: {
            textAlign: item.headerCenter ? 'center' : 'left',
            fontWeight: 500,
            fontSize: 14,
            color: 'black',
            whiteSpace: 'nowrap',
            paddingLeft: 0,
            padding: 5,
          },
          cellStyle: {
            whiteSpace: 'nowrap',
            border: 0,
            padding: 0,
            height: 50,
          },
          disableClick: true,
        };
      } else {
        return {
          ...item,
          headerStyle: {
            textAlign: item.headerCenter ? 'center' : 'left',
            fontWeight: 500,
            fontSize: 14,
            color: GENERAL_TEXT_COLOR,
            backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
            whiteSpace: 'nowrap',
            padding: 5,
            paddingLeft: 10,
            border: '0px',
            borderBottom: '1px solid #E0E0E0',
          },
          cellStyle: {
            whiteSpace: 'nowrap',
            border: 0,
            padding: 5,
            paddingLeft: 10,
            color: GENERAL_TEXT_COLOR,
            backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
            height: 50,
          },
          disableClick: props.onRowClick ? false : true,
        };
      }
    });
    return array;
  }

  function renderToolbar() {
    return (
      <div>
        <div
          className="rowToolbar"
          style={{ marginBottom: subtitle || compressedTable ? 0 : 'inherent' }}
        >
          <div
            style={{
              width: '40%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <p
              style={{
                fontWeight: 500,
                fontSize: 24,
                padding: 0,
                margin: 0,
                marginLeft: 5,
                marginRight: 20,
              }}
            >
              {title ? title : ''}
            </p>
            {props.selectOptions ? (
              props.selectOptions.length > 0 ? (
                <ThemeProvider theme={themeSec}>
                  <Select
                    variant="outlined"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={optionSelected}
                    onChange={handleSelectChange}
                  >
                    {props.selectOptions.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </ThemeProvider>
              ) : null
            ) : null}
          </div>

          <div
            style={{
              width: '60%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {appliedFilters && appliedFilters.length > 0
              ? appliedFilters.map((item, index) => {
                return (
                  <div key={index} style={{ marginRight: 5 }}>
                    <Chip
                      size={'small'}
                      icon={
                        item.typeData === 'quantity' ? (
                          <PersonOutlineIcon />
                        ) : item.typeData === 'money' ? (
                          <MonetizationOnIcon />
                        ) : item.typeData === 'date' ? (
                          <CalendarTodayIcon />
                        ) : null
                      }
                      label={
                        item.typeData === 'quantity'
                          ? `${item.value[0]} até ${item.value[1]}`
                          : item.typeData === 'money'
                            ? `${formatReal(item.value[0])} até ${formatReal(
                              item.value[1]
                            )}`
                            : item.typeData === 'date'
                              ? `${moment(item.startDate).format(
                                'DD/MM/YYYY'
                              )} até ${moment(item.endDate).format(
                                'DD/MM/YYYY'
                              )}`
                              : null
                      }
                      onDelete={() => handleDelete(item.field)}
                      color="primary"
                    />
                  </div>
                );
              })
              : null}
            {toolbarSearch ? (
              <SearchBar
                placeholder={searchPlaceholder || 'Pesquisar'}
                small={props.smallSearch}
                setSearchValue={setSearchValue}
                defaultValue={searchValue}
              />
            ) : null}
            {filters && filters.length > 0 ? (
              <ThemeProvider theme={themeSec}>
                <IconButton
                  ref={buttonRef}
                  disableFocusRipple
                  disableRipple
                  onClick={handleClick}
                >
                  <TuneIcon />
                </IconButton>
                <MenuFiltersOptions
                  filterOptions={filters}
                  appliedFilters={appliedFilters}
                  setAppliedFilters={setAppliedFilters}
                  open={Boolean(anchorEl)}
                  buttonRef={() => buttonRef.current}
                  setAnchorEl={setAnchorEl}
                />
              </ThemeProvider>
            ) : null}
          </div>
        </div>
        {subtitle ? (
          <div className="rowToolbar" style={{ marginBottom: 0, height: 20 }}>
            <div
              style={{
                width: '35%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  padding: 0,
                  margin: 0,
                  marginLeft: 5,
                  marginRight: 20,
                }}
              >
                {subtitle}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  function currencyParamsProcessing(filteredData) {
    const currencyParams = filteredData;
    const newCurrencyData = {
      currency_multiplier: currencyData?.currency_multiplier,
      currency_name: currencyData?.currency_name,
      currency_name_position: currencyData?.currency_name_position,
    };
    currencyParams.forEach((item) => {
      return item.points = customCurrencyChanger(newCurrencyData, item.points.replace(/\s/g, '').slice(2).replace(',', '.'));
    });
    return currencyParams;
  };

  return (
    <>
      <Grid item container xs={12}>
        <ThemeProvider theme={tableTheme}>
          <MaterialTable
            icons={tableIcons}
            components={{
              Toolbar: (props) => {
                return hasToolbar ? renderToolbar() : null;
              },
            }}
            style={{
              boxShadow: 'none',
              border: `2px solid ${GENERAL_TEXT_COLOR}`,
              borderRadius: 5,
              padding: 25,
              color: GENERAL_TEXT_COLOR,
              minWidth: '100%',
            }}
            //Necessário para realizar tradução do placeholder. Retirar deleteTooltip bugará.
            localization={{
              grouping: {
                groupedBy: 'Agrupar por:',
                placeholder:
                  'Clique e arraste até aqui os títulos das colunas para agrupar',
              },
              toolbar: {
                searchPlaceholder: 'Procurar',
              },
              body: {
                editRow: {
                  saveTooltip: 'Salvar',
                  cancelTooltip: 'Cancelar',
                  deleteText:
                    props.deleteText || 'Tem certeza que deseja apagar esta linha?',
                },
                addTooltip: 'Adicionar',
                deleteTooltip: 'Deletar',
                editTooltip: 'Editar',
                emptyDataSourceMessage: 'Não há dados para visualização',
              },
              header: { actions: 'Ações' },
              pagination: {
                labelRowsPerPage: 'Linhas por página',
                labelRowsSelect: 'linhas',
              },
            }}
            editable={{
              ...(add
                ? {
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {
                          const data = this.state.data;
                          data.push(newData);
                          this.setState({ data }, () => resolve());
                        }
                        resolve();
                      }, 1000);
                    }),
                }
                : {}),
              ...(edit
                ? {
                  onRowUpdate: (oldData) =>
                    new Promise((resolve, reject) => {
                      props.handleUpdate(oldData, resolve, reject);
                    }),
                }
                : {}),
              ...(remove
                ? {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      props.handleDelete(oldData.id, resolve, reject);
                    }),
                }
                : {}),
            }}
            title={title}
            columns={[...columnsWithStyle]}
            onRowClick={props.onRowClick}
            data={isUserCredits || isUsedCredits ? currencyParamsProcessing(filteredData) : filteredData}
            options={{
              actionsCellStyle: {
                whiteSpace: 'nowrap',
                border: 0,
                padding: 5,
                paddingLeft: 10,
                height: 50,
              },
              headerStyle: {
                textAlign: 'left',
                fontWeight: 500,
                fontSize: 14,
                color: 'black',
                whiteSpace: 'nowrap',
                paddingLeft: 0,
                padding: 5,
                backgroundColor: 'white',
                "&:hover":{
                  backgroundColor: 'pink'
                }
              },
              actionsColumnIndex: -1,
            }}
          />
        </ThemeProvider>
      </Grid>
    </>
  );
}
