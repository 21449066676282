import { createNumberMask, createTextMask } from 'redux-form-input-masks';

export const currencyMask = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
  locale: 'pt-BR',
});

export const phoneMask = createTextMask({
  pattern: '(99) 9 9999-9999',
});

export const plateMask = createTextMask({
  pattern: 'AAA-9999',
});

export const hoursMask = createTextMask({
  pattern: '99:99',
  stripMask: false,
});

export const dateMask = createTextMask({
  pattern: '99/99/9999',
  stripMask: false,
});

export const sixDigitCodeMask = createTextMask({
  pattern: '9 9 9 9 9 9',
  stripMask: true,
});

export const cnpjMask = createTextMask({
  pattern: '99.999.999/9999-99',
  stripMask: true,
});

export const creditCardMask = createTextMask({
  pattern: '9999 9999 9999 9999',
  stripMask: true,
});

export const dateCardMask = createTextMask({
  pattern: '99/99',
  stripMask: true,
});

export const cvcMask = createTextMask({
  pattern: '9999',
  stripMask: true,
});

export const cpfMask = createTextMask({
  pattern: '999.999.999-99',
  stripMask: true,
});
export const cepMask = createTextMask({
  pattern: '99.999-999',
  stripMask: true,
});

export const dateReducedMask = (handleChange) =>
  createTextMask({
    pattern: '99/99',
    stripMask: true,
  });
