import LoadingProgress from 'common/components/progress/loading';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginToken } from '../authActions';

class LoginToken extends Component {
  componentDidMount() {
    this.props.loginToken(this.props.match.params?.token, this.props.history);
  }

  render() {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <LoadingProgress />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loginToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginToken);
