import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  BASE_S3,
  BRB_FLA_STATION_ID,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
  IS_CAIXA,
} from '../../../config/consts';
import { makeStyles } from '@material-ui/styles';
import { formatCNPJ } from '../../../helpers/dataTransformers';
import { truncateString } from '../../../helpers/utils';
import PropTypes from 'prop-types';
import { LinkedIn, Facebook, Instagram, Twitter } from '@material-ui/icons';
import SocialLink from '../../../common/components/social-link/socialLink';
import { isMobileOnly } from 'react-device-detect';

const useStyles = makeStyles({
  footerLogo: {
    maxWidth: '90%',
    height: 'auto',
    maxHeight: 100,
  },
});
const TikTokIcon = ({ color = 'rgb(218, 218, 218)' }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="24px"
      height="24px"
      style={{ marginRight: '4px' }}
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

function StyledTextBox(props) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  return (
    <Box
      display="flex"
      flexDirection={isMobileOnly ? 'column' : 'row'}
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: xs || sm ? '100px' : '100%',
        padding: xs || sm ? '.2rem 0' : '',
        width: xs ? '100%' : '100%',
        textAlign: 'center',
        marginTop: 30,
      }}
    >
      {props.children}
    </Box>
  );
}
function StyledTextItem(props) {
  return (
    <div style={{ marginRight: isMobileOnly ? 0 : 48 }}>{props.children}</div>
  );
}

function Footer(props) {
  const { setOpenModal, setOpenModalSec, website, history } = props;
  const { footer } = COMPANY_COLORS.website;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent="center"
      alignItems="center"
      xs={12}
      style={{
        backgroundColor: footer.background,
        minHeight: xs || sm ? '250px' : '200px',
        paddingTop: 30,
      }}
    >
      <img
        className={classes.footerLogo}
        src={`${BASE_S3}/${footer.company_logo}`}
        alt="LOGO-EMPRESA"
      />
      {website.stationData.social_url_instagram ||
      website.stationData.social_url_facebook ||
      website.stationData.social_url_tiktok ||
      website.stationData.social_url_twitter ||
      website.stationData.social_url_linkedin ? (
        <Box
          display={'flex'}
          justifyContent="center"
          alignItems="center"
          style={{
            minHeight: xs || sm ? '100px' : '100%',
            padding: xs || sm ? '.2rem 0' : '',
            minWidth: '162px',
            marginTop: 30,
          }}
        >
          {website.stationData.social_url_facebook && (
            <SocialLink
              textColor={footer.text}
              icon={Facebook}
              url={website.stationData.social_url_facebook}
              label="Facebook"
            />
          )}
          {website.stationData.social_url_instagram && (
            <SocialLink
              textColor={footer.text}
              icon={Instagram}
              url={website.stationData.social_url_instagram}
              label="Instagram"
            />
          )}
          {website.stationData.social_url_twitter && (
            <SocialLink
              textColor={footer.text}
              icon={Twitter}
              url={website.stationData.social_url_twitter}
              label="Twitter"
            />
          )}
          {website.stationData.social_url_linkedin && (
            <SocialLink
              textColor={footer.text}
              icon={LinkedIn}
              url={website.stationData.social_url_linkedin}
              label="LinkedIn"
            />
          )}
          {website.stationData.social_url_tiktok && (
            <SocialLink
              textColor={footer.text}
              icon={TikTokIcon}
              url={website.stationData.social_url_tiktok}
              label="Tiktok"
            />
          )}
        </Box>
      ) : null}
      <StyledTextBox>
        <StyledTextItem>
          <Typography
            variant="subtitle1"
            style={{
              color: footer.text,
              overflowWrap: 'break-word',
              overflow: 'auto',
              textAlign: xs || sm ? 'center' : null,
            }}
          >
            {website.stationData.address &&
              truncateString(website.stationData.address, 45)}
          </Typography>
        </StyledTextItem>

        <StyledTextItem>
          <Typography
            variant="subtitle1"
            style={{
              color: footer.text,
              overflowWrap: 'break-word',
              overflow: 'auto',
            }}
          >
            {website.stationData
              ? website.stationData.establishment
                ? `Cnpj: ${formatCNPJ(website.stationData.establishment.cnpj)}`
                : null
              : null}
          </Typography>
        </StyledTextItem>

        <StyledTextItem>
          <Typography
            variant="subtitle1"
            onClick={() => history.push('/suporte')}
            style={{
              cursor: 'pointer',
              color: footer.text,
              overflowWrap: 'break-word',
              overflow: 'auto',
            }}
          >
            Fale Conosco
          </Typography>
        </StyledTextItem>

        <StyledTextItem>
          {COMPANY_STATION_ID !== BRB_FLA_STATION_ID ? (
            <Typography
              variant="subtitle1"
              style={{
                color: footer.text,
                overflowWrap: 'break-word',
                overflow: 'auto',
              }}
            >
              {website.stationData.contact_mail}
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              style={{
                color: footer.text,
                overflowWrap: 'break-word',
                overflow: 'auto',
              }}
            >
              sac@melevanacaobrb.com.br
            </Typography>
          )}
        </StyledTextItem>
      </StyledTextBox>
      <StyledTextBox>
        <StyledTextItem></StyledTextItem>
        <StyledTextItem>
          <Typography
            variant="subtitle1"
            onClick={() => {
              IS_CAIXA
                ? window.open(
                    'https://www.caixa.gov.br/privacidade/aviso-de-privacidade/Paginas/default.aspx',
                    '_blank'
                  )
                : setOpenModal(true);
            }}
            style={{
              cursor: 'pointer',
              color: footer.text,
              overflowWrap: 'break-word',
              overflow: 'auto',
            }}
          >
            Política de privacidade
          </Typography>
        </StyledTextItem>
        <StyledTextItem>
          <Typography
            variant="subtitle1"
            onClick={() => {
              IS_CAIXA
                ? window.open(
                    'https://www.caixa.gov.br/termos-de-uso/Paginas/default.aspx',
                    '_blank'
                  )
                : setOpenModalSec(true);
            }}
            style={{
              cursor: 'pointer',
              color: footer.text,
              overflowWrap: 'break-word',
              overflow: 'auto',
            }}
          >
            Termos de uso
          </Typography>
        </StyledTextItem>
        <StyledTextItem>
          {COMPANY_STATION_ID === BRB_FLA_STATION_ID && (
            <a
              target="_blank"
              href="https://s3.amazonaws.com/fermento.app.files/files/regulamento.pdf"
              rel="noopener noreferrer"
            >
              <Typography
                variant="subtitle1"
                style={{
                  color: footer.text,
                  overflowWrap: 'break-word',
                  overflow: 'auto',
                }}
              >
                Regulamento{' '}
              </Typography>
            </a>
          )}
        </StyledTextItem>
      </StyledTextBox>
    </Box>
  );
}

Footer.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  setOpenModalSec: PropTypes.func.isRequired,
  website: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Footer;
