export const stylesheets = {
  flexboxParent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  flexboxChild: {
    flex: 1,
    minHeight: '6em',
    minWidth: '60vw',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexBoxAlignCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
};
