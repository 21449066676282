/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import CallToActionCard from 'common/components/cards/callToActionCard';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getActionById,
  setDetail,
  subscribe,
  setSubscribed,
  submitEmails,
} from './activitiesActions';
import DOMPurify from 'dompurify';
import ReactPlayer from 'react-player';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DomPurifyContentContainer } from './styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  },
}));

function ActivityMemberGetMember(props) {
  const classes = useStyles();

  return (
    <>
      <Grid item container justifyContent="center" alignItems="center" xs={12}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <Typography variant="body1">
            <DomPurifyContentContainer
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activities.detail.description),
              }}
            />
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12}>
          {props.activities.detail.action_videos.length > 0 ? (
            <Grid item container xs={12} justifyContent="center" alignItems="center">
              <ReactPlayer
                width={'100%'}
                height={isMobile ? '17rem' : '30rem'}
                controls
                className={classes.reactPlayer}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12}>
          <CallToActionCard
            public={props.public || false}
            website={props.website}
            auth={props.auth}
            activity={props.activities.detail}
            onClick={
              props.callToActionLink
                ? () => props.history.push(props.callToActionLink)
                : props.onClick
                ? props.onClick
                : undefined
            }
            submitEmails={props.submitEmails}
            history={props.history}
          />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getActionById, setDetail, subscribe, setSubscribed, submitEmails },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityMemberGetMember);
