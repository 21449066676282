import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  BASE_S3,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
  NAV_FONT_FAMILY,
  SIGNED_IN_NAV_TEXT_COLOR,
} from '../../../../../config/consts';
import { sendActivityPost } from '../activitiesActions';
import { Button, Form, Input } from './styles';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '2.2em',
    height: '2.2em',
    margin: '1rem 0',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  createCommentComponent: {
    backgroundColor: 'rgba(246, 246, 246, 0.8)',
    border: `0 solid ${GENERAL_TEXT_COLOR}`,
    borderRadius: '2%',
    padding: '2rem 0',
  },
}));

const CreateActivityPost = (props) => {
  const { auth, activity } = props;
  const [postContent, setPostContent] = useState('');
  const [inputMaxLength] = useState(200);
  const classes = useStyles();

  const handleSumbit = (e) => {
    e.preventDefault();
    const values = {
      user: auth.user.id,
      action: activity.detail.id,
      description: postContent,
    };
    props.sendActivityPost(values, activity.detail.id);

    setPostContent('');
    if (!props.expandsComment) props.setExpandsComment();
    if (!isMobile) props.scrollCallback();
  };

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      alignItems="center"
      style={{
        maxWidth: '100vw',
        margin: isMobile ? '.6rem 0' : '2rem 0',
      }}
    >
      <Form onSubmit={handleSumbit} style={{ backgroundColor: 'transparent' }}>
        <Grid
          id="createCommentComponent"
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          component="section"
          className={isMobile ? '' : classes.createCommentComponent}
        >
          {/* AVATAR */}
          <Grid
            item
            container
            xs={2}
            sm={1}
            justifyContent="flex-start"
            alignItems="center"
            style={{
              display: isMobile ? 'none' : '',
            }}
          >
            <Avatar
              alt={auth.user.name.split(' ')[0]}
              className={`${isMobile ? classes.small : classes.large}`}
              src={
                props.auth.user.image
                  ? `${BASE_S3}/${auth.user.image}`
                  : 'https://s3.amazonaws.com/fermento.app.files/files/default_profile.png'
              }
            />
          </Grid>
          {/* COMMENT CONTENT INPUT */}
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            alignItems="center"
            style={{
              display: isMobile ? '' : 'none',
              paddingRight: '.5rem',
            }}
          >
            <Typography
              variant="body1"
              component="span"
              style={{ color: GENERAL_TEXT_COLOR }}
            >
              {`${postContent.length}/${inputMaxLength}`}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={10}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            style={{
              border: '1px solid rgba(204, 204, 204, 0.3)',
              borderRadius: '5px',
              backgroundColor: isMobile ? '' : COMPANY_SIGNED_IN_BACKGROUND_COLOR,
            }}
          >
            <Grid
              item
              container
              xs={8}
              md={8}
              justifyContent="center"
              alignItems="center"
            >
              <Input
                type="text"
                maxLength={inputMaxLength}
                placeholder="Digite seu comentário"
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                companyBackgroundColor={COMPANY_SIGNED_IN_BACKGROUND_COLOR}
                textColor={GENERAL_TEXT_COLOR}
                required
                isMobile={isMobile}
                rows="2"
              />
            </Grid>
            <Grid
              item
              container
              xs={1}
              md={2}
              justifyContent="flex-end"
              alignItems="center"
              style={{ display: isMobile ? 'none' : '' }}
            >
              <Typography
                variant="body1"
                component="span"
              // style={{ color: '#838383' }}
              >
                {`${postContent.length}/${inputMaxLength}`}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={4}
              md={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                type="submit"
                style={{
                  color: SIGNED_IN_NAV_TEXT_COLOR,
                  fontFamily: NAV_FONT_FAMILY,
                }}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ sendActivityPost }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateActivityPost);
