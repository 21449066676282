import React from 'react';

export default function CalendarSVG(props) {
  return (
    <svg
      width={props.width || '15'}
      height={props.height || '13.3'}
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      fill={props.color || 'inherit'}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="inherit"
        fillRule="evenodd"
      >
        <g
          id="Icon-/-Calendar"
          transform="translate(-3.000000, -5.000000)"
          fill="inherit"
        >
          <path
            d="M19,5 L14,5 L9.9,5 L5,5 C3.899,5 3,5.9 3,7 L3,19 C3,20.1 3.899,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,7 C21,5.9 20.1,5 19,5 Z M19,19 L5,19 L5,10 L19,10 L19,19 Z M9,11 L6,11 L6,14 L9,14 L9,11 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
