import { Grid, Card, CardContent, Box, Typography } from '@material-ui/core';
import { InputLabelOutlined } from 'common/components/fields/fields';
import { currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { OnboardingCard } from '../../../../../common/components/transferCard';
import {
  getListByUserId,
  userBuyPoints,
  transferRequest,
} from './creditCardsActions';
import MaterialModal from '../../../../../common/components/modal/materialModal';
import { COMPANY_COLORS } from '../../../../../config/consts';
import GenericTabs from '../../../../../common/components/navigation/actionsTab/genericTabs';
import TransferRequests from './transferRequests';

class BuyCreditsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleClose = () => {
    this.setState({ visible: false });
  };

  onSubmit = async (values) => {
    const sendValues = {
      user_id: this.props.auth.user.id,
      type_id: 5,
      points: values.points,
    };

    await this.props.transferRequest(sendValues);
    this.setState({ visible: true });
  };

  render() {
    const buyCredits = (
      <Form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        noValidate
        style={{ height: '100%', marginTop: 20 }}
      >
        <Grid conatiner spacing={3} style={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <OnboardingCard
              condition={false}
              title="Transferência bancária"
              height={580}
              width={290}
              text={
                <div style={{ textAlign: 'left' }}>
                  <span>
                    Banco: 756 - BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB
                  </span>
                  <br />
                  <span>CNPJ: 35.622.254/0001-26</span>
                  <br />
                  <span>Ag: 4259</span>
                  <br />
                  <span>CC: 3250-6</span>
                  <br />
                  <span>Nome: FAN PROJECTS S/A</span>
                </div>
              }
              hideButton
            />
            <OnboardingCard
              condition={this.props.creditCards.buttonLoading}
              title="Começar"
              height={580}
              width={290}
              text={
                'Digite abaixo o valor que irá transferir. Efetue a transferência bancária utilizando os dados ao lado. Quando verificarmos a transferência, liberaremos o crédito para uso em suas atividades.'
              }
              aditionalContent1={
                <Field
                  component={InputLabelOutlined}
                  type="tel"
                  label={'Quantidade em BRL'}
                  name="points"
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                  {...currencyMask}
                />
              }
              buttonLabel="Fazer pedido"
            />
          </Grid>
        </Grid>

        <MaterialModal
          open={this.state.visible}
          onClose={this.handleClose}
          maxWidth={'sm'}
        >
          <Card>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  style={{ color: COMPANY_COLORS.primary }}
                  variant="h6"
                >
                  {`Olá ${this.props.auth.user.name.split(' ')[0]},`}
                </Typography>
                <br />
                <Typography variat="body1" style={{ margin: '8px' }}>
                  O pedido da seu tranferência foi gerado com sucesso! Ao
                  confirmarmos a sua transação, você será notificado via e-mail
                  e poderá usar os seus créditos.
                </Typography>
                <br />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ width: '100%' }}
                >
                  <Typography
                    style={{
                      background: COMPANY_COLORS.primary,
                      color: 'white',
                      cursor: 'pointer',
                      margin: '8px',
                      padding: '10px 20px',
                      borderRadius: 7,
                    }}
                    onClick={this.handleClose}
                  >
                    Ok
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </MaterialModal>
      </Form>
    );

    return (
      <GenericTabs
        tabs={[
          {
            label: 'Abastecer créditos',
            screen: buyCredits,
          },
          {
            label: 'Pedidos de tranferência',
            screen: <TransferRequests />,
          },
        ]}
      />
    );
  }
}

BuyCreditsForm = reduxForm({ form: 'creditCardsForm' })(BuyCreditsForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('creditCardsForm')(state),
    creditCards: state.creditCards,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByUserId,
      userBuyPoints,
      transferRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyCreditsForm);
