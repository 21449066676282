import { Box, Grid } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAppointmentsList, sendAppointmentRequest } from './legalActions';
import LegalAppointmentsFormModal from './legalAppointmentsFormModal';
import LegalProcessesCard from './legalProcessesCard';
import './styles.css';

const LegalAppointments = ({
  legal,
  getAppointmentsList,
  auth,
  sendAppointmentRequest,
}) => {
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(false);

  function handleClose() {
    setIsSubmitDialogOpen(false);
    setWasSuccessful(false);
  }

  function handleOpen() {
    setIsSubmitDialogOpen(true);
  }

  useEffect(() => {
    getAppointmentsList();
  }, [getAppointmentsList]);

  function handleSubmit(values) {
    function cleanup() {
      setWasSuccessful(true);
    }
    sendAppointmentRequest(values, cleanup);
  }

  const { appointmentsList, appointmentsLoading } = legal;

  return (
    <section id="legalAppointments">
      <p className="body-title mt-4">Agendamento de consulta</p>
      <p className="h4 mt-3">
        Você pode solicitar e acompanhar seus agendamentos de consultas pelo
        Sindilegis Mais
      </p>
      <Box display="flex" justifyContent="space-between" mt={3}>
        <button
          style={{
            backgroundColor: '#378587',
            padding: '16px 32px',
            color: '#fff',
          }}
          onClick={() => handleOpen()}
        >
          Solicitar agendamento
        </button>
      </Box>
      {appointmentsLoading ? (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      ) : appointmentsList.length > 0 ? (
        <Grid container spacing={3} style={{ marginTop: 32 }}>
          {appointmentsList.map((item) => (
            <Grid item xs={4} key={item.data}>
              <LegalProcessesCard
                header={item.descricao}
                status={item.situacao}
                body={`Data da cosulta: ${format(
                  new Date(item.data),
                  'dd/MM/yyyy'
                )}`}
                footer={`Horário: ${format(new Date(item.data), 'hh:mm')}`}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <p className="h4 mt-4">
          Não foram encontrados agendamentos em seu CPF.
        </p>
      )}
      {isSubmitDialogOpen && (
        <LegalAppointmentsFormModal
          isOpen={isSubmitDialogOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
          wasSuccessful={wasSuccessful}
          user={auth.user}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    legal: state.legal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAppointmentsList,
      sendAppointmentRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalAppointments);
