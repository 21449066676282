const INITIAL_STATE = {
  loading: false,
  list: {
    content: [],
  },
  error: false,
  errors: [],
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USER_INVITATIONS_FETCHED':
      return { ...state, list: { content: action.payload }, loading: false };
    case 'USER_INVITATIONS_LOAD':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
