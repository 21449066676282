import { Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

// import { Container } from './styles';

function AccountingProcessesDetailModal({
  onClose,
  isOpen,
  title,
  status,
  body,
  footer1,
  footer2,
  moreInfoLink,
}) {
  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="sm">
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon
          onClick={onClose}
          style={{
            cursor: 'pointer',
            fontSize: 32,
            marginTop: 12,
            marginRight: 12,
          }}
        />
      </Box>
      <Box style={{ padding: '32px 71px' }}>
        <Box display="flex" justifyContent="space-between">
          <p className="h4" style={{ color: '#838383' }}>
            {title}
          </p>
          <div
            style={{
              backgroundColor: '#F98979',
              padding: '4px 12px',
              color: '#fff',
              borderRadius: 5,
            }}
          >
            {status}
          </div>
        </Box>
        <p className="h4-bold mt-4">{body}</p>
        <Box display="flex" justifyContent="space-between" mt={4}>
          <p className="h4" style={{ color: '#838383' }}>
            {footer1}
          </p>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={4}>
          <p className="h4" style={{ color: '#838383' }}>
            R$ {footer2}
          </p>
        </Box>
        {/* <Box display="flex" justifyContent="space-between" mt={5}>
          <p className="h7" style={{ color: '#282828' }}>
            Link para maiores informações
          </p>
          {console.log(COMPANY_COLORS.primary)}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={moreInfoLink}
            className="h7"
            style={{ color: COMPANY_COLORS.primary }}
          >
            Abrir
          </a>
        </Box> */}
      </Box>
    </Dialog>
  );
}

export default AccountingProcessesDetailModal;
