/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import Spinner from 'common/components/spinner/spinner';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListByUserId, setLoading } from './giveawayNumbersActions';

function calculateNumberLabel(number) {
  let stringNumber = number.toString();

  if (10 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (100 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (1000 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (10000 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (100000 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (1000000 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  return stringNumber;
}

export function GiveawayNumbers(props) {
  useEffect(() => {
    props.getListByUserId(props.auth.user.id);
  }, []);
  if (props.giveawayNumbers.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <section
      id="giveawayNumbers-list"
      style={{ backgroundColor: 'transparent', height: '100vh' }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92vh', backgroundColor: 'transparent' }}
      >
        <Typography variant="h6">
          A disponibilização dos números da sorte no site ocorrerá até o dia
          01/02/2023, às 16h.
        </Typography>
      </div>
    </section>
  );

  if (_.isEmpty(props.giveawayNumbers.list.content)) {
    return (
      <section
        id="giveawayNumbers-list"
        style={{ backgroundColor: 'transparent', height: '100vh' }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '92vh', backgroundColor: 'transparent' }}
        >
          <Typography variant="h6">
            Você ainda não possui números da sorte. Com eles você poderá
            participar de vários sorteios.
          </Typography>
        </div>
      </section>
    );
  }

  return (
    <>
      <section
        id="giveawayNumbers-list"
        style={{ backgroundColor: 'transparent', minHeight: '100vh' }}
      >
        <div className="container-fluid px-4 pt-3">
          <div className="row">
            {props.giveawayNumbers.list.content.map((item, index) => {
              return (
                <div
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-2"
                  style={{
                    borderRadius: 5,
                    padding: 30,
                    border: '1px solid lightgray',
                    marginLeft: 20,
                  }}
                  key={`${item.giveaway_id}-${item.number}`}
                >
                  <Typography variant="body1">
                    Sorteio: {item.giveaway.name}
                  </Typography>
                  <Typography variant="body1">
                    Número:{' '}
                    {item.number
                      ? calculateNumberLabel(item.number)
                      : 'Não Gerado'}
                  </Typography>
                  <Typography variant="body1">
                    Status:{' '}
                    {item.number
                      ? 'Clientes elegíveis conforme item 6.1 e 6.2 do regulamento da promoção ME LEVA NAÇÃO'
                      : 'Clientes inelegíveis conforme item 6.2 do regulamento da promoção ME LEVA NAÇÃO'}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    giveawayNumbers: state.giveawayNumbers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByUserId,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GiveawayNumbers);
