import ptBr from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box } from '@material-ui/core';
import React from 'react';

export default function LegalEvents() {
  function handleDateClick(e) {
    console.log(e.event);
  }

  return (
    <section id="legal-contacts">
      <p className="body-title mt-4">Eventos</p>
      <p className="h4 mt-4" style={{ color: '#838383', maxWidth: 500 }}>
        Aqui é um texto que explica este serviço do sindilegis. Algo que auxilie
        o entendimento dos itens abaixo e informações rápidas como telefone ou
        contato
      </p>
      <Box mt={4}>
        <FullCalendar
          defaultView="dayGridMonth"
          header={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          }}
          plugins={[dayGridPlugin, timeGridPlugin]}
          selectable={true}
          events={[
            { title: 'event 1', date: '2021-03-01' },
            { title: 'event 2', date: '2021-03-02' },
            { title: 'event 2', date: '2021-03-05' },
          ]}
          weekends
          eventColor={'#999999'}
          displayEventTime={true}
          displayEventEnd={true}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }}
          timeZone="UTC"
          locale={ptBr}
          eventClick={handleDateClick}
          slotDuration="00:15:00"
          buttonIcons={false}
        />
      </Box>
    </section>
  );
}
