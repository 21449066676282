import { Grid, Typography } from '@material-ui/core';
import Table from 'common/components/table/tableGeneral';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SimpleBlockSkeleton from '../../../../../common/components/skeleton/simpleBlockSkeleton';
import { getUsedCreditsByUser } from './extractActions';

function ExtractUsedCredits(props) {
  const { stationData } = props;
  const currencyData = {
    currency_multiplier: stationData.currency_multiplier,
    currency_name: stationData.currency_name,
    currency_name_position: stationData.currency_name_position,
  };

  useEffect(() => {
    props.getUsedCreditsByUser(props.auth.user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [columns] = useState([
    { title: 'Descrição', field: 'action.name' },
    { title: 'Valor', field: 'points' },
    { title: 'Data', field: 'createdAt' },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.extract.list.content);
  }, [props.extract.list.content]);

  if (props.extract.loading) {
    return (
      <Grid container xs={12} alignItems="flex-start" style={{ minHeight: '100vh' }}>
        <SimpleBlockSkeleton
          height="400px"
          width="100%"
        />
      </Grid>
    );
  }
  if (data.length > 0) {
    return (
      <section
        id="extract"
        style={{ backgroundColor: 'transparent', minHeight: '100vh' }}
      >
        <div>
          <Table
            isUsedCredits={true}
            currencyData={currencyData}
            filters={[]}
            searchPlaceholder={'Pesquisar por nome'}
            hasToolbar={false}
            defaultSelected={{}}
            title=""
            columns={columns}
            data={data}
          />
        </div>
      </section>
    );
  }
  return (
    <div style={{ margin: '100px auto', width: 'fit-content' }}>
      <Typography variant="body1">
        Você ainda não fez transações com créditos na plataforma.
      </Typography>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    extract: state.extract,
    general: state.general,
    stationData: state.website.stationData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUsedCreditsByUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtractUsedCredits);
