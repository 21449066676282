import Spinner from 'common/components/spinner/spinner';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUsersInvitedByUser } from './userInvitationsActions';
import Table from 'common/components/table/tableGeneral';
import { Typography } from '@material-ui/core';

let UserInvitations = function ({
  auth,
  getUsersInvitedByUser,
  userInvitation,
}) {
  useEffect(() => {
    getUsersInvitedByUser(auth.user.id);
  }, [auth.user.id, getUsersInvitedByUser]);

  const [columns] = useState([
    { title: 'Nome', field: 'invited.name' },
    { title: 'Data', field: 'createdAt' },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(userInvitation.list.content);
  }, [userInvitation.list.content]);

  if (userInvitation.loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );
  return (
    <section
      id="extract"
      style={{ backgroundColor: 'transparent', minHeight: '100vh' }}
    >

<div
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: 'transparent',
            height: '248px',
          }}
        >
        <Typography variant="h5">Meus convites</Typography>
        </div>


      <div className="py-4">
        <Table
          filters={[]}
          searchPlaceholder={'Pesquisar por nome'}
          hasToolbar={false}
          defaultSelected={{}}
          title=""
          columns={columns}
          data={data}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userInvitation: state.userInvitation,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUsersInvitedByUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInvitations);
