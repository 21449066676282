import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { BASE_S3, COMPANY_COLORS } from 'config/consts';
import React from 'react';
import { SIGNED_OFF_NAV_TEXT_COLOR } from '../../../config/consts';

const useStyles = makeStyles(() => ({
  liFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.2rem 0 !important',
    minWidth: '100%',
  },
}));

export default function NavBarBootstrap(props) {
  // const { navbar } = COMPANY_COLORS.website;
  const classes = useStyles();

  return (
    <div className="row">
      <div
        className="col-12 px-0"
        style={{ background: props.color ? props.color : '' }}
      >
        <nav
          className="navbar navbar-expand-lg navbar-light justify-content-between"
          style={{ padding: '8px 4px' }}
        >
          <a className="navbar-brand mx-3" href="/">
            <img
              src={`${BASE_S3}/${COMPANY_COLORS.website.navbar.station_primary_logo}`}
              alt="LOGO_ESTAÇÃO"
              style={{ maxHeight: 42 }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ border: 0 }}
          >
            <FontAwesomeIcon
              style={{ color: SIGNED_OFF_NAV_TEXT_COLOR }}
              icon={faBars}
            />
          </button>
          {/* <a
            href={props.has_App ? '#beneficios' : ''}
            className="mx-md-2 mx-lg-3"
          >
            <SimpleButton
              onClick={
                props.has_App ? () => {} : () => props.history.push('/login')
              }
              background={button.background}
              text={button.text}
              border={`1px solid ${button.border}`}
            >
              Entrar
            </SimpleButton>
          </a> */}

          <div className="collapse navbar-collapse " id="navbarNavDropdown">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                minWidth: '100%',
              }}
            >
              <ul
                className="navbar-nav"
                style={{
                  minWidth: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {props.website.aboutList.content
                  .filter(
                    (item) =>
                      item.menu_title !== null &&
                      item.menu_title !== undefined &&
                      item.menu_title !== ''
                  )
                  .map((item, index) =>
                    item.menu_title === 'Planos' ? (
                      <li className={classes.liFlex}>
                        <a
                          className="dropdown-item"
                          type="button"
                          style={{
                            color: SIGNED_OFF_NAV_TEXT_COLOR,
                            textAlign: 'center',
                            padding: '0',
                          }}
                          href={`#plans`}
                          onClick={() => {
                            props.history.push('/');
                          }}
                        >
                          {item.menu_title}
                        </a>
                      </li>
                    ) : (
                      <li className={classes.liFlex}>
                        <a
                          className="dropdown-item"
                          type="button"
                          style={{
                            color: SIGNED_OFF_NAV_TEXT_COLOR,
                            textAlign: 'center',
                            padding: '0',
                          }}
                          href={`#secao${item.id}`}
                          onClick={() => {
                            props.history.push('/');
                          }}
                        >
                          {item.menu_title}
                        </a>
                      </li>
                    )
                  )}
                <li className={classes.liFlex}>
                  <span
                    onClick={() => props.history.push('/suporte')}
                    className="nav-link dropdown-item"
                    style={{
                      color: SIGNED_OFF_NAV_TEXT_COLOR,
                      padding: '0',
                      textAlign: 'center',
                    }}
                  >
                    Fale Conosco
                  </span>
                </li>
                <li className={classes.liFlex}>
                  <span
                    onClick={() => props.history.push('/login')}
                    className="nav-link dropdown-item"
                    style={{
                      color: SIGNED_OFF_NAV_TEXT_COLOR,
                      padding: '0',
                      textAlign: 'center',
                    }}
                  >
                    Entrar
                  </span>
                </li>
                <li className={classes.liFlex}>
                  <span
                    onClick={() => props.history.push('/cadastrar')}
                    className="nav-link dropdown-item"
                    style={{
                      color: SIGNED_OFF_NAV_TEXT_COLOR,
                      padding: '0',
                      textAlign: 'center',
                    }}
                  >
                    Cadastrar
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
