import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
} from 'config/consts';
import React from 'react';

export function SimpleButton(props) {
  const {
    background = BUTTON_BACKGROUND_COLOR,
    text = BUTTON_TEXT_COLOR,
    border,
  } = props;
  return (
    <button
      className={`py-2 px-4 station-font-medium ${props.className}`}
      style={{
        background,
        color: text,
        border: border,
        fontSize: '0.875rem',
        height: props.height,
        width: props.width,
        fontFamily: BUTTON_FONT_FAMILY,
      }}
      {...props}
    >
      {props.children}
    </button>
  );
}
