import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from '../pages/auth/authReducer';
import userReducers from '../modules/user/userReducers';
const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  toastr: toastrReducer,

  //Reducers from Users module
  ...userReducers,
});

export default rootReducer;
