import axios from 'axios';
import { reset as resetForm, initialize } from 'redux-form';
import type from './types';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';

const URL = `${BASE_API}/subscription-plans`;
const URL_SUBSCRIPTIONS = `${BASE_API}/subscriptions`;
const URL_PLANS_SUBSCRIPTIONS = `${BASE_API}/plans-subscriptions`;

export const getList = (id) => {
  return (dispatch) => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getPlansSubscriptions = (id) => {
  return (dispatch) => {
    dispatch({ type: 'PLANS_SUBSCRIPTIONS_LOAD', payload: true });
    axios
      .get(`${URL_PLANS_SUBSCRIPTIONS}/?[station_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'PLANS_SUBSCRIPTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const remove = (id, resolve, reject, other = undefined) => {
  return (dispatch) => {
    dispatch({ type: type.LIST_LOAD, payload: false });
    axios
      .delete(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: type.LIST_LOAD,
            payload: false,
          },

          resolve(),
          getList(other),
        ]);
      })
      .catch((e) => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.message}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
        reject();
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: response,
          },
        ]);
      });
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: type.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
    ]);
  };
};

export const initializeForm = (values) => {
  return initialize('plansForm', values);
};

export const reset = () => {
  return resetForm('plansForm');
};

export const create = (values, history, firstTime) => {
  return submit(values, 'post', history, firstTime);
};

export const update = (values, history) => {
  return submit(values, 'put', history);
};

function submit(values, method, history = undefined) {
  const id = values.id ? `${values.id}/` : '';
  return (dispatch) => {
    dispatch([
      { type: type.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](`${URL}/${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: (progressEvent) =>
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: `${Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )} %`,
          },
        ]),
    })
      .then((response) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (!_.isUndefined(history)) {
          history.push('/owner/planos');
        }
      })
      .catch((e) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          if (e.response.data.error) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.error}`,
                variant: 'error',
                open: true,
              },
            });
          } else {
            for (const i in e.response.data) {
              for (const j in e.response.data[i]) {
                dispatch({
                  type: 'SNACKBAR',
                  payload: {
                    message: `${i}: ${e.response.data.errors[i][j]}`,
                    variant: 'error',
                    open: true,
                  },
                });
              }
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}

export const purchasePlan = (values, method, id) => {
  return (dispatch) => {
    dispatch([
      { type: type.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](
      `${
        method === 'post'
          ? URL_SUBSCRIPTIONS
          : `${URL_SUBSCRIPTIONS}${id ? `/${id}` : ''}`
      }`,
      values,
      {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      }
    )
      .then((response) => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          { type: 'USER_UPDATE_SUBSCRIPTION', payload: response.data },
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: 'PLANS_SUBSCRIPTIONS_SHOW_CONFIRMATION',
            payload: true,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          if (e.response.data.error) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.error}`,
                variant: 'error',
                open: true,
              },
            });
          } else {
            for (const i in e.response.data) {
              for (const j in e.response.data[i]) {
                dispatch({
                  type: 'SNACKBAR',
                  payload: {
                    message: `${i}: ${e.response.data.errors[i][j]}`,
                    variant: 'error',
                    open: true,
                  },
                });
              }
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const setLoading = (bool) => {
  return (dispatch) => {
    dispatch({
      type: type.LIST_LOAD,
      payload: false,
    });
  };
};

export const fileRejected = () => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: 'Erro: Imagem maior que 3MB',
        variant: 'error',
        open: true,
      },
    });
  };
};

export const deactivatePlan = (id, cleanup) => {
  return (dispatch) => {
    dispatch([
      { type: type.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .delete(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          cleanup(),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          if (e.response.data.error) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.error}`,
                variant: 'error',
                open: true,
              },
            });
          } else {
            for (const i in e.response.data) {
              for (const j in e.response.data[i]) {
                dispatch({
                  type: 'SNACKBAR',
                  payload: {
                    message: `${i}: ${e.response.data.errors[i][j]}`,
                    variant: 'error',
                    open: true,
                  },
                });
              }
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const showConfirmation = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'PLANS_SUBSCRIPTIONS_SHOW_CONFIRMATION',
      payload: value,
    });
  };
};

export const cancelSubscription = (values, cleanup) => {
  return (dispatch) => {
    dispatch([
      { type: 'PLANS_SUBSCRIPTIONS_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(`${URL_SUBSCRIPTIONS}/cancel`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'USER_UPDATE_SUBSCRIPTION', payload: {} },
          { type: 'PLANS_SUBSCRIPTIONS_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          cleanup(),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.messages[0]}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
