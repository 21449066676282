import { Box, Grid, Typography } from '@material-ui/core';
import { maskCPF } from 'helpers/utils';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { bindActionCreators } from 'redux';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  NAV_FONT_FAMILY,
} from '../../../../../config/consts';
import { getUser } from './virtualCardActions';

const QRCode = require('qrcode.react');

function VirtualCard({ auth, getUser, virtualCardUser, website }) {
  useEffect(() => {
    getUser(auth.user.id);
  }, [auth.user.id, getUser]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const date = new Date();
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const day = lastDayOfMonth.getDate().toString().padStart(2, '0');
  const month = (lastDayOfMonth.getMonth() + 1).toString().padStart(2, '0');
  const year = lastDayOfMonth.getFullYear();
  const formatData = `${day}/${month}/${year}`;

  if (virtualCardUser.isLoading === 'resolved') {
    return (
      <Box
        className="container-fluid d-flex-column justify-content-space-around h-100"
        style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          ref={componentRef}
          className="PrintSectionBorder"
          style={{
            width: '52%',
            margin: 'auto',
            backgroundColor: '#fff',
            borderRadius: 8,
            paddingBottom: 10,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={1}
          >
            <Grid
              className="col-12 col-md-12"
              style={{
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                textTransform: 'capitalize',
                display: 'flex',
                width: '200',
              }}
            >
              <img
                src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
                alt="LOGO-EMPRESA"
                style={{ maxWidth: '40%', height: '100', marginBottom: 32 }}
              />
            </Grid>
          </Box>
          <Box flexDirection="row" justifyContent="center" mt={1}>
            <Grid
              className="col-auto"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '45vw',
              }}
            >
              <QRCode
                value={
                  process.env.REACT_APP_API_KEY === 'test'
                    ? `http://localhost:3000/#/qr-code-user-validator/${auth.user.id}`
                    : `${website.stationData.url}/#/qr-code-user-validator/${auth.user.id}`
                }
              />
            </Grid>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
            <Grid
              className="col-md-auto col-12"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '45vw',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="h5"
                className="PrintSection"
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  color: '#333333',
                }}
              >
                {auth.user.name}
              </Typography>
              <Typography
                variant="subtitle1"
                className="PrintSection"
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  color: '#333333',
                }}
              >
                {`CPF: ${maskCPF(auth.user.cpf)}`}
              </Typography>
              <Typography
                variant="subtitle1"
                className="PrintSection"
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  color: '#333333',
                }}
              >
                {`Nascimento: ${moment(auth.user.birthday).format(
                  'DD/MM/YYYY'
                )}`}
              </Typography>
              {auth.user?.establishments?.map((item) => (
                <Typography
                  variant="h6"
                  className="PrintSection"
                  style={{
                    textAlign: 'center',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                    color: '#333333',
                  }}
                >
                  {item.name}
                </Typography>
              ))}
            </Grid>
          </Box>
          <Box className="row justify-content-center mt-2">
            <Grid
              className="col-md-auto col-12"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '45vw',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="subtitle1"
                className="PrintSection"
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  color: '#333333',
                }}
              >
                Validade:
              </Typography>
              <Typography
                variant="subtitle1"
                className="PrintSection"
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  color: '#333333',
                }}
              >
                {formatData}
              </Typography>
            </Grid>
          </Box>
        </Box>
        <Box className="row justify-content-center mt-3">
          <Grid className="col-md-auto col-11">
            <Box
              className={'py-3 station-font-medium'}
              style={{
                background: BUTTON_BACKGROUND_COLOR,
                color: BUTTON_TEXT_COLOR,
                borderRadius: 8,
                paddingLeft: isMobile ? 40 : 140,
                paddingRight: isMobile ? 40 : 140,
                maxWidth: '20vw',
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
                fontSize: '1rem',
                height: '50px',
                fontFamily: NAV_FONT_FAMILY,
                margin: 'auto',
              }}
              onClick={() => handlePrint()}
            >
              {isMobile ? 'Salvar' : 'Imprimir'}
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    virtualCardUser: state.virtualCardUser,
    website: state.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualCard);
