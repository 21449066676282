import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import { String2Currency } from 'helpers/dataTransformers';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, getFormValues, reduxForm } from 'redux-form';
import { GeneralTitle } from '../../../../../../common/styles/styles';
import { customCurrencyChanger } from '../../../../../../helpers/utils';
import {
  createCreditCard,
  initializeForm,
} from '../../../shopping/shoppingActions';
import PaymentMethods from '../../components/PaymentMethods';

const useStyles = makeStyles(() => ({
  flexForm: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
  },
  paymentInfoItem: {
    margin: '.2rem 0',
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: '0.25px',
  },
  paymentInfoValue: {
    margin: '.2rem 0',
    lineHeight: '22px',
    letterSpacing: '0.25px',
  },
}));

const paymentInfoItems = [
  { label: 'Entrega', id: 1 },
  { label: 'Produtos', id: 2 },
  { label: 'Subtotal', id: 3 },
  { label: 'Cashback', id: 4 },
  { label: 'Conclusão da compra', id: 5 },
];

let Payment = function ({
  setStep,
  handleSubmit,
  formValues,
  auth,
  initializeForm,
  timeLeft,
  createCreditCard,
  stationData,
}) {
  const classes = useStyles();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const currencyData = {
    currency_multiplier: stationData.currency_multiplier,
    currency_name: stationData.currency_name,
    currency_name_position: stationData.currency_name_position,
  };

  const handleBackwards = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  function cashback() {
    let cashback = 0;
    if (formValues && formValues.actions) {
      for (let item of formValues.actions) {
        if (parseFloat(item.action.points) > 0) {
          cashback = parseFloat(item.action.points) + cashback;
        }
        if (parseFloat(item.action.additional_cashback) > 0) {
          cashback = parseFloat(item.action.additional_cashback) + cashback;
        }
      }
    }
    return cashback.toFixed(2);
  }

  function subtotalProdutos() {
    let subtotal = 0;
    if (formValues && formValues.products) {
      for (let item of formValues.products) {
        if (parseFloat(item.detail.discount_value) > 0) {
          subtotal =
            parseFloat(item.detail.value) * parseInt(item.quantity) -
            parseFloat(item.detail.discount_value) * parseInt(item.quantity) +
            subtotal;
        } else if (parseFloat(item.detail.value) > 0) {
          subtotal =
            parseFloat(item.detail.value) * parseInt(item.quantity) + subtotal;
        }
      }
    }
    return subtotal;
  }

  function subtotalFrete() {
    let subtotal = 0;
    if (formValues && formValues.actions) {
      for (let item of formValues.actions) {
        if (item.location && item.location.deliveryLocation) {
          const deliveryLocationPrice = item.action.delivery_locations.filter(
            (deliveryLocation) =>
              parseInt(deliveryLocation.id) ===
              parseInt(item.location.deliveryLocation)
          )[0].price;

          subtotal = subtotal + parseFloat(deliveryLocationPrice);
        }
      }
    }
    return subtotal;
  }
  function subtotal() {
    return subtotalFrete() + subtotalProdutos();
  }
  function onSubmit(values) {
    if (
      values.payment?.selected_card === 0 &&
      values.payment.type === 'credit_card'
    ) {
      const payment = { ...values.payment };
      payment[
        'expiry'
      ] = `${values.payment.expiry[0]}${values.payment.expiry[1]}/${values.payment.expiry[2]}${values.payment.expiry[3]}`;
      const birthdayMoment = values.payment.birthday;
      const birthdayFinal = moment(birthdayMoment, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      );
      payment['birthday'] = birthdayFinal;
      createCreditCard(payment, setStep, auth.user.id);
    } else {
      setStep((step) => step + 1);
    }
  }

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const shooppingInfo = useMemo(() => {
    function subtotalProdutosMemo() {
      let subtotal = 0;
      if (formValues && formValues.products) {
        for (let item of formValues.products) {
          if (parseFloat(item.detail.discount_value) > 0) {
            subtotal =
              parseFloat(item.detail.value) * parseInt(item.quantity) -
              parseFloat(item.detail.discount_value) * parseInt(item.quantity) +
              subtotal;
          } else if (parseFloat(item.detail.value) > 0) {
            subtotal =
              parseFloat(item.detail.value) * parseInt(item.quantity) +
              subtotal;
          }
        }
      }
      return subtotal;
    }

    function subtotalFreteMemo() {
      let subtotal = 0;
      if (formValues && formValues.actions) {
        for (let item of formValues.actions) {
          if (item.location && item.location.deliveryLocation) {
            const deliveryLocationPrice = item.action.delivery_locations.filter(
              (deliveryLocation) =>
                parseInt(deliveryLocation.id) ===
                parseInt(item.location.deliveryLocation)
            )[0].price;

            subtotal = subtotal + parseFloat(deliveryLocationPrice);
          }
        }
      }
      return subtotal;
    }
    function subtotalMemo() {
      return subtotalFreteMemo() + subtotalProdutosMemo();
    }

    return {
      difference: subtotalMemo() - parseFloat(auth.points),
      total: String2Currency(subtotalMemo()),
      currency: String2Currency(subtotalMemo() - parseFloat(auth.points)),
    };
  }, [auth.points, formValues]);

  const userPoints = useMemo(() => {
    return customCurrencyChanger(currencyData, auth.points);
    // return String2Currency(auth.points);
  }, [auth.points, currencyData]);

  useEffect(() => {
    if (shooppingInfo.difference <= 0) {
      initializeForm({
        ...formValues,
        payment: null,
      });
    }
  }, [formValues, initializeForm, shooppingInfo.difference]);

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
        style={{
          minHeight: '90vh'
        }}
      >
        <Form
          role="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={classes.flexForm}
        >
          <Grid item container alignItems="center" justifyContent="center" xs={12}>
            {/* HEAD */}
            <Grid item container alignItems="center" xs={12} sm={10} md={12}>
              {parseFloat(shooppingInfo.difference.toFixed(2)) > 0.0 ? (
                <>
                  {auth.points > 0 ? (
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="center"
                      xs={12}
                    >
                      <Typography
                        variant="h5"
                        marginBottom={xs ? '24px' : '48px'}
                        style={{
                          marginTop: '90px',
                        }}
                      >
                        {/* <GeneralTitle
                          xs={xs}
                          sm={sm}
                        > */}
                        
                        Saldo e cartão
                        {/* </GeneralTitle> */}
                      </Typography>
                      <Grid item container xs={12}>
                        <Typography variant="body1">
                          {`Será debitado da sua carteira virtual o valor de ${userPoints} e ${shooppingInfo.currency} serão cobrados da sua forma de pagamento escolhida.`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="center"
                      xs={12}
                    >
                      <Typography variant="h6">
                        <GeneralTitle
                          xs={xs}
                          sm={sm}
                          marginBottom={xs ? '24px' : '48px'}
                        >
                          Forma da pagamento
                        </GeneralTitle>
                      </Typography>
                      <Typography variant="body1">
                        Selecione uma forma da pagamento para realizar a compra.
                        Você pagará {shooppingInfo.total} com a opção escolhida.
                      </Typography>
                    </Grid>
                  )}
                  <PaymentMethods />
                </>
              ) : (
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  xs={12}
                >
                  <Typography variant="h4">
                    <GeneralTitle
                      xs={xs}
                      sm={sm}
                      marginBottom={xs ? '24px' : '48px'}
                      style={{
                        marginTop: '90px',
                      }}
                    >
                      Saldo
                    </GeneralTitle>
                  </Typography>
                  <Typography variant="body1">
                    {`Será debitado da sua carteira o valor de ${shooppingInfo.total}.`}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {/* PAYMENT INFO */}
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              sm={10}
              md={8}
              style={{
                margin: '.5rem 0',
              }}
            >
              <Grid
                item
                container
                xs={6}
                sm={9}
                md={10}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                {paymentInfoItems.map((item) => (
                  <Typography
                    variant="body1"
                    key={item.id} // bad key
                    className={classes.paymentInfoItem}
                  >
                    {item.label}
                  </Typography>
                ))}
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={3}
                md={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {String2Currency(subtotalFrete())}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {String2Currency(subtotalProdutos())}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {String2Currency(subtotal())}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {/* {String2Currency(cashback())} */}
                  {customCurrencyChanger(currencyData, cashback())}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {timeLeft.minutes}:{timeLeft.seconds}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={sm || xs ? 'center' : 'space-between'}
              alignItems="center"
              xs={12}
              sm={10}
              md={8}
              style={{
                padding: '1.7rem 1.5rem',
                borderRadius: sm || xs ? '10px' : '0 0 10px 10px',
                marginTop: '.6rem',
              }}
            >
              <Grid
                item
                container
                justifyContent={sm || xs ? 'center' : 'flex-start'}
                alignItems="flex-start"
                xs={12}
                md={6}
                style={{
                  marginBottom: sm || xs ? '.5rem' : null,
                  height: sm || xs ? '60px' : '',
                }}
              >
                {sm || xs ? (
                  <Typography
                    gutterBottom={true}
                    variant="body1"
                    style={{ textAlign: 'start', maxWidth: '80%' }}
                  >
                    Pronto, agora é só revisar!
                  </Typography>
                ) : (
                  <Typography
                    gutterBottom={true}
                    variant="body1"
                    style={{ textAlign: 'start', maxWidth: '80%' }}
                  >
                    Na próxima etapa você irá conferir seu pedido.
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                justifyContent={sm || xs ? 'center' : 'flex-end'}
                alignItems="center"
                spacing={1}
                xs={12}
                md={6}
              >
                
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={6}
                  style={{ marginTop: sm || xs ? '.6rem' : null }}
                >
                  <FullButton classNameProp="py-3 px-4" type="submit">
                    Próximo passo
                  </FullButton>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={4}
                  style={{ marginTop: sm || xs ? '.6rem' : null }}
                >
                  <FullButton
                    classNameProp="py-3 px-4"
                    type="button"
                    onClick={handleBackwards}
                    isSecondary
                  >
                    Voltar
                  </FullButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </>
  );
};

Payment = reduxForm({
  form: 'userShoppingCartForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Payment);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities.detail,
    formValues: getFormValues('userShoppingCartForm')(state),
    stationData: state.website.stationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ initializeForm, createCreditCard }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
