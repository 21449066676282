import { Grid } from '@material-ui/core';
import React from 'react';
import DentalContactsCard from './dentalContactsCard';

const contacts = [
  {
    name: 'Ricardo Gomes',
    tel: '(61) 3214-7318',
    email: 'ricardo@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/fermento.app.files/files/system/sindilegis/ricardo.png',
    role: 'Gerente de Benefícios',
  },
  {
    name: 'Daiana Costa',
    email: 'daiana@sindilegis.org.br',
    role: 'Analista Sindical de Benefícios',
  },
  {
    name: 'Marcelo Cleyton',
    email: 'marcelo@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/fermento.app.files/files/system/sindilegis/marcelo.png',
    role: 'Analista Sindical de Benefícios',
  },
];

export default function DentalContacts() {
  return (
    <section id="dental-contacts">
      <p className="body-title mt-4">Núcleo de benefícios do Sindilegis</p>
      <p className="h7 mt-5">Telefone: (61) 3214-7318</p>
      <p className="h7">Email: beneficios@sindilegis.org.br</p>
      <Grid container spacing={3} style={{ marginTop: 48 }}>
        {contacts.map(({ name, cel, tel, email, image, role }) => (
          <Grid item xs={4}>
            <DentalContactsCard
              name={name}
              cel={cel}
              tel={tel}
              email={email}
              image={image}
              role={role}
            />
          </Grid>
        ))}
      </Grid>
    </section>
  );
}
