import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import { GENERAL_TEXT_COLOR } from 'config/consts';
import { String2Currency } from 'helpers/dataTransformers';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import { GeneralTitle } from '../../../../../../common/styles/styles';
import { customCurrencyChanger } from '../../../../../../helpers/utils';
import { RenderProducts } from './renderProducts';

const useStyles = makeStyles(() => ({
  flexForm: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentInfoItem: {
    margin: '.2rem 0',
    fontWeight: 'bold',
  },
  paymentInfoValue: {
    margin: '.2rem 0',
    color: GENERAL_TEXT_COLOR,
  },
}));

const paymentInfoItems = [
  { label: 'Entrega', id: 1 },
  { label: 'Produtos', id: 2 },
  { label: 'Subtotal', id: 3 },
  { label: 'Cashback', id: 4 },
  { label: 'Conclusão da compra', id: 5 },
];

let Cart = function ({
  setStep,
  formValues,
  handleSubmit,
  timeLeft,
  stationData,
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  // const md = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const currencyData = {
    currency_multiplier: stationData.currency_multiplier,
    currency_name: stationData.currency_name,
    currency_name_position: stationData.currency_name_position,
  };

  const handleForward = useCallback(() => {
    setStep((step) => step + 1);
  }, [setStep]);

  const handleBackwards = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  function subtotalProdutos() {
    let subtotal = 0;
    if (formValues && formValues.products) {
      for (let item of formValues.products) {
        if (parseFloat(item.detail.discount_value) > 0) {
          subtotal =
            parseFloat(item.detail.value) * parseInt(item.quantity) -
            parseFloat(item.detail.discount_value) * parseInt(item.quantity) +
            subtotal;
        } else if (parseFloat(item.detail.value) > 0) {
          subtotal =
            parseFloat(item.detail.value) * parseInt(item.quantity) + subtotal;
        }
      }
    }
    return subtotal;
  }

  function subtotalFrete() {
    let subtotal = 0;
    if (formValues && formValues.actions) {
      for (let item of formValues.actions) {
        if (item.location && item.location.deliveryLocation) {
          const deliveryLocationPrice = item.action.delivery_locations.filter(
            (deliveryLocation) =>
              parseInt(deliveryLocation.id) ===
              parseInt(item.location.deliveryLocation)
          )[0].price;

          subtotal = subtotal + parseFloat(deliveryLocationPrice);
        }
      }
    }
    return subtotal;
  }
  function subtotal() {
    return (subtotalFrete() + subtotalProdutos()).toFixed(2);
  }
  function submit() {
    handleForward();
  }
  function cashback() {
    let cashback = 0;
    if (formValues && formValues.actions) {
      for (let item of formValues.actions) {
        if (parseFloat(item.action.points) > 0) {
          cashback = parseFloat(item.action.points) + cashback;
        }
        if (parseFloat(item.action.additional_cashback) > 0) {
          cashback = parseFloat(item.action.additional_cashback) + cashback;
        }
      }
    }
    return cashback.toFixed(2);
  }

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
        style={{
          minHeight: '100vh'
        }}
      >
        {/* FORM GRID */}
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            paddingTop: sm || xs ? '.5rem' : '',
          }}
        >
          <Form
            role="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            className={classes.flexForm}
          >
            {/* MAIN */}
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={11}
              md={12}
            >
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
              >
                <Typography variant="h4">
                  <GeneralTitle
                    xs={xs}
                    sm={sm}
                    marginBottom={xs ? '24px' : '48px'}
                    style={{
                      marginTop: '90px',
                    }}
                  >
                    Informações adicionais
                  </GeneralTitle>
                </Typography>
              </Grid>
              <FieldArray
                name="actions"
                component={RenderProducts}
                formValues={formValues}
              />
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                xs={12}
              >
                {/* payment items */}
                <Grid
                  item
                  container
                  xs={6}
                  sm={9}
                  md={10}
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  {paymentInfoItems.map((item) => (
                    <Typography
                      variant="body1"
                      key={item.id}
                      className={classes.paymentInfoItem}
                    >
                      {item.label}
                    </Typography>
                  ))}
                </Grid>
                {/* payment values  */}
                <Grid
                  item
                  container
                  xs={6}
                  sm={3}
                  md={2}
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Typography
                    variant="body1"
                    className={classes.paymentInfoValue}
                  >
                    {String2Currency(subtotalFrete())}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.paymentInfoValue}
                  >
                    {String2Currency(subtotalProdutos())}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.paymentInfoValue}
                  >
                    {String2Currency(subtotal())}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.paymentInfoValue}
                  >
                    {/* {String2Currency(cashback())} */}
                    {customCurrencyChanger(currencyData, cashback())}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.paymentInfoValue}
                  >
                    {timeLeft.minutes}:{timeLeft.seconds}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent={sm || xs ? 'center' : 'space-between'}
                alignItems="center"
                xs={12}
                style={{
                  padding: '1.7rem 1.5rem',
                  borderRadius: sm || xs ? '10px' : '0 0 10px 10px',
                  marginTop: '.6rem',
                }}
              >
                <Grid
                  item
                  container
                  justifyContent={sm || xs ? 'center' : 'flex-start'}
                  alignItems="flex-start"
                  xs={12}
                  md={6}
                  style={{
                    marginBottom: sm || xs ? '.5rem' : null,
                    height: sm || xs ? '60px' : '',
                  }}
                >
                  <Typography
                    gutterBottom={true}
                    component="p"
                    style={{ textAlign: 'start', maxWidth: '80%' }}
                  >
 
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={sm || xs ? 'center' : 'flex-end'}
                  alignItems="center"
                  spacing={1}
                  xs={12}
                  md={6}
                >


              <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={6}
                    style={{ marginTop: sm || xs ? '.6rem' : null }}
                  >
                    <FullButton classNameProp="py-3 px-4" type="submit">
                      Ir para pagamento
                    </FullButton>
                  </Grid>

                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={4}
                    style={{ marginTop: sm || xs ? '.6rem' : null }}
                  >
                    <FullButton
                      classNameProp="py-3 px-4"
                      type="button"
                      onClick={handleBackwards}
                      isSecondary
                    >
                      Voltar
                    </FullButton>
                  </Grid>



                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </>
  );
};

Cart = reduxForm({
  form: 'userShoppingCartForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Cart);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities.detail,
    formValues: getFormValues('userShoppingCartForm')(state),
    stationData: state.website.stationData,
  };
};

export default connect(mapStateToProps)(Cart);
