import React from 'react';
import './styles.css';

const AccountingGeneralInfo = () => {
  return (
    <section id="legal-info" className="d-flex justify-content-center mt-5">
      <div style={{ maxWidth: 600, lineHeight: 42 }}>
        <p className="body-title mt-5">Como usar</p>
        <p className="h6 mt-5">
          A Consultoria Contábil presta atendimento todas às quintas-feiras, das
          9 às 18 horas, mediante prévio agendamento que pode ser feito conforme
          a seguir:
        </p>

        <p className="h6 mt-4">
          - Por esta plataforma{' '}
          <a
            href={
              process.env.REACT_APP_API_KEY === 'production'
                ? 'https://mais.sindilegis.org.br/#/homepage/consultoria-contabil/agendamentos'
                : process.env.REACT_APP_API_KEY === 'test'
                ? 'http://sindilegisteste.fantest.site/#/homepage/consultoria-contabil/agendamentos'
                : 'http://localhost:3000/#/homepage/consultoria-contabil/agendamentos'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#000' }}
            className="h6"
          >
            clique aqui.
          </a>
        </p>
        <p className="h6">- Por telefone – (61) 3214-7301 ou 3214-7339</p>
        <p className="h6">- Por e-mail – consulegis@sindilegis.org.br</p>
        <p className="h6 mt-4">
          Observação: devido a atual pandemia provocada pelo COVID-19, nosso
          atendimento está sendo realizado exclusivamente on line. Pode ser
          feito por e-mail, telefone ou se ainda preferir, para o seu conforto,
          por meio de vídeo conferência.
        </p>
        <p className="h6-bold mt-4">Consultoria Contábil gratuita</p>
        <p className="h6">
          O serviço é gratuito para o filiado ao Sindilegis e contempla
          servidores residentes em quaisquer regiões do país, atuando nos
          seguintes temas:
        </p>
        <p className="h6 mt-4">
          - Regularização, inscrição, baixa de empregados no âmbito do E-social
          (Empregada doméstica, caseiro, cuidador de idosos, motorista)
        </p>
        <p className="h6">
          - Regularização de débitos junto a Receita Federal e a Secretaria de
          Fazenda do Distrito Federal
        </p>
        <p className="h6">- Regularização de CPF junto a Receita Federal</p>
        <p className="h6">- Consultoria Tributária</p>
        <p className="h6">- Imposto de Renda (Instrução e Regularização)</p>
      </div>
    </section>
  );
};

export default AccountingGeneralInfo;
