import React, { useState, useEffect } from 'react';
import { LikeButton } from '../styles';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  sendActivityLike,
  sendActivityDislike,
  getActivityLikes,
} from '../../activitiesActions';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { GENERAL_TEXT_COLOR } from '../../../../../../config/consts';
import { Typography } from '@material-ui/core';

const ActivityLike = (props) => {
  const { activity, auth, getActivityLikes } = props;
  const [didLike, setDidLike] = useState(false);
  const [localLikeCount, setLocalLikeCount] = useState('...');

  useEffect(() => {
    async function fetchActionLikes() {
      await getActivityLikes(activity.detail.id);
    }
    fetchActionLikes();

    return () => setDidLike(false);
  }, [activity.detail.id, getActivityLikes]);

  useEffect(() => {
    function verifiesDidLike() {
      if (activity.actionLikes) {
        !!activity.actionLikes.find((el) => el.user_id === auth.user.id) &&
          setDidLike(
            !!activity.actionLikes.find((el) => el.user_id === auth.user.id)
          );
      }
    }
    verifiesDidLike();
    activity.actionLikes && setLocalLikeCount(activity.actionLikes.length);

    return () => setDidLike(false);
  }, [activity.actionLikes, auth.user]);

  const handleLikeClick = () => {
    const likeData = {
      action: activity.detail.id,
      user: auth.user.id,
    };
    const dislikeData = {
      action: activity.detail.id,
      user: auth.user.id,
      comment_id: null,
    };

    if (didLike === false) {
      props.sendActivityLike(likeData, activity.detail.id);
      setDidLike(!didLike);
      setLocalLikeCount(localLikeCount + 1);
    } else {
      props.sendActivityDislike(dislikeData, activity.detail.id);
      setDidLike(!didLike);
      setLocalLikeCount(localLikeCount - 1);
    }
  };

  const likeHeart = () => <FavoriteBorderIcon style={{ color: GENERAL_TEXT_COLOR }} />;
  const likeFilledHeart = () => <FavoriteIcon style={{ color: 'red' }} />;

  return (
    <div>
      <LikeButton onClick={() => handleLikeClick()}>
        {didLike ? likeFilledHeart() : likeHeart()}
        <Typography variant="body1" component="span">
          {localLikeCount}
        </Typography>
      </LikeButton>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activity: state.activities,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendActivityLike,
      sendActivityDislike,
      getActivityLikes,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLike);
