import MaterialButton from '@material-ui/core/Button';
import React from 'react';
import { COMPANY_COLORS } from '../../../config/consts';
import { appColors } from '../../../styles/color';
import LinearDeterminate from '../progress/progress';

const {
  v2: { white, mediumGray },
} = appColors;

export default function Button({
  onClick,
  label,
  buttonStyle,
  labelStyle,
  type = 'primary',
  disabled,
  progress,
  buttonType,
}) {
  if (progress) {
    return (
      <MaterialButton
        onClick={onClick}
        style={{
          padding: '16px 64px',
          backgroundColor: mediumGray,
          border: white,
          borderRadius: 6,
          display: 'block',
        }}
        disabled={Boolean(disabled)}
        type={buttonType}
      >
        <LinearDeterminate progress={progress} style={{ display: 'block' }} />
        <p
          className="h6"
          style={{
            color: white,
            textTransform: 'initial',
            ...labelStyle,
          }}
        >
          {label}
        </p>
      </MaterialButton>
    );
  } else {
    return (
      <MaterialButton
        onClick={onClick}
        style={{
          padding: '16px 64px',
          backgroundColor: disabled
            ? mediumGray
            : type === 'primary'
            ? COMPANY_COLORS.primary
            : white,
          border: disabled
            ? 'white'
            : type === 'primary'
            ? ''
            : `1px solid ${COMPANY_COLORS.primary}`,
          borderRadius: 6,
          ...buttonStyle,
        }}
        disabled={Boolean(disabled)}
        type={buttonType || 'button'}
      >
        <p
          className="h6"
          style={{
            color: disabled
              ? 'white'
              : type === 'primary'
              ? white
              : COMPANY_COLORS.primary,
            textTransform: 'initial',
            ...labelStyle,
          }}
        >
          {label}
        </p>
      </MaterialButton>
    );
  }
}
