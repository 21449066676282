import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Schedules from './schedules';
import SchedulesDetail from './schedulesDetail';

export default (
  <Route path="/homepage/calendario">
    <Switch>
      <Route exact path="/homepage/calendario" component={Schedules} />
      <Route path="/homepage/calendario/:id" component={SchedulesDetail} />
    </Switch>
  </Route>
);
