import React from 'react';

export default function Wallet(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '32.502'}
      height={props.height || '30.062'}
      fill={props.color || '#000'}
      viewBox="0 0 32.502 30.062"
    >
      <g id="Group_78" data-name="Group 78" transform="translate(20057 15846)">
        <path
          id="Path_1051"
          data-name="Path 1051"
          d="M85.062,488.691v2.44a1.83,1.83,0,0,1-1.83,1.83H58.83a1.83,1.83,0,0,1-1.83-1.83v-18.3A1.83,1.83,0,0,1,58.83,471h24.4a1.83,1.83,0,0,1,1.83,1.83v5.49"
          transform="translate(-20113 -16309.899)"
          fill="none"
          stroke={props.color || '#000'}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_1052"
          data-name="Path 1052"
          d="M98.761,490.32h-6.1A3.66,3.66,0,0,1,89,486.66h0A3.66,3.66,0,0,1,92.66,483h6.1a1.22,1.22,0,0,1,1.22,1.22v4.88A1.22,1.22,0,0,1,98.761,490.32Z"
          transform="translate(-20125.479 -16314.579)"
          fill="none"
          stroke={props.color || '#000'}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <circle
          id="Ellipse_117"
          data-name="Ellipse 117"
          cx="1"
          cy="1"
          r="1"
          transform="translate(-20034 -15829)"
          stroke-width="2"
          stroke={props.color || '#000'}
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        />
        <path
          id="Path_1053"
          data-name="Path 1053"
          d="M88.79,468.27,86.325,464l-7.4,4.27"
          transform="translate(-20121.551 -16307.17)"
          fill="none"
          stroke={props.color || '#000'}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_1054"
          data-name="Path 1054"
          d="M78.311,464.486,76.3,461l-10.566,6.1"
          transform="translate(-20116.404 -16306)"
          fill="none"
          stroke={props.color || '#000'}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}
