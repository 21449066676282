import styled from 'styled-components';

export const Container = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  margin: 40px auto;

  h1 {
    margin-bottom: 20px;
  }

  h5 {
    margin-bottom: 30px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    div {
      width: 100%;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
