import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ProductDescription from 'common/components/dialog/dialog';
import { String2Currency } from 'helpers/dataTransformers';
import React, { useMemo, useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  Delete,
  DescriptionContainer,
  FinalPrice,
  ItemImage,
  Prices,
  Quantity,
  SeeMore,
  Title,
} from './styles';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '1rem',
  },
}));

let ItemCard = function ({
  onPressDelete,
  canDelete,
  formValues,
  shouldUpdate,
  input: { onChange, value },
}) {
  const [visible, setVisible] = useState(false);
  const [items] = useState(value.quantity || 0);
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  // const md = useMediaQuery(theme.breakpoints.up('md'));

  const price = useMemo(() => {
    return String2Currency(value.detail.value);
  }, [value.detail.value]);

  const discountPrice = useMemo(() => {
    return String2Currency(value.detail.value - value.detail.discount_value);
  }, [value.detail.discount_value, value.detail.value]);

  const finalPrice = useMemo(() => {
    return ((value.detail.value - value.detail.discount_value) * items)
      .toFixed(2)
      .replace('.', ',');
  }, [value.detail.value, value.detail.discount_value, items]);

  function onAdd() {
    if (value.detail.limit < value.quantity + 1) {
      return;
    }
    return onChange({ ...value, quantity: value.quantity + 1 });
  }

  function onSubtract() {
    onChange({ ...value, quantity: value.quantity - 1 });
  }
  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        xs={12}
        className={classes.root}
        style={{
          borderBottom: '1px solid rgb(131, 131, 131, 0.5)',
          paddingBottom: '.8rem',
        }}
      >
        <Grid
          item
          container
          justifyContent={sm || xs ? 'flex-start' : 'flex-start'}
          alignItems={sm || xs ? 'space-between' : 'space-between'}
          xs={12}
          md={8}
          style={{
            minHeight: '80px',
            padding: sm || xs ? '.8rem 0' : '0',
          }}
        >
          <Grid
            item
            container
            justifyContent={sm || xs ? 'flex-start' : 'flex-start'}
            alignItems="center"
            style={{
              marginRight: xs || sm ? '.3rem' : '.7rem',
            }}
            xs={6}
            md={3}
          >
            <ItemImage
              image={value.detail.image}
              onClick={() => setVisible(true)}
              isMobile={sm || xs}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="flex-start"
            direction="column"
            xs={5}
            md={7}
          >
            <Grid item>
              <Typography variant="body1">
                <Title onClick={() => setVisible(true)} isMobile={sm || xs}>
                  {value.detail.name}
                </Title>
              </Typography>
              <Typography variant="subtitle2">
                <SeeMore onClick={() => setVisible(true)}>Ver mais</SeeMore>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Prices>
                  <span>{discountPrice}</span>
                  {value.detail.discount_value > 0 && <span>{price}</span>}
                </Prices>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems={sm || xs ? 'center' : 'flex-end'}
          xs={12}
          md={4}
          style={{
            padding: sm || xs ? '.8rem 0' : '0',
            minHeight: '100%',
          }}
        >
          <Grid
            item
            container
            justifyContent={
              !shouldUpdate
                ? 'flex-end'
                : sm || xs
                ? 'flex-end'
                : 'space-between'
            }
            alignItems={sm || xs ? 'center' : 'space-between'}
            direction={sm || xs ? 'row-reverse' : 'column'}
            xs={6}
            md={5}
            style={{
              minHeight: '100%',
            }}
          >
            {canDelete ? (
              <Typography variant="subtitle2">
                <Delete onClick={() => onPressDelete(value.id)}>Excluir</Delete>
              </Typography>
            ) : null}
            {shouldUpdate ? (
              <Quantity>
                <FiMinus onClick={() => onSubtract()} size={14} />
                <p>{items}</p>
                <FiPlus onClick={() => onAdd()} size={14} />
              </Quantity>
            ) : (
              <Quantity>
                <p>{items}</p>
              </Quantity>
            )}
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems={sm || xs ? 'flex-end' : 'flex-end'}
            direction="column"
            xs={6}
            md={7}
            style={{
              minHeight: '100%',
            }}
          >
            {/* <p>Total</p> */}
            <FinalPrice>
              <Typography variant="body1">R$ {finalPrice}</Typography>
            </FinalPrice>
          </Grid>
        </Grid>

        <ProductDescription
          visible={visible}
          onClose={() => setVisible(false)}
          maxWidth={'sm'}
          fullWidth
        >
          <ItemImage description image={value.detail.image} isRounded={false}/>
          <DescriptionContainer>
            <p>{value.detail.name}</p>
            <Prices>
              <span>{discountPrice}</span>
              {value.detail.discount_value > 0 && <span>{price}</span>}
            </Prices>
            <span>{value.detail.description}</span>
          </DescriptionContainer>
        </ProductDescription>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('userShoppingCartForm')(state),
  };
};

export default connect(mapStateToProps)(ItemCard);
