import React from 'react';
import GeneralGrid from '../layout/grid/GeneralGrid';
import { InputLabelOutlined } from 'common/components/fields/fields';
import { Field } from 'redux-form';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { FORM_RULES } from 'helpers/validations';
// import { COMPANY_COLORS } from 'config/consts';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR, GENERAL_TEXT_COLOR } from '../../../config/consts';
import { Typography } from '@material-ui/core';

export const emailForms = props => (
  <ul className="email-list" style={{
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR
  }}>
    <li>
      <Typography
        variant="body1"
        className="text-left mb-0 mt-3 mb-2"
      >
        Adicione e-mails que vão receber o convite
      </Typography>
      <GeneralGrid container spacing={2}>
        <GeneralGrid
          item
          xs={10}
          md={10}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Field
            component={InputLabelOutlined}
            label="E-mail"
            name="email_0"
            margin="normal"
            required
            validate={[FORM_RULES.required, FORM_RULES.email]}
          />
        </GeneralGrid>
        <GeneralGrid
          item
          xs={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Fab
            style={{
              boxShadow: 'none',
              background: 'transparent',
              border: 'none',
              outline: 0,
              color: GENERAL_TEXT_COLOR
            }}
            aria-label="add"
            onClick={() => {
              if (props.fields.length === 4) {
                props.dispatchError();
              } else {
                props.fields.push({});
              }
            }}
          >
            <AddIcon />
          </Fab>
        </GeneralGrid>
      </GeneralGrid>
    </li>
    {props.fields.map((name, index) => {
      return (
        <li key={index}>
          <GeneralGrid container spacing={2}>
            <GeneralGrid
              item
              xs={10}
              md={10}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Field
                component={InputLabelOutlined}
                label={`E-mail`}
                name={`email_${index + 1}`}
                margin="normal"
                required
                validate={[FORM_RULES.required, FORM_RULES.email]}
              />
            </GeneralGrid>
            {index === props.fields.length - 1 ? (
              <GeneralGrid
                item
                xs={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Fab
                  style={{
                    boxShadow: 'none',
                    background: 'transparent',
                    border: 'none',
                    outline: 0,
                  }}
                  aria-label="delete"
                  onClick={() => {
                    props.fields.remove(index);
                  }}
                >
                  <DeleteIcon />
                </Fab>
              </GeneralGrid>
            ) : null}
          </GeneralGrid>
        </li>
      );
    })}
    {props.meta.error && <li className="error">{props.meta.error}</li>}
  </ul>
);
