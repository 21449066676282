import ButtonPrimary from 'common/components/buttons/ButtonPrimary';
import NewFeedbackContent from 'common/components/modal/content/newFeedbackContent';
import Answers from 'common/components/quiz/AnswersParticipant';
import Questions from 'common/components/quiz/QuestionsParticipant';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  getLottieById,
  setFeedbackQuizModal,
  submitCheckListQuiz,
} from '../activitiesActions';
import { Container } from './styles';

let ChecklistCorrectAnswer = function (props) {
  const [percentage, setPercentage] = useState(0);

  async function onSubmit(values) {
    const { auth, activities } = props;
    const newValues = {
      user: auth.user.id,
      author: 'user',
      action: activities.detail.id,
      establishment: auth.user.establishment_participant.id,
      research: activities.detail.researches[0].id,
    };

    let marked = [];
    for (let i in values) {
      if (values[i].checked) {
        marked.push({
          option: parseInt(i.split('-')[1]),
          question: parseInt(i.split('-')[2]),
        });
      }
    }
    const questions = props.questions.map((item) => item.id);
    const answers = [];
    for (let question of questions) {
      console.log('Marked: ', marked);
      console.log('Question: ', question);
      const optionsPartial = marked.filter(
        (item) => item.question === question
      );
      answers.push({
        question: question,
        options: optionsPartial.map((item) => item.option),
      });
    }
    newValues.answers = answers;

    const allQuestions = activities.detail.researches[0].questions;
    let markedOptions = marked.map((item) => item.option);
    let correctAnswers = 0;
    console.log(allQuestions);
    allQuestions.forEach((item) =>
      item.options.forEach((item) => {
        if (markedOptions.indexOf(item.id) > -1) {
          if (item.correct) {
            correctAnswers++;
          }
        }
      })
    );
    newValues.answer_percentage = (
      correctAnswers / allQuestions.length
    ).toFixed(2);
    setPercentage((correctAnswers / allQuestions.length).toFixed(2));
    props.submitCheckListQuiz(newValues, props.history);
  }

  return (
    <>
      {!props.activities.feedbackQuizModal ? (
        <Form onSubmit={props.handleSubmit(onSubmit)} noValidate>
          {props.questions.map((question, index) => (
            <>
              <Field
                name="question"
                component={Questions}
                question={question}
              />
              <Container>
                {question.options.map((item, index) => (
                  <Field
                    name={`option-${item.id}-${question.id}`}
                    component={Answers}
                    questionId={question.id}
                    multipleQuestions
                    background={item.color ? item.color : '#2D95EF'}
                    textColor={item.textColor ? item.textColor : '#FFF'}
                    text={item.label ? item.label : null}
                    image={item.image ? item.image : null}
                    oneOnly
                    checkedOptions={props.formValues ? props.formValues : null}
                  />
                ))}
              </Container>
            </>
          ))}
          <ButtonPrimary
            disabled={props.activities.buttonLoading}
            progress={parseInt(props.general.submitProgress)}
            type="submit"
          >
            {props.activities.buttonLoading ? 'Enviando' : 'Enviar'}
          </ButtonPrimary>
        </Form>
      ) : (
        <NewFeedbackContent
          onCloseRoute="/homepage"
          history={props.history}
          quizFeedbacks={props.activities.detail.researches[0].quiz_feedbacks}
          percentage={percentage}
          lottie={props.activities.lottieDetail}
          setFeedbackQuizModal={props.setFeedbackQuizModal}
        />
      )}
    </>
  );
};

ChecklistCorrectAnswer = reduxForm({ form: 'checklistCorrectForm' })(
  ChecklistCorrectAnswer
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('checklistCorrectForm')(state),
    activities: state.activities,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitCheckListQuiz,
      setFeedbackQuizModal,
      getLottieById,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistCorrectAnswer);
