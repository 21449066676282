import React from 'react';
import './styles.css';

const DentalGeneralInfo = () => {
  return (
    <section id="legal-info" className="d-flex justify-content-center mt-5">
      <div style={{ maxWidth: 600, lineHeight: 42 }}>
        <p className="body-title mt-5">Como usar</p>
        <p className="h6 mt-5">
          Os filiados ao Sindilegis e seus dependentes têm direito ao convênio
          Porto Seguro Odonto - categoria Odonto Bronze AD -, que oferece mais
          de 25 mil estabelecimentos odontológicos credenciados em todo o
          Brasil. Veja a{' '}
          <a
            href="https://s3.amazonaws.com/fermento.app.files/files/sindilegis/ODONTO+BRONZE+-+COBERTURA+DETALHADA.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            cobertura detalhada
          </a>
          .
        </p>
        <p className="h6-bold mt-5">Carteirinha da Porto Seguro</p>
        <p className="h6">
          1) Acesse o site:{' '}
          <a
            href="https://www.portoseguro.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.portoseguro.com.br
          </a>
        </p>
        <p className="h6">
          2) Vá para a Área do Cliente e clique em “Quero me cadastrar”.
          Preencha todos os campos e confirme o cadastro (faça isso para todos
          os dependentes também).
        </p>
        <p className="h6">
          3) Baixe o aplicativo da Porto na Apple Store ou Google Play.
        </p>
        <p className="h6">
          4) Clique em: “Eu sou cadastrado”. Em seguida, preencha os dados
          conforme cadastro feito no site da Porto Seguro.
        </p>
        <p className="h6">
          5) Para acessar a carteirinha basta clicar em “Detalhes da
          carteirinha”.
        </p>
        <p className="h6">
          6) No site da Porto Seguro você também tem acesso a toda rede
          credenciada.
        </p>
        <p className="h6-bold mt-5">Odontolegis</p>
        <p className="h6">
          Uma das clínicas mais importantes da rede Porto Seguro é a
          Odontolegis, que atende exclusivamente os filiados e seus dependentes.
          A clínica está localizada na sede está localizada na sede do
          Sindilegis (610 sul – antiga Ascade).
        </p>
        <p className="h6">
          O atendimento na Odontolegis ocorre de segunda à sexta-feira, das 8h
          às 18h e aos sábados, das 8h às 12h.
        </p>

        <p className="h6">
          Para agendar uma consulta, tenha em mãos seus dados pessoais e escolha
          uma das opções:
        </p>
        <p className="h6 mt-4">
          - Por esta plataforma{' '}
          <a
            href={
              process.env.REACT_APP_API_KEY === 'production'
                ? 'https://mais.sindilegis.org.br/#/homepage/servico-odontologico/agendamentos'
                : process.env.REACT_APP_API_KEY === 'test'
                ? 'http://sindilegisteste.fantest.site/#/homepage/servico-odontologico/agendamentos'
                : 'http://localhost:3000/#/homepage/servico-odontologico/agendamentos'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#000' }}
            className="h6"
          >
            clique aqui.
          </a>
        </p>
        <p className="h6">- Por telefone – (61) 3246-2400</p>
        <p className="h6">- Pelo WhatsApp – (61) 98339-6851.</p>
        <p className="h6-bold mt-4">Mais sobre a Clínica Odontolegis</p>
        <p className="h6">
          Com um espaço totalmente reformado – são sete consultórios, sala de
          raio-x e equipamentos para radiografia panorâmica e radiografias
          periapical e interproximal, os filiados podem fazer todos os
          procedimentos dentro da própria clínica.
        </p>
        <p className="h6 mt-4">
          Atualmente são 14 profissionais nas mais diversas áreas: reabilitação
          oral; ortodontia; endodontia; clínica geral; e periodontia.
        </p>
        <p className="h6 mt-4">
          Prontinho! Você já pode usufruir de todos os benefícios odontológicos
          do Sindilegis!
        </p>
      </div>
    </section>
  );
};

export default DentalGeneralInfo;
