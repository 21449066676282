import { COMPANY_COLORS } from 'config/consts';
import { shade } from 'polished';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${isMobileOnly ? '30px 0px' : '30px'};
  min-height: 89vh;

  footer {
    display: flex;
    justify-content: flex-end;
  }
`;

export const QRCodeContainer = styled.div`
  display: flex;

  footer {
    display: flex;
    width: fit-content;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    background: #f7fafa;
    margin: 0;

    span {
      margin-top: 10px;
    }
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 50px 0;

  header {
    display: flex;
    justify-content: flex-end;

    p {
      text-align: right;
      flex: 0 0 20%;
      margin: 10px 0 0 0;
      font-size: 16px;

      & + p {
        padding-right: 35px;
      }
    }
  }
`;

export const InteractionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex: 1;

  button {
    width: fit-content;
    color: white;
    font-size: 20px;
    padding: 10px 30px;
    margin-bottom: 20px;
    background: ${COMPANY_COLORS.primary};
    cursor: pointer;
    text-align: center;
    transition: background-color 0.5s;

    &:focus {
      outline: none;
    }

    &:hover {
      background: ${shade(0.2, COMPANY_COLORS.primary)};
    }
  }

  a {
    text-decoration: underline;
    color: #505050;
  }
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 41px;

  letter-spacing: 0.25px;
`;

export const Titlecontainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: ${isMobileOnly ? 'column' : 'row'};
  justify-content: ${isMobileOnly ? 'center' : 'space-between'};
  align-items: center;
  width: 100%;
`;
