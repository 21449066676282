import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Typography,
  AppBar,
  Toolbar,
  
  // makeStyles,
  // useTheme,
  // Grid,
  // Container,
  // Paper,
} from '@material-ui/core';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CustomizedInputBase } from 'common/components/fields/fields';
import {
  BASE_S3,
  BRB_FLA_STATION_ID,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  COMPANY_FONT_FAMILY,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
} from 'config/consts';
import { getNameInitials } from 'helpers/utils';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  COMPANY_STATION_ID,
  DUKITS_STATION_ID,
  GENERAL_TEXT_COLOR,
  USER_TOKEN,
} from '../../../../config/consts';
import { hexToRgb } from '../../../../helpers/utils';
import {
  // ChangeStationButton,
  DropdownItem,
  NavigationItem,
  NavigationSubItem,
} from './styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// import { Toolbar } from 'react-md';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import AccountCircle from '@material-ui/icons/AccountCircle';

// const useStyles = makeStyles(() => ({
//   root: {
//     padding: 0,
//   },
//   headerLogo: {
//     height: 50,
//     cursosr: 'pointer',
//   },
//   discoveryButton: {
//     width: 98,
//     height: 32,
//     borderRadius: 18,
//     marginRight: 27,
//     fontStyle: 'normal',
//     fontWeight: 'normal',
//     fontSize: '16px',
//     lineHeight: '160%',
//     cursor: 'pointer',
//     background: '#E5E5E5',
//   }
// }));



const urlFan =
  process.env.REACT_APP_API_KEY === 'production'
    ? `https://app.dukits.com/#/login-token`
    : process.env.REACT_APP_API_KEY === 'test'
    ? `http://sandbox-gestor.dukits.com/#/login-token`
    : 'http://localhost:3000/#/login-token';

function OwnerNavigation(props) {
  const split = window.location.hash.split('#')[1];
  let route = split;
  const [value, setValue] = useState(undefined);


  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    const navbarColorChangeThreshold = 48; // Change this value as needed
    setScrolled(offset > navbarColorChangeThreshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // const classes = useStyles();
  // const theme = useTheme();
  // const xs = useMediaQuery(theme.breakpoints.only('xs'));
  // const sm = useMediaQuery(theme.breakpoints.only('sm'));
  // const md = useMediaQuery(theme.breakpoints.up('md'))

  function handleFirstStateActive() {
    for (let i in props.navBarItems) {
      if (props.navBarItems[i].link === route) {
        return props.navBarItems[i].id;
      } else {
        if (props.navBarItems[i].children) {
          for (let item in props.navBarItems[i].children) {
            if (props.navBarItems[i].children[item].link === route) {
              return props.navBarItems[i].id;
            }
          }
        }
      }
    }
  }

  //Escolha de empresa da plataforma
  /*   function handleEstablishmentChange(establishmentId) {
    let newEstablishmentsList = [...props.auth.user.establishments];
    for (
      let index = 0;
      index < props.auth.user.establishments.length;
      index++
    ) {
      if (props.auth.user.establishments[index].id === establishmentId) {
        [newEstablishmentsList[0], newEstablishmentsList[index]] = [
          newEstablishmentsList[index],
          newEstablishmentsList[0],
        ];
      }
    }
    return props.changeEstablishment(newEstablishmentsList);
  } */

  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(handleFirstStateActive());
  const openAnchor = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickChange() {
    if (value) {
      props.history.push(`/homepage/search/${value}`);
    } else {
    }
  }

  function handleChange(e) {
    if (e.key === 'Enter') {
      if (e.target.value) {
        e.preventDefault();
        props.history.push(`/homepage/search/${e.target.value}`);
      } else {
      }
    }
  }

  const handleOpenPerfil = () => {
    props.history.push('/homepage/perfil');
  };

  return (
    <>

      <AppBar 
        position="sticky" 
        style={{ 
        backgroundColor: scrolled ? COMPANY_SIGNED_IN_BACKGROUND_COLOR : COMPANY_SIGNED_IN_BACKGROUND_COLOR,
        boxShadow: 'none',
      
        paddingTop: '12px',
        paddingBottom: '12px',

        maxWidth: '1280px',  // Set the maximum width
        margin: '0 auto',    // Center the AppBar
        width: '100%'        // Ensure the width adjusts to the container
        
        }}>        
      
        <Toolbar>
            
                  {/* Logo área logada - Inicio */}

                 <img
                  src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
                  style={{ 
                          maxHeight: 68,
                          minHeight: 24,
                          marginRight: 32, 
                          cursor: 'pointer' 
                          }}
                  className="img-fluid"
                  alt="Imagem da estação"
                  onClick={() => props.history.push('/homepage')}
                />

              {/* Logo área logada - Fim*/}

             
              
              {/* Menu Principal - Início */}   

              {props.navBarItems.map((item, index) => (
  <div key={index} className="dropdown" style={{ backgroundColor: 'transparent', marginRight: '1.25rem' }}>
    {item.children?.length > 0 ? (
      <>
        <NavigationItem
          type="button"
          data-toggle="dropdown"
          id={'dropdownMenu' + index}
          aria-haspopup="true"
          active={active}
          item={item}
          companyColors={COMPANY_COLORS}
          style={{
            backgroundColor: active === item.id ? 'rgba(204, 204, 204, 0.3)' : 'inherit', // Change 'desiredActiveColor' to your preferred color
            borderRadius: '24px',
            padding: '8px',
            '&button:focus': {
              outline: '0px auto -webkit-focus-ring-color',
              outlineOffset: '0px',
            },
          }}
        >
          <Typography variant="subtitle1" style={{ color: active === item.id ? COMPANY_COLORS : 'inherit' }}>
            {item.primaryText}
          </Typography>
          <KeyboardArrowDownIcon />
        </NavigationItem>

                              {item.children?.length > 0 ? (
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby={'dropdownMenu' + index}
                                  style={{
                                    backgroundColor:
                                      COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                                    paddingTop: '.7rem',
                                    marginRight: 24,
                                    paddingLeft: '0',
                                    border: 'none',
                                    width: '180px',
                                    transition: 'width 2s, height 4s',
                                    boxShadow: `0 0 5px 5px ${hexToRgb(
                                      GENERAL_TEXT_COLOR,
                                      '5%'
                                    )}`,
                             
                                    borderRadius: '12px 12px 12px 12px',
                                  }}
                                >
                                  {item.children.map((subitem, index) => (
                                    <NavigationSubItem
                                      key={index}
                                      className="dropdown-item"
                                      type="button"
                                      companyColors={COMPANY_COLORS}
                                      style={{
                                        marginBottom: 10,
                                        paddingLeft: '0',
                                        
                                        // backgroundColor: 'inherit',
                                        borderRadius: 0,
                                        '&button:focus': {
                                          outline:
                                            '0px auto -webkit-focus-ring-color',
                                          outlineOffset: '0px',
                                          borderRadius: 0,
                                        },
                                      }}
                                      onClick={() => {
                                        props.history.push(subitem.route);
                                        setActive(item.id);
                                      }}
                                    >
                                      <DropdownItem>
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            margin: '0 0 0 1rem',
                                            whiteSpace: 'break-spaces',
                                          }}
                                        >
                                          {subitem.primaryText}
                                        </Typography>
                                      </DropdownItem>
                                    </NavigationSubItem>
                                  ))}
                                </div>
                              ) : null}
                            </>
                          ) : (
<NavigationItem
        type="button"
        onClick={() => {
          props.history.push(item.route);
          setActive(item.id);
        }}
        active={active}
        item={item}
        companyColors={COMPANY_COLORS}
        style={{ 
          backgroundColor: active === item.id ? 'rgba(204, 204, 204, 0.3)' : 'inherit', // Change 'desiredActiveColor' to your preferred color
            borderRadius: '24px',
          
          padding: '8px',
        }}
      >
        <Typography variant="subtitle1" style={{ color: active === item.id ? GENERAL_TEXT_COLOR : 'inherit' }}>
          {item.primaryText}
        </Typography>
      </NavigationItem>
                          )}
                        </div>
            ))}

              {/* Menu Principal - Fim */}

            {/* Botões de Gestor - Inicio */}          
      <div >
               
               {/* testar estacao dukits
               {COMPANY_STATION_ID === COMPANY_STATION_ID ? ( 
               certo
               {DUKITS_STATION_ID === COMPANY_STATION_ID ? (
               */}   
               {DUKITS_STATION_ID  === COMPANY_STATION_ID ? (
                 <>

                   <div 
                     className="d-flex justify-content-center align-items-center"
                     style={{
                       border: '1px solid rgba(204, 204, 204, 0.3)',
                       marginRight: '12px',
                       width: 150,
                       height: 36,
                       borderRadius: 18,
                       cursor: 'pointer',
                       background: '#4F4BE2',
                     }}
                     onClick={() => {
                       window.location.href = `${urlFan}/${
                         JSON.parse(localStorage.getItem(USER_TOKEN)).split(
                           ' '
                         )[1]
                       }`;
                       return null;
                     }}
                   >
                    Acessar Painel
                   </div>
                 </>
               ) : null}
             </div>


             {/* Botões de Gestor - Fim */}   

            {/* Busca - desabilitada
                <div
                      className="d-flex justify-content-start align-items-center flex-wrap"
                      style={{ height: 56, padding: 0 }}
                    >
                <CustomizedInputBase
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onKeyDown={(e) => handleChange(e)}
                  onClick={() => handleClickChange()}
                  meta={{}}
                  name="search"
                  margin="normal"
                  required
                  style={{ fontFamily: COMPANY_FONT_FAMILY }}
                />
                </div>
                 */}



               {/* Pontos - inicio */}
               <div
                      style={{
                        marginLeft: 'auto',
                      }}
                    >
                      {props.points}
                    </div>
                {/* Pontos - fim */}

                {/* Avatar - inicio */}
                <div>
                  <div style={{ paddingLeft: 20, cursor: 'pointer', marginLeft: 'auto', }}>
                    {props.auth.user.image ? (
                      <>
                        <Avatar
                          onClick={handleMenu}
                          style={{ width: 48, height: 48 }}
                          src={`${BASE_S3}/${props.auth.user.image}`}
                          alt="station_img"
                        />
                      </>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          backgroundColor: BUTTON_BACKGROUND_COLOR,
                          cursor: 'pointer',
                          height: 48,
                          width: 48,
                          borderRadius: '50%',
                          fontSize: 16,
                          color: BUTTON_TEXT_COLOR,
                          fontFamily: BUTTON_FONT_FAMILY,
                        }}
                        onClick={handleMenu}
                      >
                        {getNameInitials(props.auth.user.name)}
                      </Box>
                    )}
                  </div>

                  
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    MenuListProps={{ disablePadding: true }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={openAnchor}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleOpenPerfil}
                      style={{
                        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                        marginTop: 0,
                        display:
                          COMPANY_STATION_ID === BRB_FLA_STATION_ID
                            ? 'none'
                            : '',
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                          color: GENERAL_TEXT_COLOR,
                        }}
                      >
                        Perfil
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => props.history.push('/logout')}
                      style={{
                        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                        marginBottom: 0,
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                          color: GENERAL_TEXT_COLOR,
                        }}
                      >
                        Sair
                      </Typography>
                    </MenuItem>
                  </Menu>
                </div>
               {/* Avatar - fim */}


          </Toolbar>

       </AppBar>

                        
      
     
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerNavigation);
