import { Box, Typography } from '@material-ui/core';
import { ReactComponent as CheckIcon } from 'assets/icons/vector-stroke.svg';
import Button from 'common/components/buttons/button';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { GeneralTitle } from 'common/styles/styles';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
} from 'config/consts';
import PropTypes from 'prop-types';
import React from 'react';
import { appColors } from '../../../../../styles/color';

const {
  v2: { gray },
} = appColors;

function PlanDetail({
  selectedPlan,
  onClick,
  bgBColor = BUTTON_BACKGROUND_COLOR,
}) {
  const { name, description, benefits, amount, days, image, discount } =
    selectedPlan;
  console.log(amount, discount);
  return (
    <section
      id="plans-detail"
      style={{
        marginTop: 32,
        marginBottom: 125,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <GeneralGrid container item xs={12} justifyContent="center">
        <GeneralGrid item xs={10} sm={6} md={4}>
          {image ? (
            <img
              src={`${BASE_S3}/${image}`}
              alt="Imagem do plano"
              style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
            />
          ) : null}
          <Typography variant="h1" className="mt-4">
            <GeneralTitle>{name}</GeneralTitle>
          </Typography>
          <Typography variant="h6" className="mt-4">
            {description}
          </Typography>
          <Box mt={4}>
            {benefits.map((item) => (
              <Typography variant="body1" className="text-left mt-3">
                <CheckIcon
                  stroke={GENERAL_TEXT_COLOR}
                  fill={GENERAL_TEXT_COLOR}
                  style={{ marginRight: 8 }}
                />
                {item.benefit}
              </Typography>
            ))}
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: 'transparent', padding: '60px 0px' }}
            mt={4}
          >
            <Typography variant="h6" className="text-center">
              {name}
            </Typography>
            {amount ? (
              <Typography
                variant="body1"
                className="text-center mt-2"
                style={{ fontSize: '3rem' }}
              >
                R${' '}
                {(parseFloat(amount) - parseFloat(discount))
                  .toFixed(2)
                  .replace('.', ',')}
                <span className="h3 text-center" style={{ color: gray }}>
                  /{days === 30 ? 'mês' : days === 183 ? 'semestre' : 'ano'}
                </span>
              </Typography>
            ) : (
              <Typography
                variant="body1"
                className="text-center mt-5"
                style={{ fontSize: '2rem' }}
              >
                A negociar
              </Typography>
            )}
            <Button
              label={selectedPlan.purchase_text || 'Adquirir esse plano'}
              onClick={onClick}
              buttonStyle={{
                width: '45%',
                minWidth: '270px',
                marginTop: 32,
                backgroundColor: bgBColor,
              }}
            />
          </Box>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

PlanDetail.propTypes = {
  selectedPlan: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
  bgBColor: PropTypes.string.isRequired,
};

export default PlanDetail;
