import styled from 'styled-components';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
} from 'config/consts';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  button {
    align-self: center;
    z-index: 99;
    height: 50px;
    width: 230px;
    border-radius: 6px;
    background: ${(props) =>
      props.state ? '#505050' : COMPANY_COLORS.primary};
    cursor: pointer;
    padding: 10px 40px;
    color: white;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: ${(props) => (!props.state ? 0.9 : null)};
    }
  }

  div {
    height: 100px;
    width: 426px;
    color: white;
  }
`;
export const ContainerBehind = styled.div`
  display: flex;
  justify-content: center;
  height: 170px;
  width: 450px;
  border-radius: 14px;
`;

export const ContainerButton = styled.div`
  button {
    align-self: center;
    z-index: 99;
    height: 50px;
    width: 230px;
    border-radius: 6px;
    background: ${BUTTON_BACKGROUND_COLOR};
    cursor: pointer;
    padding: 10px 40px;
    color: ${BUTTON_TEXT_COLOR};
    font-family: ${BUTTON_FONT_FAMILY};

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const AgreementContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 500px;

  button {
    align-self: center;
    z-index: 99;
    height: 50px;
    width: 230px;
    border-radius: 6px;
    background: ${(props) =>
      props.state || props.loading ? '#505050' : COMPANY_COLORS.primary};
    cursor: pointer;
    padding: 10px 40px;
    color: white;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: ${(props) => (!props.state ? 0.9 : null)};
    }
  }
`;

export const SimpleButton = styled.button`
  align-self: center;
  z-index: 99;
  height: 50px;
  width: 230px;
  border-radius: 6px;
  background: ${(props) => (props.state ? '#505050' : BUTTON_BACKGROUND_COLOR)};
  cursor: pointer;
  padding: 10px 40px;
  color: ${BUTTON_TEXT_COLOR};
  font-family: ${BUTTON_FONT_FAMILY};

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: ${(props) => (!props.state ? 0.9 : null)};
  }
`;

export const FieldButton = styled.button`
  background: ${(props) => (props.disabled ? 'lightgray' : props.background)};
  color: ${(props) => props.text};
  border: ${(props) => props.border};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width || '100%'};
  font-family: ${BUTTON_FONT_FAMILY};

  &:focus {
    outline: 0;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;
