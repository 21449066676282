import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  svg {
    color: ${(props) => (props.active ? '#F47A63' : 'lightgray')};
    transition: color 0.2s;

    &:hover {
      color: ${(props) =>
        props.active ? shade(0.2, '#F47A63') : shade(0.2, 'gray')};
    }
  }
`;
