import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { ContainerButton, AgreementContainer, SimpleButton } from './styles';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  IS_CAIXA,
} from 'config/consts';
import { GENERAL_TEXT_COLOR } from '../../../config/consts';

function ActivityButton(props) {
  const [state, setState] = React.useState({
    checkbox1: false,
    checkbox2: true,
    checkbox3: false,
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <>
      {props.presential_course ? (
        // <ContainerBehind presential_course={props.presential_course}>
        //   <Container
        //     state={!state.checkbox1}
        //     presential_course={props.presential_course}
        //   >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Grid container justifyContent="center" alignItems="center" xs={12}>
            {props.presential_course ? (
              <Grid
                item
                container
                xs={12}
                md={6}
                justifyContent="center"
                wrap="wrap"
                alignItems="center"
              >
                <FormControl component="fieldset" style={{ maxWidth: '100%' }}>
                  {!IS_CAIXA && (
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        style={{
                          color: 'black',
                          maxWidth: isMobile ? '100%' : '100%',
                        }}
                        value="checkbox1"
                        control={
                          <Checkbox checked={state.checkbox1} color="default" />
                        }
                        label={
                          props.activities.detail.partner_id ? (
                            <Typography variant="body1">
                              Sim, eu concordo em compartilhar meus dados.
                            </Typography>
                          ) : (
                            <Typography variant="body1">
                              Sim, eu concordo em compartilhar meus dados.
                            </Typography>
                          )
                        }
                        labelPlacement="end"
                        onChange={handleChange('checkbox1')}
                      />
                      <FormControlLabel
                        style={{
                          color: 'black',
                          maxWidth: isMobile ? '100%' : '100%',
                        }}
                        value="checkbox2"
                        control={
                          <Checkbox checked={state.checkbox2} color="default" />
                        }
                        label={
                          props.activities.detail.partner_id ? (
                            <Typography variant="body1">
                              Quero que entrem em contato comigo
                            </Typography>
                          ) : (
                            <Typography variant="body1">
                              Quero que entrem em contato comigo
                            </Typography>
                          )
                        }
                        labelPlacement="end"
                        onChange={handleChange('checkbox2')}
                      />
                    </FormGroup>
                  )}
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <SimpleButton
              style={{ marginTop: isMobile ? 30 : 15 }}
              disabled={
                !state.checkbox1 && !IS_CAIXA && props.presential_course
              }
              onClick={() => props.onClick({ should_contact: state.checkbox2 })}
            >
              {props.label}
            </SimpleButton>
          </Grid>
        </Grid>
      ) : //   {/* </Container>
      // </ContainerBehind> */}
      props.agreement ? (
        <AgreementContainer
          color={props.color}
          state={!state.checkbox3}
          loading={props.loading}
          presential_course={props.agreement}
        >
          <FormControlLabel
            style={{ color: GENERAL_TEXT_COLOR, margin: '0px 0px 0px -11px' }}
            value="checkbox1"
            control={<Checkbox style={{ color: GENERAL_TEXT_COLOR }} />}
            label={props.checkboxLabel}
            labelPlacement="end"
            onChange={handleChange('checkbox3')}
          />
          <button
            style={{
              marginTop: 10,
              backgroundColor: BUTTON_BACKGROUND_COLOR,
              color: BUTTON_TEXT_COLOR,
              fontFamily: BUTTON_FONT_FAMILY,
            }}
            disabled={(!state.checkbox3 && props.agreement) || props.loading}
            onClick={() => props.onClick()}
          >
            {props.loading ? 'Enviando...' : props.label}
          </button>
        </AgreementContainer>
      ) : (
        <>
          <ContainerButton>
            <button onClick={() => props.onClick()}>{props.label}</button>
          </ContainerButton>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.activities.buttonLoading,
    activities: state.activities,
  };
};

export default connect(mapStateToProps)(ActivityButton);
