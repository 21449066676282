import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { toastr } from 'react-redux-toastr';
import types from './types';

const URL = `${BASE_API}/legisclub-fuels`;

export const getCampaignList = () => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(URL, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: response,
          },
        ]);
      })
      .catch((e) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
      });
  };
};

export const getVouchersList = (id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_PER_USER_LOAD, payload: true });
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_PER_USER_FETCHED,
            payload: response,
          },
        ]);
      })
      .catch((e) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
      });
  };
};

export const generateVoucher = (values) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .post(URL, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((e) => {
        try {
          toastr.error('Erro', e.response.data.message);
        } catch {
          toastr.error('Erro interno do sistema');
        }
      })
      .finally(() => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
      });
  };
};
