import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './type';

const URL_COUPONS = `${BASE_API}/cupoms/establishment`;

const URL_USER_DONE_ACTION = `${BASE_API}/coupons/user-done`;

export const getCouponsByEstablishmentId = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: type.COUPONS_USER_LOAD,
        payload: true,
      },
    ]);

    axios
      .get(`${URL_COUPONS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.COUPONS_USER_FETCHED,
            payload: response.data,
          },
        ]);
      });
  };
};

export const finishActionShare = () => {
  return (dispatch) => {
    axios
      .post(URL_USER_DONE_ACTION, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {});
  };
};
