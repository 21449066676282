/* eslint-disable react-hooks/exhaustive-deps */
import OrderModal from 'common/components/orderModal';
import Spinner from 'common/components/spinner/spinner';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getListById, setLoading } from './productOrdersActions';

const heightAndBackground = {
  minHeight: '100vh',
  backgroundColor: 'transparent',
};

function ProductOrder(props) {
  const { id } = useParams();
  useEffect(() => {
    props.getListById(id);
  }, []);

  if (props.productOrders.loadingDetail) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={heightAndBackground}
      >
        <Spinner />
      </div>
    );
  } else if (!props.productOrders.loadingDetail) {
    return (
      <>
        <OrderModal
          open={!props.productOrders.loadingDetail}
          onClose={() => props.history.push('/homepage/pedidos')}
          item={props.productOrders.detail}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    productOrders: state.productOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListById,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductOrder);
