import type from './types';

const INITIAL_STATE = {
  loading_button: false,
  loading: true,
  verify: {
    check: false,
    user_deliveries: [
      {
        delivered: false,
      },
    ],
  },
  status: {
    expectedDate: 'Carregando...',
    queue: 'Carregando...',
  },

  groceryList: [],
  loadingList: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @return {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.CHECK_LOADING:
      return { ...state, loading: action.payload };
    case type.CHECK_FETCHED:
      return { ...state, verify: action.payload, loading: false };
    case type.STATUS_FETCHED:
      return { ...state, status: action.payload, loading: false };
    case type.BUTTON_LOADING:
      return { ...state, loading_button: action.payload };
    case type.DELIVERY_GROCERY_LIST_LOAD:
      return { ...state, loadingList: action.payload };
    case type.DELIVERY_GROCERY_LIST_FETCHED:
      return { ...state, loadingList: false, groceryList: action.payload };
    default:
      return state;
  }
};
