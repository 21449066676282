import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react'
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  simpleBlockSkeleton: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    // minHeight: '500px',
  },
}));

function SimpleBlockSkeleton(props) {
  const {
    height,
    width,
    variant,
    animation
  } = props;
  const classes = useStyles();

  return (
    <Skeleton
      className={classes.simpleBlockSkeleton}
      width={width}
      height={height}
      variant={variant || "rect"}
      animation={animation || "pulse"}
    />
  )
}

SimpleBlockSkeleton.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  variant: PropTypes.string,
  animation: PropTypes.string,
};

export default SimpleBlockSkeleton;
