import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import { BASE_S3, COMPANY_COLORS } from 'config/consts';
import moment from 'moment';
import React from 'react';

const theme = createTheme({
  overrides: {
    MuiIcon: {
      root: {
        backgroundColor: 'blue',
        border: '1px solid black',
      },
    },
  },
});

export default function ActivitiesCard(props) {
  const { image, text, icon, haveImage, dates, callToAction } = props;
  const MAX_LENGTH_TITLE = 70;
  /* const MAX_LENGTH_SUMMARY = 82; */

  return (
    <div>
      <div
        className="card"
        style={{
          width: '100%',
          height: haveImage ? 300 : 130,
          borderWidth: 0.1,
          maxWidth: 330,
          minWidth: 240,
        }}
      >
        {haveImage ? (
          <div
            style={{
              position: 'relative',
              paddingBottom: '56.25%',
              width: '100%',
              height: 0,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <div
                style={{
                  background: `url(${BASE_S3}/${image}) center center / cover`,
                  height: '100%',
                  width: '100%',
                }}
              >
                {icon ? (
                  props.hasDone ? (
                    <div
                      className="station-font-bold d-flex justify-content-center align-items-center"
                      style={{
                        position: 'absolute',
                        bottom: -25,
                        left: '1.25rem',
                        background: 'white',
                        fontSize: 'x-small',
                        borderRadius: '50%',
                        width: 50,
                        height: 50,
                        textAlign: 'center',
                        fill: COMPANY_COLORS.activityCard.callToAction,
                      }}
                    >
                      <DoneIcon
                        style={{ color: COMPANY_COLORS.general.general_color }}
                      />
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <div
          className="card-body"
          style={{
            paddingTop: 20,
            paddingBottom: 0,
            paddingLeft: 15,
            paddingRight: 15,
            backgroundColor: 'transparent',
          }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="d-flex flex-column justify-content-start h-100">
              <p
                className="station-font-semibold"
                style={{
                  margin: 0,
                  padding: 0,
                  paddingLeft: 5,
                  color: COMPANY_COLORS.activityCard.text,
                  fontSize: '1rem',
                  lineHeight: '1.375rem',
                  minHeight: 20,
                  height: 'auto',
                  maxHeight: 60,
                  overflow: 'hidden',
                  textAlign: 'left',
                }}
              >
                {text
                  ? text.length > MAX_LENGTH_TITLE
                    ? `${text.substring(0, MAX_LENGTH_TITLE)}...`
                    : text
                  : null}
              </p>
              {/* <p
                style={{
                  color: '#626262',
                  fontSize: '0.9rem',
                  minHeight: 20,
                  height: 'auto',
                  maxHeight: 60,
                  overflow: 'hidden',
                  lineHeight: '1.275rem',
                  textAlign: 'left',
                  margin: 0,
                  padding: 0,
                }}
              >
                {summary
                  ? summary.length > MAX_LENGTH_SUMMARY
                    ? `${summary.substring(0, MAX_LENGTH_SUMMARY)}...`
                    : summary
                  : null}
              </p> */}
            </div>
            {dates ? (
              dates.isEvent ? (
                <div className="d-flex justify-content-start pb-4">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        fontSize: 16,
                        color: COMPANY_COLORS.activityCard.callToAction,
                      }}
                      icon={faCalendarAlt}
                    />
                    <span style={{ marginLeft: 10 }}>
                      {moment(dates.startEvent).format('DD/MM')}
                    </span>
                  </div>
                  <div
                    style={{
                      marginLeft: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        fontSize: 16,
                        color: COMPANY_COLORS.activityCard.callToAction,
                      }}
                      icon={faClock}
                    />
                    <span style={{ marginLeft: 10 }}>
                      {moment(dates.startEvent).format('HH:mm')}
                    </span>
                  </div>
                </div>
              ) : null
            ) : null}
            <div
              className="d-flex justify-content-start"
              style={{ paddingRight: 10 }}
            >
              <ThemeProvider theme={theme}>
                <ChevronRightIcon
                  style={{
                    display: 'inline-block',
                    minHeight: 0,
                    minWidth: 0,
                    padding: 0,
                    color: COMPANY_COLORS.activityCard.callToAction,
                  }}
                />
              </ThemeProvider>
              <p
                className="pb-2 station-font-regular"
                style={{
                  color: COMPANY_COLORS.activityCard.callToAction,
                  fontSize: '0.875rem',
                  marginLeft: 5,
                }}
              >
                {callToAction}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
