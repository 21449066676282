import DOMPurify from 'dompurify';
import React from 'react';

function DentalNewsDetail({ history }) {
  const { item } = history.location.state;

  return (
    <section
      id="news-news-detail"
      style={{
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div className="mt-4">
        <p className="h2-bold">{item.title.rendered}</p>
        <img
          src={item.image}
          alt="Imagem da notícia"
          style={{ width: '100%', height: 'auto', marginTop: 64 }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(item.content.rendered),
          }}
          style={{ marginTop: 32 }}
        />
      </div>
    </section>
  );
}

export default DentalNewsDetail;
