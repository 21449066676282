const INITIAL_STATE = {
  loading: false,
  addresses: [],
  button_loading: false,
  stock: undefined,
  productAdded: true,
  reserveTime: undefined,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@ADRESSES_LOAD':
      return {
        ...state,
        loading: true,
      };
    case '@ADDRESSES_FETCHED':
      return { ...state, loading: false, addresses: action.payload };
    case '@STOCK_LOAD':
      return {
        ...state,
        loading: true,
      };
    case '@STOCK_FETCHED':
      return { ...state, loading: false, stock: action.payload };
    case '@BUTTON_LOAD':
      return {
        ...state,
        button_loading: action.payload,
      };
    case 'PRODUCT_TO_CART_SAVED':
      return { ...state, button_loading: false, productAdded: true };
    case 'PRODUCT_TO_CART_LOAD':
      return {
        ...state,
        button_loading: true,
      };
    case '@ORDER_RESERVE_TIME':
      return { ...state, reserveTime: action.payload };
    default:
      return state;
  }
};
