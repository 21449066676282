import React, { useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { String2Currency } from 'helpers/dataTransformers';
import {
  FinalPrice,
  ItemImage,
  NoComponentQuantity,
  Prices,
  Title,
} from './styles';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { COMPANY_FONT_FAMILY, SIGNED_IN_NAV_TEXT_COLOR } from 'config/consts';

let ItemCard = function ({ data, items }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const [item, setItem] = useState(0);

  useEffect(() => {
    const quantity = items.filter((item) => item.product_id === data.id);

    setItem(quantity.length);
  }, [items, data.id]);

  const price = useMemo(() => {
    return String2Currency(data.value);
  }, [data.value]);

  const discountPrice = useMemo(() => {
    return String2Currency(data.value - data.discount_value);
  }, [data.discount_value, data.value]);

  const finalPrice = useMemo(() => {
    return ((data.value - data.discount_value) * item)
      .toFixed(2)
      .replace('.', ',');
  }, [data.value, data.discount_value, item]);

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        // alignItems="center"
        xs={12}
        style={{
          margin: '.3rem 0',
        }}
      >
        <Grid item container xs={6} sm={3}>
          <ItemImage image={data.image} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          xs={6}
          sm={3}
        >
          <section>
            <Typography variant="h6">
              <Title>{data.name}</Title>
            </Typography>
            <FiMoreHorizontal style={{ color: SIGNED_IN_NAV_TEXT_COLOR }} />
          </section>
          <Prices>
            <Typography variant="body1">{discountPrice}</Typography>
            {data.discount_value > 0 && (
              <span style={{ fontFamily: COMPANY_FONT_FAMILY }}>{price}</span>
            )}
          </Prices>
        </Grid>
        <Grid
          item
          container
          justifyContent={isMobile || xs ? 'flex-start' : 'center'}
          alignItems="center"
          xs={6}
          sm={3}
        >
          <NoComponentQuantity>
            <Typography variant="body1">{item}</Typography>
          </NoComponentQuantity>
        </Grid>
        <Grid
          item
          container
          justifyContent={isMobile || xs ? 'flex-end' : 'center'}
          xs={6}
          sm={3}
        >
          <FinalPrice>
            <Typography variant="body1">R$ {finalPrice}</Typography>
          </FinalPrice>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('shoppingForm')(state),
  };
};

export default connect(mapStateToProps)(ItemCard);
