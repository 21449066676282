import React from 'react';
export default function Icon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3861 1.3774C12.7472 1.09653 13.2528 1.09653 13.6139 1.3774L23.3639 8.96073C23.6075 9.15019 23.75 9.44149 23.75 9.75008V21.6667C23.75 22.5066 23.4164 23.3121 22.8225 23.9059C22.2286 24.4998 21.4232 24.8334 20.5833 24.8334H5.41667C4.57681 24.8334 3.77136 24.4998 3.1775 23.9059C2.58363 23.3121 2.25 22.5066 2.25 21.6667V9.75008C2.25 9.44149 2.39247 9.15019 2.63606 8.96073L12.3861 1.3774ZM4.25 10.2392V21.6667C4.25 21.9762 4.37292 22.2729 4.59171 22.4917C4.8105 22.7105 5.10725 22.8334 5.41667 22.8334H20.5833C20.8928 22.8334 21.1895 22.7105 21.4083 22.4917C21.6271 22.2729 21.75 21.9762 21.75 21.6667V10.2392L13 3.43361L4.25 10.2392Z"
        fill="#282828"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.75 13C8.75 12.4477 9.19772 12 9.75 12H16.25C16.8023 12 17.25 12.4477 17.25 13V23.8333C17.25 24.3856 16.8023 24.8333 16.25 24.8333C15.6977 24.8333 15.25 24.3856 15.25 23.8333V14H10.75V23.8333C10.75 24.3856 10.3023 24.8333 9.75 24.8333C9.19772 24.8333 8.75 24.3856 8.75 23.8333V13Z"
        fill="#282828"
      />
    </svg>
  );
}
