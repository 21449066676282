import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import { FORM_RULES } from 'helpers/validations';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import ButtonPrimary from 'common/components/buttons/ButtonPrimary';
import {
  BoxSelect,
  InputLabelOutlined,
  MultipleChoices,
  SelectLabel,
  SelectMultiple,
  InputEasyImageWithProcessing,
} from 'common/components/fields/fields';
import {
  COMPANY_COLORS,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
} from 'config/consts';
import {
  getLottieById,
  setFeedbackModal,
  submitSurvey,
} from './activitiesActions';

let SurveysForm = function (props) {
  const [step, setStep] = useState(0);

  const questions = useMemo(() => {
    return props.questions.map((item, index) => {
      // console.log(parseInt(item.type_id));
      if (parseInt(item.type_id) === 1) {
        return (
          <div key={index} className="row justify-content-center mb-2">
            <div className="col-12">
              <Field
                component={SelectLabel}
                type="text"
                label={`Selecione apenas uma`}
                name={`contactSurveyAnswer-${item.id}-1`}
                step={step}
                validate={[FORM_RULES.required]}
                options={item.options.map((item) => {
                  return {
                    value: item.id,
                    label: item.label,
                  };
                })}
                isLong
                longText={item.question}
              />
            </div>
          </div>
        );
      } else if (parseInt(item.type_id) === 2) {
        return (
          <div className="row justify-content-center mb-2" key={index}>
            <div className="col-12">
              <Field
                component={SelectMultiple}
                options={item.options.map((item) => {
                  return {
                    value: item.id,
                    label: item.label,
                  };
                })}
                name={`contactSurveyAnswer-${item.id}`}
                step={step}
                label={`Selecione uma ou mais`}
                validate={[FORM_RULES.required]}
                isLong
                longText={item.question}
              />
            </div>
          </div>
        );
      } else if (parseInt(item.type_id) === 3) {
        return (
          <div className="row justify-content-center mb-2" key={index}>
            <div className="col-12">
              <Field
                component={InputLabelOutlined}
                type="text"
                label={`Responda aqui`}
                step={step}
                multiline={true}
                limitChar={600}
                name={`contactSurveyAnswer-${item.id}`}
                validate={[FORM_RULES.required, FORM_RULES.max(600)]}
                isLong
                longText={item.question}
              />
            </div>
          </div>
        );
      } else if (parseInt(item.type_id) === 4) {
        return (
          <div className="row justify-content-center mb-2">
            <div className="col-12">
              <Field
                component={MultipleChoices}
                type="text"
                label={`Selecione apenas uma`}
                step={step}
                name={`contactSurveyAnswer-${item.id}-1`}
                validate={[FORM_RULES.required]}
                options={item.options.map((item) => {
                  return {
                    value: item.id,
                    label: item.label,
                  };
                })}
                otherOption={item.otherOption}
                isLong
                longText={item.question}
              />
            </div>
          </div>
        );
      } else if (parseInt(item.type_id) === 5) {
        return (
          <div className="row justify-content-center mb-2">
            <div className="col-12">
              <Field
                component={BoxSelect}
                type="text"
                label={`Selecione uma ou mais`}
                step={step}
                name={`contactSurveyAnswer-${item.id}`}
                validate={[FORM_RULES.required]}
                otherOption={item.otherOption}
                options={item.options.map((item) => {
                  return {
                    value: item.id,
                    label: item.label,
                  };
                })}
                isLong
                longText={item.question}
              />
            </div>
          </div>
        );
      } else if (parseInt(item.type_id) === 6) {
        return (
          <div className="row justify-content-center mb-2">
            <div className="col-12">
              <Typography
                variant="body1"
                style={{ textAlign: 'center', marginBottom: 20 }}
              >
                {item.question}
              </Typography>
              <Field
                component={InputEasyImageWithProcessing}
                name={`contactSurveyAnswer-${item.id}`}
                label="Tamanho máximo: 10MB"
                aspectLabel={'livre'}
                margin="normal"
                cols="12"
                step={step}
                validate={[FORM_RULES.required]}
                acceptVideo
                maxSize={10000000}
              />
            </div>
          </div>
        );
      }
      return null;
    });
  }, [props.questions, step]);

  function onSubmit(values) {
    if (step < questions.length - 1) {
      return setStep((step) => step < questions.length - 1 && step + 1);
    }

    const { auth } = props;
    const newValues = { ...values };
    // adicionar estabelecimento
    newValues.author =
      props.activities.detail.type_id === 7 ? 'contact' : 'user';
    newValues.user = auth.user.id;
    /* newValues.contact = auth.user.id; */
    newValues.action = props.activities.detail.id;
    newValues.establishment = auth.user.establishment_participant.id;
    newValues.research = props.activities.detail.researches[0].id;
    let arr = [];

    for (let prop in newValues) {
      if (prop.indexOf('contactSurveyAnswer') > -1) {
        if (
          (!_.isArray(newValues[prop]) && prop.split('-').length < 3) ||
          _.isString(newValues[prop])
        ) {
          arr.push({
            answer: newValues[prop],
            question: prop.split('-')[1],
          });
        } else if (prop.split('-').length > 2) {
          arr.push({
            options: [newValues[prop]],
            question: prop.split('-')[1],
          });
        } else {
          arr.push({
            options: newValues[prop],
            question: prop.split('-')[1],
          });
        }
        delete newValues[`${prop}`];
      }
    }

    newValues.answers = arr;

    //Lógica para saber se usuário ganha pontos ou não no quiz

    if (props.activities.detail.type_id === 6) {
      const questions = props.activities.detail.researches[0].questions;
      let correctAnswers = 0;
      for (let answer of newValues.answers) {
        const question = questions.filter(
          (item) => item.id === parseInt(answer.question)
        );
        const questionCorrectAnswers = question[0].options.filter(
          (item) => item.correct === true
        );
        if (question[0].type_id === 1 || question[0].type_id === 4) {
          if (
            questionCorrectAnswers.findIndex(
              (item) => item.id === parseInt(answer.options[0])
            ) >= 0
          ) {
            correctAnswers++;
          }
        } else if (question[0].type_id === 2 || question[0].type_id === 5) {
          const tempCorrectAnswer = [];
          for (let item of answer.options) {
            if (
              questionCorrectAnswers.findIndex(
                (i) => i.id === parseInt(item)
              ) >= 0
            ) {
              tempCorrectAnswer.push(item);
            }
          }
          if (
            tempCorrectAnswer.length === questionCorrectAnswers.length &&
            tempCorrectAnswer.length === answer.options.length
          ) {
            correctAnswers++;
          }
        }
      }

      const correct_answer_percentage = correctAnswers / questions.length;

      newValues.correct_answer_percentage = correct_answer_percentage;
      newValues.answer_percentage = correct_answer_percentage;
      newValues.type_id = props.activities.detail.type_id;
    }

    props.submitSurvey(newValues, props.history);
  }

  return (
    <>
      <div
        className="row justify-content-center mb-4"
        style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
      >
        {!_.isUndefined(props.activities.detail) ? (
          <div className="col-auto text-center">
            <Typography variant="h5">{props.activities.detail.name}</Typography>
          </div>
        ) : (
          <div className="col-auto text-center">
            <Typography variant="h5">Pesquisa</Typography>
          </div>
        )}
      </div>
      <div
        className="row justify-content-center"
        style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
      >
        <div className="col-12">
          <form onSubmit={props.handleSubmit(onSubmit)} noValidate>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              {questions[step]}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 10,
                backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              }}
            >
              <Typography variant="body1">
                {`${step + 1} de ${questions.length}`}
              </Typography>
            </div>
            <div
              className="row justify-content-center"
              style={{ padding: '0px 15px 30px' }}
            >
              {step > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      color: COMPANY_COLORS.primary,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      textAlign: 'center',
                    }}
                    onClick={() => setStep((step) => step > 0 && step - 1)}
                  >
                    Anterior
                  </span>
                </div>
              )}
              {step < questions.length - 1 ? (
                <ButtonPrimary
                  style={{ display: 'flex', flex: 1, margin: 0 }}
                  type="submit"
                >
                  Próximo
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  disabled={props.activities.buttonLoading}
                  progress={parseInt(props.general.submitProgress)}
                  style={{ display: 'flex', flex: 1, margin: 0 }}
                  type="submit"
                >
                  {props.activities.buttonLoading ? 'Enviando' : 'Enviar'}
                </ButtonPrimary>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

SurveysForm = reduxForm({ form: 'surveysForm' })(SurveysForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('surveysForm')(state),
    questions: state.activities.detail.researches[0].questions,
    activities: state.activities,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { submitSurvey, setFeedbackModal, getLottieById, getUserPoints },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveysForm);
