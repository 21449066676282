import { Box, Typography } from '@material-ui/core';
// import { String2Currency } from 'helpers/dataTransformers';
import React from 'react';
import { ContainerShare } from './styles';
import Wallet from '../../svg/wallet';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { customCurrencyChanger } from '../../../../helpers/utils';
import { GENERAL_TEXT_COLOR } from 'config/consts';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR } from '../../../../config/consts';

export default function ShareCard(props) {
  return (
    <ContainerShare
      bgColor={COMPANY_SIGNED_IN_BACKGROUND_COLOR}
      boxShadowColor={GENERAL_TEXT_COLOR}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" style={{ fontSize: 12 }}>
          Cashback
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            width: 60,
            height: 60,
            background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
            border: `2px solid ${GENERAL_TEXT_COLOR}`,
          }}
        >
          <Wallet color={GENERAL_TEXT_COLOR} />
        </div>
      </Box>
      <Box display="flex" flexDirection="column">
        <strong
          style={{ fontSize: 24, color: GENERAL_TEXT_COLOR, margin: '20px 0' }}
        >
          {customCurrencyChanger(props.currencyData, props.cashback)}
          {/* {String2Currency(props.cashback)} */}
        </strong>
      </Box>
      <Typography variant="body1" style={{ fontSize: 16 }}>
        Compartilhe clicando em um dos botões abaixo para resgatar sua
        recompensa!
      </Typography>
      {props.public ? (
        <div className="row justify-content-center pt-4">
          <div
            style={{ cursor: 'pointer' }}
            className="col-3 d-flex justify-content-center"
            onClick={() => props.history.push('/cadastrar')}
          >
            <FacebookIcon round />
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className="col-3 d-flex justify-content-center"
            onClick={() => props.history.push('/cadastrar')}
          >
            <TwitterIcon round />
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className="col-3 d-flex justify-content-center"
            onClick={() => props.history.push('/cadastrar')}
          >
            <WhatsappIcon round />
          </div>
        </div>
      ) : props.activity ? (
        <div className="row justify-content-center pt-4">
          <div
            style={{ cursor: 'pointer' }}
            className="col-3 d-flex justify-content-center"
          >
            <FacebookShareButton
              url={props.url}
              onShareWindowClose={props.onClick}
              quote={props.title}
            >
              <FacebookIcon round />
            </FacebookShareButton>
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className="col-3 d-flex justify-content-center"
          >
            <TwitterShareButton
              url={props.url}
              onShareWindowClose={props.onClick}
              title={props.title}
            >
              <TwitterIcon round />
            </TwitterShareButton>
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className="col-3 d-flex justify-content-center"
          >
            <WhatsappShareButton
              url={props.url}
              onShareWindowClose={props.onClick}
              title={props.title}
              separator=" "
            >
              <WhatsappIcon round />
            </WhatsappShareButton>
          </div>
        </div>
      ) : (
        <Typography
          variant="body1"
          component="span"
          style={{ marginTop: 20 }}
          className="text-center"
        >
          Olá, você já ultrapassou o limite de participações nessa atividade!
        </Typography>
      )}
    </ContainerShare>
  );
}
