import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { String2Currency } from 'helpers/dataTransformers';
import moment from 'moment';

//rotas de transaçoes da api
const URL_USED_CREDITS = `${BASE_API}/use-points-transactions`;
const URL_EARNED_CREDITS = `${BASE_API}/earn-points-transactions`;
const URL_PURCHASED_CREDITS = `${BASE_API}/user-buy-points-transactions`;

export const getUsedCreditsByUser = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'USED_CREDITS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_USED_CREDITS}/?where[user_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const filteredData = response.data.filter(
          (item) => parseFloat(item.points) > 0
        );
        for (let credit of filteredData) {
          credit.points = String2Currency(credit.points);
          credit.createdAt = moment(credit.createdAt).format(
            'DD/MM/YYYY HH:mm'
          );
          if (!credit.action && !credit.action.name) {
            credit.action = { name: 'Participação em atividade' };
          }
        }
        dispatch([
          {
            type: 'USED_CREDITS_FETCHED',
            payload: filteredData,
          },
        ]);
      })
      .catch((e) => {});
  };
};

export const getEarnedCreditsByUser = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'EARNED_CREDITS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_EARNED_CREDITS}/?where[user_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const filteredData = response.data.filter(
          (item) => parseFloat(item.points) > 0
        );
        console.log(filteredData);
        for (let credit of filteredData) {
          credit.points = String2Currency(credit.points);
          credit.createdAt = moment(credit.createdAt).format(
            'DD/MM/YYYY HH:mm'
          );
          if (credit.action && !credit.action.name) {
            credit.action = { name: 'Participação em atividade' };
          }
        }

        dispatch([
          {
            type: 'EARNED_CREDITS_FETCHED',
            payload: filteredData,
          },
        ]);
      })
      .catch((e) => {});
  };
};

export const getPurchasedCreditsByUser = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'PURCHASED_CREDITS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_PURCHASED_CREDITS}/?where[user_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        for (let i in response.data) {
          response.data[i]['createdAt'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:mm');
          response.data[i]['points'] = String2Currency(response.data[i].points);
        }
        dispatch([
          {
            type: 'PURCHASED_CREDITS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {});
  };
};
