import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './types';

const URL_CREDIT_CARDS = `${BASE_API}/giveaway-numbers`;

export const getListByUserId = (id) => {
  return (dispatch) => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL_CREDIT_CARDS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};
export const setLoading = (value) => {
  return (dispatch) => {
    dispatch([{ type: type.LIST_LOAD, payload: value }]);
  };
};
