import React from 'react';

export default function NotFound() {
  return (
    <div className="row h-75 justify-content-center align-items-center">
      <div className="col-auto">
        <h2 className="text-center">Erro 401!</h2>
        <p>Você não possui autorização para acessar esta página.</p>
      </div>
    </div>
  );
}
