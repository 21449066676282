import { BASE_API, USER_TOKEN } from 'config/consts';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { initialize, destroy, change } from 'redux-form';

//rotas de transaçoes da api
const URL_ADDRESS = `${BASE_API}/addresses`;
const URL_USER_ADDRESS = `${BASE_API}/user-addresses`;
const URL_USER_CEP = `${BASE_API}/get-address-zip-code`;
const URL_REQUEST_PRODUCT_ORDER = `${BASE_API}/cupom-product-order`;
const URL_CREDIT_CARDS = `${BASE_API}/user-credit-cards`;
const URL_STOCK = `${BASE_API}/get-product-stock`;
const PRODUCTS_TO_CART = `${BASE_API}/add-products-to-shoppping-cart`;
const URL_PRODUCT_RESERVATION = `${BASE_API}/single-product-orders`;

export const deleteAddress = (id) => {
  return (dispatch) => {
    axios
      .delete(`${URL_ADDRESS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@ADDRESSES_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const getUserAddresses = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: '@ADDRESSES_LOAD',
      },
    ]);
    axios
      .get(`${URL_USER_ADDRESS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@ADDRESSES_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const addProductToCart = (products, history = undefined) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'PRODUCT_TO_CART_LOAD',
      },
    ]);
    axios
      .post(
        `${PRODUCTS_TO_CART}`,
        { products },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'PRODUCT_TO_CART_SAVED',
            payload: response.data,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Produtos adicionados ao carrinho',
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (history) {
          history.push('/homepage/carrinho');
        }
      })
      .catch((e) => {
        try {
          if (e.response.data.message) {
            toastr.error('Erro', e.response.data.message);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const initializeForm = (values) => {
  return initialize('userShoppingCartForm', values);
};

export const destroyForm = () => {
  return destroy('userShoppingCartForm');
};

export const resetForm = (values) => {
  const newValues = { ...values };
  newValues.name = null;
  newValues.zip_code = null;
  newValues.state = null;
  newValues.city = null;
  newValues.tel = null;
  newValues.street = null;
  newValues.complement = null;
  newValues.number = null;
  newValues.neighborhood = null;
  newValues.country = null;
  newValues.lastName = null;
  return initialize('userShoppingCartForm', newValues);
};

export const editAddress = (id, values) => {
  return (dispatch) => {
    dispatch([
      {
        type: '@ADDRESSES_LOAD',
      },
    ]);
    axios
      .put(`${URL_ADDRESS}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@ADDRESSES_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const createAddress = (values) => {
  return (dispatch) => {
    dispatch([
      {
        type: '@ADDRESSES_LOAD',
      },
    ]);
    axios
      .post(`${URL_ADDRESS}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@ADDRESSES_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const getUserAddress = (values, oldValues, cardAdress = false) => {
  return (dispatch) => {
    if (cardAdress) {
      dispatch(
        initializeForm({
          ...oldValues,
          payment: {
            ...oldValues.payment,
            city: `Carregando...`,
            street: `Carregando...`,
          },
        })
      );
    } else {
      dispatch(
        initializeForm({
          ...oldValues,
          city: `Carregando...`,
          street: `Carregando...`,
        })
      );
    }
    axios
      .post(`${URL_USER_CEP}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        if (cardAdress) {
          dispatch(
            initializeForm({
              ...oldValues,
              payment: {
                ...oldValues.payment,
                ...response.data,
              },
            })
          );
        } else {
          dispatch(
            initializeForm({
              ...oldValues,
              zip_code: response.data.cep,
              ...response.data,
            })
          );
        }
      })
      .catch((e) => {
        if (cardAdress) {
          dispatch(
            initializeForm({
              ...oldValues,
              payment: {
                ...oldValues.payment,
                city: 'CEP inválido',
                street: 'CEP inválido',
              },
            })
          );
        } else {
          dispatch(
            initializeForm({
              ...oldValues,
              city: 'CEP inválido',
              street: 'CEP inválido',
            })
          );
        }
      });
  };
};

export const productOrder = (values, history = undefined) => {
  return (dispatch) => {
    dispatch({
      type: '@BUTTON_LOAD',
      payload: true,
    });
    axios
      .post(URL_REQUEST_PRODUCT_ORDER, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({
          type: '@BUTTON_LOAD',
          payload: false,
        });
        history.push(`/homepage/pedidos/${response.data.id}`);
      })
      .catch((e) => {
        dispatch({
          type: '@BUTTON_LOAD',
          payload: false,
        });
        try {
          if (e.response.data.message) {
            toastr.error(
              'Erro',
              `Não foi possível completar sua transação. Por favor, tente novamente. Motivo: ${e.response.data.message}`
            );
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const getListByUserId = (id) => {
  return (dispatch) => {
    dispatch({ type: 'CREDIT_CARDS_LOAD', payload: true });
    axios
      .get(`${URL_CREDIT_CARDS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'CREDIT_CARDS_FETCHED',
            payload: response,
          },
        ]);
      });
  };
};

export const getProductStock = (product_id) => {
  return (dispatch) => {
    dispatch({ type: '@STOCK_LOAD', payload: true });
    axios
      .get(`${URL_STOCK}/${product_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@STOCK_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const createCreditCard = (values, setStep, userID) => {
  return (dispatch) => {
    dispatch({
      type: '@BUTTON_LOAD',
      payload: true,
    });
    axios
      .post(`${URL_CREDIT_CARDS}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@BUTTON_LOAD',
            payload: false,
          },
          change(
            'userShoppingCartForm',
            'payment.selected_card',
            response.data.id
          ),
          getListByUserId(userID),
        ]);
        setStep((step) => step + 1);
      })
      .catch((e) => {
        dispatch({
          type: '@BUTTON_LOAD',
          payload: false,
        });
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          } else if (e.response.data.message) {
            toastr.error('Erro', e.response.data.message);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const reserveProducts = (values, cleanup) => {
  return (dispatch) => {
    dispatch({
      type: '@BUTTON_LOAD',
      payload: true,
    });
    axios
      .post(URL_PRODUCT_RESERVATION, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@BUTTON_LOAD',
            payload: false,
          },
          {
            type: '@ORDER_RESERVE_TIME',
            payload: response.data.reserveTime,
          },
        ]);
        if (cleanup) cleanup();
      })
      .catch((e) => {
        dispatch({
          type: '@BUTTON_LOAD',
          payload: false,
        });
        try {
          if (e.response.data.message) {
            toastr.error(
              'Erro',
              `Não foi possível continuar. Por favor, tente novamente. Motivo: ${e.response.data.message}`
            );
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};
