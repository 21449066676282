import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dental from './dental';
import DentalAppointmentsForm from './dentalAppointmentsForm';
import DentalContacts from './dentalContacts';
import DentalGeneralInfo from './dentalGeneralInfo';
import DentalInfo from './dentalInfo';
import DentalNews from './dentalNews';
import DentalNewsDetail from './dentalNewsDetail';

export default (
  <Route path="/homepage/servico-odontologico">
    <Switch>
      <Route exact path="/homepage/servico-odontologico" component={Dental} />
      <Route
        path="/homepage/servico-odontologico/orientacoes"
        component={DentalGeneralInfo}
      />
      {/* <Route
      path="conheca"
      component={DentalLearnMore}
    /> */}
      {/* <Route
      path="porto-seguro"
      component={DentalPortoSeguro}
    /> */}
      <Route
        path="/homepage/servico-odontologico/contato"
        component={DentalContacts}
      />
      <Route
        path="/homepage/servico-odontologico/informacoes"
        component={DentalInfo}
      />
      <Route
        path="/homepage/servico-odontologico/noticias"
        component={DentalNews}
      />
      <Route
        path="/homepage/servico-odontologico/noticias/detalhe"
        component={DentalNewsDetail}
      />
      <Route
        path="/homepage/servico-odontologico/agendamentos"
        component={DentalAppointmentsForm}
      />
    </Switch>
  </Route>
);
