import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccountingAppointments from './accountingAppointments';
import AccountingProcesses from './accountingProcesses';
import Accounting from './accounting';
import AccountingContacts from './accountingContacts';
import AccountingEvents from './accountingEvents';
import AccountingInfo from './accountingInfo';
import AccountingNews from './accountingNews';
import AccountingNewsDetail from './accountingNewsDetail';
import AccountingGeneralInfo from './accountingGeneralInfo';

export default (
  <Route path="/homepage/consultoria-contabil">
    <Switch>
      <Route
        exact
        path="/homepage/consultoria-contabil"
        component={Accounting}
      />
      <Route
        path="/homepage/consultoria-contabil/processos"
        component={AccountingProcesses}
      />
      <Route
        path="/homepage/consultoria-contabil/orientacoes"
        component={AccountingGeneralInfo}
      />
      <Route
        path="/homepage/consultoria-contabil/contato"
        component={AccountingContacts}
      />
      <Route
        path="/homepage/consultoria-contabil/noticias"
        component={AccountingNews}
      />
      <Route
        path="/homepage/consultoria-contabil/noticias/detalhe"
        component={AccountingNewsDetail}
      />
      <Route
        path="/homepage/consultoria-contabil/informacoes"
        component={AccountingInfo}
      />
      <Route
        path="/homepage/consultoria-contabil/eventos"
        component={AccountingEvents}
      />
      <Route
        path="/homepage/consultoria-contabil/agendamentos"
        component={AccountingAppointments}
      />
    </Switch>
  </Route>
);
