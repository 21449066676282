import React, { Component } from 'react';
import { connect } from 'react-redux';

class Home extends Component {
  componentDidMount() {
    if (this.props.auth.user.active === 0) {
      this.props.history.push('/confimation');
    } else if (this.props.auth.user.type_id === 2) {
      this.props.history.push('/homepage');
    } else if (this.props.auth.user.type_id === 1) {
      this.props.history.push('/homepage');
    }
  }
  render() {
    return <div></div>;
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Home);
