import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { toastr } from 'react-redux-toastr';
import type from './types';

const URL_APPOINTMENTS = `${BASE_API}/dental/appointments`;
const URL_NEWS = `${BASE_API}/dental/news`;

export const getAppointmentsList = () => {
  return (dispatch) => {
    dispatch({ type: 'APPOINTMENTS_LOAD', payload: true });
    axios
      .get(`${URL_APPOINTMENTS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'APPOINTMENTS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch({ type: 'APPOINTMENTS_LOAD', payload: false });
      });
  };
};

export const setLoading = (value) => {
  return (dispatch) => {
    dispatch([{ type: type.LIST_LOAD, payload: value }]);
  };
};

export const sendAppointmentRequest = (values, cleanup) => {
  return (dispatch) => {
    dispatch({ type: 'DENTAL_APPOINTMENTS_LOAD', payload: true });
    axios
      .post(`${URL_APPOINTMENTS}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([{ type: 'DENTAL_APPOINTMENTS_LOAD', payload: false }]);
        cleanup();
      })
      .catch((e) => {
        dispatch([{ type: 'DENTAL_APPOINTMENTS_LOAD', payload: false }]);
        try {
          toastr.error('Erro', e.response.data.messages[0]);
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const getNewsList = (page, tagId) => {
  return (dispatch) => {
    dispatch({
      type: type.NEWS_LIST_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_NEWS}?page=${page}&tagId=${tagId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({
          type: type.NEWS_LIST_FETCHED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch([
          {
            type: type.NEWS_LIST_LOAD,
            payload: false,
          },
        ]);
      });
  };
};

export const setNewsList = (value) => {
  return (dispatch) => {
    dispatch([{ type: type.NEWS_LIST_SET, payload: value }]);
  };
};
