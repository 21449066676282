import React from 'react';

export default function BarChart(props) {
  return (
    <svg
      width={props.width || '18.3'}
      height={props.height || '13.3'}
      viewBox="0 0 22 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      fill={props.color || 'inherit'}
    >
      <path d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
}
