/* eslint-disable react-hooks/exhaustive-deps */
import ActivitiesCard from 'common/components/cards/activitiesCard';
import Spinner from 'common/components/spinner/spinner';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { COMPANY_STATION_ID } from '../../../../../config/consts';
import { getListByStationId, setLoading } from './newsActions';

export function News(props) {
  useEffect(() => {
    props.getListByStationId(COMPANY_STATION_ID);
  }, []);

  function selectItem(id) {
    props.setLoading(true);
    props.history.push(`/homepage/noticias/${id}`);
  }

  if (props.news.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: '#fff' }}
      >
        <Spinner />
      </div>
    );
  }

  if (_.isEmpty(props.news.list.content)) {
    return (
      <section id="news-list" style={{ minHeight: '100vh' }}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '92vh', backgroundColor: '#fff' }}
        >
          <p>Não há notícias</p>
        </div>
      </section>
    );
  }

  return (
    <section
      id="news-list"
      style={{ backgroundColor: '#fff', height: '100vh' }}
    >
      <div className="container-fluid px-4 pt-4">
        <div className="row" style={{ minHeight: 400 }}>
          {props.news.list.content.map((item, index) => {
            return (
              <div
                className="mb-4 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                style={{ cursor: 'pointer' }}
                onClick={() => selectItem(item.id)}
              >
                <ActivitiesCard
                  haveImage={true}
                  icon={false}
                  image={item.image}
                  title={item.title}
                  text={item.title}
                  callToAction="Ver Mais"
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByStationId,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
