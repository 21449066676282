/* eslint-disable react-hooks/exhaustive-deps */
import CallToActionCard from 'common/components/cards/callToActionCard';
import FeedbackModal from 'common/components/modal/feedbackModal';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  buyCoupon,
  getActionById,
  getLottieById,
  setDetail,
  setFeedbackModal,
  setSubscribed,
} from './activitiesActions';
import DOMPurify from 'dompurify';
import ReactPlayer from 'react-player';
import { Grid, makeStyles } from '@material-ui/core';
import { DomPurifyContentContainer } from './styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  },
}));

function ActivityCoupon(props) {
  const classes = useStyles();

  return (
    <>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <DomPurifyContentContainer
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.activities.detail.description),
            }}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          {props.activities.detail.action_videos.length > 0 ? (
            <Grid item container xs={12} justyfy="center" alignItems="center">
              <ReactPlayer
                config={{
                  file: { attributes: { controlsList: 'nodownload' } },
                }}
                width={'100%'}
                height={isMobile ? '17rem' : '30rem'}
                controls
                className={classes.reactPlayer}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </Grid>
          ) : null}
        </Grid>
        {console.log(props.activities.detail)}
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <CallToActionCard
            public={props.public || false}
            website={props.website}
            history={props.history}
            auth={props.auth}
            noCredits={
              parseFloat(props.auth.points) <
              parseFloat(props.activities.detail.cupons[0].newPrice)
            }
            activity={props.activities.detail}
            onClick={
              props.callToActionLink
                ? () => props.history.push(props.callToActionLink)
                : () => buyCoupon()
            }
          />
        </Grid>
      </Grid>
      {props.activities.feedbackModal ? (
        <FeedbackModal
          visible={props.activities.feedbackModal}
          onCloseRoute="/homepage/minhas-compras"
          history={props.history}
          setFeedbackModal={props.setFeedbackModal}
          firstRoute="/homepage/minhas-compras"
          secondRoute="/homepage"
          firstText={'Ir para minhas compras'}
          secondText="Voltar para a página inicial"
          activity={props.activities.detail}
          firstActionType="route"
          lottie={props.activities.lottieDetail}
          cleanupFunction={() => props.getUserPoints(props.auth.user)}
          {...props}
        />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      buyCoupon,
      setSubscribed,
      setFeedbackModal,
      getLottieById,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCoupon);
