const INITIAL_STATE = {
    list: [],
    loading: false
};

/**
* @param {Object} state - Default application state
* @param {Object} action - Action from action creator
* @returns {Object} New state
*/
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'COMMENTS_BY_POST_LOAD':
            return { ...state, loading: action.payload }
        case 'COMMENTS_BY_POST_FETCHED':
            return { ...state, loading: false, list: action.payload }
        case 'CLEAR_COMMENTS':
            return { ...state, list: action.payload }
        default: return state;
    }
};