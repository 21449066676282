import styled from 'styled-components';
import { BASE_S3 } from 'config/consts';

export const Question = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 120px;
  height: auto;
  border-radius: 6px;
  padding: 20px 25px;
  background: ${(props) =>
    !props.image
      ? props.background
        ? props.background
        : '#333'
      : `url(${BASE_S3}/${props.image})`};
  background-position: center;
  background-size: cover;

  p {
    color: ${(props) => props.textColor};
    text-align: center;
    font-size: 24px;
    margin: 0;
    border: 0;
    resize: none;
    background: none;
    word-break: break-word;
  }
`;

export const TextImage = styled.div`
  color: ${(props) => props.textColor};
  min-height: 40px;
  height: auto;
  width: 100%;
  font-size: 20px;
  text-align: center;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 1px;
  padding: 20px 25px;

  p {
    color: ${(props) => props.textColor};
    text-align: center;
    font-size: 24px;
    margin: 0;
    border: 0;
    resize: none;
    background: none;
    word-break: break-word;
  }
`;
