import React, { Component } from 'react';
import './usePolicyTextStyle.css';
import DOMPurify from 'dompurify';
import moment from 'moment';

export default class UsePolicyText extends Component {
  render() {
    const {
      socialName,
      cnpj,
      address,
      stationName,
      siteAddress,
      createdAt,
      station,
    } = this.props;
    if (station?.terms_of_use_html) {
      return <div  dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(station?.terms_of_use_html),
      }} />;
    }
    if (address && cnpj) {
      return (
        <div
          className="use-policy-text"
          style={{
            backgroundColor: '#FFFF',
            color: '#000',
          }}
        >
          <h2>Termos e condições de uso</h2>
          <p style={{ textAlign: 'right', marginTop: 32 }}>
            Última atualização:{' '}
            {moment(createdAt).format('dddd, D [de] MMMM [de] YYYY')}
          </p>
          <h5>O que é</h5>
          <p>
            O {stationName} é um programa de relacionamento criado e mantido por{' '}
            {socialName}, pessoa jurídica de direito privado, inscrita no CNPJ
            sob o nº{' '}
            {`${cnpj[0]}${cnpj[1]}.${cnpj[2]}${cnpj[3]}${cnpj[4]}.${cnpj[5]}${cnpj[6]}${cnpj[7]}/${cnpj[8]}${cnpj[9]}${cnpj[10]}${cnpj[11]}-${cnpj[12]}${cnpj[13]}`}{' '}
            com sede e foro na cidade de {address.city} - {address.line_1},{' '}
            {address.line_2} - que busca recompensar e reconhecer os
            participantes por meio de ações mantidas por {socialName} e por
            parceiros anunciantes.
          </p>
          <h5>Conta de Acesso</h5>
          <p>
            A adesão ao {stationName} é voluntária, gratuita e aberta a todos os
            filiados ao {socialName}, inscritos no CPF (Cadastro de Pessoa
            Física) e com idade superior a 18 (dezoito) anos. A conta de acesso
            ao {stationName} deverá ser criada pelo próprio participante no
            ambiente digital do programa de relacionamento (aplicativo ou
            website). Os participantes que não possuírem CPF ficarão
            impossibilitados de aderir ao programa de relacionamento{' '}
            {stationName}.
          </p>
          <p>
            Compete unicamente ao participante a responsabilidade integral de
            preencher seus dados cadastrais com informações claras e precisas,
            inclusive de pessoas por ele adicionadas, assumindo, ainda, o
            compromisso de atualizar os referidos dados sempre que neles ocorrer
            alguma alteração. Essas atualizações deverão ser realizadas sempre
            no ambiente digital do Programa de Relacionamento {stationName}.
          </p>
          <p>
            Caso detectada alguma conta de acesso criada a partir de dados,
            conteúdos ou informações falsas, a conta de acesso do Participante
            será imediata e automaticamente bloqueada, de forma que ele não mais
            terá acesso ao Programa de Relacionamento {stationName} e a qualquer
            Serviço ou conteúdo, não assistindo ao participante, por esse
            motivo, qualquer indenização ou ressarcimento.
          </p>
          <p>
            Todas as informações fornecidas pelos participantes são
            confidenciais e estão sujeitas aos termos da Política de
            Privacidade.
          </p>
          <p>
            O participante reconhece e concorda, desde já, que o Programa de
            Relacionamento {stationName} irá obter, tratar e armazenar dados por
            meio de utilização do ambiente digital (aplicativo e site) com o
            objetivo de identificar, segmentar e sugerir ações direcionadas pelo
            {stationName} e por parceiros anunciantes.
          </p>
          <p>
            Caso os parceiros anunciantes recebam informações do participante,
            deverão, igualmente, tratar com confidencialidade e cumprir com os
            termos da Política de Privacidade do Programa de Relacionamento{' '}
            {stationName}.
          </p>
          <h5>Membros Elegíveis</h5>
          <p>
            A indicação dos participantes que são filiados é de responsabilidade
            do {socialName} e se dará por meio da integração dos sistemas de
            gestão do programa de relacionamento e de controle da entidade
          </p>
          <h5>Recompensas e reconhecimento</h5>
          <p>
            O objetivo do Programa de Relacionamento {stationName} é recompensar
            e reconhecer os filiados que participam ativamente e impulsionam o
            {socialName}.
          </p>
          <p>
            A participação no Programa de Relacionamento poderá ser recompensada
            pelo mantenedor das ações veiculadas no {stationName}.
          </p>
          <h5>Ações</h5>
          <p>
            O {stationName}, por meio do seu ambiente digital, veicula ações
            (conteúdos, atividades e oportunidades) criadas e mantidas pelo
            próprio {socialName} e/ou por parceiros anunciantes.
          </p>
          <p>
            A participação em ações do Programa de Relacionamento {stationName}{' '}
            é voluntária. As regras e condições de participação serão
            estabelecidas em cada ação, considerando todas as premissas da
            legislação vigente, deste termo de uso e da Política de Privacidade.
          </p>
          <p>
            Nenhum participante poderá utilizar softwares spider, ou de
            mineração de dados, de qualquer tipo ou espécie, além de outro aqui
            não tipificado, mas que atue de modo automatizado, tanto para
            realizar operações massificadas como para quaisquer outras
            finalidades.
          </p>
          <p>
            Os dados de participação em ações do Programa de Relacionamento
            poderão ser utilizados pelo
            {socialName} e por parceiros anunciantes para envio de ações
            direcionadas, considerando a Política de Privacidade.
          </p>
          <h5>Créditos virtuais</h5>
          <p>
            Os créditos virtuais de {stationName} são destinados à execução de
            transações em moeda virtual, dentro do ambiente digital do{' '}
            {stationName}.
          </p>
          <p>
            Os créditos virtuais de {stationName} podem ser recebidos pelo
            participante que interagir com as ações de relacionamento dentro do
            ambiente digital do {stationName} .
          </p>
          <p>
            O controle de créditos virtuais de {stationName} será efetuado pelo
            próprio participante por meio de extrato de créditos virtuais e
            transações dentro dos canais digitais do {stationName} .
          </p>
          <h5>Saldo de créditos virtuais {stationName}</h5>
          <p>
            Ao realizar a adesão ao {stationName} o participante garante possuir
            Conta Corrente de sua titularidade. Esta conta corrente deverá ser
            utilizada para pedidos de resgate de créditos virtuais. É vedada a
            utilização de conta-salário para pedidos de resgate.
          </p>
          <p>
            O resgate do saldo do {stationName} poderá ser solicitado pelos
            ambientes digitais do programa de relacionamento (Site e
            Aplicativo).
          </p>
          <p>
            Para solicitar Resgate de Saldo o usuário deverá ter atingido R$
            200,00 em créditos virtuais por participação em ações do{' '}
            {stationName} .
          </p>
          <p>
            O resgate dos valores acumulados ou não utilizados pelo participante
            deverá responder aos princípios da razoabilidade e boa-fé.
          </p>
          <p>
            À {socialName} é reservado o direito de bloquear o resgate de
            valores disponíveis na Carteira {stationName} quando identificado
            algum tipo de transação que fuja dos padrões e dos objetivos do
            programa de relacionamento. No caso de bloqueio, será solicitado ao
            participante o envio de cópias autenticadas de documentos que
            permitam a avaliação da transação.
          </p>
          <p>
            O resgate de créditos virtuais recebidos por participação em ações
            poderão ser resgatados desde que tenham atingido o Saldo mínimo de
            R$ 200,00. As transações serão efetivadas em até 7 dias úteis a
            partir da data de solicitação de resgate.
          </p>
          <p>
            <p>
              O resgate de créditos virtuais recebidos por participação em ações
              poderão ser resgatados desde que tenham atingido o Saldo mínimo de
              R$ 200,00. As transações serão efetivadas em até 7 dias úteis a
              partir da data de solicitação de resgate.
            </p>
            <p>
              O Programa de Relacionamento {stationName} será mantido por prazo
              indeterminado, e poderá ser suspendido ou cancelado a qualquer
              momento, sem necessidade de notificação prévia. O participante
              terá prazo de 30 (trinta) dias contados da data de cancelamento do
              programa para solicitar o resgate do saldo de sua carteira. Após
              esse prazo os participantes não poderão mais adquirir o direito de
              resgate do seu saldo residual, e o "{stationName} " não poderá ser
              responsabilizado por nenhum valor de reembolso indicado na conta
              dos participantes, cujo resgate não houver sido solicitado dentro
              do prazo
            </p>
          </p>
          <h5>Ambiente digital do Programa: Portal virtual e aplicativo</h5>
          <p>
            O ambiente digital do {stationName} pode ser acessado por portal
            virtual (Portal) ({siteAddress}) e aplicativo disponíveis nas lojas
            de aplicativo (Play e Apple Store). Nós nos reservamos o direito de:
          </p>
          <ol>
            <li>
              alterar ou remover funcionalidades do programa de relacionamento
              que não estejam alinhadas com seus interesses, bem como de
              adicionar novas que tragam benefícios à utilização do programa de
              relacionamento, sem comunicação prévia ao usuário e sem que lhe
              seja devida qualquer indenização;
            </li>
            <li>
              descontinuar de forma definitiva ou temporária os serviços
              disponibilizados por meio do aplicativo, sem qualquer comunicação
              prévia ao Usuário e sem que lhe seja devida qualquer indenização.
            </li>
            <li>
              3. suspender ou cancelar o cadastro dos usuários, bem como
              qualquer acesso e uso do programa de relacionamento, em caso de
              suspeita de fraude, obtenção de benefício ou vantagem de forma
              ilícita, ou pelo não cumprimento de quaisquer condições previstas
              neste Termo de Uso ou na legislação aplicável. Nesses casos, não
              será devida qualquer indenização ao participante e poderemos
              promover a competente ação de regresso, caso julgue necessário ou
              oportuno, bem como tomar quaisquer outras medidas necessárias para
              perseguir e resguardar seus interesses.
            </li>
          </ol>
          <h5>Limitações de responsabilidade</h5>
          <p>
            A RESPONSABILIDADE PELO CONTEÚDO DAS INFORMAÇÕES PESSOAIS É
            EXCLUSIVA DO PARTICIPANTE. O PARTICIPANTE ENTENDE QUE A UTILIZAÇÃO
            DO PROGRAMA DE RELACIONAMENTO É VOLTADA APENAS PARA FINS LÍCITOS.
            RESERVAMO-NOS O DIREITO DE, IMEDIATAMENTE, INTERROMPER O ACESSO DO
            PARTICIPANTE AO PROGRAMA DE RELACIONAMENTO CASO IDENTIFIQUE A
            FALSIDADE NO CONTEÚDO DAS INFORMAÇÕES PESSOAIS, BEM COMO A MÁ
            UTILIZAÇÃO OU USO INADEQUADO DO PROGRAMA DE RELACIONAMENTO.
          </p>
          <p>
            Cabe única e exclusivamente ao usuário manter seu sistema protegido
            contra vírus e outros malwares. Dessa forma, não nos
            responsabilizamos por qualquer dano causado por vírus e malwares em
            decorrência de acesso, utilização ou navegação no site na internet
            ou como consequência da transferência de dados, arquivos, imagens,
            textos ou áudio contidos no mesmo. É de responsabilidade do usuário
            a certificação de acessar sua conta em redes seguras.
          </p>
        </div>
      );
    } else return null;
  }
}
