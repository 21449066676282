import { Typography, Grid } from '@material-ui/core';
import { COMPANY_STATION_ID } from 'config/consts';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { DefaultCard } from '../../../../common/components/cards/DefaultCard';
import CarouselSkeleton from '../../../../common/components/skeleton/carouselSkeleton';
import { BASE_S3 } from '../../../../config/consts';
import { setLoading } from '../activities/activitiesActions';
import { getActionsByCategory } from '../categories/categoriesActions';

const id = COMPANY_STATION_ID;

let CategoryActions = function ({
  auth,
  getActionsByCategory,
  setLoading,
  categories,
  history,
  match,
}) {
  const { id: categoryRouterID } = useParams();

  useEffect(() => {
    getActionsByCategory(id, auth.user.id, categoryRouterID);
    // eslint-disable-next-line
  }, [categoryRouterID]);
  function selectActivity(id) {
    setLoading(true);
    history.push(`/homepage/atividades/${id}`);
  }

  if (categories.loading) {
    return (
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        xs={12}
        spacing={2}
        style={{
          minHeight: '100vh',
        }}
      >
        {
          Array.from(new Array(5)).map((el) =>
            <Grid item container alignItems="center" justifyContent="center" xs={12}>
              <CarouselSkeleton
                elementsHeight={150}
              />
            </Grid>
          )
        }
      </Grid>
    );
  }

  return (
    <div
      className="container h-100"
      style={{ paddingBottom: '30%', padding: 0, minHeight: '100vh' }}
    >
      {categories.list.content.length > 0 ? (
        <>
            <Typography variant="h5"
            
            style={{
              textAlign: 'center',
              marginTop: 64,
              marginBottom: 48,
            }}>



            {
              categories?.list?.content.filter(
                (category) =>
                  Number.parseInt(category.id) ===
                  Number.parseInt(categoryRouterID)
              )?.[0]?.name
            }
          </Typography>
        </>
      ) : null}
      {categories.list.content.map((category, index) => (
        <div className="row" key={index}>
          {category.category_actions.map((category_action, index) => (
            <div className="col-xs-6 col-md-3 my-3" key={index}>
              <DefaultCard
                onClick={
                  category_action.action.type_id === 13
                    ? () =>
                    (window.location.href =
                      category_action.action.redirect_links[0].url)
                    : () => selectActivity(category_action.action.id)
                }
                imgSource={`${BASE_S3}/${category_action.action.image}`}
                actionName={category_action.action.name}
                labelClick={category_action.action.click_me_label}
                key={index}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    categories: state.categories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionsByCategory,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryActions);
