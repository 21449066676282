/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid } from '@material-ui/core';
import { DefaultCard } from 'common/components/cards/DefaultCard';
import { BASE_S3 } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CarouselSkeleton from '../../../../../common/components/skeleton/carouselSkeleton';
import VoucherModal from '../../../../../common/components/voucherModal';
import { getListByUserId, setLoading } from './vouchersActions';

export function Vouchers(props) {
  useEffect(() => {
    props.getListByUserId(props.auth.user.id);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [voucherItem, setItem] = React.useState(undefined);

  function selectItem(item) {
    setOpen(true);
    setItem(item);
  }

  if (props.vouchers.loading) {
    return (
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        xs={12}
        spacing={2}
        style={{
          minHeight: '100vh',
        }}
      >
        {Array.from(new Array(5)).map((el) => (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            xs={12}
          >
            <CarouselSkeleton elementsHeight={150} />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (_.isEmpty(props.vouchers.list.content)) {
    return (
      <section id="vouchers-list">
          <div
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: 'transparent',
            height: '248px',
          }}
        >
        <Typography variant="h5">Meus vouchers</Typography>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
          
            backgroundColor: 'transparent',
          }}
        >
          <Typography variant="h6">Você ainda não possui vouchers</Typography>
        </div>
      </section>
    );
  }

  return (
    <>
      <section
        id="vouchers-list"
        style={{
          backgroundColor: 'transparent',
          height: '100vh',
        }}
      >
                  <div
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: 'transparent',
            height: '248px',
          }}
        >
        <Typography variant="h5">Meus vouchers</Typography>

        </div>
        <div className="container-fluid px-4 pt-4">
          <div className="row">
            {props.vouchers.list.content.map((item, index) => {
              return (
                <div
                  className="mb-4 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => selectItem(item)}
                >
                  <DefaultCard
                    imgSource={`${BASE_S3}/${item.presential_course.action.image}`}
                    actionName={moment(item.presential_course.createdAt).format(
                      'LLLL'
                    )}
                    labelClick="Ver Voucher"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {voucherItem ? (
        <VoucherModal open={open} setOpen={setOpen} item={voucherItem} />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    vouchers: state.vouchers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByUserId,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Vouchers);
