import type from './types';

const INITIAL_STATE = {
  loading: true,
  list: {
    content: [],
  },
  detail: {},
  appointmentsList: [],
  appointmentsLoading: false,
  newsList: [],
  newsLoading: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.LIST_FETCHED:
      return {
        ...state,
        list: { content: action.payload.data },
        loading: false,
      };
    case type.LIST_LOAD:
      return { ...state, loading: action.payload };
    case type.ITEM_DETAIL:
      return {
        ...state,
        detail: action.payload.data[0],
        loading: false,
      };
    case type.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    case 'LEGAL_PROCESSES_FETCHED':
      return {
        ...state,
        processesList: action.payload,
        processesLoading: false,
      };
    case 'LEGAL_PROCESSES_LOAD':
      return {
        ...state,
        processesLoading: action.payload,
      };
    case 'LEGAL_APPOINTMENTS_FETCHED':
      return {
        ...state,
        appointmentsList: action.payload,
        appointmentsLoading: false,
      };
    case 'LEGAL_APPOINTMENTS_LOAD':
      return {
        ...state,
        appointmentsLoading: action.payload,
      };
    case type.NEWS_LIST_FETCHED:
      return {
        ...state,
        newsList: [...state.newsList, ...action.payload],
        newsLoading: false,
      };
    case type.NEWS_LIST_LOAD:
      return { ...state, newsLoading: action.payload };
    case type.NEWS_LIST_SET:
      return { ...state, newsList: action.payload };
    default:
      return state;
  }
};
